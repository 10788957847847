import { Grid } from "@mui/material";
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types'
import ViewOffers from "./ViewOffers";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import {isObjectEmpty} from '../../../util/generalHelp'
import OfferForm from "./OfferForm";

/**
 * Page responsible for handling Offer Letters
 * @component
 * @category Pages
 * @subcategory Admin
 */
function ManageOffers(props){
    
    const unvoidable = ['voided', 'deleted', 'completed']

    const [hasLoaded, setHasLoaded] = useState(false)
    const [offerLetters, setOfferLetters] = useState([])
    // const [signingAuth, setSigningAuth] = useState([])
    // const [clients, setClients] = useState([])
    const [errorObj, setErrorObj]  = useState({})

    const handleError = (errorObject=undefined) => {
        try{
            setErrorObj({errorTitle: errorObject.errorTitle, errorContent:errorObject.errorContent})
        }catch{
            setErrorObj({errorTitile: undefined, errorContent:undefined})
        }
    }

    // const setInfo = (abortCont=undefined) => {
    //     getOfferInfo(abortCont, (json)=>{
    //         if(json){
    //             console.log('json',json)
    //             setOfferLetters(json.offer_letters)
    //             setSigningAuth(json.authorities)
    //             setClients(json.clients)
    //             setHasLoaded(true)
    //         }else{
    //             handleError()
    //         }
            
    //     })
    // }

    const updateOffers = (id, key, value) => {
        let allOffers = [...offerLetters]
        const offerIndex = allOffers.findIndex(offer => offer.id === id)
        let newOffer = {...allOffers[offerIndex]}

        newOffer[key] = value

        allOffers[offerIndex] = newOffer
        setOfferLetters(allOffers)
        
    }
    
    return(
    <Grid container sx={{justifyContent:'center',alignItems:'center'}}>
        <ErrorPopUp errorContent={errorObj.errorContent} errorTitle={errorObj.errorTitle} open={!isObjectEmpty(errorObj)}
            onClose={(e) => {e.preventDefault(); setErrorObj({})}}/>
        <Grid container sx={{justifyContent:'center',alignItems:'center', height:'30%'}}>
            <OfferForm authorities={props.authorities} clients={props.clients}
            states={props.states} venues={props.venues}
            contacts={props.contacts} onComplete={props.onComplete}  onError={handleError}/>
        </Grid>
        <Grid container sx={{justifyContent:'center',alignItems:'center', height:'65%', width:'100%'}}>
            <ViewOffers offers={props.offerLetters} onError={handleError} updateOffers={updateOffers} unvoidableStatuses={unvoidable}/>
        </Grid>
    </Grid>
    )
}

ManageOffers.propTypes={
    /**
     * profile of currently signed in user
     */
    profile: PropTypes.object.isRequired
}

export default ManageOffers