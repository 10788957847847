import React,{useEffect, useState} from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  CardActionArea,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChangePassword from "./ChangePassword";
import PropTypes from 'prop-types'
import ChangeReminders from './ChangeReminders'

/**
 * accordion used to manage all options in the general category of settings
 * @component
 * @category Settings
 * @subcategory General Settings
 */
function GeneralSettings(props) {
    const [openFunctionality, setOpenFunctionality] = useState("")
    const [expandedComponent, setExpandedComponent] = useState();

    const handleOpen = (panel) => (event, isExpanded) => {
        setExpandedComponent(isExpanded ? panel : false);
      };
    
    useEffect(()=>{
      console.log('openFunctionality',openFunctionality)
    },[openFunctionality])

  return (
    <>
      <Accordion
        expanded={expandedComponent === "password"}
        onChange={handleOpen("password")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            General settings
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Settings pertaining to account, password, etc
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CardActionArea
            sx={{
              padding: "2%",
              cursor: "pointer",
              borderTop: "1px solid grey",
              borderBottom: "1px solid grey",
            }}
            onClick={(event) => setOpenFunctionality('password')}
          >
            <Typography>Change Password</Typography>
          </CardActionArea>
          <ChangePassword
            open={openFunctionality === 'password'}
            onClose={(event) => {
              event.preventDefault();
              setOpenFunctionality("");
            }}
            updateStatus={props.updateStatus}
          />
          <CardActionArea
            sx={{
              padding: "2%",
              cursor: "pointer",
              borderTop: "1px solid grey",
              borderBottom: "1px solid grey",
            }}
            onClick={(event) => setOpenFunctionality('reminder')}
          >
            <Typography>Change Reminders</Typography>
          </CardActionArea>
          <ChangeReminders
            open={openFunctionality === 'reminder'}
            onClose={(event) => {
              event.preventDefault();
              setOpenFunctionality("");
            }}
            profile={props.profile}
            updateStatus={props.updateStatus}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

GeneralSettings.propTypes={
  /**
   * function used to update the status of parent component, returns whether or not update was successful and string alongisde it
   */
  updateStatus: PropTypes.func.isRequired,
  /**
   * profile of user who's currently logged in (Resource object that follows db schema)
   */
  profile: PropTypes.object.isRequired
}
export default GeneralSettings;