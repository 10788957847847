import React, {useEffect, useState, useMemo} from "react";
import { DataGrid, GridRowsProp, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import { getAmplifyToken } from "../../../util/AmplifyHelp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { Typography } from "@mui/material";


const columns = [
  {
    field: 'first_name',
    headerName: 'First Name',
    
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    
  },
  {
    field: 'primary_email',
    headerName: 'Primary Email',
    
  },
  {
    field: 'short_name',
    headerName: 'Client',
    
  },
  {
    field: 'project_name',
    headerName: 'Project Name',
    
  },
  {
    field: 'hours',
    headerName: 'Standard Hours',
    
  },
  {
    field: 'vacation_hours',
    headerName: ' Vacation Hours',
    
  },
  {
    field: 'holiday_hours',
    headerName: 'Holiday Hours',
    
  },
  {
    field: 'sick_hours',
    headerName: 'Sick Hours',
    
  },
  {
    field: 'month',
    headerName: 'Month',
    
  },
  {
    field: 'year',
    headerName: 'Year',
   
  },
  {
    field: 'billing_entity',
    headerName: 'Billing Entity',
    
  },
  {
    field: 'paying_entity',
    headerName: 'Paying Entity',
    
  },
  {
    field: 'pay_currency',
    headerName: 'Pay Currency',
    
  },
  {
    field: 'bill_currency',
    headerName: 'Bill Currency',
    
  },
  {
    field: 'pay_type',
    headerName: 'Pay Type',
    
  },
  {
    field: 'recruiter',
    headerName: 'Recruiter',
    
  },
  {
    field: 'account_manager',
    headerName: 'Account Manager',
    
  },
  {
    field: 'sourcer',
    headerName: 'Sourcer',
    
  },
  {
    field: 'sales',
    headerName: 'Sales',
    
  },
];

export function ReportPage({}) {
    const [data, setData] = useState([])
    const[loading, setLoading] = useState(false)
    const[refresh, setRefresh] = useState(true)
    const [pageSize, setPageSize] = useState(15);
    useEffect(() => {console.log('data',data)}, [data])
    useEffect(() => {
        setLoading(true)
        let isMounted = true; 
        getAmplifyToken((jwt) => {
          fetch(`${process.env.REACT_APP_ADMIN_API}/admin/report-monthly-hours`,{
            headers: {Authorization: jwt}
          })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {

            if (isMounted){
              let newData = {}
            newData = data.map((data, i )=>({
              ...data,
              id: i
            }))
            setData(newData);
            setLoading(false)
            }
          })
          .catch((err) => {console.error(err); setLoading(false)});
        })
        
        
      }, [refresh]);



  return (<>
  <Typography sx={{marginTop:'10px'}} align="center" variant="h4" component="div" gutterBottom>Reports</Typography>
  <div>
    <DataGrid
      autoHeight={true}
      getRowId={(row) => row.id}
      rows={data}
      columns={columns}
      components={{ Toolbar: GridToolbar }}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 15, 25]}
      checkboxSelection={true}
      sx={{
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        margin: '10px',
        overflowX: 'scroll'
      }}
    />
  </div>
  </>)

}