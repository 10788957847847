import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, TextField } from "@mui/material";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import validator from "validator";
import "./Clients.css";

const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  padding: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const CreateNewClient = ({ refresh, setRefresh }) => {
  const [open, setOpen] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const [toggle, editToggle] = useState();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [newEntry, editNewEntry] = useState({
    short_name: "",
    ap_email: "",
    contact_email: "",
    contact_first_name: "",
    contact_last_name: "",
  });

  const updateEntry = (field, value) => {
    let temp = {
      ...newEntry,
    };
    temp[field] = value;
    editNewEntry(temp);
  };
  const updateContact = (e) =>{
    const onlyDigits = e.replace(/\D/g, "");
    updateEntry("contactNumber", onlyDigits)

  };
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      if (newEntry.short_name !== "" || newEntry.ap_email !== "") return;
    }
    setOpen(false);
    editNewEntry({
      short_name: "",
      ap_email: "",
      contact_email: "",
      contact_first_name: "",
      contact_last_name: "",
    });
  };

  const onSaveHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      newEntry.short_name === "" ||
      newEntry.ap_email === "" ||
      newEntry.contact_email === ""
    ) {
      setLoading(false);
      setOpenStatus(true);
      setMessage("Please complete the required sections of the form!");
    } else if (
      !validator.isEmail(newEntry.ap_email) ||
      !validator.isEmail(newEntry.contact_email)
    ) {
      console.log("2");
      setLoading(false);
      setOpenStatus(true);
      setMessage("Invalid email format. Please try again!");
    }
    // else if (newEntry.contact_email !== '') {
    //     if (!newEntry.contact_email.match(emailValid)) {
    //         console.log("3")
    //         setLoading(false)
    //         setOpen(true)
    //         setMessage("Invalid email format. Please try again!")
    //     }
    // }
    else {
      getAmplifyToken((jwt) => {
        fetch(`${process.env.REACT_APP_ADMIN_API}/admin/clients-api`, {
          method: "POST",
          body: JSON.stringify({
            short_name: newEntry.short_name,
            ap_email: newEntry.ap_email,
            contact_email_1: newEntry.contact_email,
            contact_first_name: newEntry.contact_first_name,
            contact_last_name: newEntry.contact_last_name,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: jwt,
          },
        }).then((response) => {
          setLoading(false);
          if (response.ok) {
            setSuccess(true);
            setMessage(newEntry.short_name + " has been added as a client!");
            handleClose();
          } else {
            setOpenStatus(true);
            setMessage(
              "Could not add to the database. Client name may already exist"
            );
          }
        });
      });
    }
  };

  return (
    <div className="entry-container">
      <TextPopUp
        title="Success!"
        content={message}
        open={success}
        onClose={(event) => {
          event.preventDefault();
          setSuccess(false);
          setRefresh(!refresh);
        }}
        fullWidth
      />
      <ErrorPopUp
        open={openStatus}
        onClose={(e) => {
          e.preventDefault();
          setOpenStatus(false);
        }}
        errorContent={message}
      />
      <LoadingPopUp open={loading} />
      <Button onClick={handleOpen} variant="outlined">
        Create New Client
      </Button>
      <Modal
        sx={{ zIndex: "1100" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={10} alignSelf='center'>
                <Typography
                  fontSize={'18px'}
                  align="center"
                  fullWidth
                  fontWeight={'bold'}
                >
                  Enter Client Information
                </Typography>
              </Grid>
              <Grid item xs={1}>
              <Box display='flex' justifyContent='flex-end'>
                <IconButton
                  fullWidth
                  sx={{ display: "flex" }}
                  variant="contained"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
                </Box>
              </Grid>
            </Grid>
          
          {
            <div className="values-container">
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="Short Name"
                mode="outlined"
                required
                onChange={(event) => {
                  updateEntry("short_name", event.target.value);
                }}
              />
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="AP Email"
                mode="outlined"
                required
                onChange={(event) => {
                  updateEntry("ap_email", event.target.value);
                }}
              />
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="Contact Email"
                mode="outlined"
                required
                onChange={(event) => {
                  updateEntry("contact_email", event.target.value);
                }}
              />
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="Contact First Name"
                mode="outlined"
                onChange={(event) => {
                  updateEntry("contact_first_name", event.target.value);
                }}
              />
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="Contact Last Name"
                mode="outlined"
                onChange={(event) => {
                  updateEntry("contact_last_name", event.target.value);
                }}
              />
            </div>
          }
          {
            <div className="button-container">
              <Button
                sx={{ width: "40%", backgroundColor:'#ff6600', color: 'white', borderColor:'#ff6600', '&:hover': {
                  backgroundColor: '#fff',
                  color: '#ff6600'}}}
                variant="contained"
                onClick={onSaveHandler}
              >
                Save
              </Button>{" "}
            </div>
          }
        </Box>
      </Modal>
    </div>
  );
};
