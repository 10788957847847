import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import React, {useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import HoverButton from '../HoverButton';
import PropTypes from 'prop-types'
import {fonts} from '../../util/Theme'

/**
 * drop down that lists the different pages an admin and consultant has access to in regards to timesheet and navigates to them
 * @component 
 * @category Headers and Footers
 * @subcategory NavBar
 */
function ExpenseTile(props) {
    const[openDropDown, setOpenDropDown] = useState(false)
    const[anchoredElement,setAnchoredElement] = useState(false)

    const navigate = useNavigate()
    const ref = useRef()

    const handleClick = (event) =>{
        event.preventDefault()
        setOpenDropDown(true)
        setAnchoredElement(event.target)
    }

    const navAndClose = (url) => {
        navigate(url);
        setOpenDropDown(false)
    }

    return (
        <>
            <HoverButton ref={ref} onClick={handleClick} color={props.selected || openDropDown? props.selectedColor : props.color} hoverColor={props.hoverColor}>Expense Forms</HoverButton>
            <Menu open={openDropDown} anchorEl={anchoredElement} onClose={(event)=>setOpenDropDown(false)}
            PaperProps={{style:{minWidth:ref.current ? ref.current.offsetWidth + (ref.current.offsetWidth / 100) : 0}}}>
                {/* <MenuItem disabled><Typography variant='body1' fontSize={fonts.menuSize}>Timesheet Management</Typography></MenuItem> */}
                {props.role == 'admin' && (props.roles['expense_admin'] === true || props.roles['admin'] === true) ? 
                <div>
                <MenuItem onClick={(event)=>navAndClose("/home/admin/expenses")}>
                    <Typography fontSize={fonts.menuSize}>View Expense Forms</Typography>
                </MenuItem>
                <Divider/>
                </div>:<div></div>}
                    <MenuItem onClick={(event)=>navAndClose("/home/expenses")}>
                        <Typography fontSize={fonts.menuSize}>Submit Expense Form</Typography>
                    </MenuItem>
            </Menu>
        </>
    );
}

ExpenseTile.propTypes = {
    /**
     * whether or not TimesheetTile has been clicked on
     */
    selected: PropTypes.bool,
    /**
     * color TimesheeTile turns after having being clicked
     */
    selectedColor: PropTypes.string,
    /**
     * standard color of TimesheetTile
     */
    color: PropTypes.string,
    /**
     * color of TimesheetTile when hovered over by mouse
     */
    hoverColor: PropTypes.string,
    /**
     * role of the current user
     */
    role : PropTypes.string.isRequired,
    /**
     * roles that the user currently has access to
     */
    roles: PropTypes.object.isRequired
}
ExpenseTile.defaultProps = {
    selectedColor: 'secondary.main',
    selected:false,
    role: 'user'
}

export default ExpenseTile;