import React, {useState} from 'react';
import PropTypes from 'prop-types'
import { Avatar,Box, Button, Dialog, DialogContent, DialogTitle,Typography } from '@mui/material';
import { isFileImage, isObjectEmpty } from '../../util/generalHelp';
import { uploadPfp } from '../../util/ProfileHelper';
import ErrorPopUp from '../Pop_ups/ErrorPopUp';

/**
 * component responsible for uploading profile pictures to s3
 * @component
 * @category Settings 
 */
function UploadPfp(props) {
    const [pfp, setPfp] = useState({})
    const [fileError, setFileError] = useState(false)
    const [error, setError] = useState(false)

    const handleFile = (event) =>{
        let file = event.target.files[0]
        if(!file){
            return
        }
        
        if(Boolean(isFileImage(file))){
            setPfp(file)
            setFileError(false)
        }else{
            setFileError(true)
        }
        
    }

    const submitPfp = () => {
        if(!isObjectEmpty(pfp)){
            uploadPfp(props.profile.id, pfp, (resp)=>{
                if(!resp){
                    setError(true)
                }else{
                    props.onSuccess()
                    props.onClose()
                }
            })
        }else{
            props.onClose()
        }
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth>
            <ErrorPopUp open={error} onClose={(event)=>{event.preventDefault(); setError(!error)}}/>
            <DialogTitle>
                <Typography align='center' variant='h5'>Upload Your Profile Picture</Typography>
            </DialogTitle>
            <DialogContent sx={{justifyContent:'center',alignItems:'center', display:'flex', flexDirection:'column'}}>
            <Button variant='outlined'
            component='label'
            align='center'
            >
                Upload Profile Picture
            <input type='file' hidden onChange={handleFile}/>
            </Button>
            {pfp && !isObjectEmpty(pfp)? <Box sx={{justifyContent:'center',alignItems:'center', display:'flex', flexDirection:'column'}}>
                    {pfp.name} 
                    <Avatar src={URL.createObjectURL(pfp)}/>
                </Box> : <></>}

            {fileError ? <Typography color='error'>File Must Be An Image</Typography> : <></>}
            
            </DialogContent>
            <Box sx={{justifyContent:'center',alignItems:'center', display:'flex', flexDirection:'row'}}>
                <Button variant='outlined'sx={{m:'0.5%'}} onClick={(event)=> {event.preventDefault(); props.onClose()}}>Cancel</Button>
                <Button variant='outlined' sx={{m:'0.5%'}} onClick={(event)=>{event.preventDefault(); submitPfp()}}>Submit</Button>
            </Box>
        </Dialog>
    );
}

UploadPfp.propTypes = {
    /**
     * whether or not the dialog for upload pfp is open
     */
    open: PropTypes.bool.isRequired,
    /**
     * function called for closing uploadpfp
     */
    onClose: PropTypes.func.isRequired,
    /**
     * profile of user to upload picture, (object that follows resource schema in db)
     */
    profile: PropTypes.object.isRequired,
    /**
     * function to be called on a successful upload
     */
    onSuccess: PropTypes.func.isRequired
}


export default UploadPfp;