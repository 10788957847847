import React, { useState} from "react";
import { Button} from "@mui/material";
import TextPopUp from '../../../components/Pop_ups/TextPopUp'
import ErrorPopUp from '../../../components/Pop_ups/ErrorPopUp'
import { getAmplifyToken } from "../../../util/AmplifyHelp";

export const AppCredentialsResend = ({email, refresh, setRefresh}) => {
  
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCreateButton = (email) => {
    // fetch(`${process.env.REACT_APP_ADMIN_API}/admin/create-user`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     email: email,
    //   }),
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8",
    //   },
    // });
    getAmplifyToken((jwt)=>{
      fetch(`${process.env.REACT_APP_ADMIN_API}/admin/create-user`, {
        method: 'PATCH',
        body: JSON.stringify({
            email: email
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": jwt
        }
      }
      
    )
    .then((response) => {
      if (response.ok) {
          setSuccess(true)
          setMessage("Temporary password has been sent to email")
      
      }
      else {
          setOpen(true)
          setMessage("Error in sending temporary password")
      }
    })
    
  });
    
  };
  
  

  return (
    <>
    <TextPopUp title="Success!" content={message} open={success} onClose={(event)=>{event.preventDefault(); setSuccess(false);setRefresh(!refresh)}} fullWidth/>
    <ErrorPopUp open={open} onClose={(e) => {e.preventDefault(); setOpen(false)}} errorContent={message} />
    <Button  variant='outlined' onClick={()=>{handleCreateButton(email)}}>Resend</Button>
    </>
  );
};
