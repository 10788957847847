import React, { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import { Paper, TableContainer, IconButton, Typography, Select, MenuItem} from "@mui/material";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import "./Resource.css";
import { GlobalFilter } from "./GlobalFilter";
import { EditableRow } from "./EditableRow";
import { AppCredentialsCreate } from "./AppCredentialsCreate";
import { useNavigate } from "react-router-dom";
import { UserStatus } from "../../../lib/users.constants";
import { AppCredentialsResend } from "./AppCredentialsResend";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import { CreateNewUser } from "./CreateNewUser";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import RoleChecklist from "../../../components/admin/RoleChecklist";

export function DisplayTable({ data, columns, refresh, setRefresh,updateData, profile }) {
  const [isActive, setIsActive] = useState(false);
  const [users, setUsers] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [rowId, setRowId] = useState('');
  const [editValues, setEditValues] = useState({
    id: "",
    firstName: "",
    lastName: "",
    primaryEmail: "",
    secondaryEmail: "",
    category: "4",
    hireDate: "",
    location: "",
    contactNumber: "",
    role_obj: {}
  });

  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    selectedFlatRows,
    pageOptions,
    state,
    setGlobalFilter,
    visibleColumns,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
        hiddenColumns: ["id", "name", "resource_category", "type"],
      },
      autoResetExpanded  :false
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const { globalFilter, expanded, pageIndex, pageSize } = state;

  useEffect(() => {
    getAmplifyToken((jwt) => {
      fetch(`${process.env.REACT_APP_ADMIN_API}/admin/create-user`,
      {headers:{'Authorization':jwt}})
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        setUsers(data || []);
        setFetched(true);
      });
    })
    
  }, [data]);
  const compareEmails = (email) => {
    let foundUser = users.find((element) => element.Username === email);
    if (fetched) {
      if (!foundUser) {
        return (
          <AppCredentialsCreate
            email={email}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
      }
      switch (foundUser.UserStatus) {
        case UserStatus.CONFIRMED:
          return <Typography>CONFIRMED</Typography>;
        case UserStatus.FORCEPASS:
          return (
            <AppCredentialsResend
              email={email}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          );
        default:
          return <Typography>BROKEN</Typography>;
      }
    }
    return <Typography>Loading</Typography>;
  };
  const handleViewClick = (cell) => {
    navigate("/home/admin/users/view-user", {
      state: {
        category: cell.row.values.category,
        contactNumber: cell.row.values.contactNumber,
        firstName: cell.row.values.firstName,
        hireDate: cell.row.values.hireDate,
        id: cell.row.values.id,
        lastName: cell.row.values.lastName,
        location: cell.row.values.location,
        primaryEmail: cell.row.values.primaryEmail,
        secondaryEmail: cell.row.values.secondaryEmail,
        name: cell.row.values.name,
      },
    });
  };

  const updateRoleObj = (roleObj, index) => {
    console.log('data',data)
    let newData = [...data]
    newData[index].role_obj = roleObj
    console.log('newData',newData)
    // //call setdata from parent
    updateData(newData)
  }

  return (
    <div className="display-container">
      <TextPopUp
        title="Success!"
        content={message}
        open={success}
        onClose={(event) => {
          event.preventDefault();
          setSuccess(false);
          setRefresh(!refresh);
        }}
        fullWidth
      />
      <ErrorPopUp
        open={open}
        onClose={(e) => {
          e.preventDefault();
          setOpen(false);
        }}
        errorContent={message}
      />
      <Typography align="center" variant="h4" component="div" gutterBottom>
        Users
      </Typography>
      <div className="entrycontainer">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <CreateNewUser
          refresh={refresh}
          setRefresh={setRefresh}
          profile={profile}
        ></CreateNewUser>
      </div>
      <TableContainer sx={{ marginTop: 1 }} component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    align="center"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
              <>
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <>
                        {isEditable && index === rowId ? (
                          <EditableRow
                          cell={cell}
                          isEditable={isActive}
                          setIsActive={setIsActive}
                          setIsEditable={setIsEditable}
                          setRowId={setRowId}
                          selectedRow={selectedFlatRows}
                          editValues={editValues}
                          setEditValues={setEditValues}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          roleObj={row.original.role_obj || {}}
                        ></EditableRow>
                        ) 
                        :(<TableCell
                          key={i}
                          align={"center"}
                          {...cell.getCellProps()}
                        >
                          {cell.column.id === "edit" ? (
                            <IconButton
                              {...cell.row.getToggleRowExpandedProps()}
                              onClick={() => {
                                if (!isActive) {
                                  setIsEditable(true)
                                  setIsActive(true);
                                  setRowId(index)
                                  cell.row.toggleRowSelected();
                                } else {
                                }
                              }}
                            >
                              <EditIcon sx={{ fontSize: 20, margin: "2%" }} />
                            </IconButton>
                          ) : cell.column.id === "credential" ? (
                            compareEmails(cell.row.values.primaryEmail)
                          ) : (
                            <span>{cell.render("Cell")}</span>
                          )}
                        </TableCell>)
                        // : (
                        //   <TableCell align={"center"} {...cell.getCellProps()}>
                        //     {cell.column.id === "edit" ? (
                        //       <IconButton
                        //         // {...cell.row.getToggleRowExpandedProps()}
                        //         // onClick={() => {
                        //         //   cell.row.toggleRowSelected();
                        //         //   handleViewClick(cell);
                        //         // }}
                        //         {...cell.row.getToggleRowExpandedProps()}
                        //         onClick={() => {
                        //           if (!isActive) {
                        //             setIsActive(true);
                        //             cell.row.toggleRowExpanded();
                        //             cell.row.toggleRowSelected();
                        //           } else {
                        //           }
                        //         }}
                        //       >
                        //         <EditIcon sx={{ fontSize: 20, margin: "2%" }} />
                        //       </IconButton>
                        //     ) : cell.column.id === "credential" ? (
                        //       compareEmails(cell.row.values.primaryEmail)
                        //     ) : (
                        //       <span>{cell.render("Cell")}</span>
                        //     )}
                        //   </TableCell>
                        // )
                        }
                      </>
                    );
                  })}
                </TableRow>
                {row.isExpanded && isEditable && index === rowId? (
                    <TableRow className="details-background">
                      <TableCell colSpan={visibleColumns.length}>
                        <RoleChecklist admin={true} row={true} onUpdate={(roleObj) => {updateRoleObj(roleObj,index);}} 
                        roleObj={row.original.role_obj || {}} profile={profile} resource_id={row.values.id}/>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          </TableBody>
        </Table>
        <div className="pagination">
          <span className="page-selector">
            <Typography sx={{fontSize: '0.875rem'}}>Rows Per Page:</Typography>
            <Select
            sx={{marginRight: '24px', marginLeft: '8px', fontSize: '0.875rem'}}
              disableUnderline
              variant="standard"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 15, 25, 50].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
            <Typography {...getTableBodyProps()} sx={{fontSize: '0.875rem', marginRight:'24px'}}>
              {pageIndex + 1}{"-"}{page.length} of {data.length}
            </Typography>{" "}
          </span>
          <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ChevronLeftIcon/>
          </IconButton>
          <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <ChevronRightIcon/>
          </IconButton>
        </div>
      </TableContainer>
    </div>
  );
}
