import { Button, Dialog, Grid, Box, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import ResourceDetailsPanel from "./ResourceDetailsPanel";

/**
 * Modal used to Manage ResourceDetails when they are being added to a project
 * @component
 * @category Resource Details Management
 */
function ManageResourceModal(props){
    const [addedDetails, setAddedDetails] = useState(props.addedDetails ? props.addedDetails : [])
    const [filteredAdded, setFilteredAdded] = useState([])
    const [details, setDetails] = useState(props.details ? props.details : [])
    const [filteredDetails, setFilteredDetails] = useState([])
    const [toDelete, setToDelete] = useState(props.toDelete ? props.toDelete : [])
    const [searchString, setSearchString] = useState("");
    useEffect(()=>{
        //console.log('addedDetails',props.addedDetails)
        setAddedDetails(props.addedDetails ? props.addedDetails : [])
    },[props.addedDetails])

    useEffect(()=>{
        setDetails(props.details ? props.details : [])
    },[props.details])

    useEffect(()=>{
        setToDelete(props.toDelete ? props.toDelete : [])
    },[props.toDelete])

    useEffect(()=>{
        filterDetails(searchString)
    }, [addedDetails, details])

    //remove from pool of potential details and add to pool of addedDetails
    const updateAddedDetails = (detail) => {
        //get rid of item from toDelete if it exists
        if(toDelete.length > 0){
            let newDelete = toDelete.filter(det => det !== detail.details_id)
            setToDelete(newDelete)
        }
        

        //dubtract the item from details
        let newDetails = details.filter(det => det.resource_id !== detail.resource_id)
        setDetails(newDetails)
        
        
        //add the item to addedDetails
        let newAdded = [...addedDetails]
        newAdded.push(detail)
        setAddedDetails(newAdded)
    }

    //remove from addedDetails and add to the poll of potential details
    const updateDetails = (detail) => {
        
        //add to array of toDelete if details id exist
        if(detail.details_id){
            let newDelete = [...toDelete]
            newDelete.push(detail.details_id)
            setToDelete(newDelete)
        }
        
        //subtract the item from addedDetails
        const newAdded = addedDetails.filter(det => det.resource_id !== detail.resource_id)
        setAddedDetails(newAdded)

        //add item to pool of details
        const newDetails = [...details]
        newDetails.push(detail)
        setDetails(newDetails)
        
    }

    function filterDetails(str){
        let newDet = details.filter((det) => {
            let name = det.first_name + " " + det.last_name
            return (name.toUpperCase().includes(str.toUpperCase())
            || det.primary_email.toUpperCase().includes(str.toUpperCase()))
        })

        let newAdded = addedDetails.filter((det) => {
            let name = det.first_name + " " + det.last_name
            return (name.toUpperCase().includes(str.toUpperCase())
            || det.primary_email.toUpperCase().includes(str.toUpperCase()))
        })

        setSearchString(str);
        setFilteredAdded(newAdded);
        setFilteredDetails(newDet);
    }

    return(
        <Dialog open={props.open} onClose={() => {props.onSave(addedDetails, toDelete); props.onClose(); setSearchString("")}} fullScreen 
        sx={{padding:'2.5%'}} PaperProps={{style:{borderRadius:5}}}>
            <Grid container sx={{padding:'0.25%', width:'100%', height:'100%'}}>
                <Grid item container xs={12} sx={{padding:"0.25%", justifyContent:'center', alignItems:'center'}}>
                    <Grid item container xs={8}>
                        <TextField label="Search" value={searchString} onChange={e => {e.preventDefault(); filterDetails(e.target.value)}}
                        fullWidth/>
                    </Grid>
                </Grid>
                <Grid item container xs={5.5}  sx={{width:'100%', height:'80%',padding:'1%'}}>
                    <ResourceDetailsPanel details={filteredAdded} direction="right" onUpdate={updateDetails} title="Added Resources"/>
                </Grid>
                <Grid item xs={1}/>
                <Grid item container xs={5.5} sx={{width:'100%', height:'80%',padding:'1%'}}>
                    <ResourceDetailsPanel details={filteredDetails} direction="left" onUpdate={(updateAddedDetails)} title="Potential Resources"/>
                </Grid>
                <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                    <Button variant='outlined' onClick={(event) => {event.preventDefault(); props.onSave(addedDetails,toDelete); props.onClose(); setSearchString("")}}>
                        OK
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}

ManageResourceModal.propTypes = {
    /**
     * bool indicating whether or not the modal is open
     */
    open:PropTypes.bool.isRequired,
    /**
     * function called when modal is closed
     */
    onClose: PropTypes.func.isRequired,
    /**
     * function called to save resource Details, also called when modal is closed or when save button is clicked
     */
    onSave: PropTypes.func.isRequired,
    /**
     * Array of ProjectDetails (objects following db schema).  Representing all the details already added to the project
     */
    addedDetails: PropTypes.arrayOf(PropTypes.object),
    /**
     * Array of all remaining ProjectDetails (objects following db schema) that have yet to be added
     */
    details: PropTypes.arrayOf(PropTypes.object),
    /**
     * Array of all ids of ResourceDetails that have been removed from the project 
     */
    toDelete: PropTypes.arrayOf(PropTypes.number)
}

export default ManageResourceModal