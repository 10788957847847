import { Grid, MenuItem, TextField,Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import { DatePicker } from "@mui/lab";
import { handleFormNumbers } from "../../../util/generalHelp";
import RemoveButton from "../../../components/RemoveButton";
import moment from "moment";


function AddExpenses(props){
    const [expense, setExpense] = useState(props.expense || {})

    const updateExpenseObj = (key, value) => {
        var newObj = {...expense}
        newObj[key] = value
        setExpense(newObj)
        if(props.onUpdate){
            props.onUpdate(newObj)
        }
    }

    useEffect(()=>{
        // console.log('testing useEffect')
        setExpense(props.expense)
    },[props.expense])

    const handleNumberForm = (value, key, decimals=0) =>{
        let modfiedNum = handleFormNumbers(value, decimals)
        console.log('modified number')
        if(modfiedNum !== undefined){
            updateExpenseObj(key, modfiedNum)
        }
    } 

    return(
        <Grid container sx={{width:'100%', justifyContent:'center', alignItems:'center'}} spacing={1}>
            {props.index ?
            <Grid item xs={0.25} container sx={{alignItems:'center', justifyContent:'center'}}>
                <Typography>
                    {props.index})
                </Typography>
            </Grid> : <></>
            }
            
            <Grid item xs={2.5}>
                <DatePicker label="Date" value={expense.date || null} 
                    onChange={(value) => updateExpenseObj('date',value.format('YYYY-MM-DD').toString())} 
                    renderInput={(params) => <TextField required fullWidth {...params}/>}
                    maxDate={props.maxDate ? moment(props.maxDate) : null}
                    minDate={props.minDate ? moment(props.minDate) : null}/>
            </Grid>
            <Grid item xs={2.5}>
                <TextField label="Category" value={expense.category} fullWidth required
                    onChange={(e) => {e.preventDefault(); updateExpenseObj('category',e.target.value)}} select>
                    <MenuItem value={'Buisness Cards'}>
                        Buisness Cards
                    </MenuItem>
                    <MenuItem value={'Buisness Meals'}>
                        Buisness Meals
                    </MenuItem>
                    <MenuItem value={'Dues'}>
                        Dues
                    </MenuItem>
                    <MenuItem value={'Legal Fees'}>
                        Legal Fees
                    </MenuItem>
                    <MenuItem value={'Liscence Fees'}>
                        Liscence Fees
                    </MenuItem>
                    <MenuItem value={'Mileage'}>
                        Mileage
                    </MenuItem>
                    <MenuItem value={'Office Supplies'}>
                        Office Supplies
                    </MenuItem>
                    <MenuItem value={'Passport Fees'}>
                        Passport Fees
                    </MenuItem>
                    <MenuItem value={'Postage'}>
                        Postage
                    </MenuItem>
                    <MenuItem value={'Printer Cartridges'}>
                        Printer Cartridges
                    </MenuItem>
                    <MenuItem value={'Printer Paper'}>
                        Printer Paper
                    </MenuItem>
                    <MenuItem value={'Software'}>
                        Software
                    </MenuItem>
                    <MenuItem value={'Stationary'}>
                        Stationary
                    </MenuItem>
                    <MenuItem value={'Subscriptions'}>
                        Subscriptions
                    </MenuItem>
                    <MenuItem value={'Telephones'}>
                        Telephones
                    </MenuItem>
                    <MenuItem value={'Tools'}>
                        Tools
                    </MenuItem>
                    <MenuItem value={'Training Fees'}>
                        Training Fees
                    </MenuItem>
                    <MenuItem value={'Travel'}>
                        Travel
                    </MenuItem>
                    <MenuItem value={'Work Clothing'}>
                        Work Clothing
                    </MenuItem>
                    <MenuItem value={'Other'}>
                        Other
                    </MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={2.5}>
                <TextField  label="Cost ($)" SelectProps='0.01' onChange={(e) => {e.preventDefault(); 
                    handleNumberForm(e.target.value,'cost',2)}}
                    value={expense.cost || ""} fullWidth  required/>
            </Grid>
            <Grid item xs={2.5}>
                <TextField label="Description" value={expense.desc || ""} fullWidth required multiline
                    onChange={(e) => {e.preventDefault(); updateExpenseObj('desc',e.target.value)}}/>
            </Grid>
            <Grid item container xs={1} sx={{alignItems:'center'}}>
                <RemoveButton onClick={(e) => {{e.preventDefault(); props.removeExpense()}}}/>
            </Grid>
        </Grid>
    )
}

AddExpenses.propTypes = {
    expense: PropTypes.object,
    removeExpense : PropTypes.func.isRequired,
    onUpdate : PropTypes.func,
    maxDate : PropTypes.string,
    minDate: PropTypes.string
}

export default AddExpenses;