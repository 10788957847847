import React from "react";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PropTypes from 'prop-types'
/**
 * Simple remove icon that acts as a button
 * @component 
 * @category Stylized Components
 */
function RemoveButton(props){
    return (
        <RemoveCircleIcon sx={{color: props.color, '&:hover':{color:props.hoverColor}}}
        onClick={props.onClick}/>
    )
}

RemoveButton.propTypes = {
    /**
     * color of the button normally
     */
    color:PropTypes.string,
    /**
     * color of the button when hovered over by mouse
     */
    hoverColor:PropTypes.string,
    /**
     * function called when button is clicked, parameter to function is the clicking event, e
     */
    onClick:PropTypes.func.isRequired
}

RemoveButton.defaultProps = {
    color: 'gray',
    hoverColor:'red'
}

export default RemoveButton