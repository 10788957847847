import React from "react";
import { Grid, Typography } from "@mui/material";
import HoverButton from "../HoverButton";
import { useNavigate } from "react-router-dom";
import { fonts } from "../../util/Theme";
import PropTypes from "prop-types";
import ProjectsTile from "../NavBar/ProjectsTile";
import TimesheetTile from "../NavBar/TimesheetTile";
import ExpenseTile from "../NavBar/ExpenseTile";

/**
 * Home Page Displayed for Admins
 * @component 
 * @category Pages
 * @subcategory Admin
 */
function HomeDisplay(props) {
  const navigate = useNavigate();
  return (
    <Grid item container xs={12} sx={{ height: "100%" }} rowSpacing={8}>
      <Grid
        item
        container
        xs={12}
        sx={{ height: "40%", justifyContent: "center", alignItems: "flex-end" }}
      >
        <Typography variant="h2" align="center" color="black">
          Welcome,{" "}
          {props.profile.first_name ? props.profile.first_name : "error"}!
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{
          height: "60%",
          justifyContent: "center",
          alignContent: "flex-start",
        }}
      >
        <Grid sx={{padding:'2%', justifyContent: "center",
          alignContent: "center"}}>
          <ProjectsTile color='black' roles={props.profile.roles} role={props.profile.role}/>
        </Grid>
        <Grid sx={{padding:'2%',justifyContent: "center",
          alignContent: "center"}}>
          <ExpenseTile color='black' role={props.profile.role} roles={props.profile.roles}/>
        </Grid>
        <Grid sx={{padding:'2%',justifyContent: "center",
          alignContent: "center"}}>
          <TimesheetTile color='black' roles={props.profile.roles}/>
        </Grid>
      </Grid>
    </Grid>
  );
}

HomeDisplay.propTypes = {
  /**
   * object representing the profile of the user (Resource object accoring to db schema)
   */
  profile: PropTypes.object.isRequired,
};

export default HomeDisplay;
