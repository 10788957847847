import { ColumnFilter } from './ColumnFilter.js'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const ColumnNames = [
    {
        Header: 'Id',
        accessor: 'id'

    },
    {
        Header: 'Short Name',
        accessor: 'short_name',
        Filter: ColumnFilter,
        disableFilters: true,

    },
    
    {
        Header: 'AP Email',
        accessor: 'ap_email',
        Filter: ColumnFilter
        
    },
    {
        Header: 'Contact Email',
        accessor: 'contact_email',
        Filter: ColumnFilter
        
    },
   
    {
        Header: 'Contact First Name',
        accessor: 'contact_first_name',
        Filter: ColumnFilter
        
    },
    {
        Header: 'Contact Last Name',
        accessor: 'contact_last_name',
        Filter: ColumnFilter,
    },
    {
        Header:()=>null,
        id: 'edit',
        Filter: ColumnFilter,
    },
    {
        Header: () => null, // No header
            id: 'expander', // It needs an ID
    },
]