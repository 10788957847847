import React, {useEffect, useState} from "react";
import { Dialog, DialogTitle, DialogContent, Grid, Button, Typography, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import PasswordCheck from "../../../components/Pop_ups/PasswordCheck";
import { editUserPii, forceGetUserPii, quickCheckPassword } from "../../../util/ProfileHelper";
import { ErrorObject } from "../../../util/generalHelp";
import moment from "moment";
import GridBreak from "../../../components/GridBreak";
import { DatePicker } from '@mui/lab';
import { PiiValidator } from "../../../util/FormValidators";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";

function PiiViewer(props){
    const [openedDialog, setOpenedDialog] = useState(false);
    const [checkPassword, setCheckPassword] = useState(false);
    const [resourcePii, setResourcePii] = useState({});
    const [error, setError] = useState(null)
    const [editing, setEditing] = useState(false)
    const [editingObject, setEditingObj] = useState({})
    const [piiError, setPiiError] = useState("")
    const [piiSuccess, setPiiSuccess] = useState(false)

    useEffect(()=>{
        setEditingObj(resourcePii);
    },[resourcePii])

    const grabPersonalInformation = () => {
        forceGetUserPii(props.resource_id, (response)=>{
            console.log('force response', response)
            if(response instanceof ErrorObject){
                setResourcePii({pii: false})
                setError(response)
                response.displayError();
            }else{
                //console.log('response is', response)
                setResourcePii(response)
            }
            setOpenedDialog(true);
        })
    }

    const handlePasswordCheck = (e) => {
        e.preventDefault();
        quickCheckPassword((resp)=>{
            if(resp.ok){
                grabPersonalInformation()
            }else{
                setCheckPassword(true)
            }
        })
    }

    const closePii = (e) => {
        e.preventDefault();
        setOpenedDialog(false);
        setResourcePii({});
        setError(null);
    }

    const toggleEditing = (e) => {
        e.preventDefault();
        setEditing(!editing);
    }

    function editPii(e){
        e.preventDefault();
        //console.log('editing pii',editingObject)
        let missingFields = PiiValidator.validateFormObject(editingObject)
        if(missingFields.length > 0){
            let errorString = "Please enter the following fields: "
            errorString += missingFields.join(", ")
            setPiiError(errorString);
            return;
        }

        if(!PiiValidator.validateSSN(editingObject.social_security)){
            setPiiError("Please insure your social security number is valid.")
            return;
        }

        editUserPii(editingObject, (resp) => {
            if(resp instanceof ErrorObject){
                setPiiError(resp.message);
            }else{
                setResourcePii(editingObject);
                setPiiSuccess(true);
                setEditing(false);
            }
        })
    }

    const updatePii = (key, value) => {
        //console.log('key, value', key, value)
        let newObj = {...editingObject}
        newObj[key] = value
        //console.log('setting editing object to', newObj)
        setEditingObj(newObj)
    }

    return(
        <>
        {props.profile.roles.admin ? 
        <>
            <TextPopUp open={piiSuccess} onClose={(e) => {e.preventDefault(); setPiiSuccess(false);}} title="Success!" 
            content="Your personal identifying information has been successfully saved and encrypted."/>
            <PasswordCheck open={checkPassword} onClose={() => setCheckPassword(false)} profile={props.profile} onSuccess={() => grabPersonalInformation()}/>
            <Button variant="outlined" onClick={handlePasswordCheck}>View Pii</Button>
            <Dialog open={openedDialog} onClose={(e) => {e.preventDefault(); setEditing(false); setEditingObj({}); setOpenedDialog(false)}}>
            <ErrorPopUp open={Boolean(piiError)} errorTitle="Please ensure your form is filled out properly." 
            errorContent={piiError}
            onClose={e => {e.preventDefault(); setPiiError("")}}/>
            <DialogTitle>
            <Grid container>
                {!error ?
                <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                    <Grid item container xs={1} sx={{justifyContent:'flex-start'}}>
                        {editing ? 
                            <EditOffIcon sx={{m:'1%', cursor:'pointer','&:hover':{color:'red'}}} onClick={toggleEditing}/>
                            :
                            <EditIcon sx={{m:'1%', cursor:'pointer','&:hover':{color:'red'}}} onClick={toggleEditing}/>
                        }
                        
                    </Grid>
                    <Grid item container xs={10} sx={{justifyContent:'center'}}>
                        {resourcePii.first_name} {resourcePii.last_name}'s PII
                    </Grid>
                    <Grid item container xs={1} sx={{justifyContent:'flex-start'}}>
                        <CloseIcon sx={{m:'1%', cursor:'pointer','&:hover':{color:'red'}}} onClick={closePii}/>
                    </Grid>
                            
                </Grid> : 
                <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                    <Grid item container xs={1} sx={{justifyContent:'flex-start'}}>
                        <CloseIcon sx={{m:'1%', cursor:'pointer','&:hover':{color:'red'}}} onClick={closePii}/>
                    </Grid>
                    <Grid item container xs={10} sx={{justifyContent:'center'}}>
                        Error Code: {error.status}
                    </Grid>
                    <Grid item container xs={1} sx={{justifyContent:'flex-start'}}>
                        <CloseIcon sx={{m:'1%', cursor:'pointer','&:hover':{color:'red'}}} onClick={closePii}/>
                    </Grid>
                </Grid>}
            </Grid>
            </DialogTitle>
            <DialogContent>
                {!error ? 
                <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center', flexDirection:"row"}}>
                    {editing ? <>
                        <Typography color="red"> Currently editing {resourcePii.first_name} {resourcePii.last_name}'s pii</Typography>
                        <Grid item xs={12}>
                            <TextField label="SSN/SIN" value={editingObject.social_security} variant="standard" 
                            onChange={(e) => {e.preventDefault(); updatePii('social_security', PiiValidator.formatSSN(e.target.value))}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Primary Address" value={editingObject.primary_address} variant="standard"
                            onChange={(e) => {e.preventDefault(); updatePii('primary_address', e.target.value)}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker label="Date of Birth" value={editingObject.date_of_birth ? moment.utc(editingObject.date_of_birth) : null}
                                onChange={(value) => updatePii('date_of_birth',value ? value.format('YYYY-MM-DD').toString() : value)}
                                renderInput={(params) => <TextField required {...params} variant="standard"/>}
                                maxDate={moment()} fullWidth/>
                        </Grid>
                        <Grid container item xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                            <Button onClick={editPii} autoFocus>Save</Button>
                        </Grid>
                    </> : 
                    <>
                        {!resourcePii.pii_exists ? <Typography color="red">
                            Please Note: {resourcePii.first_name} {resourcePii.last_name} has not submitted their pii yet.
                            </Typography> : <></>}
                        <Grid item xs={12}>
                            <Typography>SSN/SIN: {resourcePii.social_security || "None"}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Date of Birth: {resourcePii.date_of_birth ? moment.utc(resourcePii.date_of_birth).format("YYYY-MM-DD").toString() : "None"}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Primary Address : {resourcePii.primary_address || "None"}</Typography>
                        </Grid>
                        <Grid container item xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                            <Button onClick={closePii} autoFocus>Ok</Button>
                        </Grid>
                    </>}
                </Grid>
                : <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center', flexDirection:"row"}}>
                    <Grid item>
                        <Typography align='center'> Something went wrong when getting your pii</Typography>
                    </Grid>
                    <GridBreak/>
                    <Grid item>
                        <Typography> Error Message:</Typography>
                    </Grid>
                    <GridBreak/>
                    <Grid item>
                        <Typography>{error.message}</Typography>
                    </Grid>
                    <Grid container item xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                        <Button onClick={closePii} autoFocus>Ok</Button>
                    </Grid>
                </Grid>}
            </DialogContent>
        </Dialog>
        </>
        : <></>}
        
        </>
    )
}

export default PiiViewer;