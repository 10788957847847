import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fonts } from "../../util/Theme";
import FormattedTextField from "../Formatted/FormattedTextField";

function TimesheetNotes(props){

    return (<FormattedTextField
        fullWidth
        InputLabelProps={{ style: { fontSize: fonts.labelSize } }}
        inputProps={{
            maxLength: props.maxCharacterCount
          }}
        disabled={props.beenSubmitted || !props.project.notes_required}
        type="text"
        step="0.01"
        variant="filled"
        label="Notes"
        value={props.notes || ""}
        onChange={(e) => props.onNoteUpdate ? props.onNoteUpdate(e.target.value) : undefined}
        multiline
        rows={5}/>)
}

TimesheetNotes.defaultProps = {
    beenSubmitted: false,
    notes_required: true,
    notes: "",
    onNoteUpdate: undefined,
    maxCharacterCount: 1024
}

export default TimesheetNotes