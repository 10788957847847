import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import PropTypes from 'prop-types'
/**
 * screen used to indicate when page is loading
 * @component 
 * @category Pages
 */
function LoadingScreen(props) {
  return (
    <Box
      sx={{
        height: `${window.innerHeight}px`,
        m: "0.25%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor:'white',
          padding:'1%',
          borderRadius:2
        }}
      >
        <Box>
          <Typography align="center" variant="h5">
            {props.loadingText}
          </Typography>
        </Box>
        <Box sx={{ml:1, mr:1}}>
          <CircularProgress />
        </Box>
      </Box>
    </Box>
  );

  
}

LoadingScreen.propTypes = {
  /**
   * text to be displayed while page is loading
   */
  loadingText: PropTypes.string
}

LoadingScreen.defaultProps ={
  loadingText: "Please Wait"
}

export default LoadingScreen;
