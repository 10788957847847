import { Modal, Box, Button, Typography, Link, CircularProgress, Grid, Dialog } from '@mui/material';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import TimeSheetFields from '../NewTimesheets/TimeSheetFields';
import DocusignTSFields from '../NewTimesheets/DocusignTSFields';
import moment from 'moment';
import { downloadDocusign } from '../../util/docusignHelper';
import TimesheetNotes from '../NewTimesheets/TimesheetNotes';
import { isObjectEmpty } from '../../util/generalHelp';

/**
 * pop up used to display a timesheet form
 * @component
 * @category Pop Ups 
 */
function TimesheetPopUp(props) {
    const [loading, setLoading] = useState(false)
    const onClick = (envelope_id) =>{
        if(envelope_id){
            // getEnvelope(props.infoObj.envelope_id, (url)=>{
            //     console.log('url',url)
            //     if(url){
            //         setDocusignUrl(url.redirect_url)
            //     }
            // }, props.path)
            setLoading(true)
            downloadDocusign(envelope_id, (blob) =>{
                try{
                    if(!blob){
                        setLoading(false)
                        alert('something went wrong when downloading your file')
                        return
                    }
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${envelope_id}.pdf`);
                    // 3. Append to html page
                    document.body.appendChild(link);
                    // 4. Force download
                    link.click();
                    // 5. Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setLoading(false)
                }catch(e){
                    console.error(e)
                    alert('something went wrong when downloading your file')
                    setLoading(false)
                }
            })
        }
    }

    return (
        <Dialog sx={{bgcolor:'transparent', justifyContent:'center', alignItems:'center', padding:'1%', m:'1%', overflowY:'auto'}}
        open={props.open}
        onClose={() => {props.onClose()}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{style:{borderRadius:4}}}
        fullScreen>
            {props.infoObj && !isObjectEmpty(props.infoObj) && props.timesheets ? 
            <Box sx={{display:'flex', bgcolor:'white', padding:'1%', borderRadius:2, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            <Box sx={{width:'100%', display:'flex', maxHeight:'100%', justifyContent:'center', alignItems:'flex-start', flexDirection:'column', padding:'1%' }}>
                <Grid item container xs={12} sx={{justifyContent:'center',alignItems:'center', width:'100%'}}>
                <Typography variant="h5" align='center'>
                    {props.infoObj.period_start_date ? `Your Submitted Timesheet For ${moment.utc(props.infoObj.period_start_date).format('MMMM DD YYYY')}` : "Your Submitted Timesheet"}
                </Typography>
                </Grid>
                <Grid container sx={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                    <Grid item xs={2}>
                        {props.infoObj.days_worked !== undefined ? <Typography>Days Worked: {props.infoObj.days_worked}</Typography> : <></>}
                    </Grid>
                    <Grid item xs={2}>
                        {props.infoObj.hours_worked !== undefined ? <Typography>Hours Worked: {props.infoObj.hours_worked}</Typography> : <></>}
                    </Grid>
                    
                </Grid>
                <Grid container sx={{display:'flex', flexDirection:'justifyContent', alignItems:'flex-start'}}>
                    <Grid item xs={2}>
                        {props.infoObj.project_name ? <Typography sx={{mr:'1%'}}>Project: {props.infoObj.project_name}</Typography> : <></>}
                    </Grid>
                    <Grid item xs={2}>
                        {props.infoObj.submitted_date ? <Typography>Submitted on {moment.utc(props.infoObj.submitted_date).format('MMMM DD YYYY')}</Typography> : <></>}
                    </Grid>
                    <Grid item xs={2}>
                        {props.infoObj.status ? <Typography>Status: {props.infoObj.status}</Typography> : <></>}
                    </Grid>
                    <Grid item xs={4}>
                        {props.infoObj.admin_submitted ? 
                        <Typography>Admin who submitted : {`${props.infoObj.admin_submitted.first_name} ${props.infoObj.admin_submitted.last_name}`}</Typography> : <></>}
                    </Grid>
                    
                </Grid>
                
                {/* {props.infoObj.envelope_id ? 
                <Box sx={{display:'flex',flexDirectiOn:'row', justifyContent:'center', alignItems:'center'}}>
                    <Typography>
                        Docusign Document <Link onClick={onClick} sx={{cursor: "pointer"}}>Click Here to Download</Link>
                    </Typography>
                    {loading ? <CircularProgress size={"1.5rem"}/> : <></>}
                </Box> : <></>} */}
                {props.infoObj.envelope_ids ?
                
                props.infoObj.envelope_ids.map((envelope_id) => (
                    <Box sx={{display:'flex',flexDirectiOn:'row', justifyContent:'center', alignItems:'center'}}>
                    <Typography>
                        Docusign Document <Link onClick={(event) => {event.preventDefault(); onClick(envelope_id)}} 
                        sx={{cursor: "pointer"}}>Click Here to Download</Link>
                    </Typography>
                    {loading ? <CircularProgress size={"1.5rem"}/> : <></>}
                </Box>
                )) 
                 : <></>}

            </Box>
            {props.infoObj.standard_vacation ? <TimeSheetFields timesheets={props.timesheets} beenSubmitted={props.beenSubmitted} daysPerRow={7}/>:<DocusignTSFields timesheets={props.timesheets} beenSubmitted={props.beenSubmitted} daysPerRow={7}/>}
            {props.infoObj.notes ? <TimesheetNotes beenSubmitted={props.beenSubmitted} notes={props.infoObj.notes || "No Notes"}/> : <></> }
            <Button variant='contained' onClick={(event) => {event.preventDefault(); props.onClose()}}>Ok</Button>
        </Box>
        : <></>
        }
            
        </Dialog>
    );
}

TimesheetPopUp.propTypes = {
    /**
     * whether or not the pop up is open
     */
    open: PropTypes.bool.isRequired,
    /**
     * function called when pop up is closed
     */
    onClose: PropTypes.func.isRequired,
    /**
     * object where each key is a date (YYYY-MM-DD) and value is hours worked on that day
     */
    timesheets: PropTypes.object.isRequired,
    /**
     * object containing information about the project such as days worked, the project name, etc.
     */
    infoObj: PropTypes.object,
    /**
     * boolean used to indicate whether timesheet has already been submitted
     */
    beenSubmitted: PropTypes.bool
}   

TimesheetPopUp.defaultProps = {
    beenSubmitted: false
}


export default TimesheetPopUp;