import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField, Grid, Dialog, DialogTitle, DialogContent, Select, MenuItem, InputLabel } from "@mui/material";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import validator from "validator";
import moment from "moment";
import { DatePicker } from "@mui/lab";

import "./Resource.css";
import RoleChecklist from "../../../components/admin/RoleChecklist";

// const style = {
//   position: "absolute",
//   display: "flex",
//   flexDirection: "column",
//   padding: "10px",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "40%",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

export const CreateNewPerm = ({ refresh, setRefresh, profile, recruiters, clients }) => {

  const defaultUserRoles = {
    admin:true,
    offers:true,
    timesheet_admin:true,
    timesheet:true,
    resource_admin:true,
    expense_admin: true
  }

  const [open, setOpen] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const [toggle, editToggle] = useState();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleObj, setRoleObj] = useState(defaultUserRoles)

  const [newEntry, editNewEntry] = useState({
    firstName: "",
    lastName: "",
    invoiceId:"",
    startDate: "",
    client: "",
    recruiter: 1,
    accountManager: 1,
    sourcer: 1,
    sales:1
  });

  const updateDate = (field, value) => {
    let temp = {
      ...newEntry,
    };
    temp[field] = moment.utc(value).format("YYYY-MM-DD").toString();
    editNewEntry(temp);
  };

  const updateEntry = (field, value) => {
    let temp = {
      ...newEntry,
    };
    temp[field] = value;
    editNewEntry(temp);
  };
  const updateContact = (e) =>{
    const onlyDigits = e.replace(/\D/g, "");
    console.log("onlyDigits", onlyDigits)
    updateEntry("contactNumber", onlyDigits)

  }
  const updateEmail = (field, e) =>{
    const removeWhitespace = e.replace(/\s/g, '');
    console.log("onlyDigits", removeWhitespace)
    updateEntry(field, removeWhitespace)
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    editNewEntry({
    firstName: "",
    lastName: "",
    invoiceId: 0,
    startDate: "",
    client: null,
    recruiter: 1,
    accountManager: 1,
    sourcer: 1,
    sales:1
    });
  };

  const onSaveHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      newEntry.firstName === "" ||
      newEntry.lastName === "" || newEntry.client == ""
    ) {
      setLoading(false);
      setOpenStatus(true);
      setMessage("Please complete the required sections of the form!");
    } else {
      getAmplifyToken((jwt) => {
        fetch(`${process.env.REACT_APP_ADMIN_API}/admin/perm`, {
          method: "POST",
          body: JSON.stringify({
            first_name: newEntry.firstName,
            last_name: newEntry.lastName,
            invoice_id: newEntry.invoiceId ? newEntry.invoiceId:null,
            start_date: newEntry.startDate ? newEntry.startDate:null,
            client: newEntry.client ? newEntry.client: null,
            recruiter: newEntry.recruiter ? newEntry.recruiter : null,
            account_manager: newEntry.accountManager ? newEntry.accountManager : null,
            sourcer: newEntry.sourcer ? newEntry.sourcer : null,
            sales: newEntry.sales ? newEntry.sales : null,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: jwt,
          },
        }).then((response) => {
          setLoading(false);
          if (response.ok) {
            setSuccess(true);
            setMessage(
              `${newEntry.firstName} ${newEntry.lastName}` +
                " has been added as a resource!"
            );
            handleClose();
          } else {
            setOpenStatus(true);
            setMessage("Could not load from the database. Please try again!");
          }
        });
      });
    }
  };

  return (
    <div className="entry-container">
      <TextPopUp
        title="Success!"
        content={message}
        open={success}
        onClose={(event) => {
          event.preventDefault();
          setSuccess(false);
          setRefresh(!refresh);
        }}
        fullWidth
      />
      <ErrorPopUp
        open={openStatus}
        onClose={(e) => {
          e.preventDefault();
          setOpenStatus(false);
        }}
        errorContent={message}
      />
      <LoadingPopUp open={loading} />
      <Button variant="outlined" onClick={handleOpen}>
        Create New Perm
      </Button>
      <Dialog
        // sx={{ zIndex: "1100" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}> */}
        
              <DialogTitle>
                <Typography
                  align="center"
                  fullWidth
                  fontWeight={'bold'}
                  fontSize={'18px'}
                >
                  Enter Perm Information
                </Typography>
                <Box display='flex' justifyContent='flex-end'>
                <IconButton
                  fullWidth
                  sx={{ display: "flex" }}
                  variant="contained"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
                </Box>
              </DialogTitle>
      <DialogContent>
          {
            <div className="values-container">
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="First Name"
                mode="outlined"
                required
                onChange={(event) => {
                  updateEntry("firstName", event.target.value);
                }}
              />
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="Last Name"
                mode="outlined"
                required
                onChange={(event) => {
                  updateEntry("lastName", event.target.value);
                }}
              />
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                type='number'
                placeholder=""
                label="Invoice ID"
                mode="outlined"
                onChange={(event) => {
                  updateEntry("invoiceId", event.target.value);
                }}
              />
              {/* <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="Recruiter"
                mode="outlined"
                onChange={(event) => {
                  updateEntry("recruiter", event.target.value);
                }}
              /> */}
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                label="Recruiter"
                placeholder=""
                select
                value={newEntry.recruiter}
                onChange={(event)=>{updateEntry("recruiter", event.target.value)}}
                >
                  {recruiters?.map((item)=>{
                    return(
                    <MenuItem value={item.id}>{item.user_name}</MenuItem>)
                  })}

              </TextField>
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                label="Client"
                mode="outlined"
                required
                select
                onChange={(event) => {
                  updateEntry("client", event.target.value);
                }}
              >{clients?.map((item)=>{
                return(
                <MenuItem value={item.id}>{item.short_name}</MenuItem>)
              })} 
              </TextField>
              <DatePicker
                error={false}
                helperText={null}
                label="Start Date"
                value={newEntry.startDate ? moment.utc(newEntry.startDate) : null}
                onChange={(value) => {
                  updateDate("startDate", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={false}
                    sx={{ width: "100%", margin: "5px" }}
                  />
                )}
              />
              <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                value={newEntry.accountManager}
                label="Account Manager"
                mode="outlined"
                select
                onChange={(event) => {
                  updateEntry("accountManager", event.target.value);
                }}
              >
                {recruiters?.map((item)=>{
                    return(
                    <MenuItem value={item.id}>{item.user_name}</MenuItem>)
                  })}
                </TextField>
               <TextField
                sx={{ width: "100%", margin: "5px" }}
                value={newEntry.sourcer}
                placeholder=""
                label="Sourcer"
                mode="outlined"
                select
                onChange={(event) => {
                  updateEntry("sourcer", event.target.value);
                }}
              >
                {recruiters?.map((item)=>{
                    return(
                    <MenuItem value={item.id}>{item.user_name}</MenuItem>)
                  })}
                </TextField>
               <TextField
                sx={{ width: "100%", margin: "5px" }}
                placeholder=""
                value={newEntry.sales}
                label="Sales"
                mode="outlined"
                select
                onChange={(event) => {
                  updateEntry("sales", event.target.value);
                }}
              >
                {recruiters?.map((item)=>{
                    return(
                    <MenuItem value={item.id}>{item.user_name}</MenuItem>)
                  })}
                </TextField>
              {/* <RoleChecklist admin={true} onUpdate={setRoleObj} roleObj={roleObj} profile={profile}/> */}
            </div>
          }
          {
            <div className="button-container">
              <Button
                sx={{ width: "40%", backgroundColor:'#ff6600', color: 'white', borderColor:'#ff6600', '&:hover': {
                  backgroundColor: '#fff',
                  color: '#ff6600'}}}
                variant='contained'
                onClick={onSaveHandler}
              >
                Save
              </Button>{" "}
            </div>
          }
        {/* </Box> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};
