import { ColumnFilter } from './ColumnFilter.js'
import moment from 'moment'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const ColumnNames = [
    {
        Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row, isEditable }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
              </span>
            ),
    },
    {
        Header: 'First Name',
        accessor: 'firstName',
        Filter: ColumnFilter,

    },
    
    {
        Header: 'Last Name',
        accessor: 'lastName',
        Filter: ColumnFilter
        
    },
    { 
        Header: 'Contact Number',
        accessor: 'contactNumber',
    },
   
    {
        Header: 'Primary Email',
        accessor: 'primaryEmail',
        Filter: ColumnFilter
        
    },
    {
        Header: 'Secondary Email',
        accessor: 'secondaryEmail',
        Filter: ColumnFilter,
    },
    {
        Header: 'Hire Date',
        accessor: 'hireDate',
        Cell: ({value})=>{ if (value === null) {return ''} else{return moment.utc(value).format('YYYY-MM-DD').toString()} },
        Filter: ColumnFilter,
    },
    {
        Header: 'Location',
        accessor: 'location',
        Filter: ColumnFilter,
    },
    { 
        Header: 'Id',
        accessor: 'id',
    },
    { 
        accessor: 'name',
    },
    { 
        accessor: 'resource_category',
    },
    { 
        accessor: 'type',
    },

    {
        Header:'App Credentials',
        id: 'credential',
        Filter: ColumnFilter,
    },
    {
        Header:()=>null,
        id: 'edit',
        Filter: ColumnFilter,
    },
]