import React from 'react';
import {TableCell} from '@mui/material'
import {fonts} from '../../util/Theme'

/**
 * TableCell formatted to use font size configured in Theme.js
 * @component 
 * @category Stylized Components 
 */
function SubmittedCell(props) {
    return (
        <TableCell align='center' sx={{ fontSize: fonts.tableSize }}>{props.children}</TableCell>
    );
}

export default SubmittedCell;