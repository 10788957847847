import react, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField } from "@mui/material";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import validator from "validator"



export function EditableRow({ cell, isActive, setIsActive, selectedRow, editValues, setEditValues, refresh, setRefresh}) {
  // const [editValues, setEditValues] = useState({
  //   id: "",
  //   short_name: "",
  //   ap_email: "",
  //   contact_email:"",
  //   contact_first_name: "",
  //   contact_last_name: "",
  // });
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)
  useEffect(() => {
    console.log('row', selectedRow)
    let [value] = selectedRow.map((row) => row.original);
    setEditValues(value);
  }, []);
  // useEffect(() => {
  //   console.log(editValues)
  // }, [editValues]);
  
  const onChangeHandler=(event)=>{
    console.log("change", editValues)
    setEditValues({...editValues,[cell.column.id]: event.target.value,});
  }
  const handleClose = () => {
    cell.row.toggleRowExpanded();
    cell.row.toggleRowSelected();
    setIsActive(false);
  };
  const onSaveHandler=(e)=>{
    console.log(editValues)
    e.preventDefault();
        setLoading(true)
        if (editValues.short_name === '' || editValues.ap_email === '') {
            setLoading(false)
            setOpen(true)
            setMessage("Please complete the required sections of the form!")
        }
        else if (!validator.isEmail(editValues.ap_email)|| !validator.isEmail(editValues.contact_email)) {
            setLoading(false)
            setOpen(true)
            setMessage("Invalid email format. Please try again!")
        }
        else {
            getAmplifyToken((jwt)=>{
                fetch(`${process.env.REACT_APP_ADMIN_API}/admin/clients-api`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        id: editValues.id,
                        short_name: editValues.short_name,
                        ap_email: editValues.ap_email,
                        contact_email_1: editValues.contact_email,
                        contact_first_name: editValues.contact_first_name,
                        contact_last_name: editValues.contact_last_name
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: jwt
                    }
                }).then((response) => {
                    setLoading(false)
                    if (response.ok) {
                        setSuccess(true)
                        setMessage(editValues.short_name + " has been edited successfully!")
                        setIsActive(false);
              
                    }
                    else {
                        setOpen(true)
                        setMessage("Could not add to the database. Please try again")
                    }
                })
            })
        }
  }
  
  return (
    <>
    <TextPopUp title="Success!" content={message} open={success} onClose={(event)=>{event.preventDefault(); setSuccess(false); setRefresh(!refresh);}} fullWidth/>
    <ErrorPopUp open={open} onClose={(e) => {e.preventDefault(); setOpen(false)}} errorContent={message} />
            <LoadingPopUp open={loading} />
    <TableCell align="center" {...cell.getCellProps()}>
      {cell.column.id === "edit" ? (
        <span>
          <IconButton sx={{ marginRight: 5 }} onClick={onSaveHandler}>
            <SaveIcon />
          </IconButton>
        </span>
      ): cell.column.id === "expander" ? (<IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>) : cell.column.id === "Id" ? (
        ""
      ) :cell.column.id === "short_name" || cell.column.id === "ap_email" || cell.column.id === "contact_email" ? 
      (<TextField
        helperText={!editValues[cell.column.id]? "required":' '}
        error={!editValues[cell.column.id]}
        value={editValues[cell.column.id]}
        required
        sx={{width:'100%', margin:0}}
        inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
            InputLabelProps={{style: {fontSize: '0.875rem'}}} 
        onChange={(event) => {onChangeHandler(event)}}
        variant="standard"
      ></TextField>):(
        <TextField
          helperText={' '}
          value={editValues[cell.column.id]}
          sx={{width:'90%', margin:0}}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}}
          onChange={(event) => {onChangeHandler(event)}}
          variant="standard"
        ></TextField>
      )}
    </TableCell>
    </>
  );
}
