import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Button,
  DialogTitle,
  Box,
  Grid,
} from "@mui/material";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
/**
 * component used to change a user's password in settings
 * @component
 * @category Settings 
 * @subcategory General Settings
 */
function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    changePassword();
    handleClose(event);
  };

  const handleDown = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const changePassword = () => {
    if (newPassword === confirmNewPassword) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then((data) =>
          props.updateStatus(true, "Password Successfully Changed!")
        )
        .catch((err) => {
          if (err.code == "InvalidParameterException") {
            props.updateStatus(false, "Passwords Failed To Meet Requirements");
          } else {
            props.updateStatus(false, err.message);
          }
        });
    } else {
      props.updateStatus(
        false,
        "Password Not Changed; Passwords Did Not Match"
      );
    }

    /*
     */
  };

  const handleClose = (event) => {
    setOldPassword();
    setNewPassword();
    setConfirmNewPassword();
    props.onClose(event);
  };

  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <Typography align="center" variant="h5">
          Change Password
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            rowSpacing={0.5}
          >
            <Grid
              item
              xs={12}
              container
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                type="password"
                label="Old Password"
                variant="filled"
                fullWidth
                defaultValue={oldPassword}
                onChange={(event) => setOldPassword(event.target.value)}
                onKeyDown={handleDown}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                type="password"
                label="New Password"
                variant="filled"
                fullWidth
                error={newPassword !== confirmNewPassword}
                helperText={
                  newPassword !== confirmNewPassword
                    ? "Passwords Must Match"
                    : ""
                }
                defaultValue={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                onKeyDown={handleDown}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                type="password"
                label="Confirm New Password"
                variant="filled"
                fullWidth
                helperText={
                  newPassword !== confirmNewPassword
                    ? "Passwords Must Match"
                    : ""
                }
                error={newPassword !== confirmNewPassword}
                defaultValue={confirmNewPassword}
                onChange={(event) => setConfirmNewPassword(event.target.value)}
                onKeyDown={handleDown}
              />
            </Grid>
            <br />
            <Grid
              item
              container
              xs={12}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Grid item sx={{ padding: "0.25%" }}>
                <Button
                  variant="contained"
                  onClick={(event) => {
                    event.preventDefault();
                    props.onClose(event);
                  }}
                >
                  Close
                </Button>
              </Grid>
              <Grid item sx={{ padding: "0.25%" }}>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

ChangePassword.propTypes = {
  /**
   * whether or not change password is open
   */
  open: PropTypes.bool.isRequired,
  /**
   * function called after closing the change password dialog
   */
  onClose: PropTypes.func.isRequired,
  /**
   * function used to update the status of parent component, returns whether or not update was successful and string alongisde it
   */
  updateStatus: PropTypes.func.isRequired,
};

export default ChangePassword;
