import { Button, Grid, Paper, Table, TableContainer, TableBody, TableHead, TablePagination, 
    TableRow, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandableRow from "../../../components/ExpandableRow";
import SubmittedCell from "../../../components/Submitted/SubmittedCell";
import moment from "moment";
import IndivExpenses from "./IndivExpenses";
import { toMoneyString } from "../../../util/generalHelp";
import { downloadDocusign } from "../../../util/docusignHelper";
import PropTypes from 'prop-types'
import 'moment-timezone'

function ExpenseDisplay(props){
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [loadingIndices, setLoadingIndices] = useState([])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    

    const updateLoadingIndices = (id, method="append") => {
        console.log('updateLoadingIndicies')
        let newIndices = [...loadingIndices]
        if(method === 'append'){
            newIndices.push(id)
        }else if(method === 'delete' && loadingIndices.includes(id)){
            newIndices.splice(newIndices.indexOf(id))
        }
        console.log('settting loading indicies',newIndices)
        setLoadingIndices(newIndices)
    }

    useEffect(()=>{
        console.log('loading indicies',loadingIndices)
    },[loadingIndices])

    const downloadExpense = (id) => {
        console.log('should download',id)
        updateLoadingIndices(id)
        downloadDocusign(id, (blob)=>{
            if(!blob){
                props.onError({errorTitle: "Uh Oh!", errorContent:"Something went wrong when trying to download the document, please try again"})
            }else{
                var url  = window.URL.createObjectURL(blob);
                window.open(url);
            }
            updateLoadingIndices(id,'delete')
        })   
    }

    return(
        <>
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <SubmittedCell>Resource</SubmittedCell>
                        <SubmittedCell>Signing Authority</SubmittedCell>
                        <SubmittedCell>Expense Start</SubmittedCell>
                        <SubmittedCell>Expense End</SubmittedCell>
                        <SubmittedCell>Submitted On</SubmittedCell>
                        <SubmittedCell>Number of Expenses</SubmittedCell>
                        <SubmittedCell>Total Amount ($)</SubmittedCell>
                        <SubmittedCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.expenses.map((expense) => (
                        <ExpandableRow alternate expand={<IndivExpenses expenses={expense.expenses}/>}>
                            <SubmittedCell>{`${expense.resource.first_name} ${expense.resource.last_name}`}</SubmittedCell>
                            <SubmittedCell>{expense.authority.name}</SubmittedCell>
                            <SubmittedCell>{moment.utc(expense.expense_start).format('YYYY-MM-DD').toString()}</SubmittedCell>
                            <SubmittedCell>{moment.utc(expense.expense_end).format('YYYY-MM-DD').toString()}</SubmittedCell>
                            <SubmittedCell>{moment.utc(expense.date_sent).tz(moment.tz.guess()).format('MMM Do YYYY HH:mm:ss zz').toString()}</SubmittedCell>
                            <SubmittedCell>{expense.expenses.length}</SubmittedCell>
                            <SubmittedCell>{toMoneyString(expense.total)}</SubmittedCell>
                            <SubmittedCell>
                                {loadingIndices.includes(expense.envelope_id) ? 
                                <CircularProgress/>
                                    :
                                <Button onClick={(e) => {e.preventDefault(); downloadExpense(expense.envelope_id)}}>Download</Button>
                                }
                            </SubmittedCell>
                        </ExpandableRow>
                    ))}
                    
                </TableBody>
            </Table>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.expenses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
        </>
    )
}

ExpenseDisplay.propTypes = {
    expenses : PropTypes.arrayOf(PropTypes.object).isRequired,
    onError : PropTypes.func
}

export default ExpenseDisplay