import { Modal, Box, Typography } from '@mui/material';
import React, { useEffect , useState} from 'react';
import {PropTypes} from 'prop-types'

/**
 * popup used to display an Iframe
 * @component
 * @category Pop Ups 
 */
function IFramePopUp(props) {

    const [loadCount, setLoadCount] = useState(0) //detecting if have left main docusign page
    
    useEffect(()=>{
        if(props.maxRedirect && loadCount >= props.maxRedirect){
            //console.log('killing frame', loadCount)
            try{
                const myFrame = document.getElementById('iframe').contentWindow.location.href
                const url = new URL (myFrame)
                //console.log('current url', url)
            }catch(e){
                console.error(e)
            }
            
            setLoadCount(0)
            props.onClose(true)
        }
    },[loadCount])

    useEffect(()=>{
        console.log('url change', props.url)
    },[props.url])

    useEffect(()=>{
        setLoadCount(0)
    },[props.url])

    const onLoad = () =>{
        try{
            const myFrame = document.getElementById('iframe').contentWindow.location.href
            const url = new URL (myFrame)
            const data = {
                event: url.searchParams.get('event'),
                project_id : url.searchParams.get('project_id'),
                timesheet_id : url.searchParams.get('timesheet_id')
            }
            //console.log('searchParams',url.searchParams)
            //console.log('data',data)
           props.onRedirect(url.searchParams)
        }catch(error){
            if (error instanceof DOMException) setLoadCount(loadCount + 1)
        }
    }
    

    return (
        <Modal open={props.open}
        onClose={() => {props.onClose(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onBackdropClick={() => {props.onClose(false)}}
        sx={{display: "flex",
        justifyContent: "center",
        alignItems: "center"}}>
        <Box sx={{
        height: props.height,
        width: props.width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"}}>
            <iframe id="iframe" src={props.url} height="100%" width="100%" title={props.title} onLoad={onLoad} border="1px solid black"/>;
        </Box>
        </Modal>
    );
}

IFramePopUp.propTypes ={
    /**
     * whether or not the popup is open
     */
    open: PropTypes.bool.isRequired,
    /**
     * function called when pop up is closed
     */
    onClose: PropTypes.func.isRequired,
    /**
     * url for iframe to display initially
     */
    url: PropTypes.string.isRequired,
    /**
     * height of iframe
     */
    height: PropTypes.string,
    /**
     * width of iframe
     */
    width: PropTypes.string,
    /**
     * title of iframe
     */
    title: PropTypes.string,
    /**
     * function to be called when iframe redirects to a new page
     */
    onRedirect: PropTypes.func,
    /**
     * maximum number of redirects that the iframe is allowed
     */
    maxRedirect: PropTypes.number,
}

IFramePopUp.defaultProps = {
    height: "90%",
    width: "90%",
    title: "iframe"
}

export default IFramePopUp;