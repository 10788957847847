import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  List,
  ListItem,
  Button,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { dateStringWithoutYear, isObjectEmpty,isCursorAtEnd, isCursorAtStart, getNumDecimals, getFirstNDecimals } from "../../util/generalHelp";
import { fonts } from "../../util/Theme";
import moment from "moment";
import ErrorPopUp from "../Pop_ups/ErrorPopUp";
import Confirmation from "../Pop_ups/Confirmation";
import FormattedTextField from "../Formatted/FormattedTextField";
import DocusignTSFields from "./DocusignTSFields";
import TimeSheetFields from "./TimeSheetFields";
import TimesheetNotes from "./TimesheetNotes";
import { areWeekendsEmpty, formateDateObject, getObjectFiller, getStartAndEndOfObject } from "../../util/newTimeSheetHelper";

/**
 * Form used to submit timesheets
 * @component
 * @category Timesheets
 * @subcategory Timesheet Form
 */
function NewTimeSheetsForm(props) {

  const sumHours = (hoursObj) => {
    let sum = 0
    for (let key of Object.keys(hoursObj)){
      sum += parseFloat(hoursObj[key].hours)
    }
    return sum
  }


  const [timesheets, setTimesheets] = useState(props.timesheets);
  const [error, setError] = useState(false);
  const [errorIndices, setErrorIndices] = useState([]);
  const [totalHours, setTotalHours] = useState(props.timesheets ? Number(sumHours(props.timesheets)).toFixed(2) : 0);
  const [errorPrompt, setErrorPrompt] = useState(false);
  const [confirmFormStatus, setConfirmFormStatus] = useState(false);
  //const [confirmHoliday, setConfirmHoliday] = useState(undefined)
  const [confirmWeekend, setConfirmWeekend] = useState(false)
  const [errorMessage, setErrorMessage] = useState("One of your entries in the form is invalid, please fix this before submitting")

  useEffect(() => {

    const test = {...props.timesheets, ...getObjectFiller(props.timesheets)}


    setTimesheets(props.timesheets);
    setTotalHours(props.timesheets ? Number(sumHours(props.timesheets)).toFixed(2) : 0)
  }, [props.timesheets]);

  // useEffect(()=>{
  //   console.log('timesheets is', timesheets)
  // })
  

  useEffect(() => {
    if (errorIndices.length == 0) {
      setError(false);
    }
  });

  const removeErrors = (index) => {
    if (errorIndices.includes(index)) {
      let newIndices = [...errorIndices];
      newIndices.splice(errorIndices.indexOf(index), 1);
      setErrorIndices(newIndices);
    }
  };

  const updateHours = (newValue, date, field, index) => {
    if (newValue == "" || (0 <= Number(newValue) && Number(newValue) <= 24)) {
      removeErrors(index);
      let newTime = Object.assign({}, timesheets);
      let value = getNumDecimals(newValue) > 2 ? getFirstNDecimals(newValue) : newValue

      if (field==='vacation')
        newTime[date].vacation_hours = String(value)
      else if (field==='holiday')
        newTime[date].holiday_hours = String(value);
      else if (field==='sick')
        newTime[date].sick_hours = String(value);
      else
        newTime[date].hours = String(value);
      
      setTimesheets(newTime);
    } else {
      let newIndices = [...errorIndices];
      newIndices.push(index);
      setErrorIndices(newIndices);
      setError(true);
    }
  };
  

  const submitForm = () => {
    // let sumError = (Object.values(timesheets).reduce(
    //   (partialSum, currentValue) => (Number(partialSum) + Number(currentValue.hours)
    //   + Number(currentValue.holiday_hours) + Number(currentValue.vacation_hours)).toFixed(2)
    // ) != Number(totalHours).toFixed(2))
    let accumulator = 0
    Object.values(timesheets).forEach((value) => {
      accumulator += Number(value.hours)
    })
    let sumError = (accumulator.toFixed(2) != Number(totalHours).toFixed(2))
    console.log('test is', accumulator)
    if (!error && !sumError) {
      setConfirmFormStatus(true);
    } else {
      if(sumError){
        setErrorMessage('Ensure your total hours matches the sum only the standard hours field')
      }else{
        setErrorMessage("One of your entries in the form is invalid, please fix this before submitting")
      }
      setErrorPrompt(true);
    }
  };

  const confirmForm = () => {
    //check for holidays
    // let hd = checkForHoliday(timesheets);
    // if (!hd) {
    //   setConfirmFormStatus(false);
    //   // const day_array = Object.keys(timesheets).map((date) =>
    //   //   Number(timesheets[date])
    //   // );
      
    //   props.onSubmit(timesheets);
    // } else {
    //   setConfirmFormStatus(false)
    //   setConfirmHoliday(hd.name)
    // }

    //check for weekends

    if(!areWeekendsEmpty(timesheets)){
      setConfirmFormStatus(false)
      setConfirmWeekend(true)
    }else{
      setConfirmFormStatus(false)
      props.onSubmit(timesheets)
    }
  };

  const onConfirmWeekend = () =>{
    setConfirmWeekend(false)
      // const day_array = Object.keys(timesheets).map((date) =>
      //   Number(timesheets[date])
      // );
      
      props.onSubmit(timesheets);
  }

  const saveForm = () => {
    props.onSave(timesheets);
  };

  const handleDown = (event, type='text') => {
    if (event.key === "Enter" || (event.key === "ArrowRight" && (isCursorAtEnd(event) || type !=='text'))) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[Math.min(index + 1, form.elements.length-1)].focus();
      event.preventDefault();
    } else if (event.key === "ArrowLeft" && (isCursorAtStart(event) || type!=='text')) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[Math.max(0, index - 1)].focus();
      event.preventDefault();
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      sx={{ justifyContent: "flex-start", alignItems: "center", width:'100%' }}
    >
      <ErrorPopUp
        open={errorPrompt}
        onClose={(event) => {
          event.preventDefault();
          setErrorPrompt(false);
        }}
        errorTitle="Please Ensure All Entries Are Valid"
        errorContent={errorMessage}
      />
      {!isObjectEmpty(timesheets) ? (
        <>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              submitForm();
            }}
            style={{'width':'100%'}}
          >
            <Grid
              container
              item
              xs={12}
              sx={{ justifyContent: "flex-start", alignItems: "center", width:'100%' }}
            >
              {props.project.standard_vacation == true ? <TimeSheetFields onDown={handleDown} updateHours={updateHours} timesheets={timesheets} errorIndices={errorIndices}
              beenSubmitted={props.beenSubmitted} daysPerRow={props.daysPerRow} boundaryDates={props.boundaryDates}/>:<DocusignTSFields onDown={handleDown} updateHours={updateHours} timesheets={timesheets} errorIndices={errorIndices}
              beenSubmitted={props.beenSubmitted} daysPerRow={props.daysPerRow} boundaryDates={props.boundaryDates}/>}
              

              <Grid item container xs={5} sx={{display:'flex', justifyContent:'center', alignItems:'center', ml:'1%'}}>
              {props.project.notes_required ?
                <TimesheetNotes beenSubmitted={props.beenSubmitted} project={props.project}
                  notes={props.notes} onNoteUpdate={props.onNoteUpdate}/>
               : <></>}
              </Grid>
              <Grid item container xs={6.5} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Grid item xs={(144 / 7) / (props.daysPerRow)}>
                <ListItem key={"total hours"}>
                  <FormattedTextField
                    fullWidth
                    InputLabelProps={{ style: { fontSize: fonts.labelSize } }}
                    type="text"
                    step="0.01"
                    value={totalHours}
                    variant="filled"
                    label="Total Standard Hours"
                    onChange={(event) => getNumDecimals(event.target.value) > 2 ? setTotalHours(getFirstNDecimals(event.target.value)) : setTotalHours(event.target.value)}
                    disabled={props.beenSubmitted}
                    error = {Object.values(timesheets).reduce(
                      (partialSum, currentValue) => (Number(partialSum) + Number(currentValue)).toFixed(2)
                    ) != Number(totalHours).toFixed(2)}
                    helperText={Object.values(timesheets).reduce(
                      (partialSum, currentValue) => (Number(partialSum) + Number(currentValue)).toFixed(2)
                    ) != Number(totalHours).toFixed(2) ? "Hours is for standard hours only" : ""}
                    onKeyDown={handleDown}
                  />
                </ListItem>
              </Grid>
              <Grid item container xs={((144 / 7) / props.daysPerRow)} sx={{padding:'1%'}}>
                <Button
                  disabled={props.beenSubmitted || /*moment.utc(props.project.end_date).endOf('day').isBefore(moment.utc(props.currDate)) ||*/
                    (Boolean(props.project.project_resource_enddate) && moment.utc(props.project.project_resource_enddate).isBefore(moment.utc(props.currDate)))}
                  variant="contained"
                  type="submit"
                  fullWidth
                  onKeyDown={(event) => {
                    if (event.key !== "Enter") handleDown(event,"button");
                  }}
                >
                  {props.beenSubmitted
                    ? "time sheet already submitted for this period"
                    : "submit"}
                </Button>
                </Grid>
                {props.onSave !== undefined ? (
                  <Grid item container xs={((144 / 7) / props.daysPerRow)} sx={{padding:'1%'}}>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={(event) => {
                      event.preventDefault();
                      saveForm();
                    }}
                    disabled={props.beenSubmitted || /*moment.utc(props.project.end_date).endOf('day').isBefore(moment.utc(props.currDate)) ||*/
                      (Boolean(props.project.project_resource_enddate) && moment.utc(props.project.project_resource_enddate).isBefore(moment.utc(props.currDate)))}
                    onKeyDown={(event) => {
                      if (event.key !== "Enter") handleDown(event,'button');
                    }}
                  >
                    Save
                  </Button>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </form>

          <Confirmation
            open={confirmFormStatus}
            onClose={() => setConfirmFormStatus(false)}
            onConfirm={(event) => {
              event.preventDefault();
              confirmForm();
            }}
            onDeny={() => setConfirmFormStatus(false)}
            title="Are You Sure You Want To Submit This Form?"
            content={`Once you submit this form, you wont be able to change your timesheet for the period of ${moment.utc(Object.keys(timesheets)[0]).format('MMMM DD YYYY')} to 
            ${moment.utc(Object.keys(timesheets)[Object.keys(timesheets).length - 1]).format('MMMM DD YYYY')} ${props.project.timesheet_type === 'docusign' ? "and you may be taken to docusign." : ""}`}
          />
          {/* <Confirmation
        open={Boolean(confirmHoliday)}
        onClose={(event) => {event.preventDefault(); setConfirmHoliday(undefined)}}
        onConfirm={onConfirmHoliday}
        onDeny={(event) => {event.preventDefault(); setConfirmHoliday(undefined)}}
        title="You Have Submitted Time For A Holiday"
        content={`You have submitted time for ${confirmHoliday}, are you sure you worked then?`}/> */}

        <Confirmation open={confirmWeekend} onClose={(event)=>{event.preventDefault(); setConfirmWeekend(false)}}
        onConfirm={onConfirmWeekend}
        onDeny={(event)=>{event.preventDefault(); setConfirmWeekend(false)}}
        title="You have submitted Time for a Weekend"
        content="You have submitted time for a weekend, are you sure this is correct?"/>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
}

NewTimeSheetsForm.propTypes = {
  /**
   * object where each key is a date (YYYY-MM-DD) and value is hours worked on that day
   */
  timesheets: PropTypes.object.isRequired,
  /**
   * function called when timesheet is ready to be submitted, passes timesheet object
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * function called when timesheet is ready to be saved, passes timesheet object
   */
  onSave: PropTypes.func,
  /**
   * boolean indicating whether or not timesheet has already been submitted
   */
  beenSubmitted: PropTypes.bool,
  /**
   * number of days per row to display on form
   */
  daysPerRow: PropTypes.number,
  /**
   * object used to indicate start and end dates of project / resource's term on said project
   * follows {startDates : [array of dates], endDates: [array of dates]}
   */
  boundaryDates: PropTypes.object,
  /**
   * object representing the project that timesheet is for (following db schema for projects)
   */
  project: PropTypes.object.isRequired
};

NewTimeSheetsForm.defaultProps = {
  beenSubmitted: false,
  daysPerRow: 7,
};

export default NewTimeSheetsForm;
