import { Cache,Auth } from 'aws-amplify';

// Run this after the sign-in
/**
 * function that takes jwt and uses it for api call
 * @callback jwtCallback
 * @param {String} jwt
 * 
 */

/**
 * gets an amplify token of tokenType and passes it as a parameter to callback
 * @param {jwtCallback} callback
 * @param {string} tokenType type of token that is being requested from amplify 
 * @category Api Calls
 * @subcategory AWS
 * @module
 */
export function getAmplifyToken(callback, tokenType='access'){
    Auth.currentSession().then(res=>{
        let accessToken = res.getAccessToken()
        let idToken = res.getIdToken()
        let refreshToken = res.getRefreshToken()
        let jwt;
        if(tokenType ==="id") jwt = idToken.getJwtToken()
        else if(tokenType ==='access') jwt=accessToken.getJwtToken()
        else if(tokenType === 'refresh') jwt=refreshToken.getToken()
        //You can print them to see the full objects
        // console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
        // console.log(`myIdToken ${JSON.stringify(idToken)}`)
        // console.log(`myJwt: ${jwt}`)
        //callback(jwt)
        callback(jwt)
      })
}

export async function getAmplifyTokenPromise(tokenType='access'){
    let res = await Auth.currentSession()
    let accessToken = res.getAccessToken()
    let idToken = res.getIdToken()
    let refreshToken = res.getRefreshToken()
    let jwt;
    if(tokenType ==="id") jwt = idToken.getJwtToken()
    else if(tokenType ==='access') jwt=accessToken.getJwtToken()
    else if(tokenType === 'refresh') jwt=refreshToken.getToken()
    //You can print them to see the full objects
    // console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
    // console.log(`myIdToken ${JSON.stringify(idToken)}`)
    // console.log(`myJwt: ${jwt}`)
    //callback(jwt)
    return jwt
}