import React from 'react';
import {Typography,Box} from '@mui/material'

/**
 * page for url that does not exist
 * @component 
 * @category Pages 
 */
function NotFound(props) {
    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', minHeight:'100vh', height:'100%'}}>
            <Typography>This page does not exist</Typography>
        </Box>
    );
}

export default NotFound;