import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types'
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Box, Typography, TextField} from '@mui/material'
import PiiForm from '../forms/PiiForm';
import ErrorPopUp from './ErrorPopUp';
import { PiiValidator } from '../../util/FormValidators';

function PiiPopUp(props){
    const [piiObj, setPiiObj] = useState({})
    const [piiError, setPiiError] = useState(false)

    useEffect(()=>{
        onUpdate('resource_obj', props.profile)
    },[props.profile])

    const expectedPiiFields = ['social_security', 'date_of_birth', 'primary_address'];

    function onUpdate(field, value){
        let newObj = {...piiObj}
        newObj[field] = value;
        setPiiObj(newObj);
        //console.log('new pii obj', newObj)
        return;
    }

    function submitPii(){
        //console.log('pii obj', piiObj)
        let missingFields = PiiValidator.validateFormObject(piiObj);
        if(missingFields.length > 0){
            let errorString = "Please enter the following fields: "
            errorString += missingFields.join(", ")
            setPiiError(errorString);
            return;
        }
        if(!PiiValidator.validateSSN(piiObj.social_security)){
            setPiiError("Please insure your social security number is valid.")
            return;
        }
        props.onSubmit(piiObj);
    }

    return(
        <>
        <ErrorPopUp open={Boolean(piiError)} errorTitle="Please ensure your form is filled out properly." 
        errorContent={piiError}
        onClose={e => {e.preventDefault(); setPiiError("")}}/>
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Please Confirm Your Personal Information
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{justifyContent:'center', alignItems:'center', padding:'0.5%'}}>
                    We seem to be missing some of your personal information within our database.  Please confirm what we have
                    and enter any missing data in the form below.  Your data will both be encrypted on its way to and within
                    our database, in compliance with personal indentifying information standards.
                </DialogContentText>
                <PiiForm onUpdate={onUpdate} piiObj={piiObj} onSubmit={submitPii} profile={piiObj.resource_obj}/>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default PiiPopUp