import React, {useEffect} from 'react';
import {TextField} from '@mui/material'
import {fonts} from '../../util/Theme'
import PropTypes from 'prop-types'

/**
 * TextField formatted using the fontsize configured in Theme.js
 * @component
 * @category Stylized Components 
 */
function FormattedTextField(props) {
    return (
        <TextField {...props} InputLabelProps={{ sx: { fontSize: fonts.labelSize} }}>
            {props.children}
        </TextField>
    );
}

// FormattedTextField.propTypes = {
//     backgroundColor: PropTypes.string
// }

// FormattedTextField.defaultProps = {
//     backgroundColor: '#f9f9f9'
// }

export default FormattedTextField;