import { getAmplifyToken } from "./AmplifyHelp";

/**
 * function that takes invoiceBody and passes it on to backend to either add or update an invoice
 * @param {AbortController} abortCont the abort controller responisble for terminating the request
 * @param {responseHandler} callback callback function to return response to 
 * @category Api Calls
 * @subcategory Offer Letters
 * @module
 */
export function getOfferInfo(abortCont, callback){
    getAmplifyToken((jwt)=>{
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/offer/details`,{
            headers: {"Authorization": jwt },
            signal: abortCont ? abortCont.signal : undefined
            }
        ).then(resp => resp.json()).then(json=>callback(json))
        .catch(err => handleError(err,callback))
    })
}

/**
 * function used to generate an offer letter
 * @param {Object} jsonBody object to be forwarded to the backend containing all releveant information
 * @param {responseHandler} callback function responsible for handling response from backend 
 */
export function createOfferDraft(jsonBody, callback){
    getAmplifyToken((jwt)=>{
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/offer/draft`,{
            headers: {"Content-Type": "application/json", "Authorization":jwt},
            method:'POST',
            body: JSON.stringify(jsonBody)
        }).then(resp => resp.json()).then(json => callback(json))
        .catch(err => handleError(err,callback))
    })
}

/**
 * function used for voiding an offer letter
 * @param {Number} id id of offer letter to be removed 
 * @param {responseHandler} callback callback used to handle the response from the backend
 * @param {Boolean} remove (deprecated) whether or not to delete from db 
 */
export function removeOfferDraft(id, callback, remove=true){
    let json_obj = {id:id}
    if(remove === true){
        json_obj = {id:id, remove:true}
    }
    getAmplifyToken((jwt)=>{
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/offer/void`,{
            headers: {"Content-Type": "application/json", "Authorization":jwt},
            method:'POST',
            keepalive: true,
            body: JSON.stringify(json_obj)
        }).then(resp => resp.json()).then(json => callback(json))
        .catch(err => handleError(err,callback))
    })
}

/**
 * function used for finalizing and submitting an offer letter
 * @param {Number} id id of offer letter to be finalized 
 * @param {responseHandler} callback function used to handle response from backend 
 */
export function submitDraft(id, envelope_id, callback){
    console.log('envelope_id',envelope_id)
    getAmplifyToken((jwt)=>{
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/offer/submit`,{
            headers: {"Content-Type": "application/json", "Authorization":jwt},
            method:'POST',
            body: JSON.stringify({id:id,envelope_id:envelope_id})
        }).then(resp => resp.json()).then(json => callback(json))
        .catch(err => handleError(err,callback))
    })
}

export function createContractDraft(jsonBody, callback){
    getAmplifyToken((jwt)=>{
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/contract/draft`,{
            headers: {"Content-Type": "application/json", "Authorization":jwt},
            method:'POST',
            body: JSON.stringify(jsonBody)
        }).then(resp => resp.json()).then(json => callback(json))
        .catch(err => handleError(err,callback))
    })
}

// export function resendEnvelope(id, callback){
//     getAmplifyToken((jwt)=>{
//         fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/offer/resend`,{
//             headers: {"Content-Type": "application/json", "Authorization":jwt},
//             method:'POST',
//             body: JSON.stringify({id:id})
//         }).then(resp => resp.json()).then(json => callback(json))
//         .catch(err => handleError(err,callback))
//     })
// }

const handleError=(err, callback)=>{
    if(err.name !== 'AbortError'){
      console.error(err)
      callback(undefined)
    }
  }