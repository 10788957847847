import React, { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import { Paper, TableContainer, IconButton, Typography, Select, MenuItem} from "@mui/material";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
  useRowState,
} from "react-table";
import "./Resource.css";
import { GlobalFilter } from "./GlobalFilter";
import { EditableRow } from "./EditableRow";
import { AppCredentialsCreate } from "./AppCredentialsCreate";
import { useNavigate } from "react-router-dom";
import { UserStatus } from "../../../lib/users.constants";
import { AppCredentialsResend } from "./AppCredentialsResend";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import { CreateNewPerm } from "./CreateNewPerm";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import RoleChecklist from "../../../components/admin/RoleChecklist";
import {CSVLink, CSVDownload} from 'react-csv';
import moment from 'moment'

export function DisplayTable({ data, columns, refresh, setRefresh,updateData, profile, recruiters, clients }) {
  const [isActive, setIsActive] = useState(false);
  const [users, setUsers] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [rowId, setRowId] = useState('');
  const [editValues, setEditValues] = useState({
    id:"",
    first_name: "",
    last_name: "",
    invoice_id: "",
    start_date: null,
    client: "",
    recruiter: "",
    account_manager: "",
    sourcer: "",
    sales:""
  });
  const navigate = useNavigate();
  let csvData = []
  let headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Invoice ID", key: "invoice_id" },
    { label: "Start Date", key: "start_date" },
    { label: "Client ", key: "Client" },
    { label: "Recruiter", key: "rec_name" },
    { label: "Account Manager", key: "acc_name" },
    { label: "Sourcer", key: "sourc_name" },
    { label: "Sales", key: "sales_name" },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    selectedFlatRows,
    pageOptions,
    state,
    rows,
    setGlobalFilter,
    visibleColumns,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
        hiddenColumns: ["id", "name", "resource_category", "type"],
      },
      autoResetExpanded  :false
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect, 
    useRowState
  );

  const { globalFilter, expanded, pageIndex, pageSize } = state;


  useEffect(()=>{
    console.log('rows', rows)
  },[rows])

  const updateRoleObj = (roleObj, index) => {
    console.log('data',data)
    let newData = [...data]
    newData[index].role_obj = roleObj
    console.log('newData',newData)
    // //call setdata from parent
    updateData(newData)
  }
  

  const getData=()=>{
    let csv = []
    rows.forEach((value)=>{
      value.values.start_date = value.values.start_date ? moment.utc(value.values.start_date).format('YYYY-MM-DD').toString():'';
      csv = [...csv, value.values]
    })
    return csv
  }
  return (
    <div className="display-container">
      <TextPopUp
        title="Success!"
        content={message}
        open={success}
        onClose={(event) => {
          event.preventDefault();
          setSuccess(false);
          setRefresh(!refresh);
        }}
        fullWidth
      />
      <ErrorPopUp
        open={open}
        onClose={(e) => {
          e.preventDefault();
          setOpen(false);
        }}
        errorContent={message}
      />
      <Typography align="center" variant="h4" component="div" gutterBottom>
        Perm
      </Typography>
      
      <div className="entrycontainer">
        <div className="filter">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        <div className="create-new">
        <CSVLink className='csv-button' data={getData()} headers={headers}>DOWNLOAD CSV</CSVLink>
        <CreateNewPerm
          refresh={refresh}
          setRefresh={setRefresh}
          profile={profile}
          recruiters={recruiters}
          clients={clients}
        ></CreateNewPerm>
        </div>
      </div>
      <TableContainer sx={{ marginTop: 1 }} component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    align="center"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
              <>
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <>
                        {isEditable && index === rowId ? (
                          <EditableRow
                          cell={cell}
                          isEditable={isActive}
                          setIsActive={setIsActive}
                          setIsEditable={setIsEditable}
                          setRowId={setRowId}
                          selectedRow={selectedFlatRows}
                          editValues={editValues}
                          setEditValues={setEditValues}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          roleObj={row.original.role_obj || {}}
                          recruiters={recruiters}
                          clients={clients}   
                        ></EditableRow>
                        ) 
                        :(<TableCell
                          key={i}
                          align={"center"}
                          {...cell.getCellProps()}
                        >
                          {cell.column.id === "edit" ? (
                            <IconButton
                              {...cell.row.getToggleRowExpandedProps()}
                              onClick={() => {
                                if (!isActive) {
                                  setIsEditable(true)
                                  setIsActive(true);
                                  setRowId(index)
                                  cell.row.toggleRowSelected();
                                } else {
                                }
                              }}
                            >
                              <EditIcon sx={{ fontSize: 20, margin: "2%" }} />
                            </IconButton>
                          ): (
                            <span>{cell.render("Cell", {items: recruiters, client_list:clients})}</span>
                          )}
                        </TableCell>)
                        // : (
                        //   <TableCell align={"center"} {...cell.getCellProps()}>
                        //     {cell.column.id === "edit" ? (
                        //       <IconButton
                        //         // {...cell.row.getToggleRowExpandedProps()}
                        //         // onClick={() => {
                        //         //   cell.row.toggleRowSelected();
                        //         //   handleViewClick(cell);
                        //         // }}
                        //         {...cell.row.getToggleRowExpandedProps()}
                        //         onClick={() => {
                        //           if (!isActive) {
                        //             setIsActive(true);
                        //             cell.row.toggleRowExpanded();
                        //             cell.row.toggleRowSelected();
                        //           } else {
                        //           }
                        //         }}
                        //       >
                        //         <EditIcon sx={{ fontSize: 20, margin: "2%" }} />
                        //       </IconButton>
                        //     ) : cell.column.id === "credential" ? (
                        //       compareEmails(cell.row.values.primaryEmail)
                        //     ) : (
                        //       <span>{cell.render("Cell")}</span>
                        //     )}
                        //   </TableCell>
                        // )
                        }
                      </>
                    );
                  })}
                </TableRow>
                {row.isExpanded && isEditable && index === rowId? (
                    <TableRow className="details-background">
                      <TableCell colSpan={visibleColumns.length}>
                        <RoleChecklist admin={true} row={true} onUpdate={(roleObj) => {updateRoleObj(roleObj,index);}} 
                        roleObj={row.original.role_obj || {}} profile={profile} resource_id={row.values.id}/>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          </TableBody>
        </Table>
        <div className="pagination">
          <span className="page-selector">
            <Typography sx={{fontSize: '0.875rem'}}>Rows Per Page:</Typography>
            <Select
            sx={{marginRight: '24px', marginLeft: '8px', fontSize: '0.875rem'}}
              disableUnderline
              variant="standard"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 15, 25, 50].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
            <Typography {...getTableBodyProps()} sx={{fontSize: '0.875rem', marginRight:'24px'}}>
              {pageIndex + 1}{"-"}{page.length} of {data.length}
            </Typography>{" "}
          </span>
          <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ChevronLeftIcon/>
          </IconButton>
          <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <ChevronRightIcon/>
          </IconButton>
        </div>
      </TableContainer>
    </div>
  );
}
