// CheckboxTextField.js
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const CheckboxTextField = ({ checkboxes,setCheckboxes,textFieldValue,setTextFieldValue,displayCheckBox,...props }) => {
  // const [textFieldValue, setTextFieldValue] = useState('');

  const handleCheckboxChange = (index) => (event) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = event.target.checked;

    setCheckboxes(updatedCheckboxes);

    // Update TextField value based on checked state
    const checkedLabels = updatedCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);
      setTextFieldValue(checkedLabels.join(';'));
  };

  return (
    <div>
      <TextField
     { ...props}
      />
      {displayCheckBox?
      <FormGroup>
        {checkboxes.map((checkbox, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={checkbox.checked}
                disabled={!displayCheckBox}
                onChange={handleCheckboxChange(index)}
              />
            }
            label={checkbox.label}
          />
        ))}
      </FormGroup>:null}
    </div>
  );
};

export default CheckboxTextField;
