import React, {useState, useEffect} from 'react';
import {MenuItem, TextField} from '@mui/material'
import PropTypes from 'prop-types'
import { Autocomplete, Grid } from '@mui/material';
import { fonts } from '../../util/Theme';
import FormattedTextField from '../Formatted/FormattedTextField';
/**
 * component used to search through timesheets for admins
 * @component
 * @category Timesheets
 * @subcategory Submitted Timesheets 
 */
function AdminSearchBar(props) {
    const [resources, setResources] = useState([]);
    const [resourceString, setResourceString] = useState("")
    const [projects, setProjects] = useState([])
    const [projectString, setProjectString] = useState("")
    const [clients, setClients] = useState([])
    const [clientString, setClientString] = useState("")
    const [status, setStatus] = useState([])
    const [invoiceNumber, setInvoiceNumber] = useState("")
    const [payment, setPayment] = useState([])
    const [hasLoaded, setHasLoaded] = useState(false)
    const [invoice, setInvoice] = useState("Both")

    useEffect(()=>{
        if(!hasLoaded) setHasLoaded(true)
        else{
            
        }
    })

    useEffect(()=>{
        if(hasLoaded){
            const searchObj = {
                resources: (resources.length > 0 ? resources.map(r => r.id) : undefined),
                resourceString: resourceString.toLowerCase(),
                projects: (projects.length > 0 ? projects.map(p => p.id) : undefined),
                projectString: projectString.toLowerCase(),
                clients: (clients.length > 0 ? clients.map(c => c.id): undefined),
                clientString: clientString.toLowerCase(),
                status: (status.length > 0 ? status: undefined),
                invoice_number: invoiceNumber,
                payment: (payment.length > 0 ? payment: undefined),
                invoiced: invoice
            }
            props.onUpdate(searchObj)
        }
    },[resources,projects,clients,status,invoiceNumber,payment,resourceString,projectString,clientString, invoice])

    return (
        <>
        
        {props.listOfProj ?
        <Grid item xs={2}>
        <Autocomplete
        disablePortal
        multiple
        options={props.listOfProj}
        value={projects}
        size='medium'
        multiline='true'
        isOptionEqualToValue={(option,value)=> option.id === value.id}
        getOptionLabel={(option)=>`${option.project_name}`}
        renderInput={(params) => <FormattedTextField {...params} InputLabelProps={{ style: { fontSize: fonts.labelSize } }} label="Projects" />}
        inputValue={projectString}
        onInputChange={(event,value,reason)=>{
            if(reason !== 'reset') setProjectString(value)
        }}
        onChange={(event, value) => {
            event.preventDefault()
          //props.onUpdate(value.map(project => project.id));
          setProjectString("")
          setProjects(value);
        }}/> 
        </Grid>: <></>}
        
        
        {props.listOfResources ? 
        <Grid item xs={2}>
            <Autocomplete
            disablePortal
            multiple
            options={props.listOfResources}
            value={resources}
            size='medium'
            multiline='true'
            isOptionEqualToValue={(option,value)=> option.id === value.id}
            getOptionLabel={(option)=>`${option.first_name} ${option.last_name}`}
            renderInput={(params) => <FormattedTextField {...params} InputLabelProps={{ style: { fontSize: fonts.labelSize } }} label="Resources" />}
            inputValue={resourceString}
            renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {`${option.first_name} ${option.last_name}`}
                  </li>
                );
              }}
            onInputChange={(event,value,reason)=>{
                if(reason !== 'reset') setResourceString(value)
            }}
            onChange={(event, value) => {
              //props.onUpdate(value.map(project => project.id));
              event.preventDefault()
              setResourceString("")
              setResources(value);
            }}/>
        </Grid>
        : <></>}

        {props.listOfClients ?
            <Grid item xs={2}>
                <Autocomplete
            disablePortal
            multiple
            options={props.listOfClients}
            value={clients}
            size='medium'
            multiline='true'
            isOptionEqualToValue={(option,value)=> option.id === value.id}
            getOptionLabel={(option)=>`${option.short_name}`}
            renderInput={(params) => <FormattedTextField {...params} InputLabelProps={{ style: { fontSize: fonts.labelSize } }} label="Clients" />}
            inputValue={clientString}
            onInputChange={(event,value,reason)=>{
                if(reason !== 'reset') setClientString(value)
            }}
            onChange={(event, value) => {
              //props.onUpdate(value.map(project => project.id));
              event.preventDefault()
              setClientString("")
              setClients(value);
            }}/>
            </Grid> : <></>
        }
        {props.statusEnabled ?
            <Grid item xs={2}>
                <Autocomplete
                disablePortal
                multiple
                options={['Submitted', 'Pending']}
                value={status}
                size='medium'
                multiline='true'
                isOptionEqualToValue={(option,value)=> option === value}
                getOptionLabel={(option)=>`${option}`}
                renderInput={(params) => <FormattedTextField {...params} InputLabelProps={{ style: { fontSize: fonts.labelSize } }} label="Status" />}
                onChange={(event, value) => {
                //props.onUpdate(value.map(project => project.id));
                event.preventDefault()
                setStatus(value);
            }}/>
            </Grid>
            : <></>
        }
        {props.invoiceEnabled? 
            <Grid item xs={2}>
                <TextField
                select
                value={invoice}
                onChange={(e) => {e.preventDefault(); setInvoice(e.target.value)}}
                label='Invoiced'
                fullWidth>
                    <MenuItem value={'Invoiced'}>Invoiced</MenuItem>
                    <MenuItem value={'Not Invoiced'}>Not Invoiced</MenuItem>
                    <MenuItem value={'Both'}>Both</MenuItem>
                </TextField>
                {/* <Autocomplete
                disablePortal
                multiple
                options={['Invoiced', 'Not Invoiced']}
                value={invoice}
                size='medium'
                multiline='true'
                isOptionEqualToValue={(option,value)=> option === value}
                getOptionLabel={(option)=>`${option}`}
                renderInput={(params) => <FormattedTextField {...params} InputLabelProps={{ style: { fontSize: fonts.labelSize } }} label="Invoiced" />}
                onChange={(event, value) => {
                    //props.onUpdate(value.map(project => project.id));
                    event.preventDefault()
                    setInvoice(value);
            }}/> */}
            </Grid>:<></>
        }
        {props.invoiceEnabled ? 
        <Grid item xs={2}>
            <FormattedTextField 
            label='Invoice Number'
            value={invoiceNumber}
            onChange={(e)=>{
                e.preventDefault();
                let value = e.target.value
                try{
                    if(Number(value) >= 0){
                        setInvoiceNumber(value)
                    }
                }catch{}
            }}/>
        </Grid> : <></>}
        {props.paymentEnabled? 
            <Grid item xs={2}>
                <Autocomplete
                disablePortal
                multiple
                options={[true, false]}
                value={payment}
                size='medium'
                multiline='true'
                isOptionEqualToValue={(option,value)=> option === value}
                getOptionLabel={(option)=>`${option}`}
                renderInput={(params) => <FormattedTextField {...params} InputLabelProps={{ style: { fontSize: fonts.labelSize } }} label="Payment Made" />}
                onChange={(event, value) => {
                    //props.onUpdate(value.map(project => project.id));
                    event.preventDefault()
                    setPayment(value);
            }}/>
            </Grid>:<></>
        }

        
        </>
    );
}

AdminSearchBar.propTypes = {
    /**
     * list of projects to choose from, each object following the db schema for a project
     */
    listOfProj: PropTypes.arrayOf(PropTypes.object),
    /**
     * list of resources to choose from, each object following the db schema for a resource
     */
    listOfResources: PropTypes.instanceOf(PropTypes.object),
    /**
     * list of clients to choose from, each object following the db schema for a client
     */
    listOfClients: PropTypes.instanceOf(PropTypes.object),
    /**
     * whether or not searching by status of timesheet is enabled
     */
    statusEnabled: PropTypes.bool,
    /**
     * whether or not searching by invoice status is enabled 
     */
    invoiceEnabled: PropTypes.bool,
    /**
     * whether or not searching by payment status in enabled
     */
    paymentEnabled: PropTypes.bool,
    /**
     * function called as search critera are changed, returns an object with the requirements of each critera
     */
    onUpdate: PropTypes.func.isRequired
}

AdminSearchBar.defaultProps = {
    statusEnabled: false,
    invoiceEnabled:false
}


export default AdminSearchBar;