import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { getAmplifyToken } from "./AmplifyHelp";
import { ErrorObject } from "./generalHelp";
/**
 * callback function that takes the given email of a user and processes it
 * @callback emailCallback
 * @param {String} email email of user's profile
 */


/**
 * function that gets email of current user's profile and passes it to callback
 * @param {emailCallback} callback function responsible for processing the user's email
 */
async function getEmail(callback) {
  Auth.currentAuthenticatedUser().then((user) => {
    callback(user.attributes.email);
  });
}
/**
 * gets profile for the logged in user by using their email
 * @param {responseHandler} onUpdate function responsible for handling the json obj of the response
 */
export async function getProfileFromEmail(onUpdate) {
  const callback = (email) => {
    getAmplifyToken((jwt) => {
      fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/resources`, 
      {
        method: "POST",
        headers: { Authorization: jwt, "Content-Type": "application/json" },
        body: JSON.stringify({email:email})
      })
        .then((res) => res.json())
        .then((data) => {
          onUpdate(data);
        })
        .catch((err) => {
          console.error(err);
          onUpdate({});
        });
    });
  };
  getEmail(callback);
}

/**
 * gets all possible profiles of developers and passes them to onUpdate in a json object
 * @param {AbortController} abortController abort controller responsible for terminating the request
 * @param {responseHandler} onUpdate function responsible for handling the response from the api
 * @category Api Calls
 * @subcategory Profile
 */
export async function getAllProfiles(abortController=undefined, onUpdate) {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/resources/developers`, 
    {headers: { Authorization: jwt }, signal: abortController? abortController.signal : undefined})
      .then((res) => res.json())
      .then((data) => onUpdate(data))
      .catch((err) => {
        onError(err, onUpdate)
      });
  });
}

export async function getProfileProjects(onUpdate, resource_id = undefined, abortController=undefined) {
  getAmplifyToken((jwt) => {
    if (!resource_id) {
      console.log('abort controller',abortController)
      const callback = (profile) => {
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/project/resource?resourceid=${profile.id}`, {
          headers: { Authorization: jwt }, signal: abortController? abortController.signal : undefined
        })
          .then((res) => res.json())
          .then((data) => {
            onUpdate(data.projects);
          })
          .catch((err) => {
            onError(err, onUpdate)
          });
      };
      getProfileFromEmail(callback);
    } else {
      fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/project/resource?resourceid=${resource_id}`, {
        headers: { Authorization: jwt }, signal: abortController? abortController.signal : undefined
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('response',data)
          onUpdate(data.projects);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  });
}

export async function getSubmittedProjectTimeSheets(
  projectids,
  onUpdate,
  to,
  from
) {
  const dateFormat = "YYYY-MM-DD";
  const callback = (profile) => {
    getAmplifyToken((jwt) => {
      let req = `${process.env.REACT_APP_TIMESHEET_API}/con/timesheets/${profile.id}?status=notpending`;

      if (to !== undefined && to != null) {
        req += `&to=${to.format(dateFormat)}`;
      }
      if (from !== undefined && from !== null) {
        req += `&from=${from.format(dateFormat)}`;
      }
      if (Array.isArray(projectids)) {
        for (let id of projectids) {
          req += `&projectid=${id}`;
        }
      } else {
        req += `&projectid=${projectids}`;
      }
      fetch(req, { headers: { Authorization: jwt } })
        .then((res) => res.json())
        .then((data) => {
          onUpdate(data.timesheets);
        })
        .catch((err) => console.error(err));
    });
  };

  getProfileFromEmail(callback);
}

/**
 * function responsible for uploading a resource's profile picture to s3
 * @param {Number} resourceId id of resource pfp is being uploaded for 
 * @param {File} file profile picture 
 * @param {responseHandler} callback function handling the status returned from the backend 
 * @category Api Calls
 * @subcategory Profile
 */
export async function uploadPfp(resourceId, file, callback){
  getAmplifyToken((jwt)=>{
    let data = new FormData()
    data.append('profile',file)
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/s3/profilepic?resource_id=${resourceId}`,{
      method:'POST',
      headers:{Authorization:jwt},
      body:data
    }).then(resp => callback(resp.status)).catch(err => {console.error(err); callback(undefined)})
  })
}

/**
 * function responsible for getting the reminders of the resouce given by resource_id and passing them to callback
 * @param {Number} resource_id id of resource for which reminders are being retrieved 
 * @param {responseHandler} callback function that handles the json obj returned from the backend 
 * @param {AbortController} abortController AbortController responsible for terminating the request
 * @category Api Calls
 * @subcategory Profile
 */
export const getProfileReminders = async(resource_id, callback, abortController=undefined) => {
  let status = undefined
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/reminders/get/${resource_id}`,{
      headers:{Authorization:jwt}, signal:abortController? abortController.signal : undefined
    }).then(resp => resp.json()).then(data=>callback(data))
    .catch(err => {console.error(err); callback(undefined)})
  })
}

/**
 * function used to update the reminders of multiple resources
 * @param {Array.Object} reminder_arr array of objects each one representing a reminder according to db schema
 * @param {responseHandler} callback function responseible for handling the response from the backend
 * @category Api Calls
 * @subcategory Profile 
 */
export const updateProfileReminders = (reminder_arr, callback) => {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/reminders/update_many`,{
      method:'PUT',
      body:JSON.stringify({'reminders':reminder_arr}),
      headers: {Authorization: jwt,"Content-Type": "application/json"}
    }).then(resp => callback(resp)).catch(err => {console.error(err); callback(undefined)})
  })
}

export const gettUserPii = (resource_id, callback) => {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/resources/pii?resource_id=${resource_id}&check_exists=true`,{
      method:'GET',
      headers: {Authorization: jwt}
    }).then(async resp => resp.ok ? resp.json() : new ErrorObject(resp.status, await resp.text())).then(data => callback(data))
    .catch(err => {
      console.error(err);
      callback(new ErrorObject(500, "error in sending request"))
    })
  })
}

export const forceGetUserPii = (resource_id, callback) => {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/resources/pii?resource_id=${resource_id}&force=true`,{
      method:'GET',
      headers: {Authorization: jwt}
    }).then(async resp => resp.ok ? resp.json() : new ErrorObject(resp.status, await resp.text())).then(data => callback(data))
    .catch(err => {
      console.error(err);
      callback(new ErrorObject(500, "error in sending request"))
    })
  })
}

export const submitUserPii = (resource_id, piiObj, callback) => {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/resources/pii?resource_id=${resource_id}`,{
      method:'POST',
      headers: { Authorization: jwt, "Content-Type": "application/json" },
      body: JSON.stringify(piiObj)
    }).then(async resp => resp.ok ? callback(resp.status) : callback(new ErrorObject(resp.status, await resp.text())))
    .catch(err => {
      console.error(err);
      callback(new ErrorObject(500, "error in sending request"))
    })
  })
}

export const checkUserPassword = (password, callback) => {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/aws/checkpassword`,{
      method:'POST',
      credentials:'include',
      headers: { Authorization: jwt, "Content-Type": "application/json" },
      body: JSON.stringify({password:password})
    }).then(async resp => resp.ok ? callback(resp.status) : callback(new ErrorObject(resp.status, await resp.text())))
    .catch(err => {
      console.error(err);
      callback(new ErrorObject(500, "error in sending request"))
    })
  })
}

export const quickCheckPassword = (callback) => {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/aws/quick_check_password`,{
      headers: { Authorization: jwt},
      credentials: 'include'
    }).then(resp => callback(resp))
    .catch(err => {
      console.error(err);
      callback(new ErrorObject(500, "error in sending request"))
    })
  })
}

export const editUserPii = (piiObj, callback) => {
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/resources/edit_pii`,{
      method:'PUT',
      headers: { Authorization: jwt, "Content-Type": "application/json" },
      body: JSON.stringify(piiObj)
    }).then(async resp => resp.ok ? callback(resp.status) : callback(new ErrorObject(resp.status, await resp.text())))
    .catch(err => {
      console.error(err);
      callback(new ErrorObject(500, "error in sending request"))
    })
  })
}

const onError = (err, callback) =>{
  if(err.name !== 'AbortError'){
    console.error(err)
    callback(undefined)
  }else{
    console.log('aborted', err)
  }
}