import { TextField } from "@mui/material"

export const GlobalFilter = ({ filter, setFilter }) => {

    const handleChange = (event) => {
        setFilter(event.taget.value)
    }
    return (
        <span className="global-container">
        <TextField id='outlined-basic' label="Search" value={filter || ''} onChange={(event)=>{setFilter(event.target.value)}}></TextField>
        </span>
    )
  }