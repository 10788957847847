import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PropTypes from 'prop-types'
import { Box, Grid, Typography, Link } from "@mui/material";
import CloseButton from '../CloseButton'
import PdfPopUp from "../Pop_ups/PdfPopUp";
import './drag_drop.css'
import GridBreak from "../GridBreak";
import UploadFileIcon from '@mui/icons-material/UploadFile';

/**
 * component used to upload / drag and drop files, props sent to FileUploader (https://github.com/KarimMokhtar/react-drag-drop-files)
 * @component
 * @category Functionality 
 */
function DragFiles(props){
    const [fileArr, setFileArr] = useState(null)
    const [openedFile, setOpenedFile] = useState(null)

    const fileColor='#0658c2'
    const darkGray = '#666'

    useEffect(()=>{
        console.log('fileArr',fileArr)
    },[fileArr])

    const handleUpload = (file) => {
        let new_file
        if(fileArr){
            new_file = [...fileArr, ...file]
        }else{
            new_file = [...file]
        }
        setFileArr(new_file)
        
        props.onUpdate(new_file)
        console.log('file',file)
    }

    const deleteFile = (index) => {
        let new_arr = [...fileArr]
        new_arr.splice(index,1)
        setFileArr(new_arr)
        props.onUpdate(new_arr)
    }

    function UploadText(props){
        switch(props.uploadStatus){
            case 'pending':
                return(
                    <>
                    <Grid>
                        <UploadFileIcon sx={{color:fileColor}}/>
                    </Grid>
                    <Grid>
                            <Typography color={darkGray} sx={{textDecoration:'underline'}} display='inline'>Upload</Typography>  
                            <Typography color={darkGray} display='inline'> or drop a file right here</Typography>
                    </Grid>
                    <Grid>
                        <Typography color={darkGray}> | {String(props.types)}</Typography>
                    </Grid>
                    </>
                )
            
            case 'success':
                return(
                    <>
                    <Grid>
                        <UploadFileIcon sx={{color:fileColor}}/>
                    </Grid>
                    <Grid>
                        <Box sx={{flexDirection:'column'}}>
                            <Typography color={darkGray} sx={{textDecoration:'underline'}} display='inline'>Upload Successful!</Typography>  
                            <Typography color={darkGray} display='inline'>  Click or Drag Here To Upload Additional Files</Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <Typography color={darkGray}> | {String(props.types)}</Typography>
                    </Grid>
                    </>
                )
            case 'error':
                return(<>
                <Grid>
                    <UploadFileIcon sx={{color:fileColor}}/>
                </Grid>
                <Grid>
                    <Typography color="red">File Types/Size Error!{props.maxSize ? ` Ensure File is less than ${props.maxSize}mb` : ""}
                    {props.maxSize && props.types ? " and" : ""}
                    {props.types ? ` Ensure File Type is One of ${props.types}` : ""}
                    </Typography>
                </Grid>
                <Grid>
                    <Typography color={darkGray}> | {String(props.types)}</Typography>
                </Grid>
                </>)
            case 'disabled':
                return(<>
                <Grid>
                    <UploadFileIcon sx={{color:darkGray}}/>
                </Grid>
                <Grid>
                <Typography color={darkGray} sx={{textDecoration:'underline'}} display='inline'>Upload disabled</Typography>  
                </Grid>
                </>)
        }
        return (<></>)
    }


    return(
        <Grid container sx={{justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
            <Grid item container xs={12} spacing={1} sx={{justifyContent:'center', alignItems:'center'}}>
                {fileArr && fileArr.length ? fileArr.map((file,index) => (
                    <Grid item container xs={Math.max(4, 12/fileArr.length)} sx={{justifyContent:'center',alignItems:'center'}}>
                        <Grid>
                            <Typography>{file.name}</Typography>
                        </Grid>
                        <Grid> <CloseButton onClick={(e) => {e.preventDefault(); deleteFile(index)}}/></Grid>
                    </Grid>
                    )) : <></>}
            </Grid>
            <Grid item xs={12}>
                <Typography align='center'>{props.uploadText ? props.uploadText : `Upload Additional ${String(props.types)} Files (optional)`}</Typography>
            </Grid>
            <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                <FileUploader {...props} handleChange={handleUpload} fileOrFiles={fileArr} classes="drag_drop">
                    <Grid container sx={{justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                        <UploadText uploadStatus={props.uploadStatus} types={props.types} maxSize={props.maxSize}/>
                    </Grid>
                </FileUploader>
            </Grid>
        </Grid>
    )
}

DragFiles.propTypes = {
    /**
     * current status of FileUploader
     */
    uploadStatus : PropTypes.oneOf(['pending','error','success', 'disabled']),
    /**
     * function called on uploading of file
     */
    onUpdate: PropTypes.func.isRequired,
    types :PropTypes.arrayOf(PropTypes.string),
    uploadText : PropTypes.string
}

DragFiles.defaultProps = {
    types : ['docx','pdf']
}

export default DragFiles