import react, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField,MenuItem} from "@mui/material";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import moment from "moment";
import { DatePicker } from "@mui/lab";
import validator from "validator";
import './Resource.css'

export function EditableRow({
  cell,
  isActive,
  setIsActive,
  selectedRow,
  setIsEditable,
  setRowId,
  editValues,
  setEditValues,
  refresh,
  setRefresh,
  roleObj,
  recruiters,
  clients
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [initialValues, setInitialValues]= useState()

  useEffect(() => {
 
    let [value] = selectedRow.map((row) => row.original);
    let formatStartDate = value.start_date ? moment.utc(value.start_date).format("YYYY-MM-DD").toString() : 'YYYY-MM-DD';
    setEditValues({...value, start_date:formatStartDate})
    

  }, []);
  useEffect(()=>{
    console.log('edit', editValues)
  },[editValues])

  const updateDate = (field, value) => {
    let temp = {
      ...editValues,
    };
    temp[field] = moment.utc(value).format("YYYY-MM-DD").toString();
    setEditValues(temp);
  };
  const updateContact = (e) =>{
    const onlyDigits = e.replace(/\D/g, "");
    setEditValues({ ...editValues, [cell.column.id]: onlyDigits });

  };
  const updateEmail = (e) =>{
    const removeWhitespace = e.replace(/\s/g, '');
    console.log("onlyDigits", removeWhitespace)
    setEditValues({...editValues, [cell.column.id]: removeWhitespace})
  }

  const onChangeHandler = (event) => {
    setEditValues({ ...editValues, [cell.column.id]: event.target.value });
  };
  const idConverter = (event)=>{
    if (cell.column.id === 'rec_name'){
      setEditValues({...editValues, recruiter: event.target.value})
    }
    if (cell.column.id === 'acc_name'){
      setEditValues({...editValues, account_manager: event.target.value})
    }
    if (cell.column.id === 'sourc_name'){
      setEditValues({...editValues, sourcer: event.target.value})
    }
    if (cell.column.id === 'sales_name'){
      setEditValues({...editValues, sales: event.target.value})
    }
    if (cell.column.id === 'Client'){
      // client_name = cell.column.
      setEditValues({...editValues, client: event.target.value})
    }
  }
  const handleClose = () => {
    cell.row.toggleRowSelected();
    setEditValues({
    first_name: "",
    last_name: "",
    invoice_id: "",
    start_date: null,
    client: "",
    recruiter: "",
    account_manager: "",
    sourcer: "",
    sales:""
    })
    setRowId('')
    setIsEditable(false)
    setIsActive(false);
  };
  const onSaveHandler = (e) => {
    try{
    e.preventDefault();
    setLoading(true);
    console.log('editValues',editValues)
    if (
      editValues.first_name === "" ||
      editValues.last_name === "" 
    ) {
      setLoading(false);
      setOpen(true);
      setMessage("Please complete the required sections of the form!");
      
    } 
    // else if (
    //   !validator.isEmail(editValues.primaryEmail) ||
    //   (editValues.secondaryEmail && !validator.isEmail(editValues.secondaryEmail))
    // ) {
    //   setLoading(false);
    //   setOpen(true);
    //   setMessage("Invalid email format. Please try again!");
    // } 
    else {
      getAmplifyToken((jwt) => {
        fetch(`${process.env.REACT_APP_ADMIN_API}/admin/perm`, {
          method: "PUT",
          body: JSON.stringify({
            id: editValues.id,
            first_name: editValues.first_name,
            last_name: editValues.last_name,
            invoice_id: editValues.invoice_id,
            start_date: editValues.start_date !== "YYYY-MM-DD" ? editValues.start_date:null,
            client:editValues.client,
            recruiter: editValues.recruiter,
            account_manager: editValues.account_manager,
            sourcer: editValues.sourcer,
            sales:editValues.sales
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: jwt,
          },
        }).then((response) => {
          setLoading(false);
          if (response.ok) {
            setSuccess(true);
            setMessage(
              `${editValues.first_name} ${editValues.last_name}`.concat(
                " has been edited successfully!"
              )
            )
            setIsActive(false);
            ;
          } else {
            setOpen(true);
            setMessage("Could not add to the database. Please try again");
          }
        });
      });
    }
    }catch(e){
      setLoading(false)
      setOpen(true)
      console.error(e)
      setMessage("An Error Occured. Please Try Again.")
    }
    
  };

  return (
    <>
      <TextPopUp
        title="Success!"
        content={message}
        open={success}
        onClose={(event) => {
          event.preventDefault();
          setSuccess(false);
          setRefresh(!refresh);
          setIsEditable(false)
        }}
        fullWidth
      />
      <ErrorPopUp
        open={open}
        onClose={(e) => {
          e.preventDefault();
          setOpen(false);
        }}
        errorContent={message}
      />
      <LoadingPopUp open={loading} />
      <TableCell className = 'editable' align="center" {...cell.getCellProps()}>
        {cell.column.id === "edit" ? (
          <span>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <IconButton onClick={onSaveHandler}>
            <SaveIcon />
          </IconButton>
          </span>
        ) : cell.column.id === "Id" ? (
          ""
        ) : cell.column.id === "expander" ? (
          <span>{cell.render("Cell")}</span>
        ): cell.column.id === "credential" ? (
          <IconButton onClick={onSaveHandler}>
            <SaveIcon />
          </IconButton>
        ) : cell.column.id === "start_date" ? (
          <DatePicker
            value={editValues.start_date ? moment.utc(editValues[cell.column.id]) : null}
            helperText={' '}
            onChange={(value) => {
              updateDate("start_date", value);
            }}
            renderInput={(params) => (
              <TextField className='date-picker' variant='standard' helperText={' '} {...params} error={false} sx={{ width: "70%", margin: "5px" }} />
            )}
          />
        ) : cell.column.id ==="contactNumber" ? (
          <TextField
            value={editValues[cell.column.id]}
            helperText={' '}
            sx={{ width: "90%", margin: 0 }}
            inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
            InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
            onChange={(event) => {
              updateContact(event.target.value);
            }}
            variant="standard"
          ></TextField>)
          : cell.column.id === "invoice_id" ? (
            <TextField
              value={editValues[cell.column.id]}
              type="number"
              helperText={' '}
              sx={{ width: "90%", margin: 0 }}
              inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
              InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
              onChange={(event) => {
                onChangeHandler(event);
              }}
              variant="standard"
            ></TextField>)
        : cell.column.id === "first_name" || cell.column.id === "last_name" ? (
          <TextField
          helperText={!editValues[cell.column.id]? "required":' '}
          error={!editValues[cell.column.id]}
          value={editValues[cell.column.id]}
          required
          sx={{ width: "90%", margin: 0 }}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
          onChange={(event) => {
            onChangeHandler(event);
          }}
          variant="standard"
          ></TextField>
        ) :cell.column.id === "Client" ? (<TextField
          value={editValues['client']}
          helperText={' '}
          sx={{ width: "90%", margin: 0 }}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
          select
          onChange={(event) => {
            idConverter(event);
          }}
          variant="standard"
          >{clients?.map((item)=>{
            return(
            <MenuItem value={item.id}>{item.short_name}</MenuItem>)
          })}
        </TextField>)
        : cell.column.id === 'rec_name' ? (
          <TextField
          value={editValues['recruiter']}
          helperText={' '}
          sx={{ width: "90%", margin: 0 }}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
          select
          onChange={(event) => {
            idConverter(event);
          }}
          variant="standard"
          >{recruiters?.map((item)=>{
            return(
            <MenuItem value={item.id}>{item.user_name}</MenuItem>)
          })}
        </TextField>
        )
        : cell.column.id === 'acc_name' ? (
          <TextField
          value={editValues['account_manager']}
          helperText={' '}
          sx={{ width: "90%", margin: 0 }}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
          select
          onChange={(event) => {
            idConverter(event);
          }}
          variant="standard"
          >{recruiters?.map((item)=>{
            return(
            <MenuItem value={item.id}>{item.user_name}</MenuItem>)
          })}
        </TextField>
        )
        : cell.column.id === 'sourc_name' ? (
          <TextField
          value={editValues['sourcer']}
          helperText={' '}
          sx={{ width: "90%", margin: 0 }}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
          select
          onChange={(event) => {
            idConverter(event);
          }}
          variant="standard"
          >{recruiters?.map((item)=>{
            return(
            <MenuItem value={item.id}>{item.user_name}</MenuItem>)
          })}
        </TextField>
        )
        : cell.column.id === 'sales_name' ? (
          <TextField
          value={editValues['sales']}
          helperText={' '}
          sx={{ width: "90%", margin: 0 }}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
          select
          onChange={(event) => {
            idConverter(event);
          }}
          variant="standard"
          >{recruiters?.map((item)=>{
            return(
            <MenuItem value={item.id}>{item.user_name}</MenuItem>)
          })}
        </TextField>
        )
        :(
          <TextField
          value={editValues[cell.column.id]}
          helperText={' '}

          sx={{ width: "90%", margin: 0 }}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
          select
          onChange={(event) => {
            onChangeHandler(event);
          }}
          variant="standard"
          >{recruiters?.map((item)=>{
            return(
            <MenuItem value={item.id}>{item.user_name}</MenuItem>)
          })}
        </TextField>
        )}
      </TableCell>
    </>
  );
}
