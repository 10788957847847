import React from "react";
import PropTypes from 'prop-types'
import { Avatar, Grid, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClickBox from "../ClickBox"

/**
 * Simble bar used to display name of Resources for use in ResourceDetailsPanel
 * @component
 * @category Resource Details Management
 */
function DetailsDisplayBar(props){
    return(
        <Grid container>
            {props.direction === 'left' ? 
            <Grid item xs={1}>
                {!props.disabled ?
                <ClickBox onClick={(event)=>{event.preventDefault(); props.onClick(props.details)}}>
                    <ChevronLeftIcon/>
                </ClickBox> 
                : <></>}
                
                
            </Grid>:<></> }
            <Grid item xs={2}>
                <Avatar sizes="small"/>
            </Grid>
            <Grid item container xs={9} sx={{justifyContent:'flex-start'}}>
                <Typography align='center'>{props.details.first_name} {props.details.last_name} ({props.details.primary_email}) {props.disabled ? '*' : ""}</Typography>
                {props.disabled ? 
                <Typography variant='subtitle1' fontSize='8pt'>This Resource Already Has A Timesheet for This Project, Please Invalidate It Before Removing This Resource</Typography> 
                : <></>}
            </Grid>
            {props.direction === 'right' ? 
            <Grid item xs={1}>
                {!props.disabled ? 
                <ClickBox onClick={(event)=>{event.preventDefault(); props.onClick(props.details)}}>
                    <ChevronRightIcon/>
                </ClickBox>
                :<></>}
                
                
            </Grid>:<></> }
        </Grid>
    )
}

DetailsDisplayBar.propTypes={
    /**
     * object representing the ProjectDetails row in db to be displayed
     */
    details: PropTypes.object.isRequired,
    /**
     * direction in which movement chevron should be on
     */
    direction: PropTypes.oneOf(['right', 'left']),
    /**
     * function called when chevron is clicked, passes the details contained within the DetailsDisplayBar
     */
    onClick: PropTypes.func.isRequired
}

export default DetailsDisplayBar