import { getAmplifyToken } from "./AmplifyHelp";
import { ErrorObject } from "./generalHelp";


/**
 * function that takes invoiceBody and passes it on to backend to either add or update an invoice
 * @param {object} invoiceBody object representing invoice to be added or edited
 * @param {responseHandler} callback callback function to return response to 
 * @category Api Calls
 * @subcategory Invoices
 * @module
 */
export function putInvoice(invoiceBody, callback){
    getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/invoice/add`,{
        method:'PUT',
        headers: { "Content-Type": "application/json", "Authorization": jwt },
        body: JSON.stringify(invoiceBody)
    }).then(resp => callback(resp)).catch(err => {console.error(err); callback(false)})
    })
    
}
/**
 * downloads an invoice document from s3 that has the name filename
 * @param {String} filename name of file to be downloaded
 * @param {responseHandler} callback function where the response is passed to
 */
export function getInvoiceDocumentUrl(filename, callback){
    getAmplifyToken((jwt)=>{
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/s3/presigned?filename=${filename}`,
        {headers:{Authorization:jwt}}).then(resp => resp.json()).then(data => callback(data.url))
        .catch(err => {console.error(err); callback(undefined)})
    })
}

/**
 * api call used to reset a timesheet to a new status
 * @param {object} jsonObj object that contains the id of the timesheet with changing status, status to change to, and admin that is changing it 
 * @param {responseHandler} callback 
 */
export function putTimesheet(jsonObj, callback){
    getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/reset`,{
        method:'POST',
        headers: { "Content-Type": "application/json", "Authorization": jwt },
        body: JSON.stringify(jsonObj)
    }).then(resp => callback(resp.ok)).catch(err => {console.error(err); callback(false)})
    })   
}

export function weekUnlockTimesheet(jsonObj, callback){
    getAmplifyToken((jwt) => {
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/week_reset`,{
            method:'POST',
            headers: { "Content-Type": "application/json", "Authorization": jwt },
            body: JSON.stringify(jsonObj)
        }).then(resp => callback(resp.ok)).catch(err => {console.error(err); callback(false)})
    })  
}


export function setTimesheetStatus(resource_id, project_id, ref_date, status, callback){
    getAmplifyToken((jwt) => {
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/week_reset`,{
            method:'POST',
            headers: { "Content-Type": "application/json", "Authorization": jwt },
            body: JSON.stringify({
                resource_id: resource_id,
                project_id: project_id,
                ref_date, ref_date,
                status: status
            })
        }).then(resp => callback(resp.ok)).catch(err => {console.error(err); callback(false)})
        })   
}

export function setTimesheetInvoice(invoiceObj, callback){
    getAmplifyToken((jwt)=>{
        fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/invoice/addweek`,{
            method: 'PUT',
            headers: { "Content-Type": "application/json", "Authorization": jwt },
            body: JSON.stringify(invoiceObj)
        }).then(async resp => resp.ok ? resp : new ErrorObject(resp.status, await resp.text()))
        .then(resp => callback(resp))
        .catch((err) => {console.error(err); return new ErrorObject(500, "could not add invoice, please try again.")})
    })
}