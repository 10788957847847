import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import React, {useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import HoverButton from '../HoverButton';
import PropTypes from 'prop-types'
import {fonts} from '../../util/Theme'
import { isObjectEmpty, isProfileAdmin } from '../../util/generalHelp';

/**
 * drop down that lists the different pages an admin has access to and navigates to them
 * @component
 * @category Headers and Footers
 * @subcategory NavBar 
 */
function ProjectsTile(props) {
    const[openDropDown, setOpenDropDown] = useState(false)
    const[anchoredElement,setAnchoredElement] = useState(false)

    const ref = useRef()

    const navigate = useNavigate()

    const handleClick = (event) =>{
        event.preventDefault()
        setOpenDropDown(true)
        setAnchoredElement(event.target)
    }

    const navAndClose = (url) => {
        navigate(url);
        setOpenDropDown(false)
    }

    return (
        <>
            {!isObjectEmpty(props.roles) && isProfileAdmin(props.roles) && props.role === 'admin' ? <>
            <HoverButton ref={ref} onClick={handleClick} color={props.selected || openDropDown ? props.selectedColor : props.color} hoverColor={props.hoverColor}>Project Management</HoverButton>
            <Menu open={openDropDown} anchorEl={anchoredElement} onClose={(event)=>setOpenDropDown(false)}
            PaperProps={{style:{minWidth:ref.current ? ref.current.offsetWidth + (ref.current.offsetWidth / 100) : 0}}}>
                {/* <MenuItem disabled><Typography variant='body1' fontSize={fonts.menuSize}>Project Management</Typography></MenuItem>
                <Divider/> */}
            
                {props.role === 'admin' && (props.roles['admin'] === true || props.roles['timesheet_admin'] === true) ? <div>
                <MenuItem onClick={(event) => navAndClose('/home/admin/clients')}>
                    <Typography variant='body1' fontSize={fonts.menuSize}>Clients View</Typography>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={(event) => navAndClose('/home/admin/submitted')}>
                    <Typography fontSize={fonts.menuSize}>Timesheet View</Typography>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={(event) =>navAndClose('/home/admin/projects')}>
                    <Typography fontSize={fonts.menuSize}>Projects View</Typography>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={(event)=> navAndClose('/home/admin/reports')}>
                    <Typography fontSize={fonts.menuSize}>Reports View</Typography>
                </MenuItem>
                <Divider/>
                </div> : <div></div>}
                {props.roles['admin'] === true || props.roles['offers'] ? <div>
                <MenuItem onClick={(event)=> navAndClose('/home/admin/envelopes')}>
                    <Typography fontSize={fonts.menuSize}>Offers/Contracts View</Typography>
                </MenuItem>
                <Divider/>
                </div> : <div></div>}
                {props.role === 'admin' && (props.roles['admin'] === true || props.roles['resource_admin'] === true) ? <div>
                <MenuItem onClick={(event)=> navAndClose('/home/admin/resources')}>
                    <Typography fontSize={fonts.menuSize}>Resources View</Typography>
                </MenuItem>
                <Divider/>
                </div> : <div></div>}
                {props.role === 'admin' && props.roles['admin'] === true ? <div>
                <MenuItem onClick={(event)=> navAndClose('/home/admin/users')}>
                    <Typography fontSize={fonts.menuSize}>Users View</Typography>
                </MenuItem>
                <Divider/>
                </div> : <div></div>}
                {props.role === 'admin' && props.roles['admin'] === true ? <div>
                <MenuItem onClick={(event)=> navAndClose('/home/admin/perm')}>
                    <Typography fontSize={fonts.menuSize}>Perm View</Typography>
                </MenuItem>
                </div> : <div></div>}
            
            </Menu>
            </> : <></>} 
        </>
    );
}

ProjectsTile.propTypes = {
    /**
     * whether or not ProjectsTile has been clicked on
     */
    selected: PropTypes.bool,
    /**
     * color of ProjectsTiles to turn after having being clicked
     */
    selectedColor: PropTypes.string,
    /**
     * standard color of ProjectsTile
     */
    color: PropTypes.string,
    /**
     * color of projectsTile when hovered over
     */
    hoverColor: PropTypes.string,
    /**
     * dictionary with roles and whether or not the profile has them
     */
    roles: PropTypes.object.isRequired,
    /**
     * string designating role of current user (either admin or developer)
     */
    role : PropTypes.string.isRequired
}

ProjectsTile.defaultProps={
    selectedColor: 'secondary.main',
    selected:false
}

export default ProjectsTile;