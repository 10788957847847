import { Typography, Button } from '@mui/material'
import { useLocation, useNavigate } from "react-router-dom";
import React, {useMemo,useState, useEffect} from 'react'
import { createEditor, Descendant } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { ClientSections } from './ClientSections';
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";

import './Clients.css'


export const ClientDetails = () => {
    const loc = useLocation();
    const [editorData, setEditorData] = useState({});
    const [error, setError] = useState(false)
    const [message,setMessage] = useState("")

    useEffect(()=>{
        getAmplifyToken((jwt) => {
            fetch(`${process.env.REACT_APP_ADMIN_API}/admin/sections`, {
              headers: { Authorization: jwt },
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }
              })
              .then((data) => {
                try{
                setEditorData(data.filter(data=>loc.state.id === data.client_id))
                }
                catch(err){
                    setMessage("There was a problem trying to load Client Details. Please try again")
                    setError(true)
                }
                // filteredDetails = editorData.filter(data=>loc.state.id === data.client_id)
              })
              .catch((err) => {
                console.log(err);
              });
          });
        
    },[])

    useEffect(()=>{
        console.log('editorData',editorData)
    }, [editorData])


  return (
    <>
    <ErrorPopUp open={error}
        onClose={(e) => {
          e.preventDefault();
          setError(false);
        }}
        errorContent={message}></ErrorPopUp>
    <Typography align='center' variant='h3'>
        Client: {loc.state.short_name}

    </Typography>
    
    <ClientSections client_id={loc.state.id} editor_data={editorData[0]?.editor_data ? editorData[0].editor_data: undefined} ></ClientSections>
    </>
  )
}
