import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Typography, Paper, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import skyline from "./skyline.jpg";
import LoadingScreen from "../../components/LoadingScreen";
import HoverButton from "../../components/HoverButton";
import { ErrorObject, isObjectEmpty, isProfileAdmin } from "../../util/generalHelp";
import {fonts} from '../../util/Theme'
import PropTypes from 'prop-types'
import ConHomeDisplay from "../../components/Home/ConHomeDisplay";
import AdminHomeDisplay from "../../components/Home/AdminHomeDisplay";
import HomeDisplay from "../../components/Home/HomeDisplay";
import { gettUserPii, submitUserPii } from "../../util/ProfileHelper";
import PiiPopUp from "../../components/Pop_ups/PiiPopUp";
import ErrorPopUp from "../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../components/Pop_ups/TextPopUp";
/**
 * Home page for users
 * @component 
 * @category Pages
 * @subcategory Consultant 
 */
function Home(props) {
  const [piiCollected, setPiiCollected] = useState(true);
  const [piiError, setPiiError] = useState(null);
  const [piiSuccess, setPiiSuccess] = useState(false);

  useEffect(() => {
    gettUserPii(props.profile.id, (response => {
      if(response instanceof ErrorObject){
        if(response.status == 404)
          setPiiCollected(false)
        else
          response.displayError()
        return;
      }
    }))
  }, [props.profile]);

  function submitPii (piiObj){
    console.log('function called')
    submitUserPii(props.profile.id, piiObj, (resp => {
      if(resp instanceof ErrorObject){
        setPiiError(resp);
      }else{
        setPiiCollected(true);
        setPiiSuccess(true)
      }
    }))
  }

  return (
    <div className="Panel">
      {!(isObjectEmpty(props.profile)) ? (
        <>
        <TextPopUp open={piiSuccess} onClose={(e) => {e.preventDefault(); setPiiSuccess(false)}} title="Success!" 
        content="Your personal identifying information has been successfully saved and encrypted."/>
        <ErrorPopUp open={Boolean(piiError)} errorTitle="An Error Has Occured" errorContent={Boolean(piiError) ? piiError.message : ""} 
        onClose={e => {e.preventDefault(); setPiiError(null)}}/>
        <PiiPopUp open={!piiCollected} onSubmit={submitPii} profile={props.profile}/>
          <Grid container>
          <Grid item container xs={4} sx={{ bgcolor: "black" }}>
            <Box
              sx={{
                boxShadow: 5,
                height: '100%',
                minHeight:'93vh',
                m: "0.25%",
                mt:'0%',
                mb:'0%',
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "background.default",
                color: "primary.contrastText",
              }}
            >
              <HomeDisplay profile={props.profile}/>
            </Box>
          </Grid>
          <Grid item container xs={8}>
            <div
              style={{
                width: "100%",
                backgroundSize: "max(cover, 100%)",
                background: `url(https://i.pinimg.com/originals/21/0c/25/210c25411bb8f28396944a476471cc5f.jpg)`,
              }}
            >
            </div>
          </Grid>
          </Grid>
      </>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}

Home.propTypes ={
  profile: PropTypes.object.isRequired
}

export default Home;
