import {Auth} from 'aws-amplify'
import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'

/**
 * function that handles the base64 encoding of a file
 * @callback b64Handler
 * @param {String} b64 base 64 of string
 */

/**
 * function used to determine whether or not an object is empty
 * @param {object} obj 
 * @returns true if obj is empty otherwise false
 * @category Helper Functions
 */
export function isObjectEmpty(obj){
    if(obj == undefined || obj == null) return true
    return Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype
}

/**
 * function used to remove the year from a date string
 * @param {String} dateString 
 * @returns the same date string but without the year
 * @category Helper Functions
 */
export function dateStringWithoutYear(dateString){
    let temp = dateString.split(' ') //['Weekday', 'Month', 'Day', 'Year']
    return `${temp[0]}, ${temp[1]} ${temp[2]}`
}

/**
 * function used to remove the weekday from a date string
 * @param {String} dateString 
 * @returns the same date string but without the weekday
 * @category Helper Functions
 */
export function dateStringWithoutWeekday(dateString){
    let temp = dateString.split(' ') //['Weekday', 'Month', 'Day', 'Year']
    return `${temp[1]} ${temp[2]}, ${temp[3]}`
}

// export async function getJWTFromAmplify(callback){
//     Auth.currentSession().then(res=>{
//         let accessToken = res.getAccessToken()
//         let jwt = accessToken.getJwtToken()
//         //You can print them to see the full objects
//         console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
//         console.log(`myJwt: ${jwt}`)
//         callback(jwt)
//       })
// }

/**
 * function used to get the searchparams of current url
 * @returns UrlSearchParams of current url
 * @category Helper Functions
 */
export function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  /**
   * function used to get the start of timesheet period given frequency and date
   * @param {String} frequency frequency of timesheet 
   * @param {Date} date reference date to be used for timesheet perido
   * @returns start date of same period
   * @category Helper Functions
   * 
   */
export function getPeriodStart(frequency='monthly',date=moment().toDate()){
    if(frequency === 'monthly'){
        return moment(date).startOf('month').toDate()
    }else if(frequency === 'semimonthly'){
        if(moment(date).date() > 15){
            return moment(date).startOf('month').set('date',16).toDate()
        }
        return moment(date).startOf('month').toDate()
    }
    return null
}

/**
 * function used to determine whether or not text cursor is at the end of a string
 * @param {Object} event typing event 
 * @returns true if cursor is at end otherwise false
 * @category Helper Functions
 */
export function isCursorAtEnd(event){
    return event.target.value.length === event.target.selectionStart && event.target.selectionStart === event.target.selectionEnd
}

/**
 * function used to determ whether or not text cursor is at the start of a string
 * @param {Object} event typing event 
 * @returns true if cursor is at start otherwise false
 * @category Helper Functions
 */
export function isCursorAtStart(event){
    return 0 === event.target.selectionStart && event.target.selectionStart === event.target.selectionEnd
}

/**
 * gets parent of passed path
 * @param {String} path absolute path being searched for direct parent
 * @returns absolute path of direct parent
 * @category Helper Functions
 */
export function getParent(path){
    return path.substring(0, path.lastIndexOf("/"));
  };

  /**
   * used to get start date of a period given date and frequency (at the moment doesn't calculate for weekly frequency)
   * @param {Date} date reference date for start of period 
   * @param {String} frequency frequency of timesheet
   * @returns start date of period
   * @category Helper Functions
   */
export function get_start_date(date, frequency){
    let temp = moment.utc(date)
    if(frequency == 'semimonthly'){
        temp = temp.date() > 15 ? temp.set("date",15) : temp.set("date",1)
    }else{
        temp.set("date",1)
    }
    return temp.toDate()
}

/**
 * converts a file to a base64 string and passes it to callback
 * @param {File} file file to be turned into base64
 * @param {b64Handler} callback 
 * @category Helper Functions
 */
export function fileTo64(file, callback){
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        //console.log('reader result',reader.result)
        //getting rid of data type and only keeping b64 string
        callback(reader.result.split(',')[1])
    };
    reader.onerror = function (error) {
        console.error('Error: ', error);
    };
}

/**
 * converts an array of files to objects containing name extension and b64
 * @param {Array<File>} fileArr 
 * @returns A promise for array of objects
 */
export async function fileTo64Arr(fileArr){
    return await Promise.all(
        fileArr.map(
          (image) =>
          new Promise((resolve, reject) => {
              const fileReader = new FileReader();
    
              fileReader.onload = (file) => {
                resolve({file_name:image.name, b64: file.target.result.split(',')[1], extension: image.name.split('.').pop()});
              };
    
              fileReader.onerror = (error) => reject(error);
    
              fileReader.readAsDataURL(image);
            })
        )
    );
}

/**
 * determins whether or not given file is an image
 * @param {File} file file being checked 
 * @returns true if file is an image otherwise false
 * @category Helper Functions
 */
export function isFileImage(file){
    try{
        return file.type.match(/.(jpg|jpeg|png|gif)$/i)
    }catch{return false}
}

/**
 * takes a string and capitalizes each word
 * @param {String} str string to be capitalized 
 * @returns str but with each word capitalized
 * @category Helper Functions
 */
export function capitalizeString(str){
    //console.log('capitalizing',str)
    try{
        if(str instanceof String || typeof str === 'string'){
            const words = str.split(" ");
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substring(1);
            }
    
            return words.join(' ')
        }
    }catch(e){
        //console.error(e)
        //console.log('error string',str,typeof(str))
        return str
    }
    
    return str
    
}

/**
 * gets number of decimals in a number
 * @param {Number} number number to be checked for number of decimals
 * @returns number of decimals in number
 * @category Helper Functions
 */
export function getNumDecimals(number){
    try{
        if(Math.floor(Number(number)) === (Number(number))) return 0;
        return String(number).split(".")[1].length || 0; 
    }catch(e){
        return 0
    }
}

/**
 * gest first n decimals in number
 * @param {Number} number number to get decimals from
 * @param {Number} n number of decimals 
 * @returns firs n decimals of number
 * @category Helper Functions
 */
export function getFirstNDecimals(number, n=2){
    try{
        if(Math.floor(Number(number)) === (Number(number))) return n;
        let splits = String(number).split(".");
        return `${splits[0]}.${splits[1].substring(0,n)}`
    }catch{
        return number
    }
}

export function handleFormNumbers (number, decimals=0, negative=false, maxdigits=undefined){
    let decimal = false
    try{
        if(number === "") return number
        if(maxdigits && String(number).length > maxdigits) return undefined
        
        if(String(number).charAt(number.length - 1) === '.' && decimals > 0){
            decimal = true
        }

        number = Number(number)
        if(isNaN(number)){
            return undefined
        }
    }catch{
        return undefined
    }
    if(!negative) number =  Math.max(number,0)

    let decimalMult = Math.pow(10,decimals)
    let newNum = String(Math.trunc(number * decimalMult) / decimalMult)
    if(decimal){
        return newNum.concat('.')
    }
    return newNum
}

export const handleError=(err, callback)=>{
    if(err.name !== 'AbortError'){
      console.error(err)
      callback(undefined)
    }
  }

export  const checkJson = (json, optionalFields=[]) => {
    console.log('optionalFields',optionalFields)
    for(const key of Object.keys(json)){
        if(json[key] === null || json[key] === undefined || json[key] === ''){
            // console.log('key',key)
            if(!optionalFields.includes(key)){
                console.log('key',key,'is empty')
                return false
            }
        }
        
        if(json[key].constructor == Object){
            if(!checkJson(json[key],optionalFields)){
                return false
            }
            continue
        }
    }
    return true
}

export const toMoneyString = (money) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(Number(money))
}
export function isProfileAdmin(roles){
    const admin_roles = ['admin', 'timesheet_admin','offers']

    for(let role of admin_roles){
        if(roles[role] === true) return true
    }
    return false
    
}


export function objectContainsString(object, string, searchable_fields = [], recursive=true, case_sensitive = false, count_whitespace=false){

    if(!case_sensitive) string = string.toLowerCase()
    if(!count_whitespace) string = string.trim()

    for(const key of Object.keys(object)){
        if(object[key] === null || object[key] === undefined) continue
        console.log('string test', object[key],(typeof object[key] === 'string' || object[key] instanceof String))
        console.log('searchable test',key, (searchable_fields.length === 0 || searchable_fields.includes(key)))
        if((typeof object[key] === 'string' || object[key] instanceof String) && (searchable_fields.length === 0 || searchable_fields.includes(key))){
            let comp = object[key]
            console.log('comp',comp,'string',string)
            if(!case_sensitive) comp = comp.toLowerCase()
            if(!count_whitespace) comp = comp.trim()

            if(comp.includes(string)){
                console.log(comp,string)
                return true
            }
        }
        
        if(object[key].constructor == Object && recursive){
            if(objectContainsString(object[key],string,searchable_fields,recursive,case_sensitive,count_whitespace)){
                return true
            }
        }
    }
    return false
    
}

export class ErrorObject{
    constructor(status, message){
        this.status = status;
        if(status == 500){
            this.message = "An internal error has occured, please reload the page and try again."
        }
        else{
            this.message = message;
        }
    }

    displayError(){
        return `Error code ${this.status}: ${this.message}`;
    }
}