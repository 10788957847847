import React, {useState} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { fonts } from "../../../util/Theme";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import AlternateRow from "../../../components/AlternateRow";
import SubmittedCell from "../../../components/Submitted/SubmittedCell";
import ClickBox from "../../../components/ClickBox";

/**
 * table used to display timesheets on a consultant's side
 * @component
 * @category Timesheets
 * @subcategory Submitted Timesheets
 */
function SubmittedTsDisplay(props) {

  const [rowsPerPage, setRowsPerPage] = useState(25)
  const[page, setPage] = useState(0)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{}}>
        <TableHead>
          <TableRow sx={{}}>
            <SubmittedCell>Project</SubmittedCell>
            <SubmittedCell>Week Of (YYYY-MM-DD)</SubmittedCell>
            <SubmittedCell>Date Submitted (YYYY-MM-DD)</SubmittedCell>
            <SubmittedCell>Hours Worked</SubmittedCell>
            <SubmittedCell>Days Worked</SubmittedCell>
            <SubmittedCell>Status</SubmittedCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.timesheets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ts) => (
            <AlternateRow
              key={ts.id}
            >
              <SubmittedCell>{ts.project_name}</SubmittedCell>
              <SubmittedCell>
                {moment
                  .utc(ts.week_start)
                  .format("YYYY-MM-DD")
                  .toString()}
              </SubmittedCell>
              <SubmittedCell>
                {ts.submitted_date ? moment(ts.submitted_date).local().toDate().toString() : 'N/A'}
              </SubmittedCell>
              <SubmittedCell>{ts.hours_worked}</SubmittedCell>
              <SubmittedCell>{ts.days_worked}</SubmittedCell>
              <SubmittedCell>
                <ClickBox
                  onClick={
                    (event) => {
                          event.preventDefault();
                          props.onClick(ts);
                        }
                  }
                  clickable={ts.status == 'submitted'}
                >
                  <Typography
                    sx={
                      ts.status
                        ? { textDecoration: "underline" }
                        : {}
                    }
                    color={ts.status ? "green" : "#c40e29"}
                  >
                    {ts.status}
                  </Typography>
                </ClickBox>
              </SubmittedCell>
            </AlternateRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.timesheets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </TableContainer>
  );
}

SubmittedTsDisplay.propTypes = {
  /**
   * list of timesheets to be displayed
   */
  timesheets: PropTypes.instanceOf(Array).isRequired,
  /**
   * function called when clicking on the status of a timesheet
   */
  onClick: PropTypes.func.isRequired,
};

export default SubmittedTsDisplay;
