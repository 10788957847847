import React, { useState, Component, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Clients.css";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { TextEditor } from "./TextEditor";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";

export const EditorManager = ({ client_id, editorData }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [editorState, setEditorState] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error,setError] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    setEditorState(
      editorData
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(editorData)))
        : EditorState.createEmpty()
    );
  }, [editorData]);

  useEffect(() => {
    console.log("editorState", editorState);
  },[editorState]);

  const navigateToClient = () => {
    navigate("/home/admin/clients");
    window.location.reload();
  };
  return (
    <>
    <TextPopUp
        title="Success!"
        content={message}
        open={open}
        onClose={(event) => {
          event.preventDefault();
          setOpen(false);
        }}
        fullWidth
      />
      <ErrorPopUp
        open={error}
        onClose={(e) => {
          e.preventDefault();
          setError(false);
        }}
        errorContent={message}
      />
      <div className="editor-container">
        {isEditable ? (
          <ControlledEditor
            client_id={client_id}
            editorState={editorState}
            setIsEditable={setIsEditable}
            setEditorState={setEditorState}
            navigateToClient={navigateToClient}        
            setOpen={setOpen}     
            setMessage={setMessage}
            setError={setError}
          />
        ) : (
          <TextEditor
            client_id={client_id}
            editorState={editorState}
            setEditorState={setEditorState}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
          ></TextEditor>
        )}
      </div>
    </>
  );
};

class ControlledEditor extends Component {
  constructor(props) {
    super(props);
    // let content = this.props.editorData ? EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.editorData))): EditorState.createEmpty();
    this.state = {
      editorState: this.props.editorState,
    };
  }
  componentDidMount() {
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSaveClick = (editorState) => {
    let contentState = editorState.getCurrentContent();
    let note = { content: convertToRaw(contentState) };
    // var finalData = str.replace(/\\/g, "");

    getAmplifyToken((jwt) => {
      fetch(`${process.env.REACT_APP_ADMIN_API}/admin/sections`, {
        method: "POST",
        body: JSON.stringify({
          client_id: this.props.client_id,
          editor_data: note["content"],
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: jwt,
        },
      }).then((response) => {
        if (response.ok) {
          console.log("success");
          this.props.setOpen(true);
          this.props.setMessage("Details have been successfully saved");
        } else {
          this.props.setError(true);
          console.log("fail");
        }
      });
    });
    this.props.setEditorState(
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(JSON.stringify(note["content"])))
      )
    );

    this.props.setIsEditable(false);
  };

  render() {
    const { editorState } = this.state;
    return (
      <>
        <Button onClick={()=>this.props.setIsEditable(false)}>Cancel</Button>
        <Button
          onClick={() => {
            this.onSaveClick(editorState);
          }}
        >
          Save
        </Button>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      </>
    );
  }
}

// class ReadOnly extends Component {
//   constructor(props) {
//     super(props);
//     //   let content = this.props.editorData ? EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.editorData))): EditorState.createEmpty();
//     this.state = {
//       editorState: this.props.editorState,
//     };
//   }
//   componentDidMount() {
//     this.onEditorStateChange = this.onEditorStateChange.bind(this);
//   }

//   onEditorStateChange = (editorState) => {
//     this.setState({
//       editorState,
//     });
//   };

//   render() {
//     const { editorState } = this.state;
//     return (
//       <>
//         <Button
//           onClick={() => {
//             this.props.setIsEditable(true);
//           }}
//         >
//           Edit
//         </Button>
//         <Editor
//           editorState={editorState}
//           toolbarHidden="true"
//           readOnly="true"
//           wrapperClassName="demo-wrapper"
//           editorClassName="demo-editor"
//           onEditorStateChange={this.onEditorStateChange}
//         />
//       </>
//     );
//   }
// }
