import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import TimeSheets from "./pages/TimeSheets";
// import Submitted from "./pages/Submitted";
import Home from "./pages/global/Home";
import Amplify, { Auth } from "aws-amplify";
import { ThemeProvider } from "@mui/material";
import { mainLightTheme } from "./util/Theme";
import DateAdapter from "@mui/lab/AdapterMoment";
import { LocalizationProvider } from "@mui/lab";
import NotFound from "./pages/global/NotFound";
import Profile from "./pages/global/Profile";
import { Authenticator, AmplifyProvider} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { components } from "./pages/global/Login";
import './pages/global/Login.css'
import Projects from "./pages/global/Projects";
import Submitted from "./pages/global/Submitted";
import TimeSheets from './pages/global/TimeSheets';
import AdminSubmitted from "./pages/admin/AdminSubmitted";
import { useState, useEffect } from "react";
import Template from './pages/Template'
import { isObjectEmpty } from "./util/generalHelp";
import {getProfileFromEmail} from './util/ProfileHelper'
import ProjectsPage from "./pages/admin/ProjectsPage"
import ManageProject from "./pages/admin/ManageProject"
import {ClientsPage2} from "./pages/admin/ClientsPage/ClientsPage2"
import { ResourcePage } from "./pages/admin/ResourcePage/ResourcePage";
import {UserPage} from "./pages/admin/UserPage/UserPage";
import {ReportPage} from "./pages/admin/ReportPage/ReportPage"
import { ClientDetails } from "./pages/admin/ClientsPage/ClientDetails";
import Envelopes from "./pages/admin/OfferLetterPage/Envelopes";
import ExpenseForm from "./pages/global/ExpenseForm/ExpenseForm";
import AdminExpenses from "./pages/global/ExpenseForm/AdminExpenses";
import {PermPage} from "./pages/admin/PermPage/PermPage"
import AdminTimesheets from "./pages/admin/AdminTimesheetsPage/AdminTimesheets";
import SubmittedTs from "./pages/global/SubmittedTimesheets/SubmittedTs";


Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_ID,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
  },
});


function App() {

  const [profile,setProfile] = useState({})
  const [refreshInterval, setRefreshInterval] = useState()
  const [sessionLoaded, setSessionLoaded] = useState(false)
  const refreshTime=2000 //2000 ms

  useEffect(() => {
    if (!sessionLoaded) /*&& !(Boolean(JSON.parse(sessionStorage.getItem('profile'))) 
    && !isObjectEmpty(JSON.parse(sessionStorage.getItem('profile')))))*/ {
      console.log('from api')
      getProfileFromEmail(setProfile)
      setRefreshInterval(setInterval(checkLoaded,refreshTime))
    }
    // else if(Boolean(JSON.parse(sessionStorage.getItem('profile'))) 
    // && !isObjectEmpty(JSON.parse(sessionStorage.getItem('profile')))){
    //   console.log('from session storage')
    //   setProfile(JSON.parse(sessionStorage.getItem('profile')))
    // }
    return(clearInterval(refreshInterval))
  }, []);

  useEffect(()=>{
    if(!isObjectEmpty(profile)){
      clearInterval(refreshInterval)
      setRefreshInterval(null)
      setSessionLoaded(true)
      console.log('test',profile)
      //sessionStorage.setItem('profile',JSON.stringify(profile))
    }
  },[profile])

  const checkLoaded = () =>{
    if(!isObjectEmpty(profile)){
      setSessionLoaded(true)
      setRefreshInterval(null)
    }else{
      getProfileFromEmail(setProfile)
    }
  }

  const getAllowedRoutes = () => {
    return (<>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route name="home" path="/home" element={<Home profile={profile}/>} />
      <Route
        name="profile"
        path="/home/profile"
        element={<Profile profile={profile} profilePicture={profile.profile_picture} onUpdate={()=> getProfileFromEmail(setProfile)}/>}
      />
      <Route
        name="expense form"
        path='/home/expenses'
        element={<ExpenseForm profile={profile}/>}
      />
      <Route name="Not found" path="/*" element={<NotFound />} />
      {profile.roles['timesheet'] === true ? <>
      <Route
        name="time sheets"
        path="/home/projects/timeSheets"
        element={<TimeSheets profile={profile} adminProfile={profile.role === 'admin' ? profile : undefined}/>}
      />
      {/* <Route
        name="submitted time sheets"
        path="/home/submitted"
        element={<Submitted />}
      /> */}
      <Route
        name="submitted time sheets"
        path="/home/submitted"
        element={<SubmittedTs profile={profile}/>}
      />
      <Route name="Your Projects" path="/home/projects" element={<Projects profile={profile}/>}/>
      </> : <></>}
      {profile.role === 'admin' && (profile.roles['timesheet_admin'] == true || profile.roles['admin'] === true) ? <>
      {/* <Route name="Admin view" path='/home/admin/submitted' element={<AdminTimesheets profile={profile}/>}/> */}
      <Route name="Admin Timesheets" path='/home/admin/submitted' element={<AdminSubmitted profile={profile}/>}/>  
      <Route name="Admin clients" path="/home/admin/clients" element={<ClientsPage2/>}/>
      <Route name="Admin Perm" path="/home/admin/perm" element={<PermPage/>}/>
      <Route name="Admin Client Details" path="/home/admin/clients/client-details" element={<ClientDetails/>}/>
      <Route name='Admin Projects' path='/home/admin/projects' element={<ProjectsPage/>}/>
      <Route name='Admin Reports' path='/home/admin/reports' element={<ReportPage/>}/>
      <Route name='Admin Add Project' path = '/home/admin/projects/manage-project/:id' element={<ManageProject/>}/>
      </> : <></>}
      {profile.role === 'admin' && (profile.roles['offers'] === true || profile.roles['admin'] == true) ? <>
      <Route name='Admin Offers' path = '/home/admin/envelopes' element={<Envelopes profile={profile}/>}/>
      </> : <></>}
      {profile.role === 'admin' && (profile.roles['expense_admin'] === true || profile.roles['admin'] === true) ? <>
      <Route name='Admin Expenses' path ='/home/admin/expenses' element={<AdminExpenses profile={profile}/>}/>
      </>: <></>}
      {profile.role === 'admin' && (profile.roles['resource_admin'] === true || profile.roles['admin'] == true) ? <>
        <Route name='Admin Resources' path ='/home/admin/resources' element={<ResourcePage profile={profile}/>}/>
      </> :<></>}
      {profile.role === 'admin' && profile.roles['admin'] === true ? <>
      
      <Route name='Admin Users' path ='/home/admin/users' element={<UserPage profile={profile}/>}/>
      </> : <></>}
    </>)
  }

  return (
    
    <AmplifyProvider>
      <Authenticator components={components}>
        {({ signOut, user }) => (
          <LocalizationProvider dateAdapter={DateAdapter}>
            <ThemeProvider theme={mainLightTheme}>
              
              <BrowserRouter>
              {!isObjectEmpty(profile) ? 
              <Template role={profile.role} profile={profile}>
                <Routes>
                  {getAllowedRoutes()}
                </Routes>
                </Template> : <></>}
              </BrowserRouter>
              
            </ThemeProvider>
          </LocalizationProvider>
        )}
      </Authenticator>
    </AmplifyProvider>
  );
}
export default App;
