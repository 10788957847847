import React, { useEffect, useState } from "react";
import { Grid, Button, Menu, MenuItem, Box, AppBar, Toolbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Options from "./Options";
import HoverButton from "../HoverButton";
import logo from "./seq_logo_small.png"
import PropTypes from 'prop-types'
import ProjectsTile from "./ProjectsTile";
import TimesheetTile from "./TimesheetTile";
import ExpenseTile from "./ExpenseTile";

/**
 * Bar inside header to navigate between different pages
 * @component
 * @category Headers and Footers 
 * @subcategory NavBar
 */
function NavBar(props) {
  const navigate = useNavigate();

  const loc = useLocation()
  
  const isCurrentTab = (path) => {
    return (path === loc.pathname ? 'secondary.main' : undefined)
  }

  return (
        <AppBar position="sticky" color="primary">
            <Toolbar>
                <Grid container sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
          
          <Grid item container sx={{display:'flex', justifyContent:'flex-start', alignItems:'center'}} xs={2}>
            <Box sx={{cursor:'pointer'}} onClick={(event)=>navigate('/home')}>
              <img src={logo} object-fit="scale-down"/>
            </Box>
            <HoverButton variant="filled" onClick={(event)=>navigate("/home")} color={isCurrentTab('/home')}>Home</HoverButton>
          </Grid>
          <Grid item container sx={{display:'flex', justifyContent:'flex-end', alignItems:'center'}} xs={10}>
            <ProjectsTile selected={Boolean(isCurrentTab('/home/admin/clients') || isCurrentTab('/home/admin/submitted') || 
            isCurrentTab('/home/admin/projects')) || isCurrentTab('/home/admin/resources')} roles={props.profile.roles} role={props.role}/>
            <ExpenseTile selected = {Boolean(isCurrentTab('/home/admin/expenses') || isCurrentTab('/home/expenses'))} role={props.role} 
            roles={props.profile.roles}/>
            <TimesheetTile selected={Boolean(isCurrentTab('/home/submitted') || isCurrentTab('/home/projects') || isCurrentTab('/home/projects/timeSheets'))}
            roles={props.profile.roles} role={props.role}/>
            
          
          <Options selected={Boolean(isCurrentTab('/home/profile'))} profile={props.profile}/>
          </Grid>
                    
            </Grid>
            </Toolbar>
        </AppBar>
  );
}

NavBar.propTypes={
  /**
   * role of current logged in user
   */
  role: PropTypes.string.isRequired,
  /**
   * object representing the profile of the user (Resource object following db schema)
   */
  profile: PropTypes.object.isRequired
}

export default NavBar;
