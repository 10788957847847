import React, {useEffect, useState, useMemo} from "react";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import './Resource.css'
import { DisplayTable } from "./DisplayTable";
import { ColumnNames } from "./ColumnNames";

export function UserPage({profile}) {

    const columns = useMemo(() => ColumnNames, []);
    const [data, setData] = useState([])
    const[loading, setLoading] = useState(false)
    const [refresh, setRefresh] =useState(false)

    useEffect(() => {
        setLoading(true)
        let isMounted = true; 
        getAmplifyToken((jwt) => {
          fetch(`${process.env.REACT_APP_ADMIN_API}/admin/resource-admin`,{
            headers: {Authorization: jwt}
          })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            if(data){
              if (isMounted){
                setData(data);
                setLoading(false)
                }
            }else{
              setRefresh(!refresh)
            }
          })
          .catch((err) => {console.error(err); setLoading(false)});
        })
        
        
      }, [refresh]);

  return (
  <div>
      <LoadingPopUp open={loading}/>
      <DisplayTable
      data={data}
      columns={columns}
      refresh={refresh}
      setRefresh={setRefresh}
      updateData={setData}
      profile={profile}
      />
  </div>
  )

}