import { Checkbox, Grid, FormControlLabel, FormGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'

function RoleChecklist(props){

    const updateRoleObj = (key, value) => {
        let newObj = {...props.roleObj}
        newObj[key] = value
        props.onUpdate(newObj)
    }

    useEffect(()=>{
        console.log('rolechecklist profile',props.profile)
    },[props.profile])

    return(
        <Grid container item sx={{justifyContent:'center', alignItem:'center'}} flexDirection={props.flexDirection}>
            {props.resource_id && props.resource_id === props.profile.id ? <>
            <Typography>You Cannot Edit The Roles Of Your Own Profile</Typography>
            </> :<>
            {(!props.profile.roles.resource_admin && !props.profile.roles.admin) ? 
            <Typography>You Must Have Admin Permissions or Resource Admin Permissions to Edit Resource Roles</Typography> : 
            <></>}
            <FormGroup row={props.row} sx={{justifyContent:'center', alignItem:'center'}} flexDirection={props.flexDirection}>
                <FormControlLabel disabled={!props.admin || ((!props.profile.roles.resource_admin && !props.profile.roles.admin))} label="Admin Permissions" 
                control={<Checkbox  checked={props.roleObj.admin} onChange={(e)=>{updateRoleObj('admin',e.target.checked)}}/>} />
                <FormControlLabel label="Resource Admin Permissions" disabled = {(!props.profile.roles.resource_admin && !props.profile.roles.admin)}
                control={<Checkbox checked={props.roleObj.resource_admin} onChange={(e)=>{updateRoleObj('resource_admin',e.target.checked)}}/>} />
                <FormControlLabel label="Offer Letters Permissions" disabled = {(!props.profile.roles.resource_admin && !props.profile.roles.admin)}
                control={<Checkbox checked={props.roleObj.offers} onChange={(e)=>{updateRoleObj('offers',e.target.checked)}}/>} />
                <FormControlLabel label="Expense Admin Permissions" disabled={(!props.profile.roles.resource_admin && !props.profile.roles.admin)}
                control={<Checkbox checked={props.roleObj.expense_admin} onChange={(e)=>{updateRoleObj('expense_admin',e.target.checked)}}/>} />
                <FormControlLabel label="Timesheet Admin Permissions" disabled={(!props.profile.roles.resource_admin && !props.profile.roles.admin)}
                control={<Checkbox checked={props.roleObj.timesheet_admin} onChange={(e)=>{updateRoleObj('timesheet_admin',e.target.checked)}}/>} />
                <FormControlLabel label="Timesheet Submission Permissions" disabled={(!props.profile.roles.resource_admin && !props.profile.roles.admin)}
                control={<Checkbox checked={props.roleObj.timesheet} onChange={(e)=>{updateRoleObj('timesheet',e.target.checked)}}/>} />
            </FormGroup>
            </>}
        </Grid>
    )

}

RoleChecklist.propTypes = {
    admin : PropTypes.bool,
    onUpdate : PropTypes.func.isRequired,
    row: PropTypes.bool,
    roleObj : PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
}

RoleChecklist.defaultProps = {
    admin: false,
    row:false,
    roleObj : {}
}


export default RoleChecklist;