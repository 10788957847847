import react, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField } from "@mui/material";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import moment from "moment";
import { DatePicker } from "@mui/lab";
import validator from "validator";
import "./Resource.css"

export function EditableRow({
  cell,
  isActive,
  setIsActive,
  setIsEditable,
  setRowId,
  selectedRow,
  editValues,
  setEditValues,
  refresh,
  setRefresh,
  roleObj
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let [value] = selectedRow.map((row) => row.original);
    let formatHireDate = moment
      .utc(value.hireDate)
      .format("YYYY-MM-DD")
      .toString();
    setEditValues({ ...value, hireDate: formatHireDate });
  }, []);

  const updateDate = (field, value) => {
    let temp = {
      ...editValues,
    };
    temp[field] = moment.utc(value).format("YYYY-MM-DD").toString();
    setEditValues(temp);
  };

  const updateContact = (e) =>{
    const onlyDigits = e.replace(/\D/g, "");
    setEditValues({ ...editValues, [cell.column.id]: onlyDigits });

  };
  const updateEmail = (e) =>{
    const removeWhitespace = e.replace(/\s/g, '');
    console.log("onlyDigits", removeWhitespace)
    setEditValues({...editValues, [cell.column.id]: removeWhitespace})
  }

  const onChangeHandler = (event) => {
    setEditValues({ ...editValues, [cell.column.id]: event.target.value });
  };
  const handleClose = () => {
    setIsActive(false);
    cell.row.toggleRowSelected();
    setEditValues({
      id: "",
      firstName: "",
      lastName: "",
      primaryEmail: "",
      secondaryEmail: "",
      category: "6",
      hireDate: "",
      location: "",
      contactNumber: "",
    });
    setRowId('')
    setIsEditable(false)
    setIsActive(false);
   
  };
  const onSaveHandler = (e) => {
   try{
    e.preventDefault();
    setLoading(true);
    if (
      editValues.firstName === "" ||
      editValues.lastName === "" ||
      editValues.primaryEmail === ""
    ) {
      setLoading(false);
      setOpen(true);
      setMessage("Please complete the required sections of the form!");
    } else if (
      !validator.isEmail(editValues.primaryEmail) ||
      (editValues.secondaryEmail && !validator.isEmail(editValues.secondaryEmail))
    ) {
      setLoading(false);
      setOpen(true);
      setMessage("Invalid email format. Please try again!");
    } else {

      getAmplifyToken((jwt) => {
        fetch(`${process.env.REACT_APP_ADMIN_API}/admin/resources-api`, {
          method: "PUT",
          body: JSON.stringify({
            id: editValues.id,
            firstName: editValues.firstName,
            lastName: editValues.lastName,
            primaryEmail: editValues.primaryEmail,
            secondaryEmail: editValues.secondaryEmail,
            category: "6",
            hireDate: editValues.hireDate,
            location: editValues.location,
            contactNumber: editValues.contactNumber,
            role_obj: roleObj
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: jwt,
          },
        }).then((response) => {
          setLoading(false);
          if (response.ok) {
            setSuccess(true);
            setMessage(
              `${editValues.firstName} ${editValues.lastName}`.concat(
                " has been edited successfully!"
              )
            );
            
          } else {
            setOpen(true);
            setMessage("Could not add to the database. Please try again");
          }
        });
      });
    }
   }catch(e){
    setLoading(false)
    console.error(e)
    setOpen(true)
    setMessage('An Error Occured. Please Try again')
   }
    
  };

  return (
    <>
      <TextPopUp
        title="Success!"
        content={message}
        open={success}
        onClose={(event) => {
          event.preventDefault();
          setSuccess(false);
          handleClose();
          setRefresh(!refresh);
          setEditValues({
            id: "",
            firstName: "",
            lastName: "",
            primaryEmail: "",
            secondaryEmail: "",
            category: "6",
            hireDate: "",
            location: "",
            contactNumber: "",
          });
        }}
        fullWidth
      />
      <ErrorPopUp
        open={open}
        onClose={(e) => {
          e.preventDefault();
          setOpen(false);
        }}
        errorContent={message}
      />
      <LoadingPopUp open={loading} />
      <TableCell className="cell-style" align="center" {...cell.getCellProps()} >
        {cell.column.id === "edit" ? (
          <span>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </span>
        ) : cell.column.id === "expander" ? (
          <span>{cell.render("Cell")}</span>
        ) : cell.column.id === "credential" ? (
          <IconButton onClick={onSaveHandler}>
            <SaveIcon />
          </IconButton>
        ) : cell.column.id === "hireDate" ? (
          <DatePicker
            error={false}
            helperText={null}
            value={
              editValues.hireDate
                ? moment.utc(editValues[cell.column.id])
                : null
            }
            onChange={(value) => {
              updateDate("hireDate", value);
            }}
            renderInput={(params) => (
              <TextField
                className="date-picker"
                variant="standard"
                {...params}
                helperText={' '}
                error={false}
                sx={{ width: "100%", margin: "5px"}}
                
              />
            )}
          />
        ) : cell.column.id === "firstName" || cell.column.id === "lastName" ? (
          <TextField
            helperText={!editValues[cell.column.id]? "required":' '}
            error={!editValues[cell.column.id]}
            value={editValues[cell.column.id]}
            required
            sx={{ width: "90%", margin: 0 }}
            inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
            InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
            onChange={(event) => {
              onChangeHandler(event);
            }}
            variant="standard"
          ></TextField>
        ) : cell.column.id ==="contactNumber" ? (
        <TextField
          value={editValues[cell.column.id]}
          helperText={' '}
          sx={{ width: "90%", margin: 0 }}
          inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
          InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
          onChange={(event) => {
            updateContact(event.target.value);
          }}
          variant="standard"
        ></TextField>)
        : cell.column.id === "primaryEmail" ? (
          <TextField
            helperText={!editValues[cell.column.id]? "required":' '}
            error={!editValues[cell.column.id]}
            value={editValues[cell.column.id]}
            required
            sx={{ width: "90%", margin: 0 }}
            inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
            InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
            onChange={(event) => {
              if(event.target.value) updateEmail(event.target.value);
            }}
            variant="standard"
          ></TextField>)
        :(
          <TextField
            value={editValues[cell.column.id]}
            helperText={' '}

            sx={{ width: "90%", margin: 0 }}
            inputProps={{style: {fontSize: '0.875rem'}}} // font size of input text
            InputLabelProps={{style: {fontSize: '0.875rem'}}} // font size of input label
            onChange={(event) => {
              onChangeHandler(event);
            }}
            variant="standard"
          ></TextField>
        )}
      </TableCell>
    </>
  );
}
