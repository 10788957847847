import { getAmplifyToken } from "../AmplifyHelp"

/**
 * function that gets all info about projects and passes it to callback in a json object
 * @param {AbortController} abortController controller responsible for terminating request 
 * @param {responseHandler} callback function responsible for handling the json object returned from the backend
 * @category Api Calls
 * @subcategory Projects 
 */
export const getAllProjectInfo = (abortController,callback) =>{
    getAmplifyToken((token) => {
        fetch(`${process.env.REACT_APP_ADMIN_API}/admin/project`,{
            headers:{Authorization:token},
            signal: abortController ? abortController.signal:undefined
        })
        .then((resp) => resp.json())
        .then((data)=>callback(data))
        .catch((err)=>{
            if(err.name !== 'AbortError'){
                console.error(err);
                callback(undefined);
            }
        })
    })
}
/**
 * function responsible for getting auxilary info needed to create a project then passes the json obj from abckend to callback
 * @param {AbortController} abortController AbortController responsible for terminating the request 
 * @param {Number} project_id id of project for which auxilary information is wanted 
 * @param {responseHandler} callback function that the json object containing the auxilary information is passed to 
 * @category Api Calls
 * @subcategory Projects
 */
export const getAuxiliaryInfo = (abortController, project_id=undefined, callback) => {
    getAmplifyToken((token)=>{
        let request = project_id ? `${process.env.REACT_APP_ADMIN_API}/admin/auxdetails?project_id=${project_id}` : 
            `${process.env.REACT_APP_ADMIN_API}/admin/auxdetails`
        fetch(request, {
            headers:{Authorization: token},
            signal: abortController ? abortController.signal : undefined
        }).then(response => response.json())
        .then(data => callback(data))
        .catch((err)=>{
            if(err.name !== 'AbortError'){
                console.error(err);
                callback(undefined);
            }
        })
    })
}

/**
 * function responsible for inserting a project into the db the returns response from backend to callback
 * @param {Object} json json object containing project to be inserted into db 
 * @param {responseHandler} callback function responsible for handling the response from backend
 * @param {AbortController} abortController AbortController responsible for terminating the request 
 * @category Api Calls
 * @subcategory Projects
 */
export const putProject = (json, callback, abortController) => {
    console.log('json',json)
    getAmplifyToken((token)=>{
        fetch(`${process.env.REACT_APP_ADMIN_API}/admin/project`,{
            method:'PUT',
            headers:{Authorization: token,  "Content-Type": "application/json"},
            signal: abortController ? abortController.signal : undefined,
            body:JSON.stringify(json)
        }).then(response => response.json())
        .then(json => callback(json))
        .catch((err)=>{
            if(err.name !== 'AbortError'){
                console.error(err);
                callback(undefined);
            }
        })
    })
}

/**
 * get all information relating to an individual project and return the backend resp to callback
 * @param {Number} project_id id of project for which info is wanted 
 * @param {responseHandler} callback function responsible for handling backend json resp 
 * @param {AbortController} abortController AbortController repsonsible for terminating the request
 */
export function getIndividialProjectinfo(project_id, callback, abortController=undefined){
    console.log('body should be',{project_id} )
    getAmplifyToken((jwt) => {
      fetch(`${process.env.REACT_APP_ADMIN_API}/admin/indiv_project`, 
      {headers:{"Content-Type": "application/json", Authorization:jwt}, 
      method:'POST', 
      body: JSON.stringify({project_id}),
      signal: abortController ? abortController.signal : undefined
      }).then((resp) => resp.json())
      .then((data) => callback(data))
      .catch((err)=>{
        if(err.name !== 'AbortError'){
            console.error(err);
            callback(undefined);
        }
        })
    })
}