import React, {useState, useEffect} from "react";
import { Grid } from "@mui/material";
import PropTypes from 'prop-types'
import ContractForm from "./ContractForm";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { getOfferInfo } from "../../../util/offerLettersHelp";
import {isObjectEmpty} from '../../../util/generalHelp'
import ViewOffers from "./ViewOffers";


function ManageContracts(props){

    const unvoidable = ['voided', 'deleted', 'completed']

    const [errorObj, setErrorObj]  = useState({})
    const [contracts, setContracts] = useState([])

    
    const handleError = (errorObject=undefined) => {
        try{
            setErrorObj({errorTitle: errorObject.errorTitle, errorContent:errorObject.errorContent})
        }catch{
            setErrorObj({errorTitile: undefined, errorContent:undefined})
        }
    }

    const updateContracts = (id, key, value) => {
        let allContracts = [...contracts]
        const conIndex = allContracts.findIndex(offer => offer.id === id)
        let newCon = {...allContracts[conIndex]}

        newCon[key] = value

        allContracts[conIndex] = newCon
        setContracts(allContracts)
        
    }

    return(
        <Grid container sx={{justifyContent:'center',alignItems:'center'}}>
        <ErrorPopUp errorContent={errorObj.errorContent} errorTitle={errorObj.errorTitle} open={!isObjectEmpty(errorObj)}
            onClose={(e) => {e.preventDefault(); setErrorObj({})}}/>
        {/* <LoadingPopUp open={!hasLoaded}/> */}
        <Grid container sx={{justifyContent:'center',alignItems:'center', height:'30%'}}>
            <ContractForm authorities={props.authorities} clients={props.clients}
            states={props.states} venues={props.venues}
            onComplete={props.onComplete} onError={handleError}/>
        </Grid>
        <Grid container sx={{justifyContent:'center',alignItems:'center', height:'65%', width:'100%'}}>
            <ViewOffers offers={props.contracts} onError={handleError} updateOffers={updateContracts} unvoidableStatuses={unvoidable}/>
        </Grid>
    </Grid>
    )
}

export default ManageContracts