import { getAmplifyToken, getAmplifyTokenPromise } from "./AmplifyHelp";
import { ErrorObject } from "./generalHelp";

/**
 * callback function that takes the response of the api call and handles it
 * @callback responseHandler
 * @param {*} response
 */

/**
 * makes api call using timesheet_id and url to create docusign document then pases api response to callback
 * @category Api Calls
 * @subcategory Docusign
 * @param {Number} timesheet_id id of timesheet to be submitted on docusign
 * @param {responseHandler} callback callback function for response
 * @param {String} url url to redirect to after docusign
 * @module
 */
export async function submitDocusign(timesheet_id, callback, url=`${process.env.REACT_APP_PORTAL}`) {
  getAmplifyToken((jwt) => {
    // const redirect_url = `https://ops-timesheet-api.seqtechnology.co:5052?timesheet_id=${timesheet_id}`;
    const redirect_url = `${url}/?timesheet_id=${timesheet_id}`
    console.log('redirect_url is', redirect_url)
    fetch(
      `${process.env.REACT_APP_TIMESHEET_API}/con/ds/submit?timesheet_id=${timesheet_id}&redirect_url=${redirect_url}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": jwt },
      }
    )
      .then((resp) => resp.json())
      .then((data) => callback(data))
      .catch((err) => {
        console.error(err);
        callback(undefined);
      });
  });
}
/**
 * function used for download a docusign document with envelope_id and document_id then passing it to callback
 * @param {String} envelope_id docusign envelope id
 * @param {responseHandler} callback callback function to pass document to
 * @param {Number} document_id document id that is being requested
 * @category Api Calls
 * @subcategory Docusign
 * @module
 * 
 */
export function downloadDocusign(envelope_id, callback, document_id=1){
  getAmplifyToken(jwt => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/ds/download/${envelope_id}?document_id=${document_id}`,
    {headers: {"Authorization":jwt}})
    .then(resp => resp.status == 200 ? resp.blob() : undefined)
    .then(blob => callback(blob))
    .catch(err => {console.error(err); callback(undefined)})
  })
}

export function previewDocusign(envelope_id, callback,  return_url=window.location.origin.toString(), abortCont){
  getAmplifyToken((jwt) =>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/ds/preview`,
    {
      method:'POST',
      body: JSON.stringify({envelope_id:envelope_id, return_url:return_url}),
      headers: { "Content-Type": "application/json", "Authorization": jwt },
      signal: abortCont ? abortCont.signal: undefined
    }).then(resp => resp.json()).then(json=>callback(json))
    .catch(err => handleError(err,callback))
  })
}

const handleError=(err, callback)=>{
  if(err.name !== 'AbortError'){
    console.error(err)
    callback(undefined)
  }
}

export function weeklyDocusignSubmit(resource_id, project_id, ref_date, redirect_url, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/ds/weekly_submit`,
    {
      headers: {Authorization: jwt, "Content-Type": "application/json"},
      method: 'POST',
      body: JSON.stringify({
        resource_id:resource_id,
        project_id: project_id,
        ref_date: ref_date,
        redirect_url: redirect_url
      })
    }).then(resp => resp.ok? resp.json() : new ErrorObject(resp.status, resp.text)).then(data => callback(data))
    .catch(err => {console.error(err); callback(new ErrorObject(500, "an internal error has occured"))})
  })
}

export async function weeklyDocusignPromise(resource_id, project_id, ref_date, redirect_url){
  let jwt = await getAmplifyTokenPromise()
  try{
    let resp = await fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/ds/weekly_submit`,
    {
      headers: {Authorization: jwt, "Content-Type": "application/json"},
      method: 'POST',
      body: JSON.stringify({
        resource_id:resource_id,
        project_id: project_id,
        ref_date: ref_date,
        redirect_url: redirect_url
      })
    })
    if(resp.ok){
      return await resp.json()
    }
    return new ErrorObject(resp.status, await resp.text())
  }catch(err){
    return new ErrorObject(500, err)
  }
}

/*import { getProfileFromEmail } from "../util/ProfileHelper";

export async function submitDocusign(data, callback) {
  const update = async (profile) => {
    fetch("/ds/submit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        resource_id: profile.id,
        week_of: data.week_of,
        project_id: data.project_id,
        ds_return_url: `http://localhost:3000/redirect?project_id=${data.project_id}&timesheet_id=${data.timesheet_id}&resource_id=${profile.id}&week_of=${data.week_of}`,
        day_array: data.day_array,
      }),
    })
      .then((res) => res.json())
      .then((data) => callback(data))
      .catch((err) => {
        console.error(err);
        callback(500);
      });
  };
  await getProfileFromEmail(update);
}*/
