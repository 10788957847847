import React, { useEffect, useState } from 'react';
import {Button, FormControl, Grid, MenuItem, Select, InputLabel, TextField, Typography} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import LoadingPopUp from '../Pop_ups/LoadingPopUp';
import { putInvoice, putTimesheet, weekUnlockTimesheet } from '../../util/invoiceHelper';
import ErrorPopUp from '../Pop_ups/ErrorPopUp';
import Confirmation from '../Pop_ups/Confirmation';
import PropTypes from 'prop-types'
// import FormattedSelect from '../Formatted/FormattedSelect';
import SuccessSnackBar from '../SuccessSnackBar';
import InvoiceForm from './InvoiceForm';
import GridBreak from '../GridBreak';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';
import HoverButton from '../HoverButton';
import { invalidateTimesheet } from '../../util/newTimeSheetHelper';

/**
 * Component used to attach an invoice to a timesheet
 * @component
 * @category Invoice Management 
 */
function AddInvoice(props) {
    const [metaId, setMetaId] = useState(0)
    const [invoices, setInvoices] = useState(props.invoices)
    // const [timesheet, setTimesheet] = useState({timesheet_id: props.timesheet.id, status: props.timesheet.status})
    // const [hasLoaded, setHasLoaded] = useState(false)
    const [invalidate, setInvalidate] = useState(false)
    const [deleted, setDeleted] = useState([])
    const [loading, setLoading] = useState(false)
    // const [success, setSuccess] = useState("")
    const [error,setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(undefined)
    const [errorTitle, setErrorTitle] = useState(undefined)

    const add_meta_ids = (arr) =>{
        //console.log('metaID before iterating', metaId)
        let temp_meta = metaId
        let new_arr = [...arr]
        for(let obj of new_arr){
            //console.log('obj',obj)
            //console.log('equaltiy', obj.meta_id == undefined)
            if(obj.meta_id == undefined){
                obj.meta_id = temp_meta
                //console.log('test',temp_meta, temp_meta + 1)
                temp_meta+= 1
            }
        }
        setMetaId(temp_meta)
        //console.log('after inserting meta ids',new_arr)
        return new_arr
    }

    const loc = useLocation()
    const navigate = useNavigate()
    //console.log('timesheet', timesheet)

    useEffect(()=>{
        //console.log('props.invoices', props.invoices)
        setInvoices(add_meta_ids(props.invoices))
    },[props.invoices])


    const unlockTimesheet = () => {
        setLoading(true);
        weekUnlockTimesheet({timesheet_id:props.timesheet.id}, (response)=>{
          if(!response){
            setError(true)
            setErrorTitle('Something went wrong')
            setErrorMessage('Something went wrong when Unlocking this timesheet.  Please refresh and try again')
          }else{
            props.onSuccess('Timesheet successfully Unlocked')
            props.onUpdate()
            if(props.onClose) props.onClose()
        }
        setLoading(false)
    })
        return
    }


    const submitInvoice = () => {
        // console.log('submitting invoice')
        // console.log('invoices would be', invoices)
        // console.log('deleted would be',deleted)
        setLoading(true)
        for (let inv of invoices){
            if(inv.invoice_number == undefined){
                setErrorMessage('please ensure all invoices have a number')
                setError(true)
                setLoading(false)
                return
            }
        }
        putInvoice({'timesheet_id':props.timesheet.id,
            'changer':props.profile.id,
            'invoices':invoices,
            'deleted':deleted}, (resp)=>{
                setLoading(false)
                if(!resp.ok){
                    setError(true)
                    setErrorMessage('something went wrong')
                }else{
                    resp.json().then((json) => updateIds(json.new_ids))
                    setDeleted([])
                    props.onSuccess("invoices has successfully submitted")
                }
                props.onUpdate()
        })
    }

    const updateIds = (meta_id_pairs) => {
        let new_invoices = [...invoices]
        for (let obj of meta_id_pairs){
            const inv_index = invoices.findIndex((inv) => inv.meta_id === obj.meta_id)
            new_invoices[inv_index].id = obj.id
        }
        setInvoices(new_invoices)
    }

    const addEmptyInvoice = () => {
        let newInvoices = [...invoices]
        newInvoices.push({meta_id: metaId})
        setInvoices(newInvoices)
        setMetaId(metaId + 1)
    }

    const handleUpdateInvoices = (newInv) => {
        // console.log('newInv',newInv)
        let new_invoices = [...invoices]
        const inv_index = invoices.findIndex((inv) => inv.meta_id === newInv.meta_id)
        // console.log('inv_index',inv_index)
        // console.log('toChange', new_invoices[inv_index])
        new_invoices[inv_index] = newInv
        setInvoices(new_invoices)
    }

    const removeInvoice = (invoice) => {
        let new_invoices = [...invoices]
        if(new_invoices.length > 1){
            const inv_index = invoices.findIndex((inv) => inv.meta_id === invoice.meta_id)
            //console.log('inv_index', inv_index)
            new_invoices.splice(inv_index, 1)
            //console.log('new_invoices after',new_invoices)
        }else{
            //console.log('delete')
            new_invoices = [{meta_id:invoice.meta_id}]
        }

        if(invoice.id !== undefined){
            let new_deleted = [...deleted]
            new_deleted.push(invoice.id)
            setDeleted(new_deleted)
        }

        setInvoices(new_invoices)
    }

    const invalidateTs = () => {
        setInvalidate(false)
        console.log('invalidating timesheet')
        setLoading(true)
        invalidateTimesheet(props.timesheet.id, (resp)=>{
            if(resp.ok){
                props.onSuccess('Timesheet invalidated')
                props.onUpdate()
                if(props.onClose) props.onClose()
            }else{
                setError(true)
                setErrorTitle('Something went wrong')
                setErrorMessage('Something went wrong when invalidating this timesheet.  Please refresh and try again')
            }
            setLoading(false)
        })
    }

    return (
        <>
        <Grid container sx={{padding:'1%', justifyContent:'center', alignItems:'center'}}>
            <LoadingPopUp open={loading}/>
            {/* <SuccessSnackBar
        open={Boolean(success)}
        onClose={(event) => {
          event.preventDefault();
          setSuccess("");
        }}
        successmessage={success}
      /> */}
      <Confirmation open={invalidate} onClose={(e) => {e.preventDefault(); setInvalidate(false)}} 
      onConfirm={(e) => {e.preventDefault(); invalidateTs()}} 
      content='Invalidating this timesheet will make it so that the timesheet is invisible to all users.'/>
      <ErrorPopUp open={error} onClose={(event) => {event.preventDefault(); setError(false); setErrorMessage(undefined); setErrorTitle(undefined)}} 
      errorContent={errorMessage} errorTitle={errorTitle}/>
      {/* <Typography align='center' variant='h4'>Add Invoice</Typography>
      <Grid item xs={12} sx={{m:'1%'}}>
          <BackButton color='black'>Back To Timesheets</BackButton>
      </Grid> */}
      <Grid item container xs={12} sx={{width:'100%'}}>
            <Grid item container xs={2}>
                <HoverButton onClick={(event) => {event.preventDefault(); unlockTimesheet()}} color='black' 
                variant='outlined' disabled={!(props.timesheet.id && props.timesheet.status === 'submitted')}>Unlock Timesheet</HoverButton> 
            </Grid>
            <Grid item container xs={2}>
            <HoverButton onClick={(event) => {event.preventDefault(); setInvalidate(true)}} color='black'
                hoverColor='red' borderColor='red' hoverBorderColor='red'
                variant='outlined'>Invalidate Timesheet</HoverButton> 
            </Grid>
      </Grid>
      <Grid item container xs={12} sx={{width:'100%', justifyContent:'flex-end'}}>
        <AddButton onClick={(e) => {e.preventDefault(); addEmptyInvoice()}}/>
      </Grid>
      <GridBreak/>
      {invoices != undefined ? invoices.map((invoice) => (
        <Grid item container sx={{width:'100%', justifyContent:'center', alignItems:'center'}}>
            <Grid item container xs={11.5} sx={{width:'100%', justifyContent:'center', alignItems:'center'}}>
                <InvoiceForm invoice={invoice} onUpdate={(newInv) => {handleUpdateInvoices(newInv)}}/>
            </Grid>
            <Grid item xs={0.5}>
                <RemoveButton onClick={(e) => {{e.preventDefault(); removeInvoice(invoice)}}}/>
            </Grid>
        </Grid>
      )) : undefined}
            <Grid item xs={12} container sx={{justifyContent:'center'}}>
                <Button variant='outlined' sx={{color:'black'}} onClick={(event) => {event.preventDefault(); submitInvoice()}}>Submit</Button>
            </Grid>
        </Grid> 
        </>
    );
}

AddInvoice.propTypes = {
    /**
     * object representing the profile of the user (Resource object accoring to db schema)
     */
    profile: PropTypes.object.isRequired,
    /**
     * array of objects each representing an Invoice object (objects that follows the db schema for invoice)
     */
    invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * object representing the timesheet that the invoice(s) will be added (following db schema)
     */
    timesheet:PropTypes.object.isRequired,
    /**
     * function called when invoice is added to the timesheet
     */
    onUpdate: PropTypes.func,
    /**
     * function called on success
     */
    onSuccess : PropTypes.func
}

export default AddInvoice;