import React from "react";
import { Box,Modal } from "@mui/material";
import LoadingScreen from '../LoadingScreen'
import PropTypes from 'prop-types'
/**
 * pop up used to display when something is loading
 * @component
 * @category Pop Ups
 */
function LoadingPopUp(props) {
  return (
    <>
      <Modal
      sx={{bgcolor:'transparent'}}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={props.boxStyle}>
          <LoadingScreen loadingText={props.loadingText}/>
        </Box>
      </Modal>
    </>
  );
}

LoadingPopUp.propTypes = {
  /**
   * boolean indicating whether or not pop up is open
   */
    open: PropTypes.bool.isRequired,
    /**
     * function called when modal is closed
     */
    onClose : PropTypes.func,
    /**
     * styling object for the box cotaining the loading text
     */
    boxStyle: PropTypes.object,
    /**
     * text to be displayed while loading
     */
    loadingText: PropTypes.string
}

LoadingPopUp.defaultProps = {
    onClose : () => {},
    boxStyle : {bgcolor:'ffffff16'}
}


export default LoadingPopUp;
