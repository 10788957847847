class FormValidator{
    static valid_fields=[]
    static validateFormObject(formObject, ignored_fields=[]){
        let missingFields = []
        for (const field of this.valid_fields){
            if(!(field in formObject) || (!formObject[field] && !ignored_fields.includes(field))){
                missingFields.push(field)
            }
        }

        return missingFields
    }

    static validateFormObjectExtended(formObject, ignored_fields=[], operationKeys, operations){
        let missingFields = []
        for (const field of this.valid_fields){
            if(!(field in formObject) || (!formObject[field] && !ignored_fields.includes(field))){
                missingFields.push(field)
            }
        }
        return missingFields
    }
}

export class PiiValidator extends FormValidator{
    static valid_fields=['social_security', 'date_of_birth', 'primary_address']
    static validateSSN(ssn){
        let ssn_patt = new RegExp(/^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/);
        let sin_patt = new RegExp(/^(?!000|8\d{2})\d{3}(?!00)\d{3}(?!000)\d{3}$/);
        return ssn_patt.test(ssn) || sin_patt.test(ssn);  
    }

    static formatSSN(ssn){
        // Remove any existing dashes or spaces
        return ssn.replace(/[^\d]/g, '').substring(0,9);
    }
}