import React, { useEffect } from 'react';
import {PropTypes} from 'prop-types'
import {TextField, Button, Grid, Box} from '@mui/material'
import moment from 'moment'
import { DatePicker } from '@mui/lab';
import GridBreak from '../GridBreak';
import HoverButton from '../HoverButton';
import { PiiValidator } from '../../util/FormValidators';

function PiiForm(props){

    const field_size = 8 // out of 12, 12 taking up full form

    function updateResource(key, value){
        let newResObj = {...props.profile}
        newResObj[key] = value
        //console.log('updated resource obj', newResObj)
        props.onUpdate('resource_obj', newResObj);
    }
      
    return(
        <Grid container item xs={12} spacing={1}
        sx={{padding:'1%', justifyContent:'center', alignitems:'center', flexDirection:'row'}}>

        <Grid item container xs={12} sx={{justifyContent:'center', alignitems:'center'}}>
                <Grid item xs={field_size}>
                    <TextField fullWidth label="Email" value={props.profile.primary_email} disabled/>
                </Grid>
            </Grid>
            
            <Grid item container xs={12} sx={{justifyContent:'center', alignitems:'center'}}>
                <Grid item xs={field_size}>
                    <TextField fullWidth label="First Name" value={props.profile.first_name}
                    onChange={(e) => {e.preventDefault(); updateResource('first_name', e.target.value)}}/>
                </Grid>
            </Grid>

            <Grid item container xs={12} sx={{justifyContent:'center', alignitems:'center'}}>
                <Grid item xs={field_size}>
                    <TextField fullWidth label="Last Name" value={props.profile.last_name}
                    onChange={(e) => {e.preventDefault(); updateResource('last_name', e.target.value)}}/>
                </Grid>
            </Grid>

            <Grid item container xs={12} sx={{justifyContent:'center', alignitems:'center'}}>
                <Grid item xs={field_size}>
                    <TextField fullWidth label="SSN/SIN" value={props.piiObj.social_security || ""} required
                    onChange={(e) => {e.preventDefault(); props.onUpdate('social_security', PiiValidator.formatSSN(e.target.value))}}/>
                </Grid>
            </Grid>

            <GridBreak/>

            <Grid item container xs={12} sx={{justifyContent:'center', alignitems:'center'}}>
                <Grid item xs={field_size}>
                    <TextField fullWidth label="Primary Address" value={props.piiObj.primary_address || ""} required
                    onChange={(e) => {e.preventDefault(); props.onUpdate('primary_address', e.target.value)}}/>
                </Grid>
            </Grid>

            <GridBreak/>
            
            <Grid item container xs={12} sx={{justifyContent:'center', alignitems:'center'}}>
                <Grid item xs={field_size}>
                    <DatePicker label="Date of Birth" value={props.piiObj.date_of_birth || null}
                    fullWidth onChange={(value) => props.onUpdate('date_of_birth',value ? value.format('YYYY-MM-DD').toString() : value)}
                    renderInput={(params) => <TextField required {...params} fullWidth/>} maxDate={moment()}/>
                </Grid>
            </Grid>

            {/* <Grid item container xs={12} sx={{justifyContent:'center', alignitems:'center'}}>
                <TextField label="Primary Address" value={props.piiObj.primary_address} 
                onChange={(e) => {e.preventDefault(); props.onUpdate('primary_address', formatSSN(e.target.value))}}/>
            </Grid> */}
            <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                <HoverButton onClick={e => {e.preventDefault(); props.onSubmit()}} color='primary.main'
                variant='outlined'>
                    Submit
                </HoverButton>
            </Grid>
        </Grid>
    )
}

PiiForm.propTypes = {
    piiObj : PropTypes.object.isRequired,

    onUpdate: PropTypes.func.isRequired,

    country: PropTypes.string
}

PiiForm.defaultProps ={
    country: 'USA'
}

export default PiiForm;