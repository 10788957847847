import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingPopUp from "../../components/Pop_ups/LoadingPopUp";
import { DatePicker } from '@mui/lab';
import ErrorPopUp from "../../components/Pop_ups/ErrorPopUp";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import BackButton from "../../components/BackButton";
import { isObjectEmpty, dateStringWithoutWeekday, ErrorObject } from "../../util/generalHelp";
import {
  getTimesheetWeek,
  getTimesheetSummary,
  saveTimeSheet,
  saveToSubmit,
  submitTimeSheet,
  getTimesheetNotes,
  getDocusignReady,
  weeklySubmitTimesheet,
  weeklySaveTimesheet,
  fullSaveToSubmit
} from "../../util/newTimeSheetHelper";
import TimeSheetFields from "../../components/NewTimesheets/TimeSheetFields";
import DocusignTSFields from "../../components/NewTimesheets/DocusignTSFields";
import NewTimeSheetsForm from "../../components/NewTimesheets/NewTimeSheetsForm";
import { submitDocusign, weeklyDocusignSubmit } from "../../util/docusignHelper";
import IFramePopUp from "../../components/Pop_ups/IFramePopUp";
//import PeriodNav from "../../components/PeriodNav";
import WeeklyPeriodNav from "../../components/NewTimesheets/WeeklyPeriodNav";
import { fonts } from "../../util/Theme";
import PropTypes from 'prop-types'
import moment from "moment";
import TextPopUp from "../../components/Pop_ups/TextPopUp";
import HoverButton from "../../components/HoverButton";
import { putTimesheet, setTimesheetStatus } from "../../util/invoiceHelper";
import GridBreak from '../../components/GridBreak'
import { DocusignHandler } from "../../util/docusignUtil";
/**
 * Page consultants use to submit timesheets
 * @component
 * @category Pages
 * @subcategory Consultant 
 */
function TimeSheets(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [project, setProject] = useState({});
  const [profile, setProfile] = useState(props.profile);
  const [timesheets, setTimesheets] = useState({});
  const [prevWeek, setPrevWeek] = useState({});
  const [currDate, setCurrDate] = useState(moment.utc());
  const [success, setSuccess] = useState("");
  //const [dsUrl, setDsUrl] = useState("");
  const [dsObj, setDsObj] = useState(null)
  const [currUrl, setCurrUrl] = useState("")
  const [loadingText, setLoadingText] = useState(undefined);
  const [timesheetId, setTimesheetId] = useState(undefined)
  const [timesheetSummary, setTimesheetSummary] = useState(undefined)
  const [notes, setNotes] = useState(undefined)

  const loc = useLocation().state;
  const navigate = useNavigate();

  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  useEffect(() => {
    if (dsObj && dsObj.dsReady) {
      dsObj.checkAllSigned((resp) => {
        setLoading(false)
        reloadTimesheets()
        setLoadingText(undefined)
        setDsObj(null)
        if (resp == null) {
          //do nothing since manually exited
        }
        else if (resp instanceof ErrorObject) {
          setError(true)
        } else {
          setSuccess("Your timesheet has been successfully saved")
        }
        return
      })
    }

    if (dsObj && dsObj.hasNextUrl()) {
      setCurrUrl(dsObj.getNextUrl().url)
    } else {
      setCurrUrl("")
    }

  }, [dsObj])

  // useEffect(()=>{
  //   console.log('profile', profile)
  //   console.log('admin_profile',props.adminProfile)
  // },[profile, props.adminProfile])


  useEffect(() => {
    if (!hasLoaded) {
      if ((!project || isObjectEmpty(project)) && loc) {
        setProject(loc.project);
      }
      if (loc && typeof loc.profile == 'object' && loc.profile != null && !isObjectEmpty(loc.profile)) {
        setProfile(loc.profile)
      }
      //getProfileFromEmail(setProfile);
      setHasLoaded(true);
    }
  });

  useEffect(() => {
    let abortCont = new AbortController()
    reloadNotes(abortCont)
    return () => abortCont.abort()
  }, [timesheetId])

  const fetch_summary = (abortCont, loading = true) => {
    if (props.adminProfile) {
      if (loading) setLoading(true)
      getTimesheetSummary(profile.id, project.id, abortCont, (summary) => {
        if (!summary) {
          handleError();
        } else {
          //console.log('summary',summary)
          setTimesheetSummary(summary.summary)
        }
        if (loading) setLoading(false)
      })
    }
  }

  useEffect(() => {
    const abortCont = new AbortController();
    reloadTimesheets(abortCont)
    return () => abortCont.abort()
  }, [profile, project]);

  const reloadNotes = (abortCont) => {
    if (profile.id && project.id && currDate) {

      const ref_date = moment.utc(currDate).format("YYYY-MM-DD").toString()
      getTimesheetNotes(profile.id, project.id, ref_date, abortCont, (resp) => {
        if (!resp) {
          handleError()
        } else {
          setNotes(resp.notes.note_text)
        }
      })
    } else {
      setNotes("")
    }

  }

  const reloadTimesheets = (abortCont) => {
    if (!isObjectEmpty(project) && !isObjectEmpty(profile)) {
      setLoading(true);
      getTimesheetWeek(
        project.id,
        profile.id,
        moment.utc(currDate).format("YYYY-MM-DD").toString(),
        (response) => {
          //console.log('response', response)
          if (!response) {
            setError(true);
          } else {
            setTimesheets(response.curr_week);
            setPrevWeek(response.prev_week)
            setTimesheetId(response.curr_week.timesheet_id)
          }
          setLoading(false)
          fetch_summary(abortCont, false)
        }
        , abortCont);
      reloadNotes(abortCont);
    }
  }

  const onSave = (timesheet) => {
    //console.log('timesheets', timesheets)
    setLoadingText(undefined)
    //console.log('day_array',day_array)
    setLoading(true);
    const ref_date = moment.utc(currDate).format("YYYY-MM-DD").toString()
    weeklySaveTimesheet(profile.id, project.id, ref_date, timesheet, notes, (resp) => {
      if (resp instanceof ErrorObject) {
        resp.displayError();
        setError(true);
      } else {
        setSuccess("Your timesheet has been successfully saved")
      }
      setLoading(false);
    })
    // saveTimeSheet(project.id, profile.id, currDate, timesheet, notes, (resp) => {
    //   setLoading(false);
    //   if (!resp) {
    //     setError(true);
    //   } else {
    //     setSuccess("Your timesheet has been successfully saved");
    //   }
    // });
  };

  // const handleDocusign = (ref_date) => {
  //   const client_url = `${window.location.origin.toString()}`
  //   //console.log('handling docusign')
  //   setLoading(true)
  //   setLoadingText("redirecting to docusign, please wait")
  //   weeklyDocusignSubmit(profile.id, project.id, ref_date, client_url, (resp) => {
  //     if(resp instanceof ErrorObject){
  //       resp.displayError();
  //     }else{
  //       setDsUrl(resp.redirect_url);
  //     }
  //     setLoading(false)
  //     setLoadingText(undefined)
  //   })
  // }

  const onSubmit = (timesheet) => {
    setLoading(true);
    //console.log('calling fcn');
    //let ref_date = moment.utc(currDate).endOf("week").format('YYYY-MM-DD').toString()
    //pull the latest date from the timesheet queue
    const date_values = Object.keys(timesheet).map(dateString => new Date(dateString));
    // const ref_date = moment.utc(Math.max(...date_values)).format("YYYY-MM-DD").toString();
    let ref_date
    if (project.timesheet_frequency == 19) {
      const maxDate = new Date(Math.max(...date_values));

      // Find the index of the maximum date in date_values
      const maxDateIndex = date_values.findIndex(date => +date === +maxDate);

      // Get the date before the maximum date
      const dateBeforeMax = date_values[maxDateIndex - 1];

      ref_date = moment.utc(dateBeforeMax).format("YYYY-MM-DD").toString()
    }
    else {
      ref_date = moment.utc(Math.max(...date_values)).format("YYYY-MM-DD").toString();
    }

    let timesheet_queue = []
    if (project.timesheet_type == 'docusign' && !props.adminProfile || (props.adminProfile && props.adminProfile.id === profile.id)) {
      getDocusignReady(profile.id, project.id, ref_date, async (response) => {
        if (response instanceof ErrorObject) {
          console.log(response.displayError())
          setLoading(false);
          setLoadingText(undefined)
          setError(true)
          return;
        }

        if (response.last_will_ready) {
          timesheet_queue.push(moment.utc(Math.min(...date_values)).format("YYYY-MM-DD").toString())
        }

        if(response.next_will_ready){
          timesheet_queue.push(moment.utc(Math.max(...date_values)).format("YYYY-MM-DD").toString())
        }
        if (response.ready || response.will_be_ready) {
          // console.log('response', response)
          // console.log('profile_id', profile.id)
          timesheet_queue.push(ref_date)
        }
        if (timesheet_queue.length > 0) {
          setLoading(true)
          const dsObj = new DocusignHandler({
            resource_id: profile.id,
            project_id: project.id,
            notes: notes,
            timesheets: timesheet,
            redirect_url: `${window.location.origin.toString()}`
          }, handleDocuRedirect, timesheet_queue)


          setLoadingText("Please wait to be redirected to docusign");
          const success = await dsObj.handleDs()
          if (success) {
            setDsObj(dsObj)
            setLoadingText("Please Wait")
          } else {
            setLoading(false);
            setError(true);
            return
          }
        } else {
          weeklySubmitTimesheet(profile.id, project.id, ref_date, timesheet, props.adminProfile ? props.adminProfile.id : undefined, notes, (resp) => {
            if (resp instanceof ErrorObject) {
              resp.displayError();
              setError(true);
            }
            reloadTimesheets();
            setLoading(false);
          })
        }
      })
    } else {
      weeklySubmitTimesheet(profile.id, project.id, ref_date, timesheet, props.adminProfile ? props.adminProfile.id : undefined, notes, (resp) => {
        //console.log('response', resp)
        if (resp instanceof ErrorObject) {
          resp.displayError();
          setError(true);
        }
        reloadTimesheets();
        setLoading(false);
      })
    }
    //let server_url = req.originalUrl;
    //console.log('this is a test')
    //console.log('client origin is', client_url)
    //console.log('server ogigin is', server_url)
    // if(project.timesheet_type == 'docusign' && !props.adminProfile || (props.adminProfile && props.adminProfile.id === profile.id)){
    //   saveTimeSheet(project.id, profile.id, currDate, timesheet, notes, (resp) => {
    //     if (!resp) {
    //       handleError();
    //       return;
    //     }
    //     setLoadingText("Please wait to be redirected to docusign");
    //     submitDocusign(resp.id, (dsResponse) => {
    //       if (!dsResponse) {
    //         handleError();
    //         return;
    //       }
    //       setDsUrl(dsResponse.redirect_url);
    //       setLoading(false);
    //       setLoadingText(undefined);
    //     },`${window.location.origin.toString()}`);
    //   });
    // }else{
    //   submitTimeSheet(project.id, profile.id, currDate, timesheet, props.adminProfile ? props.adminProfile.id : undefined, notes, (resp) => {
    //     if(!resp) {
    //       handleError()
    //       return
    //     }
    //     getTimesheetWeek(
    //       project.id,
    //       profile.id,
    //       moment.utc(currDate).format("YYYY-MM-DD").toString(),
    //       (response) => {
    //         if (!response) {
    //           handleError();
    //           return;
    //         } else {
    //           setTimesheets(response.curr_week);
    //           setTimesheetId(response.curr_week.timesheet_id)
    //           setPrevWeek(response.prev_week)
    //           setLoading(false);
    //         }
    //         fetch_summary(undefined)
    //       }
    //     );
    //   })
    //     setSuccess("Your timesheet has been successfully submitted");
    // }

  };

  const handleDocuRedirect = (data) => {
    return data.get('event') == 'signing_complete'
  };

  const getBoundaryDates = () => {
    let startDates = []
    let endDates = []
    if (project) {
      startDates.push(project.start_date)
      startDates.push(project.resource_project_startdate)
      //console.log('enddates',project.end_date, project.resource_project_enddate)
      endDates.push(project.end_date)
      endDates.push(project.resource_project_enddate)
    }

    return { startDates, endDates }
  }

  const getMaxStartDate = () => {
    let startDates = getBoundaryDates().startDates
    //console.log('startDates',startDates)
    if (startDates) {
      return startDates[0] > startDates[1] ? startDates[0] : startDates[1]
    }
    return undefined
  }

  const unlockTimesheet = () => {
    setLoading(true);
    setTimesheetStatus(profile.id, project.id, moment.utc(currDate).format('YYYY-MM-DD').toString(),
      'pending', (response) => {
        if (response instanceof ErrorObject) {
          response.displayError()
          handleError()
        } else {
          reloadTimesheets();
          setLoading(false);
        }
      })
    return
  }

  const onNoteUpdate = (noteText) => {
    setNotes(noteText)
  }

  useEffect(() => {
    if (currDate && moment.utc(currDate).isValid()) {
      reloadTimesheets()
    }
  }, [currDate])

  return (
    <>
      <LoadingPopUp open={loading || !hasLoaded} loadingText={loadingText} />
      <ErrorPopUp
        open={error}
        onClose={(event) => {
          event.preventDefault();
          setError(false);
        }}
      />
      <IFramePopUp
        open={dsObj && dsObj.hasNextUrl()}
        onClose={(from_redirect) => {
          if (dsObj && dsObj.hasNextUrl()) {
            if (!from_redirect) {
              let newDs = dsObj.clone()
              newDs.quit = true
              setDsObj(newDs)
            }
          }
        }}
        title="Docusign"
        url={currUrl}
        onRedirect={(data) => {
          if (dsObj && dsObj.hasNextUrl()) {
            let newDs = dsObj.clone()
            newDs.checkUrlSuccess(dsObj.getNextUrl(), data)
            setDsObj(newDs)
          }
        }}
        maxRedirect={2}
        clientOrigin={window.location.origin.toString()}
      />
      <TextPopUp title="Success!" content={success} open={Boolean(success)} onClose={(event) => { event.preventDefault(); setSuccess("") }} fullWidth />
      <Box sx={{ m: "0.15%", boxShadow: 3 }}>
        <Grid container rowSpacing={2} sx={{ bgcolor: "view.main" }}>
          <br />
          <Grid
            item
            container
            xs={12}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              m: "1%",
              mt: "0",
            }}
          >
            <Grid item xs={12}>
              <Button variant="outlined" onClick={() => { navigate(-1) }}>
                Back
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography align='center' fontSize={fonts.projectListSize}>Please Enter The Amount of Hours You Have Worked</Typography>
            </Grid>
            <Grid item xs={12} container sx={{ mt: "1%" }}>
              <Grid item xs={10} container sx={{
                justifyContent: "flex-start",
              }} >
                <Box sx={{ flexDirect: "column", marginRight: '20px' }}>
                  <Typography variant="h5">
                    {profile.first_name} {profile.last_name}
                  </Typography>
                  <Typography variant="body1" fontSize={fonts.projectListSize}>
                    Project: {project.project_name}
                  </Typography>
                  {!isObjectEmpty(timesheets) ? (<Typography variant="body1" fontSize={fonts.projectListSize}>
                    Period Of |{" "}
                    {dateStringWithoutWeekday(
                      moment(Object.keys(timesheets.dates)[0])
                        .toDate()
                        .toDateString()
                    )}
                  </Typography>) : <></>}


                </Box>
                <Box sx={{ flexDirect: "column" }}>
                  {props.adminProfile && !isObjectEmpty(timesheetSummary) ?
                    <>
                      <Typography variant='body1' fontSize={fonts.projectListSize}>
                        Total Hours: {timesheetSummary.total_hours || 0} Hours
                      </Typography>
                      <Typography variant='body1' fontSize={fonts.projectListSize}>
                        Current Year ({moment.utc().year()}): {timesheetSummary.current_year || 0} Hours
                      </Typography>
                      <Typography variant='body1' fontSize={fonts.projectListSize}>
                        Current Month ({moment.utc().format('MMMM')}): {timesheetSummary.current_month || 0} Hours
                      </Typography>
                      <Typography variant='body1' fontSize={fonts.projectListSize}>
                        Previous Month ({moment.utc().subtract(1, 'months').format('MMMM')}): {timesheetSummary.previous_month || 0} Hours
                      </Typography>
                    </>
                    : <></>}
                </Box>
              </Grid>
              <Grid item container xs={2} sx={{ justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column' }}>
                {props.adminProfile ?
                  <Grid item sx={{ m: '1%' }}>
                    <HoverButton onClick={(event) => { event.preventDefault(); unlockTimesheet() }} color='black'
                      variant='outlined' disabled={!timesheets.submitted}>Unlock Timesheet</HoverButton>
                  </Grid>
                  : <></>}
                <GridBreak />
                <Grid item sx={{ m: '1%' }}>
                  <DatePicker label="Date Navigation" value={moment.utc(currDate)}
                    onChange={(value) => { setCurrDate(value); }}
                    renderInput={(params) => <TextField sx={{ marginTop: 3 }}{...params} />}
                    maxDate={moment.utc()} minDate={moment.utc(getMaxStartDate())} />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: "0.5%"
                }}
              >
                <WeeklyPeriodNav
                  onUpdate={(date) => {
                    if (date !== undefined && date !== null) setCurrDate(date);
                  }}
                  variant="outlined"
                  color="black"
                  minDate={getMaxStartDate()}
                  value={moment.utc(currDate).toString()}
                />
              </Grid>
              {!isObjectEmpty(timesheets) ? (
                <>
                  <NewTimeSheetsForm
                    timesheets={timesheets.full}
                    onSubmit={onSubmit}
                    onSave={onSave}
                    notes={notes}
                    onNoteUpdate={onNoteUpdate}
                    beenSubmitted={timesheets.submitted}
                    daysPerRow={7}
                    boundaryDates={getBoundaryDates()}
                    project={project}
                    currDate={currDate}
                  />
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

TimeSheets.propTypes = {
  /**
   * profile of resource that the timesheet is being submitted of (object that follows resource schema in db)
   */
  profile: PropTypes.object.isRequired,
  /**
   * profile of admin submitting the timesheet
   */
  adminProfile: PropTypes.object
}

export default TimeSheets;
