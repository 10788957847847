import React from "react";
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close';
/**
 * Close Icon that has button functionality
 * @component 
 * @category Stylized Components 
 */
function CloseButton(props){
    return (<CloseIcon onClick={props.onClick} sx={{cursor:'pointer', borderRadius:2, '&:hover': {
        color: props.hoverColor, background:props.backgroundColor}}}/>)
}

CloseButton.propTypes = {
    /**
     * function called when button is clicked, passes the clicking event, e
     */
    onClick: PropTypes.func.isRequired,
    /**
     * color that icon will turn when mouse hovers over it
     */
    hoverColor: PropTypes.string,
    /**
     * color of background of icon when mouse hovers over it
     */
    backgroundColor: PropTypes.string
}

CloseButton.defaultProps = {
    hoverColor: 'red',
    backgroundColor: 'gainsboro'
}

export default CloseButton