import React from 'react';
import {PropTypes} from 'prop-types'
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Box, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

function Confirmation(props) {
    return (
        <Dialog
        open={props.open}
        onClose={props.onClose}>
            <DialogTitle>
                <Grid container>
                    <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                        <Grid item container xs={11} sx={{justifyContent:'center'}}>
                            {props.title}
                        </Grid>
                        <Grid item container xs={1} sx={{justifyContent:'flex-start'}}>
                            <CloseIcon sx={{m:'1%', cursor:'pointer','&:hover':{color:'red'}}} onClick={props.onClose}/>
                        </Grid>
                             
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.content}    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container item xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                    <Button onClick={props.onConfirm} autoFocus>Confirm</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

Confirmation.propTypes = {
    /**
     * whether or not the popup is open
     */
    open : PropTypes.bool.isRequired,
    /**
     * function called upon closing out of the popup
     */
    onClose : PropTypes.func.isRequired,
    /**
     * function closed upon confirming within the popup
     */
    onConfirm : PropTypes.func.isRequired
}

Confirmation.defaultProps = {
    title:"Are You Sure?",
    content : "Are you sure?"
}

export default Confirmation;