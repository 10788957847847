import React from 'react';
import PropTypes from 'prop-types'
import {Box} from '@mui/material'
/**
 * An invisible box that wraps components and gives them clicking functionality
 * @component
 * @category Functionality
 */
function ClickBox(props) {
    return (
        <Box {...props} sx={{cursor:(props.clickable ? 'pointer' : undefined),"&:hover": {
            background: (props.clickable && props.showClickable? props.hoverColour : undefined),
          }}} onClick={props.clickable ? props.onClick : undefined}>
            {props.children}
        </Box>
    );
}

ClickBox.propTypes = {
    /**
     * color box turns when mouse hovers over it
     */
    hoverColour: PropTypes.string,
    /**
     * function called when box is clicked on, passes an event e for clicking
     */
    onClick: PropTypes.func,
    /**
     * whether or not the box can be clicked on
     */
    clickable: PropTypes.bool,
    /**
     * whether or not the box will change colours when hovered
     */
    showClickable: PropTypes.bool
}

ClickBox.defaultProps ={
    hoverColour:"gainsboro",
    clickable:true,
    showClickable:true
}

export default ClickBox;