import { TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types'
/**
 * an mui TableRow that alternates between
 * @component
 * @category Stylized Components
 */
function AlternateRow(props) {
  
  const getSx = () =>{
    let sx = {}
    if(props.alternate && !props.overrideColor){
      sx[props.alternateType] = {
        backgroundColor: props.alternateColor,
      }
    }else if(props.overrideColor){
      sx={backgroundColor:props.overrideColor}
    }
    return sx
  }

    return (
        <TableRow sx={getSx()} key={props.key}>
            {props.children}
        </TableRow>
    );
}

AlternateRow.propTypes = {
  /**
   * where or not the row should alternate between colours
   */
  alternate: PropTypes.bool,
  /**
   * type of alternation that the row is doing
   */
  alternateType : PropTypes.string,
  /**
   * color that the alternate row should be according to alternateType
   */
  alternateColor: PropTypes.string,
  /**
   * unique key indicating the row
   */
  key: PropTypes.string,
  /**
   * color that a row should be regardless of posiiton
   */
  overrideColor: PropTypes.string
}

AlternateRow.defaultProps = {
  alternate: true,
  alternateType: "&:nth-of-type(even)",
  alternateColor: "#E8E8E8"
}

export default AlternateRow;