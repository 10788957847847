import { weeklyDocusignPromise } from "./docusignHelper";
import { ErrorObject } from "./generalHelp"
import { fullSaveToSubmit, weeklySaveTimesheet, weeklySaveTimesheetPromise } from "./newTimeSheetHelper"

export class DocusignUrlHandler{
    constructor(url, envelope_id, success_func, dsHandler){
        this.url = url
        this.success_func = success_func;
        this.handler = dsHandler;
        this.envelope_id = envelope_id
    }

    checkSuccess(params){
        return this.success_func(params)
    }

    toString(){
        return String(this.url)
    }
}

export class DocusignHandler{
    constructor(supp_obj, success_func, dates){
        this.supp_obj = supp_obj
        this.success_func = success_func
        this.dates = dates
        this.dsReady = false;
        this.urls = []
        this.error = false;
        this.quit = false;
    }

    setUrls(newUrls){
        this.urls = newUrls
    }
    /*remove url from list*/
    removeUrl(url){
        let newUrls = this.urls.filter(item => item.url !== url)
        this.setUrls(newUrls)
    }
    /*check if a url has been successful*/
    checkUrlSuccess(url, params){
        if(!url.checkSuccess(params)){
            this.error = true
        }
        this.removeUrl(url.url)
    }

    /*function used to clone object, use for useStates*/
    clone(){
        let c =  new DocusignHandler(
            this.supp_obj,
            this.success_func,
            this.dates
        )
        c.dsReady = this.dsReady
        c.urls = this.urls
        c.error = this.error
        c.quit = this.quit
        return c
    }

    async saveTimesheets(){
        if(this.dates.length <= 0 )
            return false;

        let response;
        //saving the week
        response = await weeklySaveTimesheetPromise(this.supp_obj.resource_id,
            this.supp_obj.project_id, this.dates[0], this.supp_obj.timesheets,
            this.supp_obj.notes)
        if(response instanceof ErrorObject){
            this.dsReady = false;
            this.urls = []
            return false
        }
        //getting all urls
        for (const date of this.dates){
            let dsResp = await weeklyDocusignPromise(this.supp_obj.resource_id, this.supp_obj.project_id,
                date, this.supp_obj.redirect_url)
            if(dsResp instanceof ErrorObject){
                this.dsReady = false;
                this.urls = []
                return false
            }
            this.urls.push(new DocusignUrlHandler(dsResp.redirect_url, dsResp.envelope_id, this.success_func, this))
        }
        return true
    }

    async handleDs(){
        let success = await this.saveTimesheets();
        if(!success) return false;
        this.dsReady = true;
        return true;
    }

    hasNextUrl(){
        return this.dsReady && this.urls.length > 0
    }

    getNextUrl(){
        if(!this.hasNextUrl())
            return undefined
        
        return this.urls[this.urls.length - 1]
    }

    checkAllSigned(callback){
        console.log(this)
        if(this.quit){
            callback(null)
            this.resetObject()
            return false;
        }else if(this.error){
            callback(new ErrorObject(500, "an internal error has occured"))
            this.resetObject()
            return false;
        }

        if(this.urls.length > 0){
            return false;
        }

        //all urls have been signed so sumbit timesheet
        fullSaveToSubmit(this.supp_obj.resource_id, this.supp_obj.project_id,
            this.dates[0], this.supp_obj.admin_id, callback)

        return true;
    }

    resetObject(){
        this.supp_obj = {}
        this.dates = []
        this.dsReady = false;
        this.urls = []
        this.error = false;
        this.quit = false;
    }

}