import React, { useEffect, useState } from "react";
import { Grid, Autocomplete, Typography } from "@mui/material";
import FormattedTextField from "./Formatted/FormattedTextField";
import PropTypes from 'prop-types'

/**
 * makes call to api to get list of projects, will then call props.onUpdate when selecting different projects
 * @component
 * @category Timesheets
 * @subcategory Submitted Timesheets
 */
function MultiProjectDropDown(props) {
  const [listOfProj, setListOfProj] = useState(props.projects)
  const [projects, setProjects] = useState([]);
  const [input, setInput] = useState("")



  useEffect(()=>{
    setListOfProj(props.projects)
  },[props.projects])

  useEffect(()=>{
    props.onUpdate(projects.map(project => project.id))
  },[projects])

  useEffect(() => {
    if(props.onUpdateValue) props.onUpdateValue(input)
  },[input])

  return (
    <Grid item xs={12} 
    sx={{m:'0.25%'}}
    >
      {listOfProj.length > 0 ?<Autocomplete
        disablePortal
        multiple
        id="combo-box-demo"
        options={listOfProj}
        value={projects}
        size='medium'
        isOptionEqualToValue={(option,value)=> option.id === value.id}
        getOptionLabel={(option)=>`${option.project_name}`}
        renderInput={(params) => <FormattedTextField {...params} label="Projects" />}
        inputValue={input}
        onInputChange = {(event, value, reason) => {
          if(reason !== 'reset') setInput(value)
        }}
        onChange={(event, value) => {
          //props.onUpdate(value.map(project => project.id));
          setProjects(value);
        }}
      /> : <Typography align="center" variant="subtitle1">You Have No Projects</Typography>}
    </Grid>
  );
}

MultiProjectDropDown.propTypes = {
  /**
   * list of projects that user can choose from with component
   */
  projects: PropTypes.instanceOf(Array).isRequired,
  /**
   * function called when one project is selected
   */
  onUpdate: PropTypes.func.isRequired,
  /**
   * function called as text is typed into the mui Autocomplete
   */
  onUpdateValue: PropTypes.func
}

MultiProjectDropDown.defaultProps = {
  projects: []
}

export default MultiProjectDropDown;
