import { Modal, Box, Button, Typography, Link, CircularProgress, Grid, Dialog } from '@mui/material';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import { downloadDocusign } from '../../../util/docusignHelper';
import TimesheetNotes from '../../../components/NewTimesheets/TimesheetNotes';
import TimeSheetFields from '../../../components/NewTimesheets/TimeSheetFields';
import DocusignTSFields from '../../../components/NewTimesheets/DocusignTSFields';

/**
 * pop up used to display a timesheet form
 * @component
 * @category Pop Ups 
 */
function AggTimesheetPopUp(props) {

    const [loading, setLoading] = useState(false)
    const onDownload = (id) =>{
        // getEnvelope(props.infoObj.envelope_id, (url)=>{
        //     console.log('url',url)
        //     if(url){
        //         setDocusignUrl(url.redirect_url)
        //     }
        // }, props.path)
        setLoading(true)
        downloadDocusign(id, (blob) =>{
            try{
                if(!blob){
                    setLoading(false)
                    alert('something went wrong when downloading your file')
                    return
                }
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${props.infoObj.envelope_id}.pdf`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
                setLoading(false)
            }catch(e){
                console.error(e)
                alert('something went wrong when downloading your file')
                setLoading(false)
            }
        })
    }
    
    function generateBackgroundColor(timesheet){
        return timesheet.submitted ? "lightgrey" : "#ff6600"
    }

    return (
        <Dialog sx={{bgcolor:'transparent', justifyContent:'center', alignItems:'center', padding:'1%', m:'1%', overflowY:'auto'}}
        open={props.open}
        onClose={() => {props.onClose()}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{style:{borderRadius:4}}}
        fullScreen>
            <Box sx={{display:'flex', bgcolor:'white', padding:'1%', borderRadius:2, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <Box sx={{width:'100%', display:'flex', maxHeight:'100%', justifyContent:'center', alignItems:'flex-start', flexDirection:'column', padding:'1%' }}>
                    <Grid item container xs={12} sx={{justifyContent:'center',alignItems:'center', width:'100%'}}>
                    <Typography variant="h5" align='center'>
                        {props.infoObj.start && props.infoObj.end ? 
                        `Your Submitted Timesheet For ${moment.utc(props.infoObj.start).format('MMMM DD YYYY').toString()} to ${moment.utc(props.infoObj.end).format('MMMM DD YYYY').toString()}` 
                        : "Your Submitted Timesheet"}
                    </Typography>
                    </Grid>
                    <Grid container sx={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                        <Grid item xs={2}>
                            {props.infoObj.days_worked !== undefined ? <Typography>Total Days Worked: {props.infoObj.days_worked}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={2}>
                            {props.infoObj.hours_worked !== undefined ? <Typography>Total Hours Worked: {props.infoObj.hours_worked}</Typography> : <></>}
                        </Grid>
                        
                    </Grid>
                    <Grid container sx={{display:'flex', flexDirection:'justifyContent', alignItems:'flex-start'}}>
                        <Grid item xs={2}>
                            {props.infoObj.project ? <Typography sx={{mr:'1%'}}>Project: {props.infoObj.project.project_name}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={2}>
                            {props.infoObj.submitted_dates ? 
                            <Typography>Submitted on {props.infoObj.submitted_dates.join(", ")}</Typography> 
                            : <></>}
                        </Grid>
                        <Grid item xs={2}>
                            {props.infoObj.full_submitted ? <Typography>Status: Submitted</Typography> : <Typography>Status: Incomplete</Typography>}
                        </Grid>
                        <Grid item xs={4}>
                            {props.infoObj.admin_submits ? 
                            <Typography>Admin(s) who submitted : {props.infoObj.admin_submits.map(admin => `${admin.first_name} ${admin.last_name}`)}</Typography> : <></>}
                        </Grid>
                        
                    </Grid>
                    
                    {props.infoObj.envelope_ids ?
                    <>
                    {props.infoObj.envelope_ids.map((id) => (
                        <Box sx={{display:'flex',flexDirectiOn:'row', justifyContent:'center', alignItems:'center'}}>
                        <Typography>
                            Docusign Document <Link onClick={(event) => {event.preventDefault(); onDownload(id)}} 
                            sx={{cursor: "pointer"}}>Click Here to Download</Link>
                        </Typography>
                        {loading ? <CircularProgress size={"1.5rem"}/> : <></>}
                    </Box>
                    ))} 
                    </> 
                    : <></>}
                </Box>
                {props.infoObj.hours_dict ? 
                <>
                {props.infoObj.standard_vacation ? 
                <TimeSheetFields timesheets={props.infoObj.hours_dict} beenSubmitted={true} daysPerRow={7} getBackgroundColor={generateBackgroundColor}/>
                :<DocusignTSFields timesheets={props.infoObj.hours_dict} beenSubmitted={props.beenSubmitted} daysPerRow={7} getBackgroundColor={generateBackgroundColor}/>}
                {props.infoObj.notes ? <TimesheetNotes beenSubmitted={true} notes={props.infoObj.notes || "No Notes"}/> : <></> }
                </>
                 : <></>}
                <Button variant='contained' onClick={(event) => {event.preventDefault(); props.onClose()}}>Ok</Button>
            </Box>
        </Dialog>
    );
}

AggTimesheetPopUp.propTypes = {
    /**
     * whether or not the pop up is open
     */
    open: PropTypes.bool.isRequired,
    /**
     * function called when pop up is closed
     */
    onClose: PropTypes.func.isRequired,
    /**
     * object containing information about the project such as days worked, the project name, etc.
     */
    infoObj: PropTypes.object.isRequired,
    /**
     * boolean used to indicate whether timesheet has already been submitted
     */
    beenSubmitted: PropTypes.bool
}   

AggTimesheetPopUp.defaultProps = {
    beenSubmitted: false
}


export default AggTimesheetPopUp;