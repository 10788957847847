import {Box} from '@mui/material'
import logo from '../../seq_login_logo.png'

export const components={
    Header () {
        return(
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: '220px' , boxShadow:2}}>
                <img src={logo}/>
            </Box>
        )
    },
    SignUp :{}
}