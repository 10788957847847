import React, { useEffect, useState } from "react";
import Template from "../Template";
import {
  Grid,
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Autocomplete,
  TextField
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getAllProfiles, getProfileProjects } from "../../util/ProfileHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { fonts } from "../../util/Theme";
import LoadingPopUp from "../../components/Pop_ups/LoadingPopUp";
import PropTypes from 'prop-types'
import ErrorPopUp from "../../components/Pop_ups/ErrorPopUp";
import FormattedTextField from "../../components/Formatted/FormattedTextField";
import moment from 'moment'

/**
 * Page used for displaying all projects a consultant can choose from
 * @component
 * @category Pages
 * @subcategory Consultant
 */
function Projects(props) {
  const [projects, setProjects] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(props.profile)
  const [allProfiles, setAllProfiles] = useState([])
  const [error,setError] = useState(false)
  const [inactiveProjects,setInactiveProjects] = useState([])

  const navigate = useNavigate();

  useEffect(()=>{
    console.log('selectedProfile',selectedProfile)
  })

  useEffect(() => {
    let abortCont = new AbortController();
    if (!hasLoaded) {
      if(selectedProfile){
        getProfileProjects(profileCallback, selectedProfile.id, abortCont);
      }else{
        getProfileProjects(profileCallback, props.profile.id, abortCont)
      }
      
      if(props.profile.role === 'admin'){
        getAllProfiles(abortCont, (profiles) =>{
          if(!profiles){
            setError(true)
          }else{
            setAllProfiles(profiles.resources)
          }
        })
      }
    }
    return () => abortCont.abort()
  }, []);

  useEffect(()=>{
    console.log('allProfiles',allProfiles)
  },[allProfiles])

  const profileCallback = (projects) =>{
    console.log('setting projects to',projects)
    if(!projects){
      setError(true)
    }else{
      console.log('projects in callback is',projects)
      let active = []
      let inactive = []
      for (let proj of projects){
        if(proj.end_date == null || (moment.utc(proj.end_date).endOf('day').isSameOrAfter(moment.utc()) && 
        (proj.resource_project_enddate == undefined || moment.utc().isSameOrBefore(moment.utc(proj.resource_project_enddate).endOf('day'))))){
          active.push(proj)
        }else{
          inactive.push(proj)
        }
      }
      setProjects(active)
      setInactiveProjects(inactive)
    }
    setLoading(false)
    setHasLoaded(true)
  }

  useEffect(()=>{
    let abortController = new AbortController();
    setLoading(true)
    if(selectedProfile) getProfileProjects(profileCallback, selectedProfile.id, abortController);
    else getProfileProjects(profileCallback, props.profile.id, abortController)
    setLoading(false)
    return () => abortController.abort()
  },[selectedProfile])

  const handleClick = (event, project) => {
    event.preventDefault();
    navigate("./timeSheets", { state: {project:project ,profile:( selectedProfile ? selectedProfile : props.profile)}});
  };

  return (
    <>
      <LoadingPopUp open={!hasLoaded || loading}/>
      <ErrorPopUp open={error} onClose={(event) => {event.preventDefault(); setError(false)}}/>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", mt: "0.5%" }}
      >
        {props.profile.role === 'admin' ? 
        <Grid item container xs={6} sx={{padding:'0.5%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
        <Typography>
          Please Enter The Resource You Wish To Immitate Here, You Are Selected By Default
        </Typography>
        <Autocomplete
        sx={{mt:'1%'}}
        fullWidth
        value={selectedProfile}
        options={allProfiles}
        isOptionEqualToValue={(option,value)=> (option.id === value.id)}
        getOptionLabel={(option)=>`${option.first_name} ${option.last_name}`}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {`${option.first_name} ${option.last_name} (${option.primary_email})`}
            </li>
          );
        }}
        renderInput={(params) => <FormattedTextField {...params} label="Resource" />}
        onChange={(event, newValue) => {
          event.preventDefault()
          setSelectedProfile(newValue);
        }}
        /> </Grid>: <></>}
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Your Projects
          </Typography>
          <hr />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {projects.length > 0  || inactiveProjects.length > 0 ? (
            <>
            {projects.length > 0 ? <List sx={{ width: "80%" }}>
              <Typography fontSize={fonts.projectListSize + 2} sx={{textDecoration: 'underline'}}>Active Projects</Typography>
              {projects.map((project) => {
                return (
                  <ListItem
                    divider
                    key={project.id}
                    onClick={(event) => handleClick(event, project)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        background: "gainsboro",
                      },
                    }}
                    secondaryAction={
                      <IconButton edge="end">
                        <ArrowForwardIosIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText>
                      <Typography fontSize={fonts.projectListSize}>
                        {project.project_name}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List> : <></>}
            
            {inactiveProjects.length > 0 ? <List sx={{ width: "80%" }}>
              <Typography fontSize={fonts.projectListSize + 2} sx={{textDecoration: 'underline'}}>Inactive Projects</Typography>
              {inactiveProjects.map((project) => {
                return (
                  <ListItem
                    divider
                    key={project.id}
                    onClick={(event) => handleClick(event, project)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        background: "gainsboro",
                      },
                    }}
                    secondaryAction={
                      <IconButton edge="end">
                        <ArrowForwardIosIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText>
                      <Typography fontSize={fonts.projectListSize}>
                        {project.project_name}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
            : <></>}
            
            </>
          ) : (
            <Typography align="center" variant="h4">
              You Have No Projects!
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}

Projects.propTypes = {
  /**
   * object used to represent the current logged in user, follows resource schema in db
   */
  profile: PropTypes.object
}

export default Projects;
