import React, { useEffect, useState } from "react";
import { Box, Grid, Card, Typography, Avatar } from "@mui/material";
import { isObjectEmpty } from "../../util/generalHelp";
import { AccountCircle } from "@mui/icons-material";
import GeneralSettings from "../../components/Settings/GeneralSettings";
import PropTypes from "prop-types";
import LoadingPopUp from "../../components/Pop_ups/LoadingPopUp";
import ErrorPopUp from "../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../components/Pop_ups/TextPopUp";
import ClickBox from '../../components/ClickBox'
import UploadPfp from '../../components/Settings/UploadPfp'
import SuccessSnackBar from '../../components/SuccessSnackBar'

/**
 * Page used for managing profile and settings
 * @component 
 * @category Pages
 * @subcategory Consultant 
 */
function Profile(props) {
  const [profile, setProfile] = useState(props.profile);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [openProfilePic, setOpenProfilePic] = useState(false)

  useEffect(() => {
    if (!isObjectEmpty(profile)) {
      setHasLoaded(true);
    }
  }, [profile]);

  useEffect(()=>{
    setProfile(props.profile)
  },[props.profile])

  const updateStatus = (success, msg) => {
    setStatusSuccess(success);
    setStatusMessage(msg);
    setShowStatus(true);
  };

  const handleClick = () =>{
    setOpenProfilePic(!openProfilePic)
  }

  return (
    <>
      <LoadingPopUp open={!hasLoaded} />
      <ErrorPopUp
        open={!statusSuccess && showStatus}
        errorTitle="Something Went Wrong"
        errorContent={statusMessage}
        onClose={(e) => {
          e.preventDefault();
          setShowStatus(false);
        }}
      />
      {/* <TextPopUp
        open={statusSuccess && showStatus}
        title="Success!"
        content={statusMessage}
        onClose={(e) => {
          e.preventDefault();
          setShowStatus(false);
        }}
      /> */}
      <SuccessSnackBar open={statusSuccess && Boolean(statusMessage)} onClose={() => setStatusMessage(false)} successmessage={statusMessage}/>
      <Grid container>
        <Grid container xs={6} sx={{ m: "1%" }}>
          <Grid item container xs={12}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{mr:'2%'}}>
                  <Typography align="center">
                    {profile.first_name} {profile.last_name}
                  </Typography>
                  <Typography align="center">
                    {profile.primary_email}
                  </Typography>
                </Box>
                <ClickBox
                  onClick={handleClick}
                  sx={{ display: "flex" }}
                  showClickable={false}
                >
                  <Avatar
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor:
                        props.selected || openProfilePic
                          ? props.hoverColor
                          : "coral",
                      "&:hover": { bgcolor: props.hoverColor },
                    }}
                    src={props.profilePicture}
                  >
                    {/* <Avatar src="https://seq-test-invoice-upload.s3.amazonaws.com/test%20image.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYEU5M75QIKZBTB57%2F20220322%2Fca-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220322T170229Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=69d24e1ff8ba70ffd4e85a5628389d625f6b77962fd706a71b1f9299fea5c733"> */}
                    {!props.profilePicture ? (
                      `${props.profile.first_name[0]}${props.profile.last_name[0]}`
                    ) : (
                      <></>
                    )}
                  </Avatar>
                </ClickBox>
              </Box>
              <hr />
              <GeneralSettings updateStatus={updateStatus} profile={props.profile}/>
              <UploadPfp open={openProfilePic} onClose={handleClick} profile={profile} 
              onSuccess={() => {setStatusMessage('profile picture uploaded'); setStatusSuccess(true); props.onUpdate()}}/>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Profile.propTypes = {
  /**
   * object used to represent the current logged in user, follows resource schema in db
   */
  profile: PropTypes.object.isRequired,
  /**
   * name of profile picture for user
   */
  profilePicture: PropTypes.string,
  /**
   * function to be called after updating profile picture
   */
  onUpdate: PropTypes.func
};

export default Profile;
