import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment'
import HoverButton from '../HoverButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function WeeklyPeriodNav(props){
    const [currDate, setCurrDate] = useState(moment.utc().toDate())


    const updateCurrDate = (value) => {
        setCurrDate(value)
        props.onUpdate(value)
    }

    useEffect(()=>{
        if(!moment.utc(currDate).isSame(moment.utc(props.value))){
            setCurrDate(props.value)
        }
    }, [props.value])

    function decreaseWeek(e){
        e.preventDefault();
        updateCurrDate(moment.utc(currDate).subtract(1, 'weeks').toDate())
    }

    function increaseWeek(e){
        e.preventDefault();
        updateCurrDate(moment.utc(currDate).add(1, 'weeks').toDate())
    }

    function isPastPeriodValid(){
        let hypodate
        if(moment.utc(currDate).weekday() === 0){
            hypodate = moment.utc(currDate).subtract(1,'weeks').startOf('day')
        }else{
            let weekday = moment.utc(currDate).weekday()
            hypodate = moment.utc(currDate).subtract(weekday, 'days').startOf('day')
        }
        return Boolean(hypodate.isSameOrAfter(moment.utc(props.minDate).startOf('day')))
    }

    function isFuturePeriodValid(){
        let hypodate
        if(moment.utc(currDate).weekday() === 0){
            hypodate = moment.utc(currDate).add(1,'days').startOf('day')
        }else{
            hypodate = moment.utc(currDate).weekday(1).startOf('day').add(1,'weeks')
        }
        return Boolean(hypodate.isSameOrBefore(moment.utc().endOf('day')))
    }

    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
            <Grid item xs={12} container sx={{justifyContent:'center', alignItems:'center'}}>
                <Grid item container sx={{justifyContent:'center', alignItems:'center'}} xs={3}>
                    <>
                    <ChevronLeftIcon onClick={(event) => {if(isPastPeriodValid()) decreaseWeek(event)}} 
                    sx={isPastPeriodValid() ? {cursor:'pointer', "&:hover": {background: "gainsboro"}} : {}}/>
                    <HoverButton onClick={decreaseWeek} disabled={!isPastPeriodValid()} {...props}>
                    Previous Period
                    </HoverButton>
                    </>
                </Grid>
                <Grid item container sx={{justifyContent:'center', alignItems:'center'}} xs={3}>
                    <>
                    <HoverButton onClick={increaseWeek} disabled={!isFuturePeriodValid()} {...props}>
                    Next Period
                    </HoverButton>
                    <ChevronRightIcon onClick={(event) => {if(isFuturePeriodValid()) increaseWeek(event)}} 
                    sx={isFuturePeriodValid() ? {cursor:'pointer', "&:hover": {background: "gainsboro"}} : {}}/>
                    </>
                </Grid>
            </Grid>
        </Box>
    );

}

WeeklyPeriodNav.propTypes = {
    /**
     * function called when buttons are clicked, passes new date
     */
    onUpdate: PropTypes.func.isRequired,
    /**
     * minimum allowed date for navigation
     */
    minDate: PropTypes.string,
    /**
     * current date of period nav
     */
    value: PropTypes.instanceOf(Date)
}

export default WeeklyPeriodNav