import React, {useEffect, useState} from "react";
import { Table, TableHead, TableRow, TableBody, Typography, IconButton, Button, TextField, useThemeProps} from "@mui/material";
import ForwardIcon from '@mui/icons-material/Forward';
import TableContainer from '@mui/material/TableContainer';
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import moment from "moment";
import SubmittedCell from "../../../components/Submitted/SubmittedCell";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../../components/Pop_ups/TextPopUp";
import Confirmation from "../../../components/Pop_ups/Confirmation";
import Paper from '@mui/material/Paper';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import { getAllProfiles, getProfileProjects } from "../../../util/ProfileHelper";
import { useLocation, useNavigate } from "react-router-dom";
import {getTimesheetSummary, getTimesheetSummary2 } from "../../../util/newTimeSheetHelper";

import './Resource.css'
import ClickBox from "../../../components/ClickBox";
import { getIndividialProjectinfo } from "../../../util/admin-api-requests/ProjectHelper";
import PiiViewer from "./PiiViewer";

export const ViewResourceData = ({profile, projects, history, id}) => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [allProfiles, setAllProfiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [error,setError] = useState(false)
    const [newProjects, setNewProjects] = useState([]);
    const [timesheetSummaryActive, setTimesheetSummaryActive] = useState([]);
    const [timesheetSummaryHistory, setTimesheetSummaryHistory] = useState([]);
    const [newPassword, setNewPassword] = useState('')
    const [confirmStatus, setConfirmStatus] = useState(false)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState('')
    const navigate = useNavigate();
    

let filteredData = projects.filter(data => data.resource_id === id)
let filteredHistory = history.filter(data => data.resource_id === id)
let filteredProfile = allProfiles.filter(data => data.id === id)


useEffect(()=>{
    getTimesheetSummary2((data)=>{
        let filteredProjects = data.summaries.filter(data=>data.resource_details.id === id)
        let date=moment.utc(Date.now()).format("YYYY-MM-DD").toString()
        let filteredActive = filteredProjects.filter(data=>moment.utc(data.project_details.end_date).format("YYYY-MM-DD").toString()>date)
        let filteredInactive = filteredProjects.filter(data=>moment.utc(data.project_details.end_date).format("YYYY-MM-DD").toString()<date)
        setTimesheetSummaryActive(filteredActive)
        setTimesheetSummaryHistory(filteredInactive)
        
    }) 
  },[])


//   useEffect(()=>{
//     let abortCont = new AbortController();
//     let hoursSum=[]
//     filteredData.forEach((data)=>{ 
//         getTimesheetSummary(id, data.project_id, abortCont, (summary) => {
//             let obj={...summary.summary, id, project_id: data.project_id}
//             hoursSum.push(obj)
//         })
//     })
    
//     setTimesheetSummary(hoursSum)
//   },[])

//   useEffect(()=>{
//     console.log('timesheetSummary', timesheetSummary)
//   },[timesheetSummary])

  

const onClickHandler = (event, project) => {
    let navData = filteredData.filter(data=>data.project_id === project.project_details.id)
    let projectCompare = newProjects.filter(data => data.id === navData[0].project_id)
    setNewProjects(projectCompare)
    event.preventDefault();

    navigate("/home/projects/timeSheets", { state: {project:projectCompare[0] ,profile:( filteredProfile[0] ? filteredProfile[0] : setError(true))}});
  };
  // useEffect(() => {
  //   console.log('password', newPassword)
  // }, [newPassword]);

  useEffect(() => {
    let abortCont = new AbortController();
    if (!hasLoaded) {
      if(id){
        getProfileProjects(profileCallback, id, abortCont);
      }else{
        getProfileProjects(profileCallback, profile.id, abortCont)
      }
      
      if(profile.role === 'admin'){
        getAllProfiles(abortCont, (profiles) =>{
          if(!profiles){
            setError(true)
          }else{
            setAllProfiles(profiles.resources)
          }
        })
      }
    }
    return () => abortCont.abort()
  }, []);

  const profileCallback = (projects) =>{
    if(!projects){
      setError(true)
    }else{
      setNewProjects(projects)
    }
    setLoading(false)
    setHasLoaded(true)
  }

  const setPassword = (email)=>{
    if(!email){
      setError(true)
    }else{
    getAmplifyToken((jwt) => {
      fetch(`${process.env.REACT_APP_ADMIN_API}/admin/set_password`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: jwt,
        },
      }).then((response) => {
        console.log('response', response)
        setLoading(false);
        if (response.ok) {
          setSuccess(true);
        } else {
          setError(true);
          setMessage("Could not set password. Please try again");
        }
      });
  })
}
}

  const navigateToProject = (project_id) => {
      // getIndividialProjectinfo(project_id, (data) => {
      //   console.log('navigate response', data)
      //   navigate('/home/admin/projects/manage-project',{state:{projectInfo:data.project_info, details:data.details}})
      // })
      navigate(`/home/admin/projects/manage-project/${project_id}`)
  }
  //console.log('profile', profile)
  return (<div>
      <div className="reset-password">
      <PiiViewer profile={profile} resource_id={id}/>
        <Button sx={{marginLeft: 2}} variant='outlined' onClick={()=>{setConfirmStatus(true)}}>Reset Password</Button>
        <Confirmation
            open={confirmStatus}
            onClose={() => setConfirmStatus(false)}
            onConfirm={(event) => {
              event.preventDefault();
              setPassword(filteredProfile[0].primary_email)
              setConfirmStatus(false)

            }}
            onDeny={() => setConfirmStatus(false)}
            title="Are You Sure?"
            content={`You are about to reset the password for this user`}
          />
      <TextPopUp
        title="Success!"
        content={'New password has been sent'}
        open={success}
        onClose={(event) => {
          event.preventDefault();
          setSuccess(false);
        }}
        fullWidth
      />
      </div>
      {timesheetSummaryActive.length > 0? (
      <>
      <ErrorPopUp open={error} onClose={(event) => {event.preventDefault(); setError(false)}}/>
      <Typography sx={{textAlign:'center', fontSize:"170%", marginBottom:"5px"}}>{filteredData[0].first_name + " " + filteredData[0].last_name} Active Projects</Typography>
      <TableContainer component={Paper}>
            <Table className="table-color">
                    <TableHead>
                    <TableRow>
                        <SubmittedCell>Client Name</SubmittedCell>
                        <SubmittedCell>Project Name</SubmittedCell>
                        <SubmittedCell>Resource Start Date</SubmittedCell>
                        <SubmittedCell>Resource End Date</SubmittedCell>
                        <SubmittedCell>Total Hours (Hours)</SubmittedCell>
                        <SubmittedCell>Current Month (Hours)</SubmittedCell>
                        <SubmittedCell>Previous Month (Hours)</SubmittedCell>
                        <SubmittedCell>Current Year (Hours)</SubmittedCell>
                        <SubmittedCell>Latest Timesheet</SubmittedCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {timesheetSummaryActive.length > 0 ? (
                        <>
                        {timesheetSummaryActive.map((summary) => {
                            
                            return (<>
                            <TableRow>
                                <SubmittedCell>{summary.client_details.short_name}</SubmittedCell>
                                <SubmittedCell><ClickBox onClick={(event) => {event.preventDefault(); navigateToProject(summary.project_details.id)}}>
                                  {summary.project_details.project_name}</ClickBox></SubmittedCell>
                                <SubmittedCell>
                                {summary.project_details.start_date
                                ? moment.utc(summary.project_details.start_date).format('YYYY-MM-DD').toString()
                                : "N/A"}
                                </SubmittedCell>
                                <SubmittedCell>
                                {summary.project_details.end_date
                                ? moment.utc(summary.project_details.end_date).format('YYYY-MM-DD').toString()
                                : "N/A"}
                                </SubmittedCell>
                                <SubmittedCell>{summary.time_summary.total_hours}</SubmittedCell>
                                <SubmittedCell>{summary.time_summary.current_month}</SubmittedCell>
                                <SubmittedCell>{summary.time_summary.previous_month}</SubmittedCell>
                                <SubmittedCell>{summary.time_summary.current_year}</SubmittedCell>
                                <SubmittedCell><IconButton variant='outlined' onClick={(event)=>{onClickHandler(event, summary);}}><ForwardIcon/></IconButton></SubmittedCell>
                            </TableRow>
                            </>)
                        })}
                        </>
                    ) : (
                        <></>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                </>):       <Typography sx={{textAlign:'center', fontSize:"170%", marginBottom:"10px"}}>No Active Projects</Typography>
 } 
 {timesheetSummaryHistory.length>0 ? (
      <>
      <Typography sx={{textAlign:'center', fontSize:"170%", marginBottom:"5px", marginTop:"20px"}}>{filteredHistory[0].first_name + " " + filteredHistory[0].last_name} Past Projects </Typography>
      <TableContainer component={Paper}>
            <Table className="table-color">
                    <TableHead>
                    <TableRow>
                        <SubmittedCell>Client Name</SubmittedCell>
                        <SubmittedCell>Project Name</SubmittedCell>
                        <SubmittedCell>Resource Start Date</SubmittedCell>
                        <SubmittedCell>Resource End Date</SubmittedCell>
                        <SubmittedCell>Total Hours (Hours)</SubmittedCell>
                        <SubmittedCell>Current Month (Hours)</SubmittedCell>
                        <SubmittedCell>Previous Month (Hours)</SubmittedCell>
                        <SubmittedCell>Current Year (Hours)</SubmittedCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {timesheetSummaryHistory.length > 0 ? (
                        <>
                        {timesheetSummaryHistory.map((summary) => (
                            <TableRow>
                                <SubmittedCell>{summary.client_details.short_name}</SubmittedCell>
                                <SubmittedCell>{summary.project_details.project_name}</SubmittedCell>
                                <SubmittedCell>
                                {summary.project_details.start_date
                                ? moment.utc(summary.project_details.start_date).format('YYYY-MM-DD').toString()
                                : "N/A"}
                                </SubmittedCell>
                                <SubmittedCell>
                                {summary.project_details.end_date
                                ? moment.utc(summary.project_details.end_date).format('YYYY-MM-DD').toString()
                                : "N/A"}
                                </SubmittedCell>
                                <SubmittedCell>{summary.time_summary.total_hours}</SubmittedCell>
                                <SubmittedCell>{summary.time_summary.current_month}</SubmittedCell>
                                <SubmittedCell>{summary.time_summary.previous_month}</SubmittedCell>
                                <SubmittedCell>{summary.time_summary.current_year}</SubmittedCell>
                            </TableRow>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                </>):       <Typography sx={{textAlign:'center', fontSize:"170%", marginTop:"10px"}}>No Past Projects</Typography>
}
 </div>

  )
}
