import { Grid, TextField, Typography } from "@mui/material";
import React, {useState, useEffect} from "react";
import ExpenseDisplay from "./ExpenseDisplay";
import { getAllExpenses } from "../../../util/ExpenseFormHelp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { objectContainsString } from "../../../util/generalHelp";

function AdminExpenses(props){
    const [expenses, setExpenses] = useState([])
    const [hasLoaded, setHasLoaded] = useState(false)
    const [error, setError] = useState("")
    const [searchExpenses, setSearchExpenses] = useState([])

    const searchableFields = ['date_sent','expense_end','expense_start','first_name','last_name','name']

    useEffect(()=>{
        setSearchExpenses(expenses)
        console.log('expenses',expenses)
    },[expenses])

    useEffect(()=>{
        let abortCont = new AbortController()
        if(!hasLoaded){
            getAllExpenses(abortCont, (json)=>{
                if(json){
                    console.log('response', json)
                    setExpenses(json)
                }else{
                    setError("Error fetching expenses from database")
                }
                setHasLoaded(true)
            })
        }

        return () => abortCont.abort()
    })

    const searchForExpenses = (searchString) => {
        if(searchString.trim().length === 0){
            setSearchExpenses(expenses)
            return
        }
        let newExpenses = []
        for(let expense of expenses){
            if(objectContainsString(expense, searchString, searchableFields)){
                newExpenses.push(expense)
            }
        }
        setSearchExpenses(newExpenses)
    }

    return(
        <Grid
            container
            sx={{ padding: "0.5%"}}
        >
            <LoadingPopUp open={!hasLoaded} />
            <ErrorPopUp
            open={Boolean(error)}
            onClose={(event) => {
                event.preventDefault();
                setError("");
            }}
            errorContent={error}
            />
            <Grid item xs={12}>
                <Typography align='center' variant='h3'>Expense View</Typography>
            </Grid>
            <Grid item xs={3}>
                <TextField fullWidth onChange={(e)=>{e.preventDefault(); searchForExpenses(e.target.value)}} label='Search'/>
            </Grid>
            <ExpenseDisplay expenses={searchExpenses} onError={setError}/>
        </Grid>
    )
}

export default AdminExpenses