import { Box } from "@mui/system";
import React from "react";
/**
 * Component used to indicate a line break within and MUI grid
 * @component
 * @category Functionality 
 */
function GridBreak(props){
    return (
        <Box sx={{width:"100%"}}/>
    )
}

export default GridBreak