import React from "react";
import PropTypes from 'prop-types'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { toMoneyString } from "../../../util/generalHelp";


function IndivExpenses(props){
    return(
        <Grid>
            {props.expenses.length > 0 ? 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Date
                            </TableCell>
                            <TableCell>
                                Category
                            </TableCell>
                            <TableCell>
                                Cost
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.expenses.map((expense)=>(
                            <TableRow>
                                <TableCell>{moment.utc(expense.date).format('YYYY-MM-DD').toString()}</TableCell>
                                <TableCell>{expense.category}</TableCell>
                                <TableCell>{toMoneyString(expense.cost)}</TableCell>
                                <TableCell>{expense.desc}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                :
            <Grid></Grid>}
        </Grid>
    )
}

IndivExpenses.propTypes = {
    expenses : PropTypes.arrayOf(PropTypes.object).isRequired
}

export default IndivExpenses