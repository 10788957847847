import {
  Box,
  Grid,
  TableContainer,
  Typography,
  Paper,
  Button,
  Autocomplete,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllProjectInfo } from "../../util/admin-api-requests/ProjectHelper";
import { capitalizeString, isObjectEmpty } from "../../util/generalHelp";
import SubmittedCell from "../../components/Submitted/SubmittedCell";
import moment from "moment";
import AlternateRow from "../../components/AlternateRow";
import ClickBox from "../../components/ClickBox";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorPopUp from "../../components/Pop_ups/ErrorPopUp";
import LoadingPopUp from "../../components/Pop_ups/LoadingPopUp";
import { fonts } from "../../util/Theme";
import GridBreak from "../../components/GridBreak";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Divider from "@mui/material/Divider";

/**
 * page used to display and manage all projects
 * @component
 * @category Pages
 * @subcategory Admin
 */

const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  padding: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "80%",
  width: "70%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ProjectsPage(props) {
  const [activeOpen, setActiveOpen] = React.useState(false);
  const [onboardOpen, setOnboardOpen] = React.useState(false);
  const [projectData, setProjectData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [error, setError] = useState(false);
  const [filter, setFilter] = useState("");
  //const [projectNames, setProjectNames] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [activity, setActivity] = useState("active");
  const [projectCount, setProjectCounts] = useState({ active: 0, inactive: 0 });
  const [resourceCount, setResourceCount] = useState({
    active: 0,
    onboarding: 0,
  });
  const [inactiveCount, setInactiveCount] = useState(0);
  const [activeResList, setActiveResList] = useState([]);
  const [inactiveResList, setInactiveResList] = useState([]);
  const [onboardingResList, setOnboardingResList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("activity", activity);
    onFilter({ filter: filter, type: activity });
  }, [filter, activity, projectData]);
 

  useEffect(() => {
    //setFilteredProjects(projectData)
    countProjects();
    countResources();
  }, [projectData]);

  useEffect(() => {
    const abortCont = new AbortController();
    if (projectData.length === 0 && !hasLoaded) {
      getAllProjectInfo(abortCont, (response) => {
        if (response === undefined) {
          setError(true);
        } else {
          console.log("setting data to", response.data);
          let data = response.data;
          for (let project of data) {
            project.legal_entity = project.legal_entity
              ? project.legal_entity.toUpperCase()
              : undefined;
            project.billing_entity = project.billing_entity
              ? project.billing_entity.toUpperCase()
              : undefined;
            project.paying_entity = project.paying_entity
              ? project.paying_entity.toUpperCase()
              : undefined;
            project.project_name = project.project_name
              ? capitalizeString(project.project_name)
              : undefined;
            project.manager_first_name = project.manager_first_name
              ? capitalizeString(project.manager_first_name)
              : undefined;
            project.manager_last_name = project.manager_last_name
              ? capitalizeString(project.manager_last_name)
              : undefined;
            project.timesheet_frequency.category_name = project
              .timesheet_frequency.category_name
              ? capitalizeString(project.timesheet_frequency.category_name)
              : undefined;
            project.timesheet_type.category_name = project.timesheet_type
              .category_name
              ? capitalizeString(project.timesheet_type.category_name)
              : undefined;
            project.client.short_name = project.client.short_name
              ? capitalizeString(project.client.short_name)
              : undefined;
            project.standard_vacation = project.standard_vacation
              ? project.standard_vacation
              : undefined;
            project.docu_extended_hours = project.docu_extended_hours
              ? project.docu_extended_hours
              : undefined;
            project.myta_project = project.myta_project
              ? project.myta_project
              : undefined;
          }
          setProjectData(data);
          //setProjectNames(response.project_names)
          setHasLoaded(true);
        }
      });
    }

    return () => abortCont.abort();
  });

  const onFilter = (filterObj) => {
    let newProjects = projectData.filter((project) => {
      let query = false;
      let activity = true;
      if (filterObj.filter !== undefined) {
        if (project.client.short_name)
          query =
            query ||
            project.client.short_name
              .toUpperCase()
              .includes(filterObj.filter.toUpperCase());
        if (project.project_name)
          query =
            query ||
            project.project_name
              .toUpperCase()
              .includes(filterObj.filter.toUpperCase());
        if (project.legal_entity)
          query =
            query ||
            project.legal_entity
              .toUpperCase()
              .includes(filterObj.filter.toUpperCase());
        if (project.billing_entity)
          query =
            query ||
            project.billing_entity
              .toUpperCase()
              .includes(filterObj.filter.toUpperCase());
        if (project.paying_entity)
          query =
            query ||
            project.paying_entity
              .toUpperCase()
              .includes(filterObj.filter.toUpperCase());
        if (project.start_date)
          query =
            query ||
            moment
              .utc(project.start_date)
              .format("YYYY-MM-DD")
              .toString()
              .includes(filterObj.filter);
        if (project.end_date)
          query =
            query ||
            moment
              .utc(project.end_date)
              .format("YYYY-MM-DD")
              .toString()
              .includes(filterObj.filter);
      }
      if (filterObj.type) {
        if (filterObj.type == "active")
          activity =
            (activity &&
              moment
                .utc(project.end_date)
                .isSameOrAfter(moment.utc().startOf("day")) &&
              moment
                .utc(project.start_date)
                .isSameOrBefore(moment.utc().startOf("day"))) ||
            project.end_date === null;
        else if (filterObj.type == "inactive")
          activity =
            activity &&
            (moment
              .utc(project.end_date)
              .isBefore(moment.utc().startOf("day")) ||
              moment
                .utc(project.start_date)
                .isAfter(moment.utc().startOf("day"))) &&
            project.end_date !== null;
      }
      return query && activity;
    });

    setFilteredProjects(newProjects);
  };

  const countProjects = () => {
    let active_count = 0;
    let inactive_count = 0;

    for (let obj of projectData) {
      if (
        moment.utc(obj.end_date).isSameOrAfter(moment.utc()) ||
        obj.end_date === null
      ) {
        active_count += 1;
      } else {
        inactive_count += 1;
      }
    }
    setProjectCounts({ active: active_count, inactive: inactive_count });
  };
  const countResources = () => {
    let active_count = 0;
    let onboarding_count = 0;
    let inactive_count = 0;
    let resource_array = [];
    let active_list = [];
    let inactive_list = [];
    let onboarding_list = [];
    function containsElement(array, id) {
      return array.some((element) => element.id === id);
    }

    for (let resource of projectData) {
      for (let detail of resource.details) {
        // if(moment.utc(detail.resource_project_enddate).isSameOrAfter(moment.utc()) || detail.resource_project_enddate === null){
        //     active_count += 1
        // }
        if (
          moment.utc(detail.resource_project_startdate).isAfter(moment.utc())
        ) {
          onboarding_count += 1;
          onboarding_list.push({
            resource_name: detail.resource_name,
            resource_email: detail.primary_email,
            project_name: resource.project_name,
          });
          resource_array.push({ id: detail.resource_id, status: "onboarding" });
        } else if (
          moment
            .utc(detail.resource_project_enddate)
            .isSameOrAfter(moment.utc()) ||
          detail.resource_project_enddate === null
        ) {
          active_count += 1;
          active_list.push({
            resource_name: detail.resource_name,
            resource_email: detail.primary_email,
            project_name: resource.project_name,
          });
          resource_array.push({ id: detail.resource_id, status: "active" });
        } else {
          inactive_count += 1;
          inactive_list.push({
            resource_name: detail.resource_name,
            resource_email: detail.primary_email,
            project_id: detail.project_id,
          });
          resource_array.push({ id: detail.resource_id, status: "inactive" });
        }
      }
    }
    setResourceCount({ active: active_count, onboarding: onboarding_count });
    setActiveResList(active_list);
    setOnboardingResList(onboarding_list);
    setInactiveCount(inactive_count);
    setInactiveResList(inactive_list);
  };
  const handleOpen = () => {
    setActiveOpen(true);
  };
  const handleClose = (event, reason) => {
    setActiveOpen(false);
  };
  const handleOnboardOpen = () => {
    setOnboardOpen(true);
  };
  const handleOnboardClose = () => {
    setOnboardOpen(false);
  };

  return (
    <Grid container sx={{ padding: "1%" }}>
      <Modal
        sx={{ zIndex: "1100" }}
        open={activeOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              fullWidth
              sx={{ display: "flex" }}
              variant="contained"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            sx={{ marginBottom: 2 }}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Active Resources
            </Typography>
          </Box>
          <Grid container>
            {activeResList.map((resource) => {
              return (
                <>
                  <Grid item xs={6}>
                    <Typography>
                      {resource.resource_name} - {resource.resource_email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{resource.project_name}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: "5px", marginTop: "5px" }}
                  >
                    <Divider></Divider>{" "}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Modal>

      <Modal
        sx={{ zIndex: "1100" }}
        open={onboardOpen}
        onClose={handleOnboardClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              fullWidth
              sx={{ display: "flex" }}
              variant="contained"
              onClick={handleOnboardClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            sx={{ marginBottom: 2 }}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Resources Waiting to Onboard
            </Typography>
          </Box>
          <Grid container>
            {onboardingResList.map((resource) => {
              return (
                <>
                  <Grid item xs={6}>
                    <Typography>
                      {resource.resource_name} - {resource.resource_email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{resource.project_name}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: "5px", marginTop: "5px" }}
                  >
                    <Divider></Divider>{" "}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Modal>
      <ErrorPopUp
        open={error}
        onClose={(event) => {
          event.preventDefault();
          setError(false);
        }}
      />
      <LoadingPopUp open={!hasLoaded} />
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Projects
        </Typography>
      </Grid>
      <Grid item container xs={4} sx={{ mt: "2%" }}>
        <TextField
          label="Search"
          value={filter || ""}
          onChange={(e) => {
            e.preventDefault();
            setFilter(e.target.value);
          }}
        />
      </Grid>
      <Grid
        item
        container
        xs={4}
        sx={{ mt: "2%", display: "flex", justifyContent: "center" }}
      >
        <Grid
          sx={{ mt: "2%", display: "flex", justifyContent: "center" }}
          item
          xs={12}
        >
          <Typography
            onClick={handleOpen}
            sx={{ "&:hover": { color: "blue" } }}
          >
            Active Resources: {resourceCount.active}
          </Typography>
        </Grid>
        <Grid
          sx={{ mb: "2%", display: "flex", justifyContent: "center" }}
          item
          xs={12}
        >
          <Typography
            onClick={handleOnboardOpen}
            sx={{ "&:hover": { color: "blue" } }}
          >
            Resources Waiting to Onboard: {resourceCount.onboarding}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={4}
        sx={{ justifyContent: "flex-end", alignItems: "center" }}
      >
        <Button
          variant="outlined"
          onClick={(event) => {
            event.preventDefault();
            navigate("./manage-project/create", { state: {} });
          }}
        >
          Create Project
        </Button>
      </Grid>
      <GridBreak />
      <Grid
        item
        xs={12}
        container
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <ToggleButtonGroup
          value={activity}
          exclusive
          onChange={(event, activity) => {
            event.preventDefault();
            setActivity(activity);
          }}
        >
          <ToggleButton value="active">
            {`Active (${projectCount.active})`}
          </ToggleButton>
          <ToggleButton value="inactive">
            {`Inactive (${projectCount.inactive})`}
          </ToggleButton>
          <ToggleButton value="both">
            {`Both (${projectCount.active + projectCount.inactive})`}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item container xs={12} sx={{ mt: "0.25%" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <SubmittedCell>Client Name</SubmittedCell>
                <SubmittedCell>Project Name</SubmittedCell>
                <SubmittedCell>Start Date</SubmittedCell>
                <SubmittedCell>End Date</SubmittedCell>
                <SubmittedCell>Legal Entity</SubmittedCell>
                <SubmittedCell>Billing Entity</SubmittedCell>
                <SubmittedCell>Paying Entity</SubmittedCell>
                <SubmittedCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProjects.length > 0 ? (
                <>
                  {filteredProjects
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((project) => (
                      <AlternateRow
                        overrideColor={
                          moment
                            .utc()
                            .add(60, "days")
                            .isSameOrAfter(moment.utc(project.end_date)) &&
                          moment.utc().isSameOrBefore(project.end_date)
                            ? "orange"
                            : undefined
                        }
                      >
                        <SubmittedCell>
                          {project.client.short_name}
                        </SubmittedCell>
                        <SubmittedCell>{project.project_name}</SubmittedCell>
                        <SubmittedCell>
                          {moment
                            .utc(project.start_date)
                            .format("YYYY-MM-DD")
                            .toString()}
                        </SubmittedCell>
                        <SubmittedCell>
                          {project.end_date
                            ? moment
                                .utc(project.end_date)
                                .format("YYYY-MM-DD")
                                .toString()
                            : "N/A"}
                        </SubmittedCell>
                        <SubmittedCell>{project.legal_entity}</SubmittedCell>
                        <SubmittedCell>{project.billing_entity}</SubmittedCell>
                        <SubmittedCell>{project.paying_entity}</SubmittedCell>
                        <SubmittedCell>
                          <ClickBox
                            onClick={(event) => {
                              event.preventDefault();
                              navigate(`./manage-project/${project.id}`, {
                                state: {
                                  projectInfo: {
                                    id: project.id,
                                    client: project.client,
                                    project_name: project.project_name,
                                    start_date: project.start_date,
                                    end_date: project.end_date,
                                    timesheet_frequency:
                                      project.timesheet_frequency,
                                    timesheet_type: project.timesheet_type,
                                    legal_entity: project.legal_entity,
                                    paying_entity: project.paying_entity,
                                    billing_entity: project.billing_entity,
                                    manager_first_name:
                                      project.manager_first_name,
                                    manager_last_name:
                                      project.manager_last_name,
                                    manager_email: project.manager_email,
                                  },
                                  details: project.details,
                                },
                              });
                            }}
                          >
                            <EditIcon />
                          </ClickBox>
                        </SubmittedCell>
                      </AlternateRow>
                    ))}
                </>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredProjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, page) => setPage(page)}
            onRowsPerPageChange={(event) =>
              setRowsPerPage(parseInt(event.target.value))
            }
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default ProjectsPage;
