import React,{useEffect, useState} from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Clients.css";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export const TextEditor = ({client_id, editorState,setEditorState,isEditable, setIsEditable}) => {
    
  // const onSaveClick = (editorState) => {
  //   let contentState = editorState.getCurrentContent();
  //   let note = { content: convertToRaw(contentState) };
  //   // var finalData = str.replace(/\\/g, "");


  //   getAmplifyToken((jwt) => {
  //       fetch(`${process.env.REACT_APP_ADMIN_API}/admin/sections`, {
  //         method: "POST",
  //         body: JSON.stringify({
  //           client_id:this.props.client_id,
  //           editor_data:note["content"]
  //         }),
  //         headers: {
  //           "Content-type": "application/json; charset=UTF-8",
  //           Authorization: jwt,
  //         },
  //       }).then((response) => {
  //         if (response.ok) {
  //           console.log("success")

  //         } else {
  //           console.log("fail")
  //         }
  //       });
        
  //     });
  //   setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(JSON.stringify(note["content"])))))
  //   setIsEditable(false)

  // };
    // const onEditorStateChange = () =>{
    //     setEditorState(editorState)
    // }
  return (
    <>
    <Button onClick={()=>{setIsEditable(true)}}>Edit</Button>
      <Editor
        editorState={editorState}
        toolbarHidden ="true"
        readOnly="true"
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
      />
    </>
  )
}
