import { DatePicker } from '@mui/lab';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import FormattedTextField from './Formatted/FormattedTextField';
/**
 * Component used to select a range of dates
 * @component 
 * @category Functionality
 */
function ToFrom(props) {
    const [to,setTo] = useState(null)
    const [from, setFrom] = useState(null)

    const handleTo = (value) =>{
        setTo(value)
    }

    const handleFrom = (value) =>{
        setFrom(value)
    }

    useEffect(()=>{
        props.onUpdate(to,from)
    },[to,from])

    return (
        <>
            <Box 
            sx={{padding:'0.1%'}}
            >
                <DatePicker label={props.fromLabel} value={from} onChange={handleFrom} renderInput={(params) => <FormattedTextField {...params}/>}  
                maxDate={Boolean(to) ? to : undefined}
                />
            </Box>
            <Box 
            sx={{padding:'0.1%'}}
            >
                <DatePicker label={props.toLabel} value={to} onChange={handleTo} renderInput={(params) => <FormattedTextField {...params} sx={{padding:'0.1%'}}/>}  
                minDate={Boolean(from) ? from : undefined}/>
            </Box>
        </>
    );
}

ToFrom.propTypes={
    /**
     * label for starting date box
     */
    fromLabel: PropTypes.string,
    /**
     * label for ending date box
     */
    toLabel: PropTypes.string,
    /**
     * function called when values are changed, passes an array in the form of [to value, from value]
     */
    onUpdate: PropTypes.func.isRequired,
}

ToFrom.defaultProps={
    fromLabel: 'From',
    toLabel: 'To'
}
export default ToFrom;