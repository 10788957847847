import { TextField, Typography, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import "./Clients.css"
import { EditorManager } from './EditorManager'
import { getAmplifyToken } from "../../../util/AmplifyHelp";

export const ClientSections = ({client_id, editor_data, refreshDetails, setRefreshDetails}) => {
    const [title, setTitle] = useState("Enter Section Title");
    const [isNameFocused, setIsNamedFocused] = useState(false);
   
  return (
    <div className='container-cliensections'>
    <Paper sx={{margin:'10px', height:'100%'}} variant='elevation'>
    <div className='section-container'>
       
        <EditorManager client_id={client_id} editorData={editor_data} ></EditorManager>
    </div>
    </Paper>
    </div>
  )
}
