import React from "react";
import NavBar from "../components/NavBar/NavBar"
import Footer from '../components/Footer'
import {Box, Grid} from '@mui/material'
import PropTypes from 'prop-types'

/**
 * general template all pages fall into, contains footer and header
 * @component
 * @category Pages
 */
function Template(props) {
  return (
    <>
      <Box
        sx={{
          height: `100%`,
          minHeight: "93vh",
          bgcolor: "background.default",
        }}
      >
        <Grid item xs={12}>
          <NavBar role={props.role} profile={props.profile}/>
        </Grid>
        {props.children}
      </Box>
      <Box
        sx={{
          height: `7vh`,
          bgcolor: "background.default",
          alignItems: "flex-end",
        }}
      >
        <Footer />
      </Box>
    </>
  );
}

Template.propTypes = {
  role: PropTypes.string,
  profile: PropTypes.object.isRequired
}

Template.defaultProps={
  role: 'developer'
}

export default Template;
