import { getProfileFromEmail } from "./ProfileHelper";
import moment from "moment";
import { getAmplifyToken, getAmplifyTokenPromise } from "./AmplifyHelp";
import { ErrorObject } from "./generalHelp";

/**
 * Function that gets all timesheets for currently logged in profile
 * @param {AbortController} abortController abortController responsible for terminating the request
 * @param {Array.Number} project_ids an array of project_ids to include in the query
 * @param {Date} to a start date to include in the query
 * @param {Date} from a end date to include in the query
 * @param {responseHandler} callback callback function to return response's json obj to
 * @category Api Calls
 * @subcategory Timesheets
 * @module
 */
export function getProfileTimesheets(
  abortController = undefined,
  project_ids = undefined,
  to = undefined,
  from = undefined,
  callback
){
  const func = (profile) => {
    getAmplifyToken((jwt) => {
      let request = `${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/get/${profile.id}?status=notsaved&`;
      if (project_ids && project_ids.length > 0) {
        for (let id of project_ids) {
          request += `project_id=${id}&`;
        }
      }
      if (to) request += `to=${to}&`;
      if (from) request += `from=${from}`;

      fetch(request, { headers: { Authorization: jwt }, signal: abortController ? abortController.signal:undefined} )
        .then((response) => response.json())
        .then((data) => callback(data))
        .catch((err) => {
          if(err.name !== 'AbortError'){
            console.error(err);
            callback(undefined);
          }
        });
    });
  };

  getProfileFromEmail(func);
};

/**
 * function responsible for getting all pending timesheets
 * @param {Number} project_id id of project
 * @param {Number} profile_id id of profile for which pending timesheets are being retrieved
 * @param {Date} period_date date lying within period for timesheet
 * @param {Function} callback callback function for returning value's json obj
 * @param {AbortController} abortController abortController responsible for terminating the request
 * @category Api Calls
 * @subcategory Timesheets
 */
export function getPendingTimeSheets(
  project_id,
  profile_id,
  period_date,
  callback,
  abortController = undefined
){
  //console.log('period_date',period_date)
  getAmplifyToken((jwt) => {
    let request = `${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/gethours?project_id=${project_id}&resource_id=${profile_id}&period_start=${period_date}`;
    //console.log('request is',request)
    fetch(request, { headers: { Authorization: jwt }, signal: abortController ? abortController.signal : undefined})
      .then((response) => response.json())
      .then((data) => callback(data))
      .catch((err) => {
        if(err.name !== 'AbortError'){
          console.error(err);
          callback(undefined);
        }
      });
  });
};

export function getTimesheetWeek(
  project_id,
  profile_id,
  period_date,
  callback,
  abortController = undefined
){
  //console.log('period_date',period_date)
  getAmplifyToken((jwt) => {
    let request = `${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/weekhours?project_id=${project_id}&resource_id=${profile_id}&ref_date=${period_date}`;
    //console.log('request is',request)
    fetch(request, { headers: { Authorization: jwt }, signal: abortController ? abortController.signal : undefined})
      .then((response) => response.json())
      .then((data) => callback(data))
      .catch((err) => {
        if(err.name !== 'AbortError'){
          console.error(err);
          callback(undefined);
        }
      });
  });
};

/**
 * gets an object for given timesheet where keys are dates and values are hours worked from api then passes it to callback
 * @param {Number} ts_id id of timesheet that's being looked for
 * @param {responseHandler} callback function to handle the response's json obj
 * @category Api Calls
 * @subcategory Timesheets
 */
export function getHoursByTsId(ts_id, callback){
  getAmplifyToken(jwt => {
    let request = `${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/gethours?timesheet_id=${ts_id}`
    //console.log('request is', request)
    fetch(request, {headers: {Authorization: jwt}})
    .then(response => response.json())
    .then(data => callback(data))
    .catch(err => {console.error(err); callback(undefined)})
  })
}

export function getStrictTsHours(ts_id, callback){
  getAmplifyToken(jwt => {
    let request = `${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/full_hours?timesheet_id=${ts_id}`
    //console.log('request is', request)
    fetch(request, {headers: {Authorization: jwt}})
    .then(response => response.json())
    .then(data => callback(data))
    .catch(err => {console.error(err); callback(undefined)})
  })
}

export function getHoursByTsIdNoPadding(ts_id, callback){
  getAmplifyToken(jwt => {
    let request = `${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/gethours?timesheet_id=${ts_id}&no_padding=true`
    //console.log('request is', request)
    fetch(request, {headers: {Authorization: jwt}})
    .then(response => response.json())
    .then(data => callback(data))
    .catch(err => {callback(undefined)})
  })
}

/**
 * function responsible for saving a timesheet to db
 * @param {Number} project_id id of project timesheet falls under
 * @param {Number} profile_id id of resource timesheet falls under
 * @param {Date} period_date day within period that the timesheet is for
 * @param {Object} timesheets object where dates are keys and values are hours worked on said day
 * @param {responseHandler} callback callback function for return values's json obj
 * @category Api Calls
 * @subcategory Timesheets
 */
export function saveTimeSheet(
  project_id,
  profile_id,
  period_date,
  timesheets,
  note_text = undefined,
  callback
){
  // period_date = moment.utc(period_date).format("YYYY-MM-DD").toString();
  // getAmplifyToken((jwt) => console.log('token',jwt))
  // console.log('body',JSON.stringify({
  //   project_id: project_id,
  //   resource_id: profile_id,
  //   start_date: period_date,
  //   timesheets: timesheets,
  // }))
  // callback("test")
  // return
  //console.log('timesheets',timesheets)
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/save`, {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: jwt },
      body: JSON.stringify({
        project_id: project_id,
        resource_id: profile_id,
        start_date: moment.utc(period_date).format('YYYY-MM-DD').toString(),
        note_text : note_text,
        timesheets: timesheets,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => callback(data))
      .catch((err) => {
        console.error(err);
        callback(undefined);
      });
  });
};

/**
 * function used to convert a timesheet from being saved to being submitted sends response to callback
 * @param {Number} timesheet_id timesheet being marked as submitted 
 * @param {Number} admin_id (optional)  
 * @param {responseHandler} callback function that recieves response status from api
 * @category Api Calls
 * @subcategory Timesheets
 */
export function saveToSubmit(timesheet_id, admin_id=undefined, callback) {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/savetosubmit/${timesheet_id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: jwt },
      body:{admin_id:admin_id}
    })
      .then((resp) => callback(resp.status))
      .catch((err) => {
        console.error(err);
        callback(undefined);
      });
  });
};
/**
 * function used to submit timesheet to api
 * @param {Number} project_id id of project that timesheet is being submitted under 
 * @param {Number} profile_id id of resource that timesheet is being submitted under
 * @param {Date} period_date date in period that timesheet is being submitted for
 * @param {Object} timesheets object where key is dates and value is hours worked for said date, to be used for filling out timesheet
 * @param {Number} admin_id id of admin that is submitting timesheet (optional)
 * @param {responseHandler} callback function where status of response is passed back to
 * @category Api Calls
 * @subcategory Timesheets
 */
export function submitTimeSheet(
  project_id,
  profile_id,
  period_date,
  timesheets,
  admin_id=undefined,
  note_text=undefined,
  callback
){
  period_date = moment.utc(period_date).format("YYYY-MM-DD").toString();
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/submit`, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: jwt },
      body: JSON.stringify({
        project_id: project_id,
        resource_id: profile_id,
        start_date: moment.utc(period_date).format('YYYY-MM-DD').toString(),
        timesheets: timesheets,
        admin_id: admin_id,
        note_text:note_text
      }),
    })
      .then((resp) => callback(resp.status))
      .catch((err) => {
        console.error(err);
        callback(undefined);
      });
  });
};

/**
 * gets all timesheets in db that are not saved and returns them to callback
 * @param {AbortController} abortController AbortController responsible for terminating the request 
 * @param {responseHandler} callback function responsible for handling the json obj containing all timesheets
 * @category Api Calls
 * @subcategory Timesheets
 */
export async function getAllTimesheets(abortController=undefined, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/admin/get?status=notsaved`, {headers:{Authorization:jwt}, signal: abortController ? abortController.signal : undefined})
    .then(resp => resp.json()).then(data=>callback(data))
    .catch(err => handleError(err,callback))
  })
  
}

const handleError=(err, callback)=>{
  if(err.name !== 'AbortError'){
    console.error(err)
    callback(undefined)
  }
}

/**
 * goes through keys in a object where dates are keys and finds start and end dates
 * @param {Object} dateObject object where keys are dates
 * @returns an object following with keys start (representing the start date) and end (representing the end date)
 */
export function getStartAndEndOfObject(dateObject){
    let start = undefined
    let end = undefined

    for(let date of Object.keys(dateObject)){
      if(!start || moment.utc(date).isSameOrBefore(moment.utc(start))) start = moment.utc(date)
      if(!end || moment.utc(date).isSameOrAfter(moment.utc(end))) end = moment.utc(date) 
    }

    return {start,end}
}

/**
 * adds padding to end and start of given date object so that it contains only full weeks
 * @param {Object} dateObject object where keys are dates and values are hours worked for said dates 
 * @returns object with all the filler needed for dateObject
 */
export function getObjectFiller (dateObject) {
  //starting on saturday, sunday
  let start_end = getStartAndEndOfObject(dateObject)
  //moment.date() returns a number from 0-6 with 0 corresponding to sunday and 6 corresponding to saturday
  const start_diff = Math.abs(start_end.start.diff(moment.utc(start_end.start).startOf('week'), "days"))
  const end_diff = Math.abs(start_end.end.diff(moment.utc(start_end.end).endOf('week'), "days"))

  //console.log('start is',start_end.start)

  //console.log('start_diff',start_diff)
  //console.log('end_dif',end_diff)

  let filler = {}
  for(let i = 1; i <= start_diff ; i ++){
    filler[moment.utc(start_end.start).subtract(i,'days').format('YYYY-MM-DD').toString()] = "filler"
  }
  for(let i = 1; i <= end_diff; i ++){
    filler[moment.utc(start_end.end).add(i,'days').format('YYYY-MM-DD').toString()] = "filler"
  }
  //add a number of filler equal to the difference between the sunday and start
  return filler


}

/**
 * function used to determine if all weekends in timesheetObj have no hours
 * @param {Object} timesheetObj object where keys are dates and values are the hours worked on said dates 
 * @returns false if a weekend in timesheetObj has hours worked otherwise true
 */
export function areWeekendsEmpty(timesheetObj){
  for(let date of Object.keys(timesheetObj)){
    if((moment.utc(date).day() == 0 || moment.utc(date).day() == 6) && timesheetObj[date] > 0) return false
  }
  return true
}
/**
 * gets a summary for all timesheets that fall under the given resource_id and project_id then passes it back to callbck
 * @param {Number} resource_id id of resource that timesheet is under
 * @param {Number} project_id id of project that timesheet is under
 * @param {AbortController} abortController AbortController that is responsible for terminating the request
 * @param {responseHandler} callback callback that handles the json obj returned from the backend
 * @category Api Calls
 * @subcategory Timesheets
 */
export function getTimesheetSummary(resource_id, project_id, abortController, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/summary?resource=${resource_id}&project=${project_id}`, 
    {headers:{Authorization:jwt},
    signal: abortController ? abortController.signal : undefined})
    .then(resp => resp.json()).then(data=>callback(data))
    .catch(err => handleError(err,callback))
  })
}

/**
 * gets summary of all timesheets from api and pases back the json obj contatining them to callback
 * @param {responseHandler} callback function that handles the json obj returned from backend
 * @category Api Calls
 * @subcategory Timesheets
 */
export async function getTimesheetSummary2(callback) {
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/full_summary`, {headers:{Authorization:jwt}})
    .then((resp) => resp.json())
    .then((data) => callback(data))
    .catch((error) => console.error(error));
  })  
}

export function invalidateTimesheet(timesheet_id,callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/invalidate/${timesheet_id}`, 
    {method:'POST', headers:{Authorization:jwt}})
    .then(resp => callback(resp))
    .catch((error)=>console.error(error))
  })
}

export function invalidateTimesheetWeekly(jsonObj, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/invalidate_weekly`, 
    {headers:{Authorization:jwt, 'Content-Type': 'application/json'},
    method:"POST",
    body: JSON.stringify(jsonObj)
    }).then(async resp => resp.ok ? resp : new ErrorObject(resp.status, await resp.text()))
    .then(data => callback(data))
    .catch(err => {console.error(err); callback(new ErrorObject(500, 'an internal error has occured'))})
  })
}

export function getTimesheetNotesById(timesheet_id, abortController, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/notes?timesheet_id=${timesheet_id}`,
    {method:'GET', headers:{Authorization:jwt}, signal: abortController ? abortController.signal : undefined})
    .then(resp => resp.json()).then(resp => callback(resp)).catch((error)=>console.error(error))
  })
}

export function getTimesheetNotes(resource_id, project_id, ref_date, abortController, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/notes?resource_id=${resource_id}&project_id=${project_id}&ref_date=${ref_date}`,
    {method:'GET', headers:{Authorization:jwt}, signal: abortController ? abortController.signal : undefined})
    .then(resp => resp.json()).then(resp => callback(resp)).catch((error)=>handleError(error,callback))
  })
}

export function getDocusignReady(resource_id, project_id, ref_date, callback){
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/is_ds_ready?resource_id=${resource_id}&project_id=${project_id}&ref_date=${ref_date}`,
    {
      headers: {Authorization: jwt}
    }).then(resp => resp.ok ? resp.json() : new ErrorObject(resp.status, resp.text)).then(data => callback(data)).catch(err => callback(new ErrorObject(500, err)))  
  })  
}

export function weeklySubmitTimesheet(resource_id, project_id, ref_date, timesheets, admin_id=undefined, notes=undefined, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/weeksubmit`,{
      method: 'PUT',
      headers : {Authorization: jwt, "Content-Type": "application/json" },
      body: JSON.stringify({
        resource_id: resource_id,
        project_id: project_id,
        timesheets: timesheets,
        start_date: ref_date,
        note_text: notes,
        admin_id: admin_id
      })
    }).then(async resp => resp.ok ? resp : new ErrorObject(resp.status, await resp.text())).then(data => callback(data))
      .catch(err => new ErrorObject(500, err))
  })
}

export function weeklySaveTimesheet(resource_id, project_id, ref_date, timesheets, notes=undefined, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/weeksave`,{
      method: 'PUT',
      headers : {Authorization: jwt, "Content-Type": "application/json" },
      body: JSON.stringify({
        resource_id: resource_id,
        project_id: project_id,
        timesheets: timesheets,
        start_date: ref_date,
        note_text: notes,
      })
    }).then(resp => resp.ok ? resp : new ErrorObject(resp.status, resp.text)).then(data => callback(data))
      .catch(err => new ErrorObject(500, err))
  })
}

export async function weeklySaveTimesheetPromise(resource_id, project_id, ref_date, timesheets, notes=undefined){
  let jwt = await getAmplifyTokenPromise()
  try{
    let resp = await fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/weeksave`,{
      method: 'PUT',
      headers : {Authorization: jwt, "Content-Type": "application/json" },
      body: JSON.stringify({
        resource_id: resource_id,
        project_id: project_id,
        timesheets: timesheets,
        start_date: ref_date,
        note_text: notes,
      })
    })
    if(resp.ok){
      return resp
    }
    return new ErrorObject(resp.status, await resp.text())
  }catch(err){
    return new ErrorObject(500, err)
  }
  
    // .then(async resp => resp.ok ? resp : new ErrorObject(resp.status, await resp.text()))
    //   .catch(err => new ErrorObject(500, err))
}

export function fullSaveToSubmit(resource_id, project_id, ref_date, admin_id, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/full_save_to_submit`,{
      method: 'PUT',
      headers : {Authorization: jwt, "Content-Type": "application/json" },
      body: JSON.stringify({
        resource_id: resource_id,
        project_id: project_id,
        ref_date: ref_date,
        admin_id: admin_id,
      })
    }).then(resp => resp.ok ? resp : new ErrorObject(resp.status, resp.text)).then(data => callback(data))
      .catch(err => new ErrorObject(500, err))
  })
}

export function getAggregatedTimesheets(callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/aggregate`,{
      method:'GET',
      headers: {Authorization: jwt}
    }).then( async resp => resp.ok ? resp.json() : new ErrorObject(resp.status, await resp.text())).then(data => callback(data))
    .catch(err => new ErrorObject(500, err))
  })
}

export function getResourceTimesheets(resource_id, abortCont, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/timesheet/resource_getall?resource_id=${resource_id}`,
    {method:'GET',
    headers : {Authorization: jwt},
    signal: abortCont ? abortCont.signal : undefined})
    .then(async resp => resp.ok ? resp.json() : new ErrorObject(resp.status, await resp.text())).then(info => callback(info))
    .catch(err => 
      {
        if(err.name != "AbortError"){
          console.error(err); 
          callback(new ErrorObject(500, 'an internal error has occured'))    
        }
      })
  })
}