import { Grid, Box, TextField, Typography, Autocomplete, MenuItem, Button } from "@mui/material";
import React, {useEffect, useState} from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import GridBreak from "../../components/GridBreak";
import LoadingPopUp from "../../components/Pop_ups/LoadingPopUp";
import { getAuxiliaryInfo, getIndividialProjectinfo, putProject } from "../../util/admin-api-requests/ProjectHelper";
import { getParent, isObjectEmpty } from "../../util/generalHelp";
import {fonts} from "../../util/Theme"
import validator  from "validator";
import { DatePicker } from "@mui/lab";
import moment from "moment";
import HoverButton from "../../components/HoverButton";
import ErrorPopUp from "../../components/Pop_ups/ErrorPopUp";
import TextPopUp from "../../components/Pop_ups/TextPopUp";
import ResourceDetailsBar from "../../components/admin/ResourceDetailsBar";
import ManageResourceModal from "../../components/admin/ManageResourceModal";
/**
 * page used to create / edit projects
 * @component 
 * @category Pages
 * @subcategory Admin 
 */
function ManageProject(props){
    const loc = useLocation();
    const navigate = useNavigate()
    const {id} = useParams()

    const [projectDetails, setProjectDetails] = useState([])
    const [toDelete, setToDelete] = useState([])
    const [auxInfo, setAuxInfo] = useState({})
    const [loading, setLoading] = useState(false)
    const [hasLoaded, setHasLoaded] = useState(false)
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")
    const [putObj, setPutObj] = useState({end_date:'2099-12-31', standard_vacation:false, docu_extended_hours:false, myta_project:false,
                                        notes_required:false})
    const [openResource, setOpenResource] = useState(false)
    const [saved, setSaved] = useState()
    const [hasLoadedDetails, setHasLoadedDetails] = useState(false)

    useEffect(()=>{
        let abortCont = new AbortController()
        if(!hasLoadedDetails){
            //console.log('search param is', id)

            if(id && !isNaN(id)){
                //console.log('time to fetch')
                getIndividialProjectinfo(id, (data)=>{
                    
                    if(data){
                        if(data.project_info){
                            let temp = {...data.project_info}
                            setPutObj(temp)
                        }
                        if(data.details){
                            setProjectDetails(data.details)
                            setSaved(Boolean(data.details) && !isObjectEmpty(data.details))
                        } 
                    }else{
                        setError('something went wrong on our end, please try relaoding the page')
                    }
                    setHasLoadedDetails(true)
                }, abortCont)

            }else{
                setHasLoadedDetails(true)
            }
        }
        return () => abortCont.abort()
    },[hasLoadedDetails])

    useEffect(()=>{
        if(hasLoaded){
            let newDets = [...projectDetails]
            if(!putObj || !putObj.end_date){
                return;
            }

            for(let det of newDets){
                if(det.resource_project_enddate && moment.utc(det.resource_project_enddate).isAfter(moment.utc(putObj.end_date))){
                    det.resource_project_enddate = putObj.end_date
                }
            }
            setProjectDetails(newDets)
        }
        
    },[putObj])


    useEffect(()=>{
        let abortCont = new AbortController();
            //console.log('state is', loc.state)
        getAuxiliaryInfo(abortCont, putObj.id ? putObj.id : undefined, (data) =>{
            if(data == undefined){
                setError("Something went wrong on our end, please try reloading the page")
                //window.location.reload()
            }else{
                setAuxInfo(data)
                setHasLoaded(true)
            }
            
        })

        return () => abortCont.abort()
    },[hasLoadedDetails])

    const updatePutObj = (key, value) => {
        let newObj = {...putObj}
        
        newObj[key] = value
        setPutObj(newObj)
    }

    const handleSave = () =>{
        let errorFields = []
        let date_conflicts = false
        try{
            setLoading(true)
            // console.log('saving')
           
            let projectObj = {...putObj}
            
        
            if(!putObj.project_name) errorFields.push('Project Name')
            else projectObj.project_name = putObj.project_name

            if(!putObj.legal_entity) errorFields.push('Legal Entity')
            else projectObj.legal_entity = putObj.legal_entity

            if(!putObj.billing_entity) errorFields.push('Billing Entity')
            else projectObj.billing_entity = putObj.billing_entity

            if(!putObj.paying_entity) errorFields.push('Paying Entity')
            else projectObj.paying_entity = putObj.paying_entity
            
            if(!putObj.timesheet_frequency) errorFields.push('Timesheet Frequency')
            else projectObj.timesheet_frequency = putObj.timesheet_frequency.category_id

            if(!putObj.timesheet_type) errorFields.push('Timesheet Type')
            else projectObj.timesheet_type = putObj.timesheet_type.category_id

            if(putObj.standard_vacation == null) errorFields.push('Standard Vacation')
            else projectObj.standard_vacation = putObj.standard_vacation

            if(putObj.docu_extended_hours == null) errorFields.push('Docusign Extended Hours')
            else projectObj.docu_extended_hours= putObj.docu_extended_hours

            if(!putObj.myta_project == null) errorFields.push('Myta Project')
            else projectObj.myta_project = putObj.myta_project

            projectObj.notes_required = putObj.notes_required

            if(!putObj.client) errorFields.push('Client')
            else projectObj.client_id = putObj.client.id

            if(!putObj.start_date) errorFields.push('Start Date')
            else projectObj.start_date = moment.utc(putObj.start_date).format('YYYY-MM-DD').toString()

            if(putObj.end_date) projectObj.end_date = moment.utc(putObj.end_date).format('YYYY-MM-DD').toString()
            else projectObj.end_date = '2099-12-31'

            if(moment.utc(projectObj.start_date).isAfter(moment.utc(projectObj.end_date))){
                //console.log('project')
                date_conflicts = 'project'
            }
            
           // console.log('details test',projectDetails)
            projectObj.num_resources = projectDetails.length

            if(!putObj.manager_first_name) errorFields.push('Manager First Name')
            if(!putObj.manager_last_name) errorFields.push('Manager Last Name')
            if(!putObj.manager_email || !validator.isEmail(putObj.manager_email)) errorFields.push('Manager Email')
            const managerObj = {first_name: putObj.manager_first_name, last_name: putObj.manager_last_name, email: putObj.manager_email}
            
            let detailsArr = []

            for(let det of projectDetails){
                // console.log('det',det)
                // console.log('start,end',projectObj.start_date, projectObj.end_date)
                if(!det.resource_type){
                    // console.log('det',det)
                    errorFields.push('Resource Type')
                    break;
                }
                if(!det.billing_entity){
                    errorFields.push('Billing Entity')
                    break;
                }

                if(!det.paying_entity){
                    errorFields.push('Paying Entity')
                    break;
                }

                if(!det.pay_currency){
                    errorFields.push('Pay Currency')
                    break;
                }

                if(!det.bill_currency){
                    errorFields.push('Bill Currency')
                    break;
                }
                if(!det.pay_type){
                    errorFields.push('Pay Type')
                    break;
                }


                if(!det.resource_project_startdate) det.resource_project_startdate = moment.utc(projectObj.start_date).format('YYYY-MM-DD').toString()
                if(!det.resource_project_enddate) det.resource_project_enddate = projectObj.end_date ? projectObj.end_date : '2099-12-31'
                
                if(det.resource_project_startdate && !(moment.utc(det.resource_project_startdate).endOf('day').isSameOrAfter(moment.utc(projectObj.start_date)) && (
                    projectObj.end_date === undefined || moment.utc(det.resource_project_startdate).startOf('day').isSameOrBefore(moment.utc(projectObj.end_date))))){
                    date_conflicts = 'resource'
                    break;
                }else if(det.resource_project_enddate && !(moment.utc(det.resource_project_enddate).startOf('day').isSameOrBefore(moment.utc(projectObj.end_date)) && 
                    moment.utc(det.resource_project_enddate).endOf('day').isSameOrAfter(moment.utc(projectObj.start_date)))){
                    date_conflicts = 'resource'
                    break;
                }
                detailsArr.push({
                    ...det,
                    id:det.details_id,
                    resource_id: det.resource_id,
                    start_date: det.resource_project_startdate ? moment.utc(det.resource_project_startdate).format('YYYY-MM-DD').toString() : null,
                    end_date: det.resource_project_enddate ? moment.utc(det.resource_project_enddate).format('YYYY-MM-DD').toString() : '2099-12-31',
                    // resource_type: det.resource_type,
                    // subscribed: det.subscribed,
                    // recruiter : det.recruiter,
                    // sourcer: det.sourcer,
                    // sales : det.sales,
                    // account_manager : det.account_manager,
                    // paying_entity: det.paying_entity,
                    // billing_entity: det.billing_entity
                })
            }
            if(errorFields.length > 0){
                let err = new Error("missing fields")
                err.name = "FieldError"
                throw err
            }

            if(date_conflicts){
                let err = new Error('date conflict')
                err.name = "DateConflict"
                throw err
            }
            
            const requestObj = {
                project: projectObj,
                manager_info: managerObj,
                project_details:detailsArr,
                to_delete:toDelete
            }
            putProject(requestObj, (json)=>{
                if(json){
                    setSuccess(saved ? "Project successfully edited" : "Project successfully added")

                    if(!saved){
                        navigate(`${getParent(loc.pathname)}/${json.id}`)
                    }

                    updatePutObj('id',json.id)
                    setSaved(true)
                    
                    
                }else{
                    setError("Something went wrong please try again")
                }
                setLoading(false)
            })
        }catch(e){
            if(e.name === "FieldError"){
                console.error(e)
                setError(`Please make sure to fill out the following fields, ${errorFields}`)
                setLoading(false)
            }
            else if(e.name === "DateConflict"){
                console.error(e)
                setError(date_conflicts === 'resource' ? 
                "Please ensure resource start and end dates are within project start and end dates"
                : "Please ensure project start date preceeds project end date")
                setLoading(false)
            }else{
                console.error(e)
                setError("Please Make Sure to Fill out all required Form Fields")
                setLoading(false)
            } 
        }
        
    }   

    const handleDetails = (details) => {
        let newDetails = [...projectDetails]
        const detailsIndex = newDetails.findIndex(det => det.id === details.id)
        if(detailsIndex === -1){
            newDetails.push(details)
        }else{
            newDetails[detailsIndex] = details
        }
        setProjectDetails(newDetails)
    }

    const onDetailsSave = (details, toDelete) => {
        // console.log('toDelete saved',toDelete)

        for(let det of details){
            if(!det.resource_type){
                det.resource_type = 8
            }
        }

        setProjectDetails(details)
        setToDelete(toDelete)
    }
    return (
        <Grid container>
            <ErrorPopUp open={Boolean(error)} onClose={(event)=>{event.preventDefault(); setError("")}} errorContent={error}/>
            <ManageResourceModal open={openResource} onClose={() => setOpenResource(false)} details={auxInfo.remaining_resources}
            addedDetails={projectDetails} onSave={onDetailsSave} toDelete={toDelete}/>
            <TextPopUp title="Success!" content={success} 
            onClose={(event)=> {event.preventDefault(); setSuccess("")}} open={Boolean(success)}/>
            <LoadingPopUp open={loading || !hasLoaded || !hasLoadedDetails}/>
            <Box sx={{boxShadow:4, width:"100%", padding:"1%"}}>
            
            <Grid container sx={{mb:'0.5%'}}>
                <Grid item xs={4}>
                <BackButton color="black">
                    Go Back
                 </BackButton>
                 </Grid>
                <Grid item xs={4}>
                <Typography  align="center" variant="h4">{saved ? "Edit Project" : "Create Project"}</Typography>
                </Grid>
                
                <Grid item xs={4}>
                <Box display='flex' justifyContent='flex-end' marginRight={7} >
                <Button sx={{ width: "10%", backgroundColor:'#ff6600', color: 'white', borderColor:'#ff6600', '&:hover': {
                  backgroundColor: '#fff',
                  color: '#ff6600'}}} variant='contained' onClick={(event)=>{event.preventDefault(); handleSave();}}>
                        Save
                    </Button>
                </Box>
                </Grid>
                
            </Grid>
            {!isObjectEmpty(auxInfo) ? <Grid container item xs={12} sx={{mt:"1%"}}>
                    <Grid container rowSpacing={2} sx={{width:"100%", justifyContent:'center', alignItems:'center'}}>
                        <Grid item xs={11/4} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <Autocomplete 
                            disablePortal
                            fullWidth
                            options={auxInfo.clients}
                            value={putObj.client || null}
                            onChange={(e,value) => updatePutObj("client",  value)}
                            isOptionEqualToValue={(option1, option2) => option1.id === option2.id}
                            getOptionLabel={(option)=>`${option.short_name}`}
                            renderInput={(params) => <TextField {...params} 
                                InputLabelProps={{  }} fullWidth required label="Client" />}
                            />
                        </Grid>
                        <Grid item xs={11/4} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <TextField label="Project Name"
                            fullWidth 
                            required
                            value={putObj.project_name ? putObj.project_name : ""}
                            onChange={(e) => updatePutObj("project_name",e.target.value)}/>
                        </Grid>
                        <Grid item xs={11/4} container sx={{ml:'0.1%', mr:'0.1%'}} >
                            <DatePicker 
                            label="Start Date"
                            fullWidth
                            value={putObj.start_date ? moment.utc(putObj.start_date) : null}
                            maxDate={putObj.end_date ? moment.utc(putObj.end_date) : undefined}
                            onChange={(value)=>updatePutObj('start_date',value)}
                            renderInput={(params)=><TextField fullWidth required {...params}/>}/>
                        </Grid>
                        <Grid item xs={11/4} container sx={{ml:'0.1%', mr:'0.1%'}}>
                        <DatePicker 
                            fullWidth
                            label="End Date"
                            value={putObj.end_date ? moment.utc(putObj.end_date) : moment.utc('2099-12-31')}
                            onChange={(value)=>updatePutObj('end_date',value)}
                            minDate={putObj.start_date ? moment.utc(putObj.start_date) : undefined}
                            renderInput={(params)=><TextField fullWidth {...params}/>}/>
                        </Grid>
                        <GridBreak/>
                        <Grid item xs={11/6} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <Autocomplete 
                                disablePortal
                                options={auxInfo.timesheet_frequencies}
                                fullWidth
                                value={putObj.timesheet_frequency || null}
                                onChange={(e,value) => updatePutObj("timesheet_frequency", value)}
                                getOptionLabel={(option)=>`${option.category_name}`}
                                isOptionEqualToValue={(option1, option2) => option1.category_id === option2.category_id}
                                renderInput={(params) => <TextField {...params} 
                                    InputLabelProps={{ }} fullWidth required label="Timesheet Frequency" />}
                                />
                        </Grid>
                        <Grid item xs={11/6} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <Autocomplete 
                                disablePortal
                                options={auxInfo.timesheet_types}
                                fullWidth
                                value={putObj.timesheet_type || null}
                                onChange={(e,value) => {updatePutObj("timesheet_type", value)}}
                                getOptionLabel={(option)=>`${option.category_name}`}
                                isOptionEqualToValue={(option1, option2) => option1.category_id === option2.category_id}
                                renderInput={(params) => <TextField {...params} 
                                    InputLabelProps={{ }} required fullWidth label="Timesheet Type" />}
                                />
                        </Grid>
                        <Grid item xs={11/6} container sx={{ml:'0.1%', mr:'0.1%'}}>
                        <TextField label="Vacation/Sick/Holiday ON"
                            select
                            required
                            fullWidth
                            value={putObj.standard_vacation ? true: false}
                            onChange={(e) => updatePutObj('standard_vacation',e.target.value)}>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11/6} container sx={{ml:'0.1%', mr:'0.1%'}}>
                        <TextField label="Docusign Vacation/Sick/Holiday ON"
                            select
                            required
                            fullWidth
                            value={putObj.docu_extended_hours ? true: false}
                            onChange={(e) => updatePutObj('docu_extended_hours',e.target.value)}>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11/6} container sx={{ml:'0.1%', mr:'0.1%'}}>
                        <TextField label="Myta Project"
                            select
                            required
                            fullWidth
                            value={putObj.myta_project ? true: false}
                            onChange={(e) => updatePutObj('myta_project',e.target.value)}>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11/6} container sx={{ml:'0.1%', mr:'0.1%'}}>
                        <TextField label="Notes Required"
                            select
                            required
                            fullWidth
                            value={putObj.notes_required ? true: false}
                            onChange={(e) => updatePutObj('notes_required',e.target.value)}>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </TextField>
                        </Grid>
                        <GridBreak/>
                        <Grid item xs={11/3} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <TextField label="Legal Entity"
                            select
                            required
                            fullWidth
                            value={putObj.legal_entity ? putObj.legal_entity : ""}
                            onChange={(e) => updatePutObj('legal_entity',e.target.value)}>
                                <MenuItem value='CAN'>CAN</MenuItem>
                                <MenuItem value='IND'>IND</MenuItem>
                                <MenuItem value='USA'>USA</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11/3} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <TextField label="Billing Entity"
                            select
                            required
                            fullWidth
                            value={putObj.billing_entity ? putObj.billing_entity : ""}
                            onChange={(e) => updatePutObj('billing_entity',e.target.value)}>
                                <MenuItem value='CAN'>CAN</MenuItem>
                                <MenuItem value='IND'>IND</MenuItem>
                                <MenuItem value='USA'>USA</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11/3} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <TextField label="Paying Entity"
                            select
                            required
                            fullWidth
                            value={putObj.paying_entity ? putObj.paying_entity : ""}
                            onChange={(e) => updatePutObj('paying_entity',e.target.value)}>
                                <MenuItem value='CAN'>CAN</MenuItem>
                                <MenuItem value='IND'>IND</MenuItem>
                                <MenuItem value='USA'>USA</MenuItem>
                            </TextField>
                        </Grid>
                        <GridBreak/>
                        <Grid item xs={11/3} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <TextField label="Manager First Name"
                            required
                            fullWidth
                            value={putObj.manager_first_name ? putObj.manager_first_name : ""}
                            onChange = {(e) => updatePutObj("manager_first_name",e.target.value)}/>
                        </Grid>
                        <Grid item xs={11/3} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <TextField label="Manager Last Name"
                            required
                            fullWidth
                            value={putObj.manager_last_name ? putObj.manager_last_name : ""}
                            onChange = {(e) => updatePutObj("manager_last_name",e.target.value)}/>
                        </Grid>
                        <Grid item xs={11/3} container sx={{ml:'0.1%', mr:'0.1%'}}>
                            <TextField label="Manager Email"
                            required
                            fullWidth
                            value={putObj.manager_email ? putObj.manager_email : ""}
                            error={Boolean(putObj.manager_email) && !validator.isEmail(putObj.manager_email)}
                            helperText={Boolean(putObj.manager_email) && !validator.isEmail(putObj.manager_email) ? "Please use a valid email" : ""}
                            onChange={(e)=>updatePutObj("manager_email",e.target.value)}/>
                        </Grid>
                    </Grid>
                </Grid> : <></>}
                <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center', mt:'1%', marginBottom:'20px'}}>
                    <HoverButton variant="outlined" onClick={(event)=> {event.preventDefault(); setOpenResource(true)}} color="black">Manage Project Resources</HoverButton>
                </Grid>
                <Grid container>
                    {projectDetails && projectDetails.length > 0 ? 
                    <>
                    {projectDetails.map(detail => 
                        (<ResourceDetailsBar details={detail} detail_types={auxInfo.resource_types} onUpdate={handleDetails}
                        project_end={putObj.end_date ? moment.utc(putObj.end_date) : putObj.end_date} 
                        project_start={putObj.start_date ? moment.utc(putObj.start_date) : putObj.start_date}
                        recruiters={auxInfo.recruiters ? auxInfo.recruiters : []}
                        currencies={auxInfo.currencies ? auxInfo.currencies : []}
                        pay_types={auxInfo.pay_types ? auxInfo.pay_types : []}/>)
                    )}
                    </>
                    :<></>}
                </Grid>
                <Grid item container xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center', mt:'1%'}}>
                    <Button sx={{ width: "10%", backgroundColor:'#ff6600', color: 'white', borderColor:'#ff6600', '&:hover': {
                  backgroundColor: '#fff',
                  color: '#ff6600'}}} variant='contained' onClick={(event)=>{event.preventDefault(); handleSave();}}>
                        Save
                    </Button>
                </Grid>
                {/* {projectDetails && projectDetails.length > 3 ? 
                <Grid item container xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center', mt:'1%'}}>
                    <HoverButton align="center" color="black" variant="outlined" onClick={(event)=>{event.preventDefault(); handleSave();}}>
                        Save
                    </HoverButton>
                </Grid> : <></>} */}
            </Box>
        </Grid>
    )
}

export default ManageProject