import React, {useState, useEffect} from "react";
import PropTypes, { number } from 'prop-types'
import Template from "../../Template";
import {Button, Grid, MenuItem, TextField, Typography} from '@mui/material'
import { DatePicker } from "@mui/lab";
import AddButton from "../../../components/AddButton";
import moment from "moment";
import AddExpenses from "./AddExpenses";
import DragFiles from "../../../components/FileManagement/DragFiles";
import { checkJson, fileTo64Arr, toMoneyString } from "../../../util/generalHelp";
import { getExpenseAuthorities, saveExpenseForm, submitExpenseForm } from "../../../util/ExpenseFormHelp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import IFramePopUp from "../../../components/Pop_ups/IFramePopUp";
import GridBreak from "../../../components/GridBreak";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import SuccessSnackBar from "../../../components/SuccessSnackBar";

function ExpenseForm(props){
    const defaultObj = {
        // employee_name : "",
        manager_name : "",
        manager_email : "",
        expense_start : null,
        expense_end : null,
    }

    const expenseTemplate = {
        cost : 0,
        desc : "",
        category: "Other",
        date: null
    }

    const [expenseObj, setExpenseObj] = useState(defaultObj)
    const [expenses, setExpenses] = useState ([expenseTemplate])
    const [fileArr, setFileArr] = useState([])
    const [docusignUrl, setDocusignUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [uploadStatus, setUploadStatus] = useState('pending')
    const [hasLoaded, setHasLoaded] = useState(false)
    const [authorities, setAuthorities] = useState([])
    const [manager, setManager] = useState({})
    const [envelopeId, setEnvelopeId] = useState("")

    useEffect(()=>{
        var abortCont = new AbortController();
        if(!hasLoaded){
            getExpenseAuthorities(abortCont, (json) => {
                if(json){
                    console.log('auth',json.authorities)
                    let managerName = json.authorities.find((auth) => auth.email === 'rebecca.restani@seqtechnology.com')
                    console.log('managerName',managerName)
                    setAuthorities(managerName)
                }else{
                    setError("something went wrong on our end, please refresh the page and try again.")
                }
                setHasLoaded(true)
            })
        }
        return () => {abortCont.abort()}
    })

    const updateExpenseObj = (key, value) => {
        var newObj = {...expenseObj}
        newObj[key] = value
        setExpenseObj(newObj)
    }

    const addEmptyExpense = () => {
        // console.log('expenses.length',expenses.length)
        // console.log('maxExpenses',props.maxExpenses)
        if(expenses.length < props.maxExpenses){
            // console.log('pushing expense')
            let newExpenses = [...expenses]
            newExpenses.push(expenseTemplate)
            setExpenses(newExpenses)
        }else{
            alert('A maximum of 10 expenses can be filed at a time')
        }
    }

    const removeExpense = (index) => {
        if(expenses.length > 1){
            var newExpenses = [...expenses]
            newExpenses.splice(index,1)
            setExpenses(newExpenses)
        }else{
            var newExpense = [...expenses]
            newExpense[index] = {}
            setExpenses(newExpense)
        }  
    }
    const updateExpense = (newExpense, index) => {
        var newExpenses = [...expenses]
        newExpenses[index] = newExpense
        setExpenses(newExpenses)
    }

    const handleExpenseSubmit = () => {
        var newExpenses = {}
        var subtotal = 0
        for(var i = 0; i < expenses.length; i++){
            // console.log('newExpenses',newExpenses)
            var tempExpense = expenses[i]
            var newExpense = {}
            subtotal += Number(tempExpense.cost)
            newExpense[`exp_date_${i}`] = tempExpense.date
            newExpense[`exp_desc_${i}`] = tempExpense.desc
            newExpense[`exp_category_${i}`] = tempExpense.category
            newExpense[`exp_cost_${i}`] = toMoneyString(tempExpense.cost)
            newExpenses = {...newExpenses, ...newExpense}
            console.log('subtotal',subtotal)
        }
        newExpenses.subtotal = toMoneyString(subtotal)
        newExpense.total = toMoneyString(tempExpense.subtotal)
        return newExpenses
    }

    const handleSubmit = async () => {
        setLoading(true)
        var expenseBody = handleExpenseSubmit()
        console.log('expense Body',expenseBody)
        var reqBody = {...expenseObj, ...expenseBody}
        var reqObj = {document_args:reqBody}
        reqObj.document_b64s = await fileTo64Arr(fileArr)
        // reqObj.employee_email = props.profile.primary_email
        // reqObj.manager_email = reqBody.manager_email
        reqObj.expenses = expenses
        reqObj.authority_id = reqBody.authority_id
        reqObj.resource_id = props.profile.id
        reqObj.expense_start = reqBody.expense_start
        reqObj.expense_end = reqBody.expense_end

        let optionalFields = ['document_b64s']

        for(let i = 0; i < 10; i++){
            optionalFields.push(`exp_desc_${i}`)
        }
        
        if(!checkJson(reqObj, optionalFields)){
            setLoading(false)
            setError("Please Make Sure there are no empty fields")
            return
        }

        submitExpenseForm(reqObj, (json) => {
            console.log('json is',json)
            if(json){
                console.log('setting docusign url')
                setDocusignUrl(json.redirect_url)
                setEnvelopeId(json.envelope_id)
            }else{
                setError("Something went wrong when generating a docusign document, please try again.")
            }
            setLoading(false)
        })
    }

    const updateManager = (managerObj) => {
        var newExpenseObj = {...expenseObj}
        newExpenseObj.manager_name = managerObj.name
        newExpenseObj.manager_email = managerObj.email
        newExpenseObj.authority_id = managerObj.id
        setManager(managerObj)
        setExpenseObj(newExpenseObj)
    }

    const saveExpense = () => {
        
        setLoading(true)
        var expenseBody = handleExpenseSubmit()
        console.log('expense Body',expenseBody)
        var reqBody = {...expenseObj, ...expenseBody}
        var reqObj = {
            expenses : expenses,
            authority_id : reqBody.authority_id,
            resource_id : props.profile.id,
            expense_start : reqBody.expense_start,
            expense_end: reqBody.expense_end,
            envelope_id : envelopeId
        }
        console.log('saving form')
        saveExpenseForm(reqObj, (resp) => {
            console.log('got response')
            if(resp.ok){
                console.log('response is')
                setSuccess("Expense Form Successfully Submitted!")
                setExpenseObj(defaultObj); 
                setExpenses([expenseTemplate]); 
                setFileArr([]);
                setEnvelopeId("")
                setDocusignUrl("")
            }else{
                setError('Something went wrong when saving your form to our database, please submit it again')
            }
            setLoading(false)
        })
    }


    return(
        <Grid container sx={{alignItems:'center', justifyContent:'center', padding:'1%'}}>
            <LoadingPopUp open={loading || !hasLoaded}/>
            <SuccessSnackBar open={Boolean(success)} onClose={(e)=>{e.preventDefault(); setSuccess("")}}/>
            <ErrorPopUp open={Boolean(error)} onClose={(e) => {e.preventDefault(); setError("")}} errorContent={error ? error : undefined}/>
            <IFramePopUp open={Boolean(docusignUrl)} url={docusignUrl} onClose={(e) => {e.preventDefault(); setDocusignUrl("")}} maxRedirect={2}
                onRedirect={() => {setDocusignUrl(""); saveExpense()}}/>
            <Typography variant="h4" sx={{padding:'1%'}}>Expense Form</Typography>
            <Grid container spacing={1} sx={{justifyContent:'center', alignItems:'center'}}>
                <Grid item xs={0.25}/>
                {/* <Grid item xs={2}>
                    <TextField label="Employee Name" value={expenseObj.employee_name} fullWidth required
                        onChange={(e) => {e.preventDefault(); updateExpenseObj('employee_name',e.target.value)}}/>
                </Grid> */}
                <Grid item xs={2}>
                    <TextField label="Manager" value={manager} fullWidth required select
                    onChange={(e) => {e.preventDefault(); updateManager(e.target.value)}}>
                        {/* {
                            authorities.length > 0 ? 
                            authorities.map((auth)=>(
                                <MenuItem value={auth}>
                                    {auth.name} ({auth.email})
                                </MenuItem>
                            )) : <></>
                        } */}
                        {
                            <MenuItem value={authorities}>
                            {authorities.name} ({authorities.email})
                        </MenuItem>
                        }
                    </TextField>
                </Grid>
                {/* <Grid item xs={2}>
                    <TextField label="Manager Name" value={expenseObj.manager_name} fullWidth  required
                        onChange={(e) => {e.preventDefault(); updateExpenseObj('manager_name',e.target.value)}}/>
                </Grid>
                
                <Grid item xs={2}>
                    <TextField label="Manager Email" value={expenseObj.manager_email} fullWidth  required
                        onChange={(e) => {e.preventDefault(); updateExpenseObj('manager_email',e.target.value)}}/>
                </Grid> */}
                <Grid item xs={2}>
                    <DatePicker label="Expense Period Start" value={expenseObj.expense_start} 
                        onChange={(value) => updateExpenseObj('expense_start',value.format('YYYY-MM-DD').toString())} 
                        renderInput={(params) => <TextField required fullWidth {...params}/>}  
                        maxDate={expenseObj.expense_end ? moment.min(moment(expenseObj.expense_end), moment()) : moment()}/>
                </Grid>
                <Grid item xs={2}>
                    <DatePicker label="Expense Period End" value={expenseObj.expense_end} 
                        onChange={(value) => updateExpenseObj('expense_end',value.format('YYYY-MM-DD').toString())} 
                        renderInput={(params) => <TextField required fullWidth {...params}/>}  
                        minDate={expenseObj.expense_start ? moment(expenseObj.expense_start) : null}
                        maxDate={moment()}/>
                </Grid>
                <Grid item xs={1} container sx={{alignItems:'center'}}>
                    <AddButton onClick={(e) => {e.preventDefault(); addEmptyExpense()}}/>
                </Grid>
                <GridBreak/>
                <Typography align='center'>Individual Expenses (Max 10 per form)</Typography>
                {expenses.map((expense, index) => (
                    <Grid container item sx={{width:'100%', justifyContent:'center', alignItems:'center'}} xs={12}>
                        <AddExpenses expense={expense} removeExpense={() => removeExpense(index)} index={index+1}
                            onUpdate={(newExpense) => updateExpense(newExpense,index)}
                            minDate={expenseObj.expense_start} maxDate={moment.min(expenseObj.expense_end, moment())}/>
                    </Grid>
                ))}
                <DragFiles multiple={true} onUpdate={(fileArr) => {setFileArr(fileArr); setUploadStatus('success')}} 
                uploadText="Please Drag or Upload Receipts in The Box Below (jpg, pdf, and png allowed)"
                    types={['jpg','pdf','png']} maxSize={15}
                    onTypeError={setError} onSizeError={(error) => {setError(error); setUploadStatus('error')}}
                    uploadStatus={uploadStatus}/>
                <Grid item>
                    <Button onClick={(e) => {e.preventDefault(); handleSubmit()}}>Submit</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

ExpenseForm.propTypes = {
    maxExpenses : PropTypes.number
}

ExpenseForm.defaultProps = {
    maxExpenses : 10
}

export default ExpenseForm