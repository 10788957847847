import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import AlternateRow from "./AlternateRow";

/**
 * a mui table row that can be expanded to show another component
 * @component
 * @category Functionality
 */
function ExpandableRow(props) {
  const [open, setOpen] = useState(false);

  useEffect(()=>{
    setOpen(false)
  },[props.columnClick])

  useEffect(()=>{
    console.log('value of open',open)
  },[open])

  return (
    <>
      <AlternateRow alternate={Boolean(props.alternate)} alternateType="&:nth-of-type(4n+1)" overrideColor={props.overrideColor}>
        {props.expandSide == 'left' ? <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell> : <></>}
        {props.children}
        {props.expandSide === 'right' ? <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell> : <></>}
      </AlternateRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {React.cloneElement(props.expand, {onClose: () => setOpen(false)})}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

ExpandableRow.propTypes = {
  /**
   * React element displayed after expanding the row
   */
  expand: PropTypes.element.isRequired,
  /**
   * whether or not the row should also be an AlternateRow
   */
  alternate: PropTypes.bool,
  /**
   * what side of the row the expand button should be on
   */
  expandSide: PropTypes.string,
  /**
   * overrideColor for the AlternateRow component
   */
  overrideColor: PropTypes.string,
  /**
   * prop used to indicate whether or not a different row has been clicked
   */
  columnClick: PropTypes.bool,
};

ExpandableRow.defaultProps = {
  expandSide: "right",
};

export default ExpandableRow;
