import React from 'react';
import {PropTypes} from 'prop-types'
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from '@mui/material'
/**
 * pop up used to display given text
 * @component
 * @category Pop Ups
 */
function TextPopUp(props) {
    return (
        <Dialog
        open={props.open}
        onClose={props.onClose}
        sx={props.dialogSx}
        fullWidth={props.fullWidth}>
            <DialogTitle sx={{color:props.titleColor}}>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={props.contentSx}>
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

TextPopUp.propTypes = {
    /**
     * whether or not the popup is open
     */
    open : PropTypes.bool.isRequired,
    /**
     * function called upon closing the popup
     */
    onClose : PropTypes.func.isRequired,
    /**
     * title of the popup
     */
    title: PropTypes.string.isRequired,
    /**
     * body of the popup to be displayed
     */
    content:PropTypes.string.isRequired,
    /**
     * styling object for the dialog that the popup is within
     */
    dialogSx: PropTypes.object,
    /**
     * styling object for the DialogContentText
     */
    contentSx: PropTypes.object,
    /**
     * boolean indicating whether or not dialog containing popup should be full width of container
     */
    fullWidth: PropTypes.bool,
    /**
     * color of the title
     */
    titleColor: PropTypes.string
}

export default TextPopUp;