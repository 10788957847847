import { InputLabel } from '@mui/material';
import React from 'react';
import {fonts} from '../../util/Theme'
import PropTypes from 'prop-types'
/**
 * Formatted InputLabel component using fontsize set up in theme
 * @component
 * @category Stylized Components
 */
function FormattedInputLabel(props) {
    return (
        <InputLabel {...props} sx={{ fontSize: fonts.labelSize, backgroundColor: props.changeBackground ? props.backgroundColor : undefined }}>
            {props.children}
        </InputLabel>
    );
}

FormattedInputLabel.propTypes = {
    /**
     * boolean indicating whether or not the background color of the label should be changed
     */
    changeBackground: PropTypes.bool,
    /**
     * background color of the text
     */
    backgroundColor: PropTypes.string
}

FormattedInputLabel.defaultProps ={
    changeBackground: true,
    backgroundColor: '#f9f9f9'
}


export default FormattedInputLabel;