import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, {useState, useEffect} from "react";
import { checkUserPassword } from "../../util/ProfileHelper";
import { ErrorObject } from "../../util/generalHelp";
import CloseIcon from '@mui/icons-material/Close';

function PasswordCheck(props){
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(false)

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const checkPassword = () =>{
        checkUserPassword(password, (response)=>{
            if(response instanceof ErrorObject){
                if(response.status == 403 || response.status == 400){
                    console.log('invalid login')
                    setLoginError(true)
                }else{
                    response.displayError()
                }
            }else{
                console.log('successful login!')
                setLoginError(false);
                props.onClose();
                props.onSuccess()
            }
            setPassword("");
        })
    }

    const handleClose = (e) => {
        e.preventDefault();
        setPassword("");
        setLoginError(false);
        props.onClose()
    }

    return(
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>
            <Grid container>
                <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                    <Grid item container xs={11} sx={{justifyContent:'center'}}>
                        Please Enter Your Password
                    </Grid>
                    <Grid item container xs={1} sx={{justifyContent:'flex-start'}}>
                        <CloseIcon sx={{m:'1%', cursor:'pointer','&:hover':{color:'red'}}} onClick={handleClose}/>
                    </Grid>
                            
                </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are about to access sensitive data, please re-enter your password.
                </DialogContentText>
                <form
                    onSubmit={(event) => {
                    event.preventDefault();
                    checkPassword()
                    }}
                    style={{'width':'100%', justifyContent:'center', alignItems:'center'}}
                >
                <Grid item container xs={12} sx={{justifyContent:'center', alignItems:'center'}}>
                    <TextField fullWidth type={showPassword ? 'text' : 'password'} label="Password"
                    value={password} onChange={handlePasswordChange} error={loginError}
                    helperText={loginError ? 'Invalid Password, Please Try Again' : ""}
                    autoComplete="new-password"
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            edge="end"
                            onClick={toggleShowPassword}
                            onMouseDown={(event) => event.preventDefault()}
                            aria-label="toggle password visibility"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    />
                    <Button align='center' type="submit">Submit</Button>
                </Grid>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default PasswordCheck;