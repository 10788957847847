import React from 'react';
import {Button} from '@mui/material'
import PropTypes from 'prop-types'
import {fonts} from "../util/Theme"
/**
 * button that changes color when mouse hovers over it
 * @component
 * @category Stylized Components
 */
const HoverButton = React.forwardRef((props,ref) => {
    return (
        <Button ref={ref} fullWidth={props.fullWidth} variant={props.variant} onClick={props.onClick} sx={{
            fontSize: props.fontSize,
            borderColor: props.borderColor ? props.borderColor : undefined,
            color:props.color, bgcolor:(props.bgcolor ? props.bgcolor : undefined), '&:hover': {
                borderColor: props.hoverBorderColor ? props.hoverBorderColor : undefined,   
                color: props.hoverColor, bgcolor:(props.bgcolor ? props.bgcolor : undefined)}}} disabled={props.disabled}>
            {props.children}
        </Button>
    );
})

HoverButton.propTypes={
    /**
     * variant of mui button
     */
    variant: PropTypes.string,
    /**
     * function called when button is clicked, passes the clicking event e
     */
    onClick: PropTypes.func,
    /**
     * standard colour of button
     */
    color: PropTypes.string,
    /**
     * colour of button when mouse hovers over it
     */
    hoverColor: PropTypes.string,
    /**
     * whether or not the button is disabled
     */
    disabled: PropTypes.bool,
    /**
     * background color of the button
     */
    bgcolor: PropTypes.string,
    /**
     * font size of text inside button
     */
    fontSize: PropTypes.number,
    /**
     * whether or not the button should take up the entire width of its wrapping component
     */
    fullWidth: PropTypes.bool
}

HoverButton.defaultProps={
    fontSize:fonts.buttonSize,
    variant:'filled',
    color:'primary.contrastText',
    hoverColor:'secondary.main',
    fullWidth:false,
    disabled:false
}

export default HoverButton;