import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types'
import {Box, Dialog, Grid, Typography} from '@mui/material'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PdfPopUp(props){

    const onLoadError = (error) => {
        console.error('error loading document:',error)
        props.onClose()
    }

    return(
    <Dialog sx={{bgcolor:'transparent', justifyContent:'center', alignItems:'center', display:'flex'}}
        open={props.open}
        onClose={(e) => {e.preventDefault(); props.onClose()}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        
        <Document file={props.fileUrl} onLoadError={onLoadError}>
            <Grid sx={{justifyContent:'center', alignItems:'center'}}>
            <Page pageNumber={props.pageNumber}/>
            </Grid>
        </Document>
    </Dialog>)
}

PdfPopUp.propTypes = {
    fileUrl: PropTypes.string.isRequired,
    pageNumber: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

PdfPopUp.defaultProps = {
    pageNumber: 1,
    open:false
}

export default PdfPopUp