import { ColumnFilter } from './ColumnFilter.js'
import moment from 'moment'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const ColumnNames = [
    // {
    //     Header: () => null, // No header
    //         id: 'expander', // It needs an ID
    //         Cell: ({ row, isEditable }) => ( 
    //           <span {...row.getToggleRowExpandedProps()}>
    //             {row.isExpanded ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
    //           </span>
    //         ),
    // },
    {
        Header: 'id',
        accessor: 'id',
        Filter: ColumnFilter,

    },
    
    {
        Header: 'First Name',
        accessor: 'first_name',
        Filter: ColumnFilter,

    },
    
    {
        Header: 'Last Name',
        accessor: 'last_name',
        Filter: ColumnFilter
        
    },
    
   
    {
        Header: 'Invoice ID',
        accessor: 'invoice_id',
        Filter: ColumnFilter
        
    },
    {
        Header: 'Start Date',
        accessor: 'start_date',
        Cell: ({value})=>{ if (value === null) {return ''} else{return moment.utc(value).format('YYYY-MM-DD').toString()} },
        Filter: ColumnFilter,
    },
    // {
    //     Header: 'Hire Date',
    //     accessor: 'hireDate',
    //     Cell: ({value})=>{ if (value === null) {return ''} else{return moment.utc(value).format('YYYY-MM-DD').toString()} },
    //     Filter: ColumnFilter,
    // },
    {
        Header: 'Client',
        accessor: (row)=>{
            
            if (row.client_name === null){
                return row.client
            }else{
                return row.client_name
            }

        },
        
        // Cell: (props)=>{ 
        //     let a1 
        //     a1 = props.client_list?.filter((item)=>{
        //         return item.id == props.value })
        //     return a1?.length>0 ? a1[0].short_name:props.value
        // },
        Filter: ColumnFilter,
    },
    { 
        Header: 'Recruiter',
        accessor: 'rec_name',
        // Cell: ( props)=>{ return props.items?.map((item)=>{
        //     return item.id == props.value ? item.first_name:''
        // })}
    },
    { 
        Header: 'Account Manager',
        accessor: 'acc_name',
        // Cell: ( props)=>{ return props.items?.map((item)=>{
        //     return item.id == props.value ? item.first_name:''
        // })}
    },
    { 
        Header: 'Sourcer',
        accessor: 'sourc_name',
        // Cell: ( props)=>{ return props.items?.map((item)=>{
        //     return item.id == props.value ? item.first_name:''
        // })}
    },
    { 
        Header: 'Sales',
        accessor: 'sales_name',
        // Cell: ( props)=>{ return props.items?.map((item)=>{
        //     return item.id == props.value ? item.first_name:''
        // })}
    },

    // {
    //     Header:'App Credentials',
    //     id: 'credential',
    //     Filter: ColumnFilter,
    // },
    {
        Header:()=>null,
        id: 'edit',
        Filter: ColumnFilter,
    },
]