import React, {useEffect, useState, useMemo} from "react";

import { ColumnNames } from "./ColumnNames";
import { getAmplifyToken } from "../../../util/AmplifyHelp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import './Clients.css'
import { DisplayTable } from "./DisplayTable";


export function ClientsPage2({}) {
    const columns = useMemo(() => ColumnNames, []);
    const [data, setData] = useState([])
    const[loading, setLoading] = useState(false)
    const[refresh, setRefresh] = useState(true)

    useEffect(() => {
        setLoading(true)
        let isMounted = true; 
        getAmplifyToken((jwt) => {
          fetch(`${process.env.REACT_APP_ADMIN_API}/admin/clients-api`,{
            headers: {Authorization: jwt}
          })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            if (isMounted){
            setData(data);
            setLoading(false)
            }
          })
          .catch((err) => {console.error(err); setLoading(false)});
        })
        
        
      }, [refresh]);



  return (
  <div>
      <LoadingPopUp open={loading}/>
      <DisplayTable
      data={data}
      columns={columns}
      refresh={refresh}
      setRefresh={setRefresh}
      />
  </div>
  )

}