import {
    Typography,
    Grid,
    Box,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
  import { getHoursByTsId, getProfileTimesheets, getResourceTimesheets } from "../../../util/newTimeSheetHelper";
  import moment from "moment";
  import SubmittedDisplay from "../../../components/Submitted/SubmittedDisplay";
  import MultiProjectDropDown from "../../../components/MultiProjectDropDown";
  import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
  import ToFrom from "../../../components/ToFrom";
  import TimesheetPopUp from "../../../components/Pop_ups/TimesheetPopUp";
  import { ErrorObject, isObjectEmpty } from "../../../util/generalHelp";
import SubmittedTsDisplay from "./SubmittedTsDisplay";
  
  /**
   * page used for displaying submitted timesheets to consultants
   * @component
   * @category Pages
   * @subcategory Consultant
   */
  function SubmittedTs(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [timesheets, setTimesheets] = useState([]);
    const [error, setError] = useState(false);
    const [listOfProjects, setListOfProjects] = useState([])
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toFrom, setToFrom] = useState([undefined, undefined]);
    const [hours, setHours] = useState({})
    const [filteredTimesheets, setFilteredTimesheets] = useState([])
    const [infoObj, setInfoObj] = useState({})
    const [currString, setCurrString] = useState("")
  
  
    useEffect(() => {
      const abortCont = new AbortController()
      if (!hasLoaded) {
        console.log("fuck me")
        console.log('profile', props.profile)
        getResourceTimesheets(props.profile.id, abortCont, (response) => {
            console.log('testing')
          if (response instanceof ErrorObject) {
            setError(response)
          } else {
            setTimesheets(response.timesheets);
            setListOfProjects(response.projects);
            console.log('loaded timesheets', response.timesheets)
          }
          setHasLoaded(true);
        });
      }
      return () => abortCont.abort()
    });
  
    useEffect(()=>{
      const searchObj = {to: toFrom[0], from: toFrom[1], projects: projects, currString: currString.toLowerCase()}
      filterTimeSheets(searchObj)
    },[timesheets, toFrom,projects, currString])
  
  
    const filterTimeSheets = (searchObj) => {
      console.log('searchObj', searchObj, timesheets.length)
      let tempTimesheets = timesheets.filter(ts =>{
        console.log('ts', ts)
        console.log('project_id', ts.project_id)
        let query = true
        if (searchObj.to) query = (query && moment(ts.submitted_date).toDate() <= moment(searchObj.to).toDate())
        if (searchObj.from) query = (query && moment(ts.submitted_date).toDate() >= moment(searchObj.from).toDate())
        if(searchObj.projects.length > 0) {
          if(!searchObj.currString) query = (query && (searchObj.projects.some(project => project == ts.project_id)))
          else query = (query && (searchObj.projects.some(project_id => project_id == ts.project_id)) || ts.project_name.toLowerCase().includes(searchObj.currString))
        }else{
          query = query && ts.project_name.toLowerCase().includes(searchObj.currString)
        }
        return query
      })
      setFilteredTimesheets(tempTimesheets)
    }
  
    const handleDates = (to, from) => {
      if(to) to = moment(to).format('YYYY-MM-DD').toString()
      if(from) from = moment(from).format('YYYY-MM-DD').toString()
      setToFrom([to, from]);
    };
  
    const onClick = (tsObj) =>{
      console.log('onClick', tsObj.hours_dict)
      let info_obj = {
        project_name: tsObj.project_name,
        period_start_date: tsObj.week_start,
        days_worked: tsObj.days_worked,
        hours_worked: tsObj.hours_worked,
        status: tsObj.status,
        submitted_date: tsObj.submitted_date,
        standard_vacation: tsObj.standard_vacation,
        notes: tsObj.note_text,
        envelope_id: tsObj.envelope_id
      }
      setInfoObj(info_obj)
      setHours(tsObj.hours_dict)
        //setHours(tsObj.hours_dict)
    }
  
    const handleProjects  = (value) => {
      console.log('value',value)
      setProjects(value)
    }
  
    return (
      <>
        <LoadingPopUp open={loading || !hasLoaded} />
        <ErrorPopUp open={error} onClose={(event) => {event.preventDefault(); setError(false)}}/>
        {/* <IFramePopUp
          open={Boolean(docusignUrl)}
          url={docusignUrl}
          onClose={(event) => setDocusignUrl("")}
          maxRedirect={2}
        /> */}
        <TimesheetPopUp open={!isObjectEmpty(hours)} timesheets={hours} onClose={() => {setHours({}); setInfoObj({})}} beenSubmitted={true} infoObj={infoObj}/>
          <Box sx={{ boxShadow: 3, padding: "0.5%" }}>
            <Grid
              container
              rowSpacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "view.main"
              }}
            >
              <Grid item xs={12}>
                <Typography variant='h4' align='center'>Your Submitted Timesheets</Typography>
              </Grid>
              <br/>
              <MultiProjectDropDown projects={listOfProjects} onUpdate={handleProjects} onUpdateValue={setCurrString}/>
              <br/>
              <Grid
              item
              container
              xs={12}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                ml: "0.25%",
              }}
              columnSpacing={5}
            >
              <ToFrom onUpdate={handleDates} />
            </Grid>
              {timesheets.length > 0 ? (
              <Grid
                container
                item
                xs={12}
                sx={{ justifyContent: "center", alignItems: "center"}}
              >
               <SubmittedTsDisplay timesheets={filteredTimesheets} onClick={onClick}/>
              </Grid>
              ) : (
                <Box sx={{m:'1%'}}>
                <Typography align="center" variant="h3">
                  You have no timesheets!
                </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        
      </>
    );
  }
  
  export default SubmittedTs;
  