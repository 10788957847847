import React, {useState, useEffect} from "react";
import Table from "@mui/material/Table";
import { Paper, TableContainer, IconButton, Typography, Select, MenuItem } from "@mui/material";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ForwardIcon from '@mui/icons-material/Forward';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import "./Clients.css";
import { GlobalFilter } from "./GlobalFilter";
import { EditableRow } from "./EditableRow";
import { CreateNewClient } from "./CreateNewClient"
import { useLocation, useNavigate } from "react-router-dom";
import { getAmplifyToken } from "../../../util/AmplifyHelp";

export function DisplayTable({ data, columns,refresh, setRefresh }) {
  const navigate = useNavigate()
  const [isActive, setIsActive] = useState(false);
  const [editorData, setEditorData] = useState({});
  const [refreshDetails, setRefreshDetails] = useState (true)
  const [editValues, setEditValues] = useState({
    id: "",
    short_name: "",
    ap_email: "",
    contact_email:"",
    contact_first_name: "",
    contact_last_name: "",
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    selectedFlatRows,
    pageOptions,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 25, hiddenColumns:'id' },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const { globalFilter, expanded, pageIndex, pageSize } = state;

//   useEffect(()=>{
//     getAmplifyToken((jwt) => {
//         fetch(`${process.env.REACT_APP_ADMIN_API}/admin/sections`, {
//           headers: { Authorization: jwt },
//         })
//           .then((response) => {
//             if (response.ok) {
//               return response.json();
//             }
//           })
//           .then((data) => {
//             setEditorData(data)
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       });
    
// },[])

useEffect(()=>{
  console.log('JSON', editorData)
},[editorData])


  return (
    <div className="display-container">
        <Typography align='center' variant="h4" component="div" gutterBottom>
            Clients
          </Typography>
        <div className="entrycontainer">
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      {/* <CreateNewEntry refresh={refresh} setRefresh={setRefresh}></CreateNewEntry> */}
      <CreateNewClient refresh={refresh} setRefresh={setRefresh}/>
      </div>
      <TableContainer sx={{marginTop: 1}} component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, i) => (
              <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell align="center"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    
                    {column.isSorted
                      ? column.isSortedDesc
                        ? (<ArrowDropDownIcon sx={{paddingTop:'2px'}}/>)
                        : (<ArrowDropUpIcon/>)
                      : ''}
  
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <>
                        {row.isExpanded ? (
                          <EditableRow
                            cell={cell}
                            isActive={isActive}
                            setIsActive={setIsActive}
                            selectedRow={selectedFlatRows}
                            editValues={editValues}
                            setEditValues={setEditValues}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          ></EditableRow>
                        ) : (
                          <TableCell align ={'center'} {...cell.getCellProps()}>
                            {cell.column.id === "edit" ? (<>
                              <IconButton
                                {...cell.row.getToggleRowExpandedProps()}
                                onClick={() => {
                                  if (!isActive) {
                                    setIsActive(true);
                                    cell.row.toggleRowExpanded();
                                    cell.row.toggleRowSelected();
                                    console.log('cell',cell)
                                  } else {
                                  }
                                }}
                              >
                                <EditIcon sx={{ fontSize: 20, margin: "2%" }} />
                              </IconButton>
                              </>
                            ) :cell.column.id ==='expander' ? (<IconButton onClick={()=>{navigate('./client-details',{
                              state:{
                                id: cell.row.values.id,
                                short_name:cell.row.values.short_name,
                                ap_email:cell.row.values.ap_email,
                                contact_email:cell.row.values.contact_email,
                                contact_first_name:cell.row.values.contact_first_name,
                                contact_last_name:cell.row.values.contact_last_name,
                                // editorData: editorData,
                              },
                              
                            })}}>
                              <ForwardIcon></ForwardIcon>
                            </IconButton>): (
                              <Typography>{cell.render("Cell")}</Typography>
                            )}
                          </TableCell>
                        )}
                      </>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="pagination">
          <span className="page-selector">
            <Typography sx={{fontSize: '0.875rem'}}>Rows Per Page:</Typography>
            <Select
            sx={{marginRight: '24px', marginLeft: '8px', fontSize: '0.875rem'}}
              disableUnderline
              variant="standard"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 15, 25, 50].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
            <Typography sx={{fontSize: '0.875rem', marginRight:'24px'}}>
              {pageIndex + 1} of {pageOptions.length}
            </Typography>{" "}
          </span>
          <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ChevronLeftIcon/>
          </IconButton>
          <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <ChevronRightIcon/>
          </IconButton>
        </div>
      </TableContainer>
    </div>
  );
}
