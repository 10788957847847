import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import React, {useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import HoverButton from '../HoverButton';
import PropTypes from 'prop-types'
import {fonts} from '../../util/Theme'
import { isObjectEmpty } from '../../util/generalHelp';

/**
 * drop down that lists the different pages an admin and consultant has access to in regards to timesheet and navigates to them
 * @component 
 * @category Headers and Footers
 * @subcategory NavBar
 */
function TimesheetTile(props) {
    const[openDropDown, setOpenDropDown] = useState(false)
    const[anchoredElement,setAnchoredElement] = useState(false)

    const navigate = useNavigate()
    const ref = useRef()

    const handleClick = (event) =>{
        event.preventDefault()
        setOpenDropDown(true)
        setAnchoredElement(event.target)
    }

    const navAndClose = (url) => {
        navigate(url);
        setOpenDropDown(false)
    }

    return (
        <>
        {!isObjectEmpty(props.roles)  && (props.roles['timesheet'] === true  || props.roles['admin'] === true) ? <>
            <HoverButton ref={ref} onClick={handleClick} color={props.selected || openDropDown? props.selectedColor : props.color} hoverColor={props.hoverColor}>Timesheet Management</HoverButton>
            <Menu open={openDropDown} anchorEl={anchoredElement} onClose={(event)=>setOpenDropDown(false)}
            PaperProps={{style:{minWidth:ref.current ? ref.current.offsetWidth + (ref.current.offsetWidth / 100) : 0}}}>
            <MenuItem onClick={(event)=>navAndClose("/home/projects")}>
                <Typography fontSize={fonts.menuSize}>Submit Timesheet</Typography>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={(event)=>navAndClose("/home/submitted")}>
                <Typography fontSize={fonts.menuSize}>Submitted Timesheets</Typography>
            </MenuItem>
                {/* <MenuItem disabled><Typography variant='body1' fontSize={fonts.menuSize}>Timesheet Management</Typography></MenuItem> */}
            </Menu>
            </> : <></>}
        </>
    );
}

TimesheetTile.propTypes = {
    /**
     * whether or not TimesheetTile has been clicked on
     */
    selected: PropTypes.bool,
    /**
     * color TimesheeTile turns after having being clicked
     */
    selectedColor: PropTypes.string,
    /**
     * standard color of TimesheetTile
     */
    color: PropTypes.string,
    /**
     * color of TimesheetTile when hovered over by mouse
     */
    hoverColor: PropTypes.string
}
TimesheetTile.defaultProps = {
    selectedColor: 'secondary.main',
    selected:false
}

export default TimesheetTile;