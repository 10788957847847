import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import {Accordion, AccordionDetails, AccordionSummary, Button, Box,
    Dialog, DialogContent, DialogTitle, Grid, List, ListItem, Typography, TextField, Switch, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { getProfileReminders } from "../../util/ProfileHelper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseButton from "../CloseButton";
import { updateProfileReminders } from "../../util/ProfileHelper";
/**
 * component responsible for changing a user's reminder settings
 * @component 
 * @category Settings
 * @subcategory General Settings
 */
function ChangeReminders(props){
    const [hasLoaded, setHasLoaded]  = useState(false)
    const [reminderArr, setReminderArr] = useState([])
    const [currentReminder, setCurrentReminder] = useState("")
    const [updatedReminders, setUpdatedReminders] = useState([])

    const handleChange = (panel) => (event, isExpanded) => {
        setCurrentReminder(isExpanded ? panel : false);
    };

    const updateField = (id, field, value) => {
        console.log('updateField')
        let new_reminders = [...reminderArr]

        const reminder_index = new_reminders.findIndex(reminder => reminder.id === id)

        let new_reminder = Object.assign({},new_reminders[reminder_index])

        //let new_type = typeof(new_reminder[field])
        new_reminder[field] = value
        console.log('new_reminder', new_reminder)

        new_reminders[reminder_index] = new_reminder
        console.log('new_reminders', new_reminders)
        setReminderArr(new_reminders)
        
        let new_updated = [...updatedReminders]
        const new_updated_index = new_updated.findIndex((reminder) => reminder.id === new_reminder.id)
        if(new_updated_index <= 0){
            new_updated.push(new_reminder)
        }else{
            new_updated[new_updated_index] = new_reminder
        }
        setUpdatedReminders(new_updated)

    }

    useEffect(()=>{
        if(!hasLoaded){
            console.log('not has load')
            getProfileReminders(props.profile.id, (data) => {
                if(data != undefined){
                    setReminderArr(data.reminders)
                    setHasLoaded(true)
                }  
            })
            
        }
    })

    const handleClose = (e) => {
        props.onClose(e)
    }

    const handleSave = (e) => {
        console.log('updatedReminders', updatedReminders)
        // setUpdatedReminders([])
        // props.onClose(e)
        updateProfileReminders(updatedReminders, (resp) => {
            console.log('response code', resp.status)
            setUpdatedReminders([])
            if(props.updateStatus) props.updateStatus(resp, resp ? 'Reminders succesfully updated' : 'Something went wrong, please try again')
            props.onClose(e)
        })
    }

    useEffect(()=>{
        console.log('reminderArr')
    },[reminderArr])

    return (
        <>
        <Dialog open={props.open} onClose={(e) => handleClose(e)} fullScreen sx={{paddingTop: '5%', paddingBottom:'5%', paddingRight:'10%', paddingLeft:'10%'}}>
            <Grid container sx={{justifyContent:'flex-end', width:'100%', paddingTop:'1%', paddingRight:'1%'}}>
                <CloseButton onClick={handleClose}/>
            </Grid>
            <DialogTitle>
                <Typography align='center'>Reminder Details</Typography>
            </DialogTitle>
            <DialogContent>

                {reminderArr &&  Array.isArray(reminderArr)  && reminderArr.length > 0 ? <>
                    <Grid container sx={{height:'100%'}}>
                        <Grid container>
                        {reminderArr.map(reminder => (
                            // <Box sx={{width:'100%', mb:'0.5%'}}>
                                <Accordion sx={{width:'100%', mb:'0.5%', alignSelf:'start'}}>
                                    <AccordionSummary 
                                    expandIcon={<ExpandMoreIcon/>}>
                                        <Typography align='center'>{reminder.project_name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{width:'100%'}}>
                                        <Grid container sx={{width:'100%'}}>
                                            <Grid container item xs={6} sx={{width:'100%', flexDirection:'column'}}>
                                                <FormGroup>
                                                    <FormControlLabel label="Subscribed" control={
                                                        <Checkbox defaultChecked={reminder.subscribed} checked={reminder.subscribed} 
                                                        onChange={(e) => {updateField(reminder.id, 'subscribed', e.target.checked)}}/>
                                                    }/>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item container xs={6} sx={{padding:"0.75%"}}>
                                                    <TextField label="Reminder Email" value={reminder.email_address} 
                                                    fullWidth disabled={!reminder.subscribed} 
                                                    onChange={(e) => updateField(reminder.id, 'email_address', e.target.value)}/>
                                            </Grid>
                                            <Grid item xs={6} sx={{padding:"0.75%"}}>
                                                <TextField label="Days Before" value={reminder.days_before} fullWidth type='number'
                                                disabled={!reminder.subscribed}
                                                onChange={(e) => updateField(reminder.id, 'days_before', e.target.value)}/>
                                            </Grid>
                                            <Grid item xs={6} sx={{padding:"0.75%"}}>
                                                <TextField label="Days After" value={reminder.days_after} fullWidth type='number'
                                                disabled={!reminder.subscribed}
                                                onChange={(e) => updateField(reminder.id, 'days_after', e.target.value)}/>
                                            </Grid>
                                            <Grid item xs={6} sx={{padding:"0.75%"}}>
                                                <TextField label='Carbon Copy Address' value={reminder.carbon_copy_address} fullWidth 
                                                disabled={!reminder.subscribed || props.profile.role !== 'admin'}
                                                onChange={(e) => updateField(reminder.id, 'carbon_copy_address', e.target.value)}/>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                
                            // </Box>
                            ) 
                        )}
                        </Grid>
                        <Grid container sx={{height:'5%',justifyContent:'center', marginTop:'auto'}}>
                            <Button variant='contained' onClick={(e) => {e.preventDefault(); handleSave(e)}}>Save Reminders</Button>
                        </Grid>
                    </Grid>
                </>
                : 
                <>
                <Typography align='center'>It looks like you have no reminders to configure. 
                    If you have active projects please reload the page and try again</Typography>
                </>}
            </DialogContent>
            
        </Dialog>
        </>
    )


}

ChangeReminders.propTypes = {
    /**
     * whether or not change password is open
     */
    open: PropTypes.bool.isRequired,
    /**
     * function called after closing the change password dialog
     */
    onClose: PropTypes.func.isRequired,
    /**
     * function used to update the status of parent component, returns whether or not update was successful and string alongisde it
     */
    updateStatus: PropTypes.func.isRequired,
    /**
     * profile of user who's reminders are being updated
     */
    profile: PropTypes.object.isRequired
}

export default ChangeReminders