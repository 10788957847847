import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TablePagination
} from "@mui/material";
import ExpandableRow from "../ExpandableRow";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TextPopUp from "../Pop_ups/TextPopUp";
import ClickBox from "../ClickBox";
import EditIcon from '@mui/icons-material/Edit';
import SubmittedCell from "./SubmittedCell";
import AlternateRow from "../AlternateRow";
import AddInvoice from "../invoice/AddInvoice";
import {fonts} from '../../util/Theme'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
/**
 * Table Displaying admin's version of submitted timesheets
 * @component 
 * @category Timesheets
 * @subcategory Submitted Timesheets 
 */
function AdminSubmittedDisplay(props) {
  const [infoTitle, setInfoTitle] = useState("");
  const [infoBody, setInfoBody] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const[page, setPage] = useState(0)
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [columnClick, setColumnClick] = useState(false)
  
  useEffect(()=>{
    //if will go past maximum page, go to new max page
    if(props.timesheets && props.timesheets.length < (page+1) * rowsPerPage){
      setPage(Math.max(Math.ceil(props.timesheets.length / rowsPerPage)-1,0))
   }
  },[props.timesheets])

  useEffect(()=>{
    setColumnClick(props.columnClick)
  },[props.columnClick])

  // const dropLabels = ['Client', 'Invoiced', 'Invoice Date', 'Invoice #', 'Pay Recieved', 'Pay Date', 'Pay Document']

  // const formatRow = (ts) =>{
  //   return [(`${ts.resource.first_name} ${ts.resource.last_name}`),ts.project.project_name,ts.timesheet.period_start_date,ts.timesheet.submitted_date,
  //   ts.hours,ts.days,ts.timesheet.status]
  // }
  // const formatDrop = (ts) =>{
  //   return [ts.client.short_name, ts.invoice.invoiced,
  //     ts.invoice.invoice_date ? moment.utc(ts.invoice.invoice_date).format('YYYY-MM-DD').toString() : ts.invoice.invoice_date,
  //      ts.invoice.id, ts.invoice.payment_made,
  //      ts.invoice.payment_date ? moment.utc(ts.invoice.payment_date).format('YYYY-MM-DD').toString() : ts.invoice.payment_date, 'todo']
  // }
  const infoOrNa = (info) => {
    return info ? info : "N/A";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 

  const columns = [
    { label: "Resource", accessor: "resource", sortable: true },
    { label: "Project", accessor: "project", sortable: true },
    { label: "Client", accessor: "client", sortable: true },
    { label: "Period", accessor: "period", sortable: true },
    { label: "Submitted on", accessor: "submitted_on", sortable: true },
    { label: "Hours", accessor: "hours", sortable: true},
    { label: "Days", accessor: "days", sortable: true},
    { label: "Status", accessor: "status", sortable: true },
    { label: "Invoice Number", accessor: "invoice_number", sortable: true },
    { label: "Payment Made", accessor: "payment_made", sortable: true },
    { label: "Consultant Paid", accessor: "consultant_paid", sortable: true },
    { label: "Invoice Document", accessor: "invoice_document", sortable: false },
   ];

  const handleSortingChange = (accessor) => {
    setColumnClick(!columnClick)
    const sortOrder =
    accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    props.handleSorting(accessor, sortOrder);
 };

 const getTimesheetInvoiceInfo = (timesheet) =>{
  let invoice_numbers = []
  let payment_mades = []
  let consultant_paids = []
  let invoice_documents = []  
  for(let inv of timesheet.invoice){
    if(!inv.invoice_number){
      continue
    }

      invoice_numbers.push(inv)
      if(inv.payment_made) payment_mades.push(inv)
      if(inv.consultant_paid) consultant_paids.push(inv)
      if(inv.invoice_document) invoice_documents.push(inv)
  }
  
  return {
    invoice_numbers,
    payment_mades,
    consultant_paids,
    invoice_documents
  }

 }
//  const handleSorting = (sortField, sortOrder) => {
//   if (sortField) {
//    const sorted = [...tableData].sort((a, b) => {
//     if (a[sortField] === null) return 1;
//     if (b[sortField] === null) return -1;
//     if (a[sortField] === null && b[sortField] === null) return 0;
//     return (
//      a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
//       numeric: true,
//      }) * (sortOrder === "asc" ? 1 : -1)
//     );
//    });
//    setTableData(sorted);
//   }
//  };

  return (
    <>
      <TextPopUp
        open={Boolean(infoBody) && Boolean(infoTitle)}
        content={infoBody}
        title={infoTitle}
        onClose={(event) => {
          event.preventDefault();
          setInfoBody("");
          setInfoTitle("");
        }}
        fullWidth
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {columns.map(({label,accessor,sortable}) => {
                const cl = sortable
                ? sortField && sortField === accessor && order === "asc"
                 ? <ArrowDropUpIcon/>
                 : sortField && sortField === accessor && order === "desc"
                 ? <ArrowDropDownIcon/>
                 : ""
                : "";
                return <TableCell key={accessor} align='center' sx={{ fontSize: fonts.tableSize }} onClick={sortable ? () => handleSortingChange(accessor) : null} >{label}{cl}</TableCell>
              })}
              {/* <TableCell /> */}
              {/* <SubmittedCell>Resource</SubmittedCell>
              <SubmittedCell>Project</SubmittedCell>
              <SubmittedCell>Client</SubmittedCell>
              <SubmittedCell>Period</SubmittedCell>
              <SubmittedCell>Submitted On</SubmittedCell>
              <SubmittedCell>Hours {props.timesheets ? props.timesheets.reduce((a, b) => Number(a) + (Number(b.hours) || 0), 0 ).toFixed(2) : ""}</SubmittedCell>
              <SubmittedCell>Days {props.timesheets ? (props.timesheets.reduce((a, b) => Number(a) + (Number(b.hours) || 0), 0 ) / 8).toFixed(2) : ""}</SubmittedCell>
              <SubmittedCell>Status</SubmittedCell>
              <SubmittedCell>Invoice Number</SubmittedCell>
              <SubmittedCell>Payment Made</SubmittedCell>
              <SubmittedCell>Consultant Paid</SubmittedCell>
              <SubmittedCell>Invoice Document</SubmittedCell>
              <SubmittedCell /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.timesheets.length > 0 ? (
              <>
                {props.timesheets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ts) => {
                  let invoice_obj = getTimesheetInvoiceInfo(ts)
                  //console.log('invoice_obj',invoice_obj)
                  return (
                  
                  <ExpandableRow columnClick={columnClick} setColumnClick={setColumnClick} expand={<AddInvoice profile={props.profile} invoices={ts.invoice} timesheet={ts.timesheet}
                  onUpdate={props.onUpdate? props.onUpdate : undefined} onSuccess={props.onSuccess}/>} 
                  alternate overrideColor={ts.timesheet.admin_submitted_id ? 'orange' : undefined}>
                    <SubmittedCell>
                      {`${ts.resource.first_name} ${ts.resource.last_name}`}
                    </SubmittedCell>
                    <SubmittedCell>{ts.project.project_name}</SubmittedCell>
                    <SubmittedCell>{ts.client.short_name}</SubmittedCell>
                    <SubmittedCell>
                      {moment
                        .utc(ts.timesheet.period_start_date)
                        .format("YYYY-MM-DD")
                        .toString()}
                    </SubmittedCell>
                    <SubmittedCell>
                      {ts.timesheet.submitted_date ? moment
                        .utc(ts.timesheet.submitted_date).local()
                        .format("YYYY-MM-DD")
                        .toString() : "N/A"}
                    </SubmittedCell>
                    <SubmittedCell>{ts.hours}</SubmittedCell>
                    <SubmittedCell>{ts.days}</SubmittedCell>
                    <SubmittedCell>
                      <ClickBox
                        onClick={(event) => {
                          event.preventDefault();
                          props.onEnvelope(ts);
                        }}
                        clickable={Boolean(ts.timesheet.status !== "pending")}
                      >
                        <Typography
                          color="green"
                          sx={{ textDecoration: "underline" }}
                        >
                          {ts.timesheet.status}
                        </Typography>
                      </ClickBox>
                    </SubmittedCell>
                    <SubmittedCell>
                      {invoice_obj.invoice_numbers.length !== 0 ? 
                      <>
                      {invoice_obj.invoice_numbers.map((inv)=>(
                        <ClickBox
                        onClick={(event) => {
                          event.preventDefault();
                          setInfoBody(
                            `Invoice Number: ${infoOrNa(
                              inv.invoice_number
                            )} | Invoice Date: ${
                              inv.invoice_date
                                ? moment(inv.invoice_date)
                                    .format("YYYY-MM-DD")
                                    .toString()
                                : "N/A"
                            }`
                          );
                          setInfoTitle("Invoice Info");
                        }}
                        clickable={Boolean(inv.invoice_number)}
                      >
                          <Typography
                            color="green"
                            sx={{ textDecoration: "underline" }}
                          >
                            {inv.invoice_number}
                          </Typography>
                      </ClickBox>
                      ))}
                      </> : <Typography>No Invoices</Typography>}
                    </SubmittedCell>
                    <SubmittedCell>
                      {invoice_obj.payment_mades.length > 0 ? <>
                        {invoice_obj.payment_mades.map((inv)=>(
                        <ClickBox
                        onClick={(event) => {
                          event.preventDefault();
                          setInfoTitle("Invoice Payment Info");
                          setInfoBody(
                            `Payment Made On: ${
                              inv.payment_date
                                ? moment(inv.payment_date)
                                    .format("YYYY-MM-DD")
                                    .toString()
                                : "N/A"
                            }`
                          );
                        }}
                        clickable={Boolean(inv.payment_made)}
                      >
                          <Typography
                            color="green"
                            sx={{ textDecoration: "underline" }}
                          >
                            {inv.invoice_number}
                          </Typography>
                      </ClickBox>
                      ))}
                      </> : <Typography>None</Typography>}
                    </SubmittedCell>
                    <SubmittedCell>
                      {invoice_obj.consultant_paids.length > 0 ? <>
                        {invoice_obj.consultant_paids.map((inv)=>(
                          <ClickBox
                          onClick={(event) => {
                            event.preventDefault();
                            setInfoTitle("Consultant Payment Info");
                            setInfoBody(
                              `Consultant Paid On: ${
                                inv.consultant_paid_date
                                  ? moment(inv.consultant_paid_date)
                                      .format("YYYY-MM-DD")
                                      .toString()
                                  : "N/A"
                              }`
                            );
                          }}
                          clickable={Boolean(inv.consultant_paid)}
                        >
                            <Typography
                              color="green"
                              sx={{ textDecoration: "underline" }}
                            >
                              {inv.invoice_number}
                            </Typography>
                        </ClickBox>
                      ))}
                      </> : <Typography>None</Typography>}
                      
                    </SubmittedCell>
                    <SubmittedCell>
                      {invoice_obj.invoice_documents.length > 0 ? <>
                        {invoice_obj.invoice_documents.map((inv)=>(
                        <ClickBox onClick={props.onClickInvoice? (event) => {event.preventDefault(); props.onClickInvoice(inv.invoice_document)} : undefined}>
                        <Typography
                            color="green"
                            sx={{ textDecoration: "underline" }}
                          >
                            {inv.invoice_number}
                          </Typography>
                      </ClickBox>
                      ))}
                      </> : <Typography>None</Typography>}
                      
                    </SubmittedCell>
                  </ExpandableRow>
                )})}
              </>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.timesheets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

AdminSubmittedDisplay.propTypes = {
  /**
   * list of objects that represent timesheets, following schema in db
   */
  timesheets: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * function called when clicking status of timesheet
   */
  onEnvelope: PropTypes.func,
  /**
   * function used to handle the sorting of the given timesheets
   */
  handleSorting: PropTypes.func,
  /**
   * function called when clicking an invoice number
   */
  onClickInvoice: PropTypes.func,
  /**
   * profile of current user viewing timesheets, object following db schema
   */
  profile: PropTypes.object.isRequired,
  /**
   * function called when tables are updated
   */
  onUpdate: PropTypes.func,
  /**
   * boolean toggle, update to close all expandable invoices
   */
  columnClick: PropTypes.bool
};

export default AdminSubmittedDisplay;
