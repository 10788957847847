import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography, MenuItem } from '@mui/material'
import { DatePicker } from '@mui/lab';
import PropTypes from 'prop-types'
import GridBreak from '../../../components/GridBreak'
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import IFramePopUp from '../../../components/Pop_ups/IFramePopUp';
import { useBeforeunload } from 'react-beforeunload';
import moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import DragFiles from "../../../components/FileManagement/DragFiles";
import { fileTo64, fileTo64Arr, handleFormNumbers } from "../../../util/generalHelp";
import { useBackListener } from "../../../util/Hooks";
import { createContractDraft, submitDraft, removeOfferDraft } from "../../../util/offerLettersHelp";
import { previewDocusign } from "../../../util/docusignHelper";


const fileTypes = ['docx', 'pdf']

/**
 * component used to create and send Offer Letters
 * @component 
 * @category Offer Letters
 */

function ContractForm(props) {

    const defaultCANLegal = {
        venue: 'Toronto, ON',
        jurisdiction: 'Ontario'
    }

    const defaultUSALegal = {
        venue: 'New York, NY',
        jurisdiction: 'New York'
    }

    const defaultObj = {
        document_args: {
            "pay_days": 0,
            "effective_date": null,
            "salary": 0,
            "job_title": "",
            insurance_included: true,
            "rate_type": "daily",
            venue: 'New York, NY',
            jurisdiction: 'New York',
            contractor_name: "",
            seq_hire_helper: "",
            seq_hire_statement: "SEQ shall maintain the right to hire Contractor from COMPANY after X months of service under this contract.  Nothing contained in the foregoing paragraph shall terminate this right during the pendency of this contract.",

            service_description: ""
        },
        // company_name: "",
        region: 'USA',
        name: "",
        email_address: "",
        authority_id: null,
        "return_url": window.location.origin.toString(),
        venue_id: "",
        state_id: "",

    }

    const [requestObj, setRequestObj] = useState({ ...defaultObj })
    const [previewUrl, setPreviewUrl] = useState()
    const [previewClicked, setPreviewClicked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileArr, setFileArr] = useState([])
    const [uploadStatus, setUploadStatus] = useState('error')
    const [envelopeId, setEnvelopeId] = useState("")


    useBackListener(({ location }) => {
        if (requestObj.id) {
            console.log('test')
            removeOfferDraft(requestObj.id, (resp) => { }, true)
        }
    })


    useBeforeunload((event) => {
        if (requestObj.id) {
            removeOfferDraft(requestObj.id, (resp) => { }, true)

        }
    });


    useEffect(() => {
        // console.log('fileArr',fileArr)
        if (fileArr && fileArr.length > 0) {
            setUploadStatus('success')
        } else {
            setUploadStatus('pending')
        }
    }, [fileArr])

    // useEffect(()=>{
    //     if(requestObj.document_args.venue === defaultCANLegal.venue && requestObj.document_args.jurisdiction === defaultCANLegal.jurisdiction
    //         && requestObj.region === 'USA'){
    //             let newReq = {...requestObj}
    //             newReq.document_args.venue = defaultUSALegal.venue
    //             newReq.document_args.jurisdiction = defaultUSALegal.jurisdiction
    //             setRequestObj(newReq)
    //     }
    //     else if(requestObj.document_args.venue === defaultUSALegal.venue && requestObj.document_args.jurisdiction === defaultUSALegal.jurisdiction
    //         && requestObj.region === 'CAN'){
    //             let newReq = {...requestObj}
    //             newReq.document_args.venue = defaultCANLegal.venue
    //             newReq.document_args.jurisdiction = defaultCANLegal.jurisdiction
    //             setRequestObj(newReq)
    //         }
    // },[requestObj.region])

    const checkJson = (json) => {
        for (const key of Object.keys(json)) {
            if (json[key] === null || json[key] === undefined || json[key] === '') {
                console.log('key', key)
                if (!props.optionalFields.includes(key))
                    return false
            }

            if (json[key].constructor == Object) {
                if (!checkJson(json[key])) {
                    return false
                }
                continue
            }
        }
        return true
    }

    const updateOffer = (key, value, del = false) => {
        let newOffer = { ...requestObj }
        if (!del) {
            newOffer[key] = value
            setRequestObj(newOffer)
        } else {
            delete newOffer[key]
            setRequestObj(newOffer)
        }

    }

    const updateDocument = (key, value) => {
        let newDoc = { ...requestObj }
        if (key == 'seq_hire_helper') {
            newDoc['document_args']['seq_hire_statement'] = defaultObj.document_args.seq_hire_statement.replace(/X/, `${value}`)
            // console.log("value,",newDoc['document_args']['seq_hire_statement'] )
        }
            newDoc['document_args'][key] = value
       
        setRequestObj(newDoc)
    }

    const handleFile = async (fileArr) => {
        return await fileTo64Arr(fileArr)
    }

    const handleNumberForm = (e, key, document = false, decimals = 0, maxdigits = undefined) => {
        let modfiedNum = handleFormNumbers(e.target.value, decimals, false, maxdigits)
        if (modfiedNum !== undefined) {
            if (document) {
                updateDocument(key, modfiedNum)
            } else {
                updateOffer(key, modfiedNum)
            }
        }
    }

    const handleClear = () => {
        if (requestObj.id) {
            setLoading(true)
            removeOfferDraft(requestObj.id, (resp) => {
                if (resp) {
                    let newOffer = { ...requestObj }
                    delete newOffer['id']
                    setRequestObj(newOffer)
                    setPreviewUrl()
                    setUploadStatus('pending')
                } else {
                    props.onError({})
                }
                setLoading(false)
            })
        }
    }

    const handleDraft = async () => {
        // console.log('requestObj',requestObj)
        if (previewUrl && requestObj.id) {
            setLoading(true)
            submitDraft(requestObj.id, envelopeId, (resp) => {
                if (resp) {
                    console.log('submission successful')
                    setRequestObj(defaultObj)
                    setFileArr([])
                    setPreviewUrl()
                    setEnvelopeId("")
                    if (props.onComplete) {
                        props.onComplete()
                    }
                } else {
                    props.onError({})
                }
                setLoading(false)
            })
        } else {
            setLoading(true)
            setPreviewUrl()
            if (checkJson(requestObj)) {
                let request_obj = { ...requestObj }
                //console.log('testing testing 123', fileArr)
                request_obj.document_b64s = await handleFile(fileArr)
                createContractDraft(request_obj, (resp) => {
                    if (resp) {
                        //console.log('draft resp',resp)
                        updateOffer('id', resp.id)
                        setPreviewUrl(resp.redirect_url)
                        setUploadStatus('disabled')
                        setEnvelopeId(resp.envelope_id)
                    } else {
                        props.onError({})
                    }
                    setLoading(false)
                })
            } else {
                props.onError({ errorTitle: 'Missing Form Fields', errorContent: "Please fill out all available form fields" })
                setLoading(false)
            }

        }

    }

    const closePreview = () => {
        setPreviewClicked(false)
        previewDocusign(envelopeId, (json) => {
            if (json) {
                setPreviewUrl(json.redirect_url)
            }
        })
    }

    return (
        <Grid sx={{ flexDirect: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '2%', height: '100%' }}>
            <LoadingPopUp open={loading} />
            <IFramePopUp open={previewClicked} url={previewUrl} onClose={closePreview} maxRedirect={2} />
            <Typography align='center' variant='h4'>Manage Contracts</Typography>
            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%', padding: '1%' }}>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="Signatory Name" onChange={(e) => { e.preventDefault(); updateOffer('name', e.target.value) }}
                        value={requestObj.name} fullWidth required />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="Contractor Name" onChange={(e) => { e.preventDefault(); updateDocument('contractor_name', e.target.value) }}
                        value={requestObj.document_args.contractor_name} fullWidth required />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="Company Name" onChange={(e) => { e.preventDefault(); updateDocument('company_name', e.target.value) }}
                        value={requestObj.document_args.company_name} fullWidth required />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="Signatory Email" onChange={(e) => { e.preventDefault(); updateOffer('email_address', e.target.value) }}
                        value={requestObj.email_address} fullWidth required />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="Job Title" onChange={(e) => { e.preventDefault(); updateDocument('job_title', e.target.value) }}
                        value={requestObj.document_args.job_title} fullWidth required />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="Region" required onChange={(e) => { e.preventDefault(); updateOffer('region', e.target.value) }}
                        value={requestObj.region} fullWidth select>
                        <MenuItem key={1} value="CAN">
                            CAN
                        </MenuItem>
                        <MenuItem key={2} value="USA">
                            USA
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField label="Insurance Included" required disabled={previewUrl} onChange={(e) => { e.preventDefault(); updateDocument('insurance_included', e.target.value) }}
                        value={requestObj.document_args.insurance_included} fullWidth select>
                        <MenuItem value={true}>
                            Yes
                        </MenuItem>
                        <MenuItem value={false}>
                            No
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <DatePicker disabled={Boolean(previewUrl)} label="SOW Effective Date" value={requestObj.document_args.effective_date}
                        onChange={(value) => updateDocument('effective_date', value.format('YYYY-MM-DD').toString())}
                        renderInput={(params) => <TextField required fullWidth {...params} />} />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label='Payment Deadline (days)'
                        SelectProps='0.01' onChange={(e) => {
                            e.preventDefault();
                            handleNumberForm(e, 'pay_days', true, 0)
                        }}
                        value={requestObj.document_args.pay_days} fullWidth required />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="Payment Type" required onChange={(e) => { e.preventDefault(); updateDocument('rate_type', e.target.value) }}
                        value={requestObj.document_args.rate_type} fullWidth select>
                        <MenuItem key={1} value="daily">
                            Daily
                        </MenuItem>
                        <MenuItem key={2} value="hourly">
                            Hourly
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} SelectProps='0.01' onChange={(e) => {
                        e.preventDefault();
                        handleNumberForm(e, 'salary', true, 2)
                    }}
                        label={requestObj.document_args.rate_type == 'daily' ? "Daily Rate ($)" : 'Hourly Rate ($/hr)'}
                        value={requestObj.document_args.salary} fullWidth required />
                </Grid>
                {/* <Grid item xs={3}>
                    <TextField label="Performance Bonus" SelectProps='0.01' onChange={(e) => {e.preventDefault(); updateOffer('bonus',Math.trunc(Math.max(e.target.value,0) * 100)/100)}}
                        value={requestObj.bonus} fullWidth  disabled={requestObj.type !== 'SALARY'}/>
                </Grid> */}
                {/* <Grid item xs={3}>
                    <TextField label="Company Name" required disabled={ previewUrl} onChange={(e) => {e.preventDefault(); updateOffer('company_name',e.target.value)}}
                        value={requestObj.company_name} fullWidth />
                </Grid> */}
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="Service Description" onChange={(e) => { e.preventDefault(); updateDocument('service_description', e.target.value) }}
                        value={requestObj.document_args.service_description} fullWidth required multiline />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={true} label="SEQ Non-Competition Statement"
                        value= {requestObj.document_args.seq_hire_statement} fullWidth required multiline />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} label="SEQ Non-Competition Statement Month(s)"
                        onChange={(e) => {
                            e.preventDefault();
                            let val = e.target.value.replace(/\D/, '')
                            updateDocument('seq_hire_helper', val)
                        }}
                        value={requestObj.document_args.seq_hire_helper} fullWidth required multiline />
                </Grid>
           
                {/* <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)}  label="Venue" onChange={(e) => {e.preventDefault(); updateDocument('venue',e.target.value)}}
                        value={requestObj.document_args.venue} fullWidth required />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)}  label="Jurisdiction" onChange={(e) => {e.preventDefault(); updateDocument('jurisdiction',e.target.value)}}
                        value={requestObj.document_args.jurisdiction} fullWidth required />
                </Grid> */}
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} required label="Jurisdiction"
                        onChange={(e) => { e.preventDefault(); updateOffer('state_id', e.target.value) }}
                        value={requestObj.state_id} fullWidth select>
                        {props.states.map((auth) => (
                            <MenuItem key={auth.id} value={auth.id}>
                                {auth.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl) || !requestObj.state_id} required label="Venue"
                        onChange={(e) => { e.preventDefault(); updateOffer('venue_id', e.target.value) }}
                        value={requestObj.venue_id} fullWidth select>
                        {props.venues.filter(v => v.type === requestObj.state_id).map((auth) => (
                            <MenuItem key={auth.id} value={auth.id}>
                                {auth.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled={Boolean(previewUrl)} required label="Signing Authority" onChange={(e) => { e.preventDefault(); updateOffer('authority_id', e.target.value) }}
                        value={requestObj.authority_id} fullWidth select>
                        {props.authorities.map((auth) => (
                            <MenuItem key={auth.id} value={auth.id}>
                                {auth.name}({auth.email_address})
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <GridBreak />
                <Grid item container xs={12} sx={{ justifyContnet: 'center', alignItems: 'center' }}>
                    <DragFiles types={fileTypes} multiple={true} disabled={Boolean(previewUrl)} onUpdate={setFileArr}
                        maxSize={15}
                        onTypeError={(error) => { setUploadStatus('error'); props.onError({ errorTitle: 'File Type Error', errorContent: error }) }}
                        onSizeError={(error) => { setUploadStatus('error'); props.onError({ errorTitle: 'File Size Error', errorContent: error }) }}
                        uploadStatus={uploadStatus} />
                </Grid>
                <Grid item xs={4} container sx={{ justifyContnet: 'center', alignItems: 'center' }}>
                    <Button fullWidth disabled={!previewUrl || !requestObj.id} onClick={(e) => { e.preventDefault(); handleClear() }}>Cancel</Button>
                </Grid>
                <Grid item xs={4} container sx={{ justifyContnet: 'center', alignItems: 'center' }}>
                    <Button fullWidth disabled={!previewUrl || !requestObj.id} onClick={(e) => { e.preventDefault(); setPreviewClicked(true) }}>Preview Document</Button>
                </Grid>
                <Grid item xs={4} container sx={{ justifyContnet: 'center', alignItems: 'center' }}>
                    <Button fullWidth onClick={(e) => { e.preventDefault(); handleDraft() }}>{requestObj.id && previewUrl ? "Send" : "Create Draft"}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

ContractForm.propTypes = {
    /**
     * list of valid signing authorities, following db schema
     */
    authorities: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * list of available clients, follwoing db schema
     */
    clients: PropTypes.arrayOf(PropTypes.object),
    /**
     * function to be called when sending out the offer letter is sucsessful
     */
    onComplete: PropTypes.func,
    /**
     * function to be called when an error occures
     */
    onError: PropTypes.func,
    /**
     * optional fields for the form
     */
    optionalFields: PropTypes.arrayOf(PropTypes.string)
}

ContractForm.defaultProps = {
    onError: () => { },
    optionalFields: ['period_interval', 'period_length']
}

export default ContractForm