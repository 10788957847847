import { Grid, TextField, Box, Avatar, Typography, Select, MenuItem, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from "@mui/lab";
import moment from 'moment';
import {compareDate} from "../../util/generalHelp"
import GridBreak from "../GridBreak";

/**
 * Bar displayed within Projects used to edit ProjectDetails information
 * @component 
 * @category Resource Details Management
 */
function ResourceDetailsBar(props){

    const fieldSize = 12/5

    const setDefault = (details) => {
        if(details.resource_type && details.resource_project_enddate && details.sales) return details
        let new_details = {...details}
        if(!new_details.resource_type) new_details.resource_type = 8
        if(!new_details.sales) new_details.sales = 1
        if(!new_details.sourcer) new_details.sourcer = 1
        if(!new_details.recruiter) new_details.recruiter = 1
        if(!new_details.account_manager) new_details.account_manager = 1
        if(!new_details.resource_project_enddate){
            new_details.resource_project_enddate =  props.project_end ? moment.min(moment.utc(props.project_end),moment.utc('2099-12-31')) : moment.utc('2099-13-31')
        }
        return new_details
    }

    const [details, setDetails] = useState(setDefault(props.details))
    // useEffect(()=>{
    //     props.onUpdate(details)
    // },[details])

    useEffect(()=>{
        setDetails(setDefault(props.details))
    },[props.details])

    useEffect(()=>{
        if(props.project_start){
            if(moment.utc(props.project_start).isAfter(moment.utc(details.resource_project_startdate))){
                updateDetails('resource_project_startdate',moment.utc(props.project_start))
            }
    
            if(moment.utc(props.project_start).isAfter(moment.utc(details.resource_project_enddate))){
                updateDetails('resource_project_enddate',moment.utc(props.project_start))
            }
        }
        if(props.project_end){
            if(moment.utc(props.project_end).isBefore(moment.utc(details.resource_project_enddate))){
                updateDetails('resource_project_enddate',moment.utc(props.project_end))
            }
    
            if(moment.utc(props.project_end).isBefore(moment.utc(details.resource_project_startdate))){
                updateDetails('resource_project_startdate',moment.utc(props.project_end))
            }
        }
        
    },[props.project_end, props.project_start])

    const updateDetails = (key, value) => {
        let newDetails = {...details}
        newDetails[key] = value
        setDetails(newDetails)
        props.onUpdate(newDetails)
    }

    return (
        <Grid item container xs={12} sx={{mt:'0.5%'}}>
            <Box sx={{width:"100%", height:"100%", boxShadow:3, padding:'1%', border:1, borderRadius:2}}>
            <Grid container sx={{flexDirection:'row'}}>
                <Grid container item xs={10.5}>
                    <Grid container tiem xs={fieldSize} sx={{flexDirection:'row', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                        <Grid item container xs={3} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <Avatar/>
                        </Grid>
                        <Grid item container xs={9} sx={{alignItems:'center'}}>
                            <Typography>
                                {details.first_name} {details.last_name}
                            </Typography>
                        </Grid>
                    </Grid>    
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                    
                        <DatePicker 
                        label="Start Date"
                        value={details.resource_project_startdate ? moment.utc(details.resource_project_startdate) : moment.utc(props.project_start)}
                        maxDate={details.resource_project_enddate ? moment.utc(details.resource_project_enddate) : null}
                        minDate={props.project_start ? moment.utc(props.project_start) : null}
                        onChange={(value) => updateDetails("resource_project_startdate",value ? moment.utc(value) : null)}
                        renderInput={(params)=><TextField fullWidth {...params} InputLabelProps={{shrink:true}}/>}/>
                    </Grid>
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                     
                        <DatePicker 
                        label="End Date"
                        value={details.resource_project_enddate ? moment.utc(details.resource_project_enddate) : moment.utc('2099-12-31')}
                        minDate={details.resource_project_startdate ? moment.utc(details.resource_project_startdate) : null}
                        maxDate={props.project_end ? moment.utc(props.project_end) : null}
                        onChange={(value) => updateDetails("resource_project_enddate",value ? moment.utc(value) : null)}
                        renderInput={(params)=><TextField fullWidth InputLabelProps={{shrink:true}} {...params}/>}/>
                    
                    </Grid>
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                        <TextField select fullWidth label="Resource Type" required value={!details.resource_type ? updateDetails('resource_type', '8'):details.resource_type} 
                        onChange={(event) => {event.preventDefault(); updateDetails('resource_type', event.target.value)}}>
                            {props.detail_types.length > 0 ?  props.detail_types.map(type => (
                            <MenuItem value={type.category_id}>{type.category_name}</MenuItem>
                            ))
                            : <MenuItem value={{}}>Error</MenuItem>}
                        </TextField>
                    </Grid>
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                        <TextField select fullWidth label="Sales" value={details.sales?details.sales:updateDetails('sales', '1')} 
                        onChange={(event) => {event.preventDefault(); updateDetails('sales', event.target.value)}}>
                            {props.recruiters.length > 0 ?  props.recruiters.map(recruiter => (
                            <MenuItem value={recruiter.id}>{recruiter.user_name}</MenuItem>
                            ))
                            : <MenuItem value={{}}>Error</MenuItem>}
                        </TextField>
                    </Grid>
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                            <TextField label="Billing Entity"
                            select
                            fullWidth
                            required
                            value={details.billing_entity ? details.billing_entity : ""}
                            onChange={(e) => updateDetails('billing_entity',e.target.value)}>
                                <MenuItem value='CAN'>CAN</MenuItem>
                                <MenuItem value='IND'>IND</MenuItem>
                                <MenuItem value='USA'>USA</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                            <TextField label="Paying Entity"
                            select
                            fullWidth
                            required
                            value={details.paying_entity ? details.paying_entity : ""}
                            onChange={(e) => updateDetails('paying_entity',e.target.value)}>
                                <MenuItem value='CAN'>CAN</MenuItem>
                                <MenuItem value='IND'>IND</MenuItem>
                                <MenuItem value='USA'>USA</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                            <TextField label="Pay Currency"
                            select
                            fullWidth
                            required
                            value={details.pay_currency ? details.pay_currency : ""}
                            onChange={(e) => updateDetails('pay_currency',e.target.value)}>
                                {props.currencies.length > 0 ? props.currencies.map((currency) => (
                                    <MenuItem value={currency.category_id}>{currency.category_name}</MenuItem>
                                )) : <></>}
                            </TextField>
                        </Grid>
                        <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                            <TextField label="Bill Currency"
                            select
                            fullWidth
                            required
                            value={details.bill_currency ? details.bill_currency : ""}
                            onChange={(e) => updateDetails('bill_currency',e.target.value)}>
                                {props.currencies.map((currency)=>(
                                    <MenuItem value={currency.category_id}>{currency.category_name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                            <TextField label="Pay Type"
                            select
                            fullWidth
                            required
                            value={details.pay_type ? details.pay_type : ""}
                            onChange={(e) => updateDetails('pay_type',e.target.value)}>
                                {props.pay_types.map((type)=>(
                                    <MenuItem value={type.category_id}>{type.category_name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                        <TextField select fullWidth label="Recruiter"  value={details.recruiter?details.recruiter:updateDetails('recruiter', '1')} 
                        onChange={(event) => {event.preventDefault(); updateDetails('recruiter', event.target.value)}}>
                            {props.recruiters.length > 0 ?  props.recruiters.map(recruiter => (
                            <MenuItem value={recruiter.id}>{recruiter.user_name}</MenuItem>
                            ))
                            : <MenuItem value={{}}>Error</MenuItem>}
                        </TextField>
                    </Grid>
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                        <TextField select fullWidth label="Sourcer" value={details.sourcer?details.sourcer:updateDetails('sourcer', '1')} 
                        onChange={(event) => {event.preventDefault(); updateDetails('sourcer', event.target.value)}}>
                            {props.recruiters.length > 0 ?  props.recruiters.map(recruiter => (
                            <MenuItem value={recruiter.id}>{recruiter.user_name}</MenuItem>
                            ))
                            : <MenuItem value={{}}>Error</MenuItem>}
                        </TextField>
                    </Grid>
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                        <TextField select fullWidth label="Account Manager" value={details.account_manager?details.account_manager:updateDetails('account_manager', '1')} 
                        onChange={(event) => {event.preventDefault(); updateDetails('account_manager', event.target.value)}}>
                            {props.recruiters.length > 0 ?  props.recruiters.map(recruiter => (
                            <MenuItem value={recruiter.id}>{recruiter.user_name}</MenuItem>
                            ))
                            : <MenuItem value={{}}>Error</MenuItem>}
                        </TextField>
                    </Grid>
                    <Grid item xs={fieldSize} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                        <FormGroup>
                            <FormControlLabel label="MYTA Resource"
                            control={<Checkbox defaultChecked={details.myta_resource !== undefined ? details.myta_resource : false} 
                                onChange={(e) => updateDetails('myta_resource', e.target.checked)}/>}/>
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid item xs={1.5} container sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0.25%'}}>
                    <FormGroup>
                        <FormControlLabel label="Subscribed to reminders"
                        control={<Checkbox defaultChecked={details.subscribed !== undefined ? details.subscribed : false} 
                            onChange={(e) => updateDetails('subscribed', e.target.checked)}/>}/>
                    </FormGroup>
                </Grid>
            </Grid>
            </Box>
        </Grid>
    )
}

ResourceDetailsBar.propTypes = {
    /**
     * object representing a ProjectDetails row, following db schema
     */
    details: PropTypes.object.isRequired,
    /**
     * the various types of resources (GeneralCategory objects following db schemas)
     */
    detail_types: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * function alled when the resource details bar is updated, passes new ProjectDetails object, follwing db schema
     */
    onUpdate: PropTypes.func.isRequired,
    /**
     * start date of project, passed as a moment time object
     */
    project_start: PropTypes.instanceOf(moment),
    /**
     * end date of project, passed as a moment time object
     */
    project_end: PropTypes.instanceOf(moment),
    /**
     * available recruiters / sourcers / account_managers of project details
     */
    recruiters : PropTypes.arrayOf(PropTypes.object).isRequired
}

ResourceDetailsBar.defaultProps = {
    detail_types: []
}

export default ResourceDetailsBar