import { getAmplifyToken } from "./AmplifyHelp"
import { handleError } from "./generalHelp"

export function submitExpenseForm(requestObj, callback,  return_url=window.location.origin.toString(), abortCont){
    requestObj.return_url = return_url
    getAmplifyToken((jwt) =>{
      fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/expense/submit`,
      {
        method:'POST',
        body: JSON.stringify(requestObj),
        headers: { "Content-Type": "application/json", "Authorization": jwt },
        signal: abortCont ? abortCont.signal: undefined
      }).then(resp => resp.json()).then(json=>callback(json))
      .catch(err => handleError(err,callback))
    })
  }

export function saveExpenseForm(requestObj, callback){
  getAmplifyToken((jwt)=>{
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/expense/save`,
      {
        method:'PUT',
        body: JSON.stringify(requestObj),
        headers: { "Content-Type": "application/json", "Authorization": jwt },
        // signal: abortCont ? abortCont.signal: undefined
      }).then(resp => callback(resp))
      .catch(err => handleError(err,callback))
    })
}

export function getExpenseAuthorities(abortCont, callback){
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/expense/getauthorities`,
    {
      method:'GET',
      headers: {'Authorization':jwt},
      signal: abortCont ? abortCont.signal : undefined
    }).then(resp => resp.json()).then(json => callback(json))
    .catch(err => handleError(err, callback))
  })
}

export function getAllExpenses(abortCont, callback){
  getAmplifyToken((jwt) => {
    fetch(`${process.env.REACT_APP_TIMESHEET_API}/con/expense/get`,
    {
      method:'GET',
      headers: {'Authorization':jwt},
      signal: abortCont ? abortCont.signal : undefined
    }).then(resp => resp.json()).then(json => callback(json))
    .catch(err => handleError(err, callback))
  })
}