import React from 'react';
import {PropTypes} from 'prop-types'
import TextPopUp from './TextPopUp';

/**
 * pop used to display an error
 * @component
 * @category Pop Ups 
 */
function ErrorPopUp(props) {
    return (
        <TextPopUp {...props} title={props.errorTitle} content={props.errorContent} titleColor='red'/>
    );
}

ErrorPopUp.propTypes = {
    /**
     * whether or not the popup is open
     */
    open : PropTypes.bool.isRequired,
    /**
     * function called upon closing the popup, returns clicking event e
     */
    onClose : PropTypes.func.isRequired,
    /**
     * title of error to be displayed
     */
    errorTitle: PropTypes.string,
    /**
     * content of error message
     */
    errorContent:PropTypes.string
}

ErrorPopUp.defaultProps = {
    errorTitle: "Uh Oh!",
    errorContent: "Something went wrong on our end, please try again."
}

export default ErrorPopUp;