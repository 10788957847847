import React, { useState } from 'react';
import {Button, Menu, MenuItem, Box, Typography, Avatar} from '@mui/material'
import {Auth} from 'aws-amplify'
import { useNavigate, useLocation } from "react-router-dom";
import HoverButton from '../HoverButton'
import PropTypes from 'prop-types'
import { AccountCircle } from '@mui/icons-material';
import ClickBox from '../ClickBox';
import { fonts } from '../../util/Theme';

/**
 * Drop down used to represent the different options for a user (profile, logout, etc)
 * @component 
 * @category Headers and Footers 
 * @subcategory NavBar
 */
function Options(props) {
    const navigate = useNavigate()
    const [openDropDown, setOpenDropDown] = useState(false)
    const [anchoredElement, setAnchoredElement] = useState(false)

    const handleClick = (event) =>{
        event.preventDefault()
        setOpenDropDown(true)
        setAnchoredElement(event.target)
    }

    const handleProfile = () =>{
        navigate('/home/profile')
    }
    const handleLogOut = async () =>{
        try{
            await Auth.signOut()
            navigate('/home')
            window.location.reload(false)
            
        }catch(exception){
            console.error(exception)
        }
    }
    return (
        <div>
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
            <ClickBox onClick={handleClick} sx={{display:'flex'}} showClickable={false}>
                <Avatar sx={{ justifyContent:'center', alignItems:'center',
                    bgcolor: props.selected || openDropDown ? props.hoverColor : 'coral', '&:hover':{bgcolor:props.hoverColor}}} src={props.profile.profile_picture}>
                {/* <Avatar src="https://seq-test-invoice-upload.s3.amazonaws.com/test%20image.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYEU5M75QIKZBTB57%2F20220322%2Fca-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220322T170229Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=69d24e1ff8ba70ffd4e85a5628389d625f6b77962fd706a71b1f9299fea5c733"> */}
                    {!props.profile.profile_picture? `${props.profile.first_name[0]}${props.profile.last_name[0]}` : <></>}
                </Avatar>
            </ClickBox>
            </Box>
            <Menu open={openDropDown} anchorEl={anchoredElement} onClose={(event)=>setOpenDropDown(false)}>
                <MenuItem onClick={handleProfile}>
                    <Typography fontSize={fonts.menuSize}>Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogOut}>
                    <Typography fontSize={fonts.menuSize}>Logout</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}

Options.propTypes ={
    /**
     * whether or not the options have been clicked on
     */
    selected: PropTypes.bool,
    /**
     * color for options to turn when hovered over / clicked 
     */
    hoverColor: PropTypes.string,
    /**
     * object representing the Resoure logged in (object that follows same schema as resource in db)
     */
    profile: PropTypes.object
    //profilePicture: PropTypes.string
}

Options.defaultProps = {
    selected: false,
    hoverColor:'secondary.main'
}

export default Options;