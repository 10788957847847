import { createTheme } from "@mui/material";

export const mainLightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#02375a',
    },
    secondary: {
      main: '#ff6600',
    },
    background: {
      default: '#f9f9f9',
    },
    warning: {
      main: '#fffc00',
    },
    error: {
      main: '#a20f05',
    },
    text: {
      primary: '#000000',
      secondary: '#ff6600',
    },
    view: {
        main: '#FAFAFA'
    }
  },
});

export const fonts = {
  defaultSize:18,
  labelSize:18,
  buttonSize:15,
  welcomeButtonSize:16,
  projectListSize:20,
  tableSize: 16,
  menuSize:18,
  snackBarSize: 18
}