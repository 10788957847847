import React from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PropTypes from 'prop-types'
/**
 * Simple add icon that acts as a button
 * 
 * @component
 * @category Stylized Components
 */
 function AddButton(props){

    return(
        <AddCircleIcon onClick={props.onClick} disabled={props.disabled}
        sx={{color:props.color, '&:hover':{color:props.hoverColor}, cursor:'pointer'}}/>
    )
}

AddButton.propTypes = {
    /**
     * function called when button is clicked, parameter to function is the clicking event, e
     */
    onClick: PropTypes.func.isRequired,
    /**
     * color of the button normally
     */
    color: PropTypes.string,
    /**
     * color of the button when mouse is hovered over it
     */
    hoverColor: PropTypes.string
}

AddButton.defaultProps = {
    color: 'gray',
    hoverColor:'green'
}

export default AddButton
