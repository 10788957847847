import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import HoverButton from './HoverButton'
import {fonts} from '../util/Theme'
import {getParent} from '../util/generalHelp'
/**
 * A button that navigates to the previous page
 * @component
 * @category Functionality
 */
function BackButton(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HoverButton
      fullWidth={props.fullWidth}
      color={props.color}
      variant={props.variant}
      fontSize={props.fontSize}
      onClick={(event) => navigate(-1)}
    >
      {props.children}
    </HoverButton>
  );
}

BackButton.propTypes = {
  /**
   * the type of mui buttont that will be taken on by the button
   */
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  /**
   * color of the button
   */
  color: PropTypes.string,
  /**
   * whether or not the button is fullWidth
   */
  fullWidth: PropTypes.bool,
  /**
   * fontsize of text in button
   */
  fontSize: PropTypes.number
};
BackButton.defaultProps = {
  variant: "outlined",
  fullWidth:false,
  fontSize: fonts.buttonSize
};

export default BackButton;
