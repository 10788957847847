import React, {useEffect, useState} from "react";
import FormattedInputLabel from '../Formatted/FormattedInputLabel';
import FormattedTextField from '../Formatted/FormattedTextField';
import { fileTo64, isObjectEmpty } from '../../util/generalHelp';
import TextPopUp from '../Pop_ups/TextPopUp';
import { DatePicker } from '@mui/lab';
import {Grid, Button, FormControl, MenuItem, Select, InputLabel, TextField, Typography} from '@mui/material'
import moment from "moment";
import PropTypes from 'prop-types'

/**
 * form used to fill out an invoice
 * @component 
 * @category Invoice Management 
 */
function InvoiceForm(props){

    const [errorMessage, setErrorMessage] = useState(undefined)
    const [errorTitle, setErrorTitle] = useState(undefined)
    const [invoice, setInvoice] = useState(props.invoice)
    // const [timesheet, setTimesheet] = useState({})
    const [error, setError] = useState()

    useEffect(()=>{
        //console.log('updating invoice in form to', props.invoice)
        setInvoice(props.invoice)
    },[props.invoice])

    // const changeTimesheetField=(field, value)=>{
    //     let newTimesheet = Object.assign({},timesheet)
    //     newTimesheet[field] = value
    //     setTimesheet(newTimesheet)
    // }

    const changeInvoiceField=(field, value)=>{
        let newInvoice = {...invoice}
        newInvoice[field] = value
        updateInvoiceForm(newInvoice)
        
    }

    const handleFile = (event) =>{
        let file = event.target.files[0]
        if(!file){
            return
        }
        if(file.type !== "application/pdf"){
            setErrorTitle("File Error")
            setErrorMessage("File must be a pdf")
            setError(true)
            return
        }
        //setFile(file)
        fileTo64(file, (base64) => changeInvoiceField('invoice_document',{name:file.name, b64:base64}))
    }
    
    const updateInvoiceForm = (newInv) => {
        setInvoice(newInv)
        props.onUpdate(newInv)
    }

    return(
        <>
        <Grid item container xs={1.5} sx={{m:'0.25%'}}>
                <TextField
                labelId='invoice_invoiced_number'
                label="Invoice Number"
                error={error}
                fullWidth
                value={invoice.invoice_number || ""}
                onChange={(event) => {changeInvoiceField('invoice_number', event.target.value)}}/>
            </Grid>
            <Grid item container xs={1.5} sx={{m:'0.25%'}}>
                <DatePicker disabled={!invoice.invoice_number} label="Invoice Date" value={invoice.invoice_number && invoice.invoice_date ? moment.utc(invoice.invoice_date) : null} 
                onChange={(value) => changeInvoiceField('invoice_date',moment.utc(value).format('YYYY-MM-DD').toString())} 
                renderInput={(params) => <FormattedTextField {...params} backgroundColor='white' />}  maxDate={moment.utc()}/>
            </Grid>
            <Grid item container xs={1.5} sx={{m:'0.25%'}}>
                <FormControl sx={{width:'100%'}}>
                    <FormattedInputLabel id='invoice_payment_made' backgroundColor='white'>Payment Made</FormattedInputLabel>
                <Select
                labelId='invoice_Payment_made'
                label="Payment Made"
                fullWidth
                value={Boolean(invoice.payment_made)}
                onChange={(event) => {event.preventDefault(); changeInvoiceField('payment_made', event.target.value)}}>
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
                </FormControl>
            </Grid>
            <Grid item container xs={1.5} sx={{m:'0.25%'}}>
                <DatePicker disabled={!invoice.payment_made} value={invoice.payment_made && invoice.payment_date? moment.utc(invoice.payment_date) : null} label="Pay Date"
                onChange={(value) => changeInvoiceField('payment_date',moment.utc(value).format('YYYY-MM-DD').toString())} 
                renderInput={(params) => <TextField {...params}  backgroundColor='white'/>}  maxDate={moment.utc()}/>
            </Grid>
            <Grid item container xs={1.5} sx={{m:'0.25%'}}>
                <FormControl sx={{width:'100%'}}>
                    <FormattedInputLabel id='invoice_consultant_paid' backgroundColor='white'>Consultant Paid</FormattedInputLabel>
                <Select
                labelId='invoice_consultant_paid'
                label="Consultant Paid"
                fullWidth
                value={Boolean(invoice.consultant_paid)}
                onChange={(event) => {event.preventDefault(); changeInvoiceField('consultant_paid', event.target.value)}}>
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
                </FormControl>
            </Grid>
            <Grid item container xs={1.5} sx={{m:'0.25%'}}>
                <DatePicker disabled={!invoice.consultant_paid} label="Con. Pay Date" value={invoice.consultant_paid && invoice.consultant_paid_date ? moment.utc(invoice.consultant_paid_date): null} 
                onChange={(value) => changeInvoiceField('consultant_paid_date',moment.utc(value).format('YYYY-MM-DD').toString())} 
                renderInput={(params) => <TextField {...params} backgroundColor='white'/>}  maxDate={moment.utc()}/>
            </Grid>

            {/* <Grid item container xs={1.5} sx={{m:'0.25%'}}>
                <FormControl sx={{width:'100%'}}>
                    <FormattedInputLabel id='status' backgroundColor='white'>Status</FormattedInputLabel>
                <Select
                labelId='status'
                label="Status"
                fullWidth
                value={timesheet.status}
                onChange={(event) => {event.preventDefault(); changeTimesheetField('status', event.target.value)}}>
                    <MenuItem value={'pending'}>Pending</MenuItem>
                    <MenuItem value={'submitted'}>Submitted</MenuItem>
                </Select>
                </FormControl>
            </Grid> */}
            <Grid item container xs={2} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Button variant='contained'
            component='label'
            >
                Add Invoice Document
                <input type='file' hidden onChange={(event)=> handleFile(event)}/>
            </Button> 
            <Typography align='center'>{Boolean(invoice.invoice_document) && invoice.invoice_document.name ? 
            invoice.invoice_document.name : "no file selected"}</Typography>
            </Grid>
        </>
    )
}

InvoiceForm.propTypes = {
    /**
     * object representing the Invoice (according to db schema)
     */
    invoice: PropTypes.object.isRequired,
    /**
     * function called when invoice is updated, passes object representing invoice with new info 
     */
    onUpdate: PropTypes.func.isRequired
}

export default InvoiceForm