import React from "react";
import {Snackbar, Alert} from '@mui/material'
import PropTypes from 'prop-types'
import {fonts} from '../util/Theme'
/**
 * snackbar used to indicate whether something was successful
 * @component
 * @category Stylized Components
 */
function SuccessSnackBar(props) {
  return (
    <Snackbar {...props}>
      <Alert onClose={props.onClose} severity="success" sx={{ width: "100%", fontSize:fonts.snackBarSize}}>
        {props.successmessage}
      </Alert>
    </Snackbar>
  );
}

SuccessSnackBar.propTypes={
  /**
   * message to be displaced alongside success status
   */
    successmessage: PropTypes.string
}

SuccessSnackBar.defaultProps = {
    successmessage: 'Success!'
}

export default SuccessSnackBar;
