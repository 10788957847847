import React, { useEffect } from 'react'
import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import DetailsDisplayBar from './DetailsDisplayBar'

/**
 * Panel inside ManageResourceModal used to display all DetailsDisplayBar
 * @component
 * @category Resource Details Management
 */
function ResourceDetailsPanel(props){
    return(
    <Box sx={{width:'100%',height:'100%'}}>
        {props.title ? <Typography align='center'>{props.title}</Typography> : <></>}
    <Box sx={{boxShadow:4, border:0.5, borderRadius:2, width:'100%', height:'100%', overflowY:'auto'}}>
        <Grid container sx={{justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column', padding:'0.25%'}}>
                {props.details ? props.details.map((detail) => (
                    <Grid item container xs={12} sx={{padding:'1%', mt:'0.5%', border:1, 
                    borderRadius: 2,
                    backgroundColor: detail.invalid ? '#E8E8E8' : undefined}}>
                        <DetailsDisplayBar details={detail} direction={props.direction} onClick={props.onUpdate}
                        disabled={detail.invalid}/>
                    </Grid>
                )) : <></>}
        </Grid>
    </Box>
    </Box>
    )
}

ResourceDetailsPanel.propTypes = {
    /**
     * list of ProjectDetails objects (following db schema) that are to be displayed in the panel
     */
    details: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * way each DetailsDisplayBar should have their chevron pointing
     */
    direction: PropTypes.oneOf(['right', 'left']),
    /**
     * function called when chevron on DetailsDisplayBar is clicked, passes the ProjectDetails object
     */
    onUpdate: PropTypes.func.isRequired,
    /**
     * title of the panel
     */
    title: PropTypes.string
}
ResourceDetailsPanel.defaultProps = {
    direction : 'right'
}

export default ResourceDetailsPanel