import { Typography, Box, Grid, Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import AdminSubmittedDisplay from "../../components/Submitted/AdminSubmittedDisplay";
import moment from "moment";
import ToFrom from "../../components/ToFrom";
import AdminSearchBar from "../../components/Submitted/AdminSearchBar";
import {
  getAllTimesheets,
  getHoursByTsId,
  getHoursByTsIdNoPadding,
  getStrictTsHours,
} from "../../util/newTimeSheetHelper";
import ErrorPopUp from "../../components/Pop_ups/ErrorPopUp";
import LoadingPopUp from "../../components/Pop_ups/LoadingPopUp";
import { useNavigate } from "react-router-dom";
import { get_start_date, isObjectEmpty } from "../../util/generalHelp";
import PropTypes from "prop-types";
import { getInvoiceDocumentUrl } from "../../util/invoiceHelper";
import { fonts } from "../../util/Theme";
import TimesheetPopUp from "../../components/Pop_ups/TimesheetPopUp";
import SuccessSnackBar from "../../components/SuccessSnackBar";

/**
 * Page responsible for displaying timesheets on the admin end
 * @component
 * @category Pages
 * @subcategory Admin 
 */
function AdminSubmitted(props) {
  const [timesheets, setTimesheets] = useState([]);
  const [nameLists, setNameLists] = useState({});
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [periodToFrom, setPeriodToFrom] = useState([undefined, undefined]);
  const [submittedToFrom, setSubmittedToFrom] = useState([
    undefined,
    undefined,
  ]);
  const [error, setError] = useState(false);
  const [currentTimeSheet, setCurrentTimeSheet] = useState({});
  const [infoObj, setInfoObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchObj, setSearchObj] = useState({});
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [columnClick, setColumnClick] = useState(false)
  const [success, setSuccess] = useState("")

  const navigate = useNavigate();

  useEffect(() => {
    let abortCont = new AbortController();
    if (!hasLoaded) {
      setLoading(true);
      getTimesheets(abortCont);
      setLoading(false);
    }
    return () => abortCont.abort();
  });
  

  const getTimesheets = (abortController) => {
    getAllTimesheets(abortController, (response) => {
      if (response) {
        setTimesheets(response.timesheets);
        const nameList = {
          resources: response.resources,
          projects: response.projects,
          clients: response.clients,
        };
        setNameLists(nameList);
        setHasLoaded(true);
      } else {
        setError(true);
      }
    });
  };

  useEffect(() => {
    handleSearch();
  }, [timesheets]);

  const handleDates = (to, from, type = "period") => {
    if (to) to = moment(to).format("YYYY-MM-DD").toString();
    if (from) from = moment(from).format("YYYY-MM-DD").toString();
    if (type === "period") setPeriodToFrom([to, from]);
    else if (type === "submitted") setSubmittedToFrom([to, from]);
  };

  useEffect(() => {
    //console.log('searchObj', searchObj)
    handleSearch();
    setColumnClick(!columnClick)
  }, [searchObj, periodToFrom, submittedToFrom]);

  const handleSearch = () => {
    let tempTimesheets = timesheets.filter((ts) => {
      let query = true;
      if (searchObj.resources) {
        if (!searchObj.resourceString)
          query = query && searchObj.resources.includes(ts.resource.id);
        else
          query =
            (query && searchObj.resources.includes(ts.resource.id)) ||
            `${ts.resource.first_name} ${ts.resource.last_name}`
              .toLowerCase()
              .includes(searchObj.resourceString);
      } else if (searchObj.resourceString) {
        query =
          query &&
          `${ts.resource.first_name} ${ts.resource.last_name}`
            .toLowerCase()
            .includes(searchObj.resourceString);
      }

      if (searchObj.projects) {
        if (!searchObj.projectString)
          query = query && searchObj.projects.includes(ts.project.id);
        else
          query =
            query &&
            (searchObj.projects.includes(ts.project.id) ||
              ts.project.project_name
                .toLowerCase()
                .includes(searchObj.projectString));
      } else if (searchObj.projectString) {
        query =
          query &&
          ts.project.project_name
            .toLowerCase()
            .includes(searchObj.projectString);
      }

      if (searchObj.clients) {
        if (!searchObj.clientString)
          query = query && searchObj.clients.includes(ts.client.id);
        else
          query =
            query &&
            (searchObj.clients.includes(ts.client.id) ||
              ts.client.short_name
                .toLowerCase()
                .includes(searchObj.clientString));
      } else if (searchObj.clientString) {
        query =
          query &&
          ts.client.short_name.toLowerCase().includes(searchObj.clientString);
      }
      if (searchObj.status)
        query = query && searchObj.status.includes(ts.timesheet.status);
      // if (searchObj.invoice) query = (query && searchObj.invoice.includes(ts.invoice.invoiced))
      if(searchObj.invoiced && searchObj.invoiced !== 'Both'){
        //console.log('searchObj.invoiced',searchObj.invoiced)
        query = query && (ts.invoice.some((inv) => Boolean(inv.invoice_number) === Boolean(searchObj.invoiced === 'Invoiced') ))
      }

      if (searchObj.invoice_number) {
        query =
          query &&
          ts.invoice.some((inv) => String(inv.invoice_number).includes(searchObj.invoice_number));
      }

      if (searchObj.payment)
        query = query && ts.invoice.some((inv) => searchObj.payment.includes(inv.payment_made))
      if (periodToFrom[0])
        query =
          query &&
          moment(ts.timesheet.period_start_date).toDate() <=
            moment(periodToFrom[0]).toDate();
      if (periodToFrom[1])
        query =
          query &&
          moment(ts.timesheet.period_start_date).toDate() >=
            get_start_date(periodToFrom[1], ts.project.timesheet_frequency);
      if (submittedToFrom[0])
        query =
          query &&
          moment(ts.timesheet.submitted_date).toDate() <=
            moment(submittedToFrom[0]).toDate();
      if (submittedToFrom[1])
        query =
          query &&
          moment(ts.timesheet.submitted_date).toDate() >=
            moment(submittedToFrom[1]).toDate();
      return query;
    });
    setFilteredTimesheets(tempTimesheets);
  };

  const handleTs = (ts) => {
    setLoading(true);
    getStrictTsHours(ts.timesheet.id, (resp) => {
      if (resp === undefined) {
        setError(true);
        return;
      }
      setCurrentTimeSheet(resp.full);
      let infoObj = {
        period_start_date: ts.timesheet.period_start_date,
        days_worked: ts.days,
        hours_worked: ts.hours,
        project_name: ts.project.project_name,
        submitted_date: ts.timesheet.submitted_date,
        status: ts.timesheet.status,
        envelope_ids: resp.envelope_ids,
        admin_submitted: ts.timesheet.admin_submitted,
        standard_vacation: ts.project.standard_vacation,
        notes: resp.note_text
      };
      setInfoObj(infoObj);
    });
    setLoading(false);
  };

  const handleInvoice = (filename) => {
    getInvoiceDocumentUrl(filename, (url) => {
      if (!url) {
        setError(true);
        return;
      }
      window.open(url);
    });
  };
  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = filteredTimesheets.sort((a, b) => {
        if (sortField === "resource") {
          return (
            a.resource.first_name
              .toString()
              .localeCompare(b.resource.first_name.toString()) *
            (sortOrder === "asc" ? 1 : -1)
          );
        }
        if (sortField === "project") {
          return (
            a.project.project_name
              .toString()
              .localeCompare(b.project.project_name.toString()) *
            (sortOrder === "asc" ? 1 : -1)
          );
        }
        if (sortField === "client") {
          return (
            a.client.short_name
              .toString()
              .localeCompare(b.client.short_name.toString()) *
            (sortOrder === "asc" ? 1 : -1)
          );
        }
        if (sortField === "period") {
          return (
            a.timesheet.period_start_date
              .toString()
              .localeCompare(b.timesheet.period_start_date.toString()) *
            (sortOrder === "asc" ? 1 : -1)
          );
        }
        if (sortField === "submitted_on") {
            let a_submitted = a.timesheet.submitted_date || -1
            let b_submitted = b.timesheet.submitted_date || -1
            moment(a_submitted).format("YYYY-MM-DD").toString();
          return (
            moment(a_submitted).format("YYYY-MM-DD").toString().localeCompare(moment(b_submitted).format("YYYY-MM-DD").toString()) *
            (sortOrder === "asc" ? 1 : -1)
          );
        }
        if (sortField === "hours") {
          return (
            a.hours.toString().localeCompare(b.hours.toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
          );
        }
        if (sortField === "days") {
          return (
            a.days.toString().localeCompare(b.days.toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
          );
        }
        if (sortField === "invoice_number") {
        //   if (
        //     !a.invoice.invoice_number
        //   ) {
        //     return 1;
        //   }
        //   if (
        //     !b.invoice.invoice_number
        //   ) {
        //     return -1;
        //   }

        //   if (!a.invoice.invoice_number && !b.invoice.invoice_number) {
        //     return 0;
        //   }
        let a_invoice = a.invoice.invoice_number || -1
        let b_invoice= b.invoice.invoice_number || -1

          return (
            a_invoice
              .toString()
              .localeCompare(b_invoice.toString(), "en", {
                numeric: true,
              }) * (sortOrder === "asc" ? 1 : -1)
          );
        }

        if (sortField === "payment_made") {
          return (
            a.invoice.payment_made
              .toString()
              .localeCompare(b.invoice.payment_made.toString()) *
            (sortOrder === "asc" ? 1 : -1)
          );
        }

        if (sortField === "consultant_paid") {
          return (
            a.invoice.consultant_paid
              .toString()
              .localeCompare(b.invoice.consultant_paid.toString()) *
            (sortOrder === "asc" ? 1 : -1)
          );
        }
        if (sortField === "status") {
          let a1, b1;
          if (a.timesheet.status === "pending") a1 = 1;
          if (b.timesheet.status === "pending") b1 = 1;
          if (a.timesheet.status === "submitted") a1 = -1;
          if (b.timesheet.status === "submitted") b1 = -1;
          return (
            a1.toString().localeCompare(b1.toString()) *
            (sortOrder === "asc" ? 1 : -1)
          );
        }
      });

      setFilteredTimesheets(sorted);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{ padding: "0.5%", justifyContent: "center", alignItems: "center" }}
      >
        <LoadingPopUp open={loading || !hasLoaded} />
        <ErrorPopUp
          open={error}
          onClose={(event) => {
            event.preventDefault();
            setError(false);
          }}
        />
        <TimesheetPopUp
          open={!isObjectEmpty(currentTimeSheet)}
          onClose={() => {
            setCurrentTimeSheet({});
            setInfoObj({});
          }}
          infoObj={infoObj}
          timesheets={currentTimeSheet}
          beenSubmitted={true}
        />
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Timesheet View
          </Typography>
        </Grid>

        <Button
          sx={{ marginBottom: "5px", marginTop: "20px" }}
          variant="outlined"
          onClick={() => {
            setFilterExpanded(!filterExpanded);
          }}
        >
          Filters
        </Button>

        {filterExpanded ? (
          <>
            <Grid
              item
              container
              xs={12}
              sx={{
                m: "1%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AdminSearchBar
                listOfProj={nameLists.projects}
                listOfResources={nameLists.resources}
                listOfClients={nameLists.clients}
                statusEnabled
                invoiceEnabled
                onUpdate={(data) => setSearchObj(data)}
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Grid
                item
                container
                sx={{
                  m: "1%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={fonts.defaultSize}>
                  Period Date
                </Typography>
                <ToFrom
                  onUpdate={(to, from) => handleDates(to, from, "period")}
                />
              </Grid>
              <Grid
                item
                container
                sx={{
                  m: "1%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={fonts.defaultSize}>
                  Submitted Date
                </Typography>
                <ToFrom
                  onUpdate={(to, from) => handleDates(to, from, "submitted")}
                />
              </Grid>
            </Box>
          </>
        ) : (
          ""
        )}
        <Grid item container xs={12}>
          {timesheets.length > 0 ? (
            <>
            <AdminSubmittedDisplay
              timesheets={filteredTimesheets}
              handleSorting={handleSorting}
              onEnvelope={(ts) => handleTs(ts)}
              onClickInvoice={handleInvoice}
              profile={props.profile}
              onUpdate={() => getTimesheets()}
              columnClick={columnClick}
              onSuccess={setSuccess}
            />
            <SuccessSnackBar
              open={Boolean(success)}
              onClose={(event) => {
                event.preventDefault();
                setSuccess("");
              }}
              successmessage={success}
            />
            </>
          ) : (
            <Grid item xs={12}>
            <Typography variant="h4" align="center">
              no timesheets found
            </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

AdminSubmitted.propTypes = {
  /**
   * profile of currently logged in resource (object following db schema for resource)
   */
  profile: PropTypes.object.isRequired,
};

export default AdminSubmitted;
